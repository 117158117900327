export interface WixMediaPlatformServicesUrls {
    managementService: string;
    fileService: string;
    imageService: string;
    videoService: string;
}

export function wixMediaPlatformGetImagesServiceDomain (projectId: string) {
    return `https://img-wixmp-${projectId}.wixmp.com`
}

export function wixMediaPlatformGetVideoServiceDomain (projectId: string) {
    return `https://repackager-${projectId}.wixmp.com`
}

export function wixMediaPlatformGetFileServiceDomain (projectId: string) {
    return `https://wixmp-${projectId}.wixmp.com`
}

export function wixMediaPlatformGetManagementServiceDomain (projectId: string) {
    return `wixmp-${projectId}.appspot.com`
}

export function wixMediaPlatformResolveServicesUrls (mediaPlatformProjectId: string) : WixMediaPlatformServicesUrls {
    return {
        managementService: `wixmp-${mediaPlatformProjectId}.appspot.com`,
        fileService: `http://wixmp-${mediaPlatformProjectId}.wixmp.com`,
        imageService: `http://img-wixmp-${mediaPlatformProjectId}.wixmp.com`,
        videoService: `http://repackager-${mediaPlatformProjectId}.wixmp.com`
    };
}