import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeMembershipList} from "./doppe-action-type-membership-list";

export const doppeActionTypeMembershipListViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeMembershipList, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeMembershipListWidget: (await import(
                './doppe-action-type-membership-list-widget/doppe-action-type-membership-list-widget'
                )).DoppeActionTypeMembershipListWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeMembershipListWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeMembershipListWidget
                {...renderProps}
            />
        );

    }
})