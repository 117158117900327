import {assertDefined} from "@wix/devzai-utils-common";
import {validateIsFQDN} from './validate-is-fqdn'
import {validateIsIp} from './validate-is-ip'

/*
options for isURL method

require_protocol - if set as true isURL will return false if protocol is not present in the URL
require_valid_protocol - isURL will check if the URL's protocol is present in the protocols option
protocols - valid protocols can be modified with this option
require_host - if set as false isURL will not check if host is present in the URL
require_port - if set as true isURL will check if port is present in the URL
allow_protocol_relative_urls - if set as true protocol relative URLs will be allowed
validate_length - if set as false isURL will skip string length validation (IE maximum is 2083)

*/

const wrapped_ipv6 = /^\[([^\]]+)\](?::([0-9]+))?$/;

export function validateIsUrl(
    rawUrl: string,
    options: {
        protocols?: string[];
        require_protocol?: boolean;
        require_host?: boolean;
        require_port?: boolean;
        require_valid_protocol?: boolean;
        allow_protocol_relative_urls?: boolean;
        allow_fragments?: boolean;
        allow_query_components?: boolean;
        validate_length?: boolean;
        disallow_auth?: boolean;
        disableUrlEncoding?: boolean;
        fqdnValidationOptions?: Parameters<typeof validateIsFQDN>[1]
    } = {}
) {
    const {
        protocols = ['http', 'https', 'ftp'],
        require_protocol = false,
        require_host = true,
        require_port = false,
        require_valid_protocol = true,
        allow_protocol_relative_urls = false,
        allow_fragments = true,
        allow_query_components = true,
        validate_length = true,
        disallow_auth = false,
        disableUrlEncoding = false,
        fqdnValidationOptions = {}
    } = options;

    let url = disableUrlEncoding ? rawUrl : encodeURI(rawUrl);

    if (!url || /[\s<>]/.test(url)) {
        return false;
    }

    if (url.indexOf('mailto:') === 0) {
        return false;
    }

    try {
        if (validate_length && url.length >= 2083) {
            return false;
        }

        if (!allow_fragments && url.includes('#')) {
            return false;
        }

        if (!allow_query_components && (url.includes('?') || url.includes('&'))) {
            return false;
        }

        let protocol: string;
        let auth: string;
        let host: string | undefined;
        let port: number;
        let port_str: string | null;
        let split: string[];
        let ipv6: string | null;

        split = url.split('#');
        url = assertDefined(split.shift());
        split = url.split('?');
        url = assertDefined(split.shift());
        split = url.split('://');

        if (split.length > 1) {
            protocol = assertDefined(split.shift()).toLowerCase();

            if (require_valid_protocol && protocols.indexOf(protocol) === -1) {
                return false;
            }
        } else if (require_protocol) {
            return false;
        } else if (url.substr(0, 2) === '//') {
            if (!allow_protocol_relative_urls) {
                return false;
            }

            split[0] = url.substr(2);
        }

        url = split.join('://');

        if (url === '') {
            return false;
        }

        split = url.split('/');
        url = assertDefined(split.shift());

        if (url === '' && !require_host) {
            return true;
        }

        split = url.split('@');

        if (split.length > 1) {
            if (disallow_auth) {
                return false;
            }

            if (split[0] === '') {
                return false;
            }

            auth = assertDefined(split.shift());

            if (auth.indexOf(':') >= 0) {
                return false;
            }

            const authSplitted = auth.split(':');
            if (authSplitted.length === 2) {
                return authSplitted[0] !== '' && authSplitted[1] !== '';
            } else if (authSplitted.length === 1) {
                return authSplitted[0] !== '';
            } else {
                return false;
            }
        }

        const hostname = split.join('@');
        port_str = null;
        ipv6 = null;
        const ipv6_match = hostname.match(wrapped_ipv6);

        if (ipv6_match) {
            host = '';
            ipv6 = ipv6_match[1];
            port_str = ipv6_match[2] || null;
        } else {
            split = hostname.split(':');
            host = split.shift();

            if (split.length) {
                port_str = split.join(':');
            }
        }

        if (port_str !== null && port_str.length > 0) {
            port = parseInt(port_str, 10);

            if (!/^[0-9]+$/.test(port_str) || port <= 0 || port > 65535) {
                return false;
            }
        } else if (require_port) {
            return false;
        }

        if (!host || !validateIsIp(host) && !validateIsFQDN(host, fqdnValidationOptions) && (!ipv6 || !validateIsIp(ipv6, 6))) {
            return false;
        }

        return true;
    } catch (error) {
        return false;
    }
}