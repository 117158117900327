import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeVideosList} from "./doppe-action-type-videos-list";

export const doppeActionTypeVideosListViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeVideosList, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeVideosListWidget: (await import(
                './doppe-action-type-videos-list-widget/doppe-action-type-videos-list-widget'
                )).DoppeActionTypeVideosListWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeVideosListWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeVideosListWidget
                {...renderProps}
            />
        );

    }
})