import {iterableFindAndSelect, Values} from "@wix/devzai-utils-common";

export const WixAppsIds = {
    Printful: '50d0aa3b-fb2d-4b54-9f73-db7c07c7392c',
    Modalyst: 'f4ef9627-612a-4b63-b8bd-edb7831dcc92',
    Stores: '1380b703-ce81-ff05-f115-39571d94dfcd',
    StoresNew: '215238eb-22a5-4c36-9e7b-e7c08025e04e',
    Booking: '13d21c63-b5ec-5912-8397-c3a5ddb27a97',
    Blog: '14bcded7-0066-7c35-14d7-466cb3f09103',
    PricingPlans: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
    ProGallery: '14271d6f-ba62-d045-549b-ab972ae1f70e',
    Cashier: '14bca956-e09f-f4d6-14d7-466cb3f09103',
    Inbox: '141fbfae-511e-6817-c9f0-48993a7547d1',
    PromoteHome: 'f123e8f1-4350-4c9b-b269-04adfadda977',
    PromotePaidAds: '9bead16f-1c73-4cda-b6c4-28cff46988db',
    PromoteSEO: '1480c568-5cbd-9392-5604-1148f5faffa0',
    PromoteShareIt: '14b89688-9b25-5214-d1cb-a3fb9683618b',
    ShoutOut: '135c3d92-0fea-1f9d-2ba5-2a1dfb04297e',
    Tasks: '146c0d71-352e-4464-9a03-2e868aabe7b9',
    VideoMakerHome: '307ba931-689c-4b55-bb1d-6a382bad9222',
    WixCodeSecretsManager: '4b10fcce-732d-4be3-9d46-801d271acda9',
    Workflow: 'ea2821fc-7d97-40a9-9f75-772f29178430',
    Events: '140603ad-af8d-84a5-2c80-a0f60cb47351',
    Video: '14409595-f076-4753-8303-9a86f9f71469',
    MetaSite: '22bef345-3c5b-4c18-b782-74d4085112ff',
    Hopp: '17fda750-26c4-4ffb-8bd3-c8600d12348c',
    HoppAutomations: '80202054-2d21-4295-9c26-1cf2d9db7012',
    HoppCreatorsStaging: 'a97fd278-ef69-420d-bed8-718d95306236',
    HoppCreatorsLocal: '7d24568b-af78-495c-a3e0-c062974aedc8',
    HoppCreatorsProduction: '166b1ef9-dc04-4993-93ed-2ee437421ba7',
};

export const WixAppState = {
    Disabled: 'DISABLED',
    Enabled: 'ENABLED',
    Unknown: 'UNKNOWN',
    Template: 'TEMPLATE',
    Pending: 'PENDING',
} as const;

export type WixAppState = Values<typeof WixAppState>;


// t.WIX_HOTELS = "135aad86-9125-6074-7346-29dc6a3c9bcf",
//     t.WIX_BLOG = "14bcded7-0066-7c35-14d7-466cb3f09103",
//     t.WIX_EVENTS = "140603ad-af8d-84a5-2c80-a0f60cb47351",
//     t.WIX_BOOKINGS = "13d21c63-b5ec-5912-8397-c3a5ddb27a97",
//     t.WIX_FORUM = "14724f35-6794-cd1a-0244-25fd138f9242",
//     t.PRICING_PLANS = "1522827f-c56c-a5c9-2ac9-00f9e6ae12d3",
//     t.WIX_ECOM = "1380b703-ce81-ff05-f115-39571d94dfcd",
//     t.WIX_STORES = "1380b703-ce81-ff05-f115-39571d94dfcd",
//     t.WIX_NEW_STORES = "215238eb-22a5-4c36-9e7b-e7c08025e04e",
//     t.MEMBERS_AREA = "14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9",
//     t.MEMBERS_AREA_V2 = "b976560c-3122-4351-878f-453f337b7245",
//     t.MEMBERS = "14dbefd2-01b4-fb61-32a7-3abd44da4908",
//     t.META_SITE = "22bef345-3c5b-4c18-b782-74d4085112ff",
//     t.NOTIFICATIONS = "14f25924-5664-31b2-9568-f9c5ed98c9b1",
//     t.WIX_RESERVATIONS = "1475ab65-206b-d79a-856d-fa10bdb479ea",
//     t.WIX_RESTAURANTS_ORDERS = "13e8d036-5516-6104-b456-c8466db39542",
//     t.WIX_RESTAURANTS_MENUS = "13c1402c-27f2-d4ab-7463-ee7c89e07578",
//     t.WIX_RESTAURANTS_MENUS_NEW = "b278a256-2757-4f19-9313-c05c783bec92",
//     t.WIX_RESTAURANTS_TABLE_RESERVATIONS = "f9c07de2-5341-40c6-b096-8eb39de391fb",
//     t.WIX_CHAT = "14517e1a-3ff0-af98-408e-2bd6953c36a2",
//     t.DEVIANTART_FEED = "7d297b79-baed-46d7-ac58-4bd68dcb70d0",
//     t.INSTAGRAM_FEED = "14635256-b183-1c71-a4d2-f55179b80e8a",
//     t.WIX_GET_SUBSCRIBERS = "1375baa8-8eca-5659-ce9d-455b2009250d",
//     t.WIX_VIDEO = "14409595-f076-4753-8303-9a86f9f71469",
//     t.WIX_HOTELS_RUNNER = "826670ba-3a6b-4157-ac72-7c4fca9ce220",
//     t.WIX_PHOTO_ALBUMS = "13ff8629-c1fc-e289-e81f-bc8c8968e9d6",
//     t.WIX_PRO_GALLERY = "14271d6f-ba62-d045-549b-ab972ae1f70e",
//     t.WIX_PORTFOLIO = "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
//     t.BANDSINTOWN = "1405ef82-0ee0-65fb-88a1-2f172aa3573c",
//     t.WIX_MUSIC = "13bb5d67-1add-e770-a71f-001277e17c57",
//     t.WIX_SITE_SEARCH = "1484cb44-49cd-5b39-9681-75188ab429de",
//     t.WIX_FAQ = "14c92d28-031e-7910-c9a8-a670011e062d",
//     t.WIX_FORMS = "14ce1214-b278-a7e4-1373-00cebd1bef7c",
//     t.WIX_CHALLENGES = "2936472a-a1ed-4ae5-9f71-614313a9f4e7",
//     t.WIX_GROUPS = "148c2287-c669-d849-d153-463c7486a694",
//     t.RICH_CONTENT = "949cfcc9-2a3f-4a96-bd6c-11d9d82763a4",
//     t.WIX_FILE_SHARE = "1537b24e-29d1-6d8f-b8e1-d6860f2f70b9",
//     t.RESTAURANTS = "9142e27e-2e30-4948-ad8f-7e128939efc4";
//

export function wixAppGetDisplayName (wixAppId: string) {
    const appName = iterableFindAndSelect(
        Object.entries(WixAppsIds),
        ([appName, appId], skip) => appId === wixAppId ? appName : skip);

    return appName ?? wixAppId;
}