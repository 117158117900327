import React, {EventHandler, useRef} from 'react';
import {
    CurrentItemType,
    DefaultCommonListViewProps,
    IListView,
    ListViewBasedComponentsCommonProps,
    ListViewDataSourceDataItem,
    ListViewDefaultState,
    ListViewItemId,
    ListViewNavigationActionType,
    ListViewSelectionAction,
    ListViewSelectionType,
    ListViewState,
    ListViewStateUpdateFunction,
    NavigationOrientation,
    StateUpdateReason,
    TypeAheadStrategy
} from './list-view-types';
import {ListViewStateController} from './list-view-state-controller';
import {
    arrayFindIndex,
    arrayFindValue,
    arrayFlatten,
    ArrayOrSingle,
    arraySubtract,
    asArray,
    assertNotNullable,
    evaluateFunction
} from '@wix/devzai-utils-common';
import {SearchMatchingFunction} from './list-view-search';
import {focusListView} from './list-view-utils';
import {ListViewInstanceContext} from './list-view-instance-context';
import {
    ContainingListViewIdAttributeName,
    listViewHandleListViewItemEvent,
    ListViewIdAttributeName,
    ListViewInstanceElementPropName,
    ListViewItemEventHandler,
    ListViewItemEventHandlerOptions
} from './list-view-dom';
import {domNodesListToArray, elementClosest, KeyCodes} from '@wix/devzai-utils-dom';
import {keyCodeGetFromReactEvent, ReactPointerLikeEvent} from "../react-events/react-events";
import {refCreateUnion} from "../ref-utils";

export const ListViewContext = React.createContext<ListViewContextData | null>(null);

export interface ListViewContextData {
    selectedIdsSet: Set<ListViewItemId>;
    disabledIdsSet: Set<ListViewItemId>;
    typeAheadValue: string | null;
    listView: ListViewComposable;
    currentListViewItemId: ListViewItemId | null;
    isFocusable: boolean;
    navigationListId: string;
    handleItemRendered: (itemId: ListViewItemId) => void;
    isItemShouldBeFocusedWhenRendered: (itemId: ListViewItemId) => boolean;
    typeAheadStrategy: TypeAheadStrategy | null;
    searchMatchingFunction: SearchMatchingFunction | null;
    searchText: string | undefined;
    firstNavigationScopeItemId: ListViewItemId | null;
}

export { ListViewItemsView } from './list-view-items-view';

type CustomListViewItemEventHandler<T, EVENT extends React.BaseSyntheticEvent = React.BaseSyntheticEvent> = ListViewItemEventHandlerOptions & {
    eventHandler: ListViewItemEventHandler<T, EVENT>;
}

export type ListViewItemEventHandlerType<T, EVENT extends React.BaseSyntheticEvent = React.BaseSyntheticEvent> =
    ListViewItemEventHandler<T, EVENT> |
    CustomListViewItemEventHandler<T, EVENT> |
    CustomListViewItemEventHandler<T, EVENT>[]

export function listViewItemEventHandlerCreate<T, EVENT extends React.BaseSyntheticEvent = React.BaseSyntheticEvent> (
    eventHandler: ListViewItemEventHandler<T, EVENT>,
    options: ListViewItemEventHandlerOptions = {}
) : CustomListViewItemEventHandler<T, EVENT> {
    return {
        eventHandler: eventHandler,
        ...options
    }
}

export interface ListViewItemEventProps<T = unknown> {
    onItemClick?: ListViewItemEventHandlerType<T, React.MouseEvent>;
    onItemMouseDown?: ListViewItemEventHandlerType<T, React.MouseEvent>;
    onItemMouseMove?: ListViewItemEventHandlerType<T, React.MouseEvent>;
    onItemMouseOut?: ListViewItemEventHandlerType<T, React.MouseEvent>;
    onItemTouchStart?: ListViewItemEventHandlerType<T, React.TouchEvent>;
    onItemPointerLikeEventStart?: ListViewItemEventHandlerType<T, ReactPointerLikeEvent>;
    onItemChange?: ListViewItemEventHandlerType<T, React.ChangeEvent<HTMLInputElement>>;
    onItemKeyDown?: ListViewItemEventHandlerType<T, React.KeyboardEvent>;
    onItemAction?: ListViewItemEventHandlerType<T, React.KeyboardEvent | React.MouseEvent>;
}

/**
 * Properties that are common to ListViewComposable and ListView
 */
export interface ListViewCommonProps<T = any> extends ListViewBasedComponentsCommonProps<T> {
    listViewState?: ListViewState;
    defaultListViewState?: ListViewState;
    /**
     * List view's navigation orientation (horizontal/vertical).
     */
    orientation: NavigationOrientation;
    keyboardHandler: (listView: ListViewComposable, event: React.KeyboardEvent<Element>) => boolean;
    onChange?: (event: ListViewState) => void;

    /**
     * Called after the ListView is mount and the all the relevant dom attributes are applied on its dom element,
     * and its items' dom elements.
     */
    onDomReady?: () => void;
}

export const DefaultListViewSharedProps = {
    ...DefaultCommonListViewProps,
    keyboardHandler: () => false,
    orientation: NavigationOrientation.Vertical
};

interface ListViewComposableProps extends ListViewCommonProps, ListViewItemEventProps {
    dataSourcesArray: Array<ListViewDataSourceDataItem<any>[]>;
}

interface ListViewComposableState {
    listViewDataItemsArray: ListViewDataSourceDataItem<any>[];
    listViewState: ListViewState;
    clientRootAttributes?: Record<string, any>;
}

type ListViewIdsProviderContextData = {nextNavigationListId: number};

const ListViewIdsProviderContext = React.createContext<ListViewIdsProviderContextData | null>(null);

export const ListViewIdsProvider = React.memo(function ListViewIdsProvider (props: { children: React.ReactNode }) {
    const contextDataRef = useRef<ListViewIdsProviderContextData>({nextNavigationListId: 0})

    return (
        <ListViewIdsProviderContext.Provider
            value={contextDataRef.current}
        >
            {props.children}
        </ListViewIdsProviderContext.Provider>
    );
});

export class ListViewComposable extends React.Component<ListViewComposableProps, ListViewComposableState>
    implements IListView {
    private listViewRoot = React.createRef<HTMLElement>();
    private navigationListId;

    state: ListViewComposableState;

    constructor(props: ListViewComposableProps, contextData: ListViewIdsProviderContextData | null) {
        super(props);

        this.navigationListId = (contextData ? contextData.nextNavigationListId++ : ListViewComposable.nextNavigationListId++).toString()

        const isControlled = (this.isControlled = props.listViewState !== undefined);

        const defaultListViewState = props.defaultListViewState;

        if (isControlled && defaultListViewState) {
            console.warn(`When ListView is controlled, defaultListViewState property is redundant`);
        }

        this.state = {
            listViewDataItemsArray: [],
            listViewState: defaultListViewState ? defaultListViewState : ListViewDefaultState,
            clientRootAttributes: undefined
        };
    }

    static defaultProps = DefaultListViewSharedProps;

    private static nextNavigationListId = 0;
    static contextType = ListViewIdsProviderContext;

    private isControlled: boolean;

    private stateController?: ListViewStateController = undefined;

    private currentTypeAheadTimeOut?: number = undefined;

    private itemShouldBeFocusedWhenRendered?: ListViewItemId = undefined;

    private _handleItemRendered = (itemId: ListViewItemId) => {
        if (this.itemShouldBeFocusedWhenRendered === itemId) {
            this.itemShouldBeFocusedWhenRendered = undefined;
        }
    };

    private _isItemShouldBeFocusedWhenRendered = (itemId: ListViewItemId) => {
        return this.itemShouldBeFocusedWhenRendered === itemId;
    };

    static getDerivedStateFromProps(props: ListViewComposableProps) {
        return {
            listViewDataItemsArray: arrayFlatten(props.dataSourcesArray)
        };
    }

    public getId() {
        return this.navigationListId;
    }

    public getRootElement() {
        return this.listViewRoot.current;
    }

    public getListViewState(): ListViewState {
        return this.chooseListViewState(this.props, this.state);
    }

    public getSelectionType(): ListViewSelectionType {
        return this.props.selectionType;
    }

    private chooseListViewState(props: ListViewComposableProps, state: ListViewComposableState): ListViewState {
        return this.isControlled ? props.listViewState! : state.listViewState;
    }

    render() {
        const {
            listViewState,
            children,
            tagName,
            listViewElementRef,
            selectionType,
            onChange,
            isFocusable,
            forceRenderNavigatableItem,
            dataSourcesArray,
            typeAheadStrategy,
            isCyclic,
            keyboardHandler,
            keymapController,
            searchMatchingFunction,
            orientation,
            searchText,
            defaultListViewState,
            navigationScopeReducingBySearchStrategy,
            onCurrentItemChange,
            onSelectionChange,
            onDomReady,

            onItemClick,
            onItemMouseDown,
            onItemTouchStart,
            onItemMouseMove,
            onItemMouseOut,
            onItemPointerLikeEventStart,
            onItemChange,
            onItemKeyDown,
            onItemAction,

            ...restProps
        } = this.props;

        const clientRootAttributes = this.state.clientRootAttributes;

        const usedListViewState = this.getListViewState();

        const { selectedIds, disabledIds, currentNavigatableItemId, typeAheadValue } = usedListViewState;

        this.stateController = undefined;

        const disabledIdsSet = new Set<ListViewItemId>(disabledIds);

        const firstNavigationScopeItem = arrayFindValue(this.getListViewItemsArray(), dataSourceItem => {
            return (
                !dataSourceItem.isExcluded &&
                !disabledIdsSet.has(dataSourceItem.id) &&
                (!searchText || !searchMatchingFunction || searchMatchingFunction(searchText, dataSourceItem))
            );
        });

        const listViewContextData: ListViewContextData = {
            selectedIdsSet: new Set<ListViewItemId>(selectedIds),
            disabledIdsSet: disabledIdsSet,
            listView: this,
            typeAheadValue,
            currentListViewItemId: currentNavigatableItemId,
            isFocusable,
            navigationListId: this.navigationListId,
            handleItemRendered: this._handleItemRendered,
            isItemShouldBeFocusedWhenRendered: this._isItemShouldBeFocusedWhenRendered,
            typeAheadStrategy: typeAheadStrategy,
            searchMatchingFunction: searchMatchingFunction,
            searchText: searchText,
            firstNavigationScopeItemId: firstNavigationScopeItem ? firstNavigationScopeItem.id : null
        };

        const listViewRootProps = {
            ref: refCreateUnion([this.listViewRoot, listViewElementRef]),
            ...restProps,
            role: 'listbox',
            ...clientRootAttributes,
            'aria-orientation': orientation === NavigationOrientation.Vertical ? 'vertical' : 'horizontal',
            ...this.listViewElementEventHandlers
        };

        return (
            <ListViewContext.Provider value={listViewContextData}>
                <ListViewInstanceContext.Provider value={this}>
                    {React.createElement(tagName, listViewRootProps, children)}
                </ListViewInstanceContext.Provider>
            </ListViewContext.Provider>
        );
    }

    shouldComponentUpdate(nextProps: ListViewComposableProps) {
        const isControlled = nextProps.listViewState !== undefined;

        if (isControlled !== this.isControlled) {
            throw new Error(`A component is changing an uncontrolled ListView to be controlled.`);
        }

        return true;
    }

    public getDataItemById(itemId: ListViewItemId) {
        const dataSourceItem = arrayFindValue(
            this.getListViewItemsArray(),
            dataSourceItem => dataSourceItem.id === itemId
        );

        return dataSourceItem ? dataSourceItem.dataItem : undefined;
    }

    public getOrderedListViewItemsIds(): ListViewItemId[] {
        return this.state.listViewDataItemsArray.map(dataSourceItem => dataSourceItem.id);
    }

    public getListViewItemsArray(): ListViewDataSourceDataItem<any>[] {
        return this.state.listViewDataItemsArray;
    }

    public handleItemPointerInteraction(listViewItemId: ListViewItemId, event: React.MouseEvent) {
        this.updateState(stateController => {
            stateController.handleInteractiveSelection(listViewItemId, event);
        }, StateUpdateReason.PointerInteraction);
    }

    public updateState(
        updateFunction: ListViewStateUpdateFunction,
        reason: StateUpdateReason = StateUpdateReason.Manual
    ) {
        let stateController = this.stateController;
        if (!stateController) {
            stateController = this.stateController = new ListViewStateController(this);
        }

        updateFunction(stateController);

        if (stateController.hasStateChanged()) {
            const updatedState = stateController.getCurrentState();

            this.handleStateChange(updatedState);

            const onCurrentItemChange = this.props.onCurrentItemChange;
            if (onCurrentItemChange && stateController.hasCurrentChanged()) {
                const currentItemId = updatedState.currentNavigatableItemId;

                onCurrentItemChange({
                    itemsList: this,
                    currentItemId: currentItemId,
                    triggeringAction: reason
                });
            }

            if (this.props.onSelectionChange && stateController.hasSelectionChanged()) {
                this.props.onSelectionChange({
                    itemsList: this,
                    selectedIds: updatedState.selectedIds ?? [],
                    triggeringAction: reason
                });
            }

            return true;
        } else {
            return false;
        }
    }

    private handleStateChange(state: ListViewState) {
        if (!this.isControlled) {
            this.setState({
                listViewState: state
            });
        }

        const onChange = this.props.onChange;
        if (onChange) {
            onChange(state);
        }
    }

    componentDidMount() {
        assertNotNullable(this.listViewRoot.current as any)[ListViewInstanceElementPropName] = this;

        this.setState({
            clientRootAttributes: {
                [ListViewIdAttributeName]: this.navigationListId
            }
        });

        if (this.props.autoFocus) {
            this.focus();
        }
    }

    componentWillUnmount() {
        assertNotNullable(this.listViewRoot.current as any)[ListViewInstanceElementPropName] = undefined;
    }

    componentDidUpdate(prevProps: ListViewComposableProps, prevState: ListViewComposableState) {
        const { typeAheadStrategy } = this.props;

        const prevListViewState = this.chooseListViewState(prevProps, prevState);
        const currentListViewState = this.chooseListViewState(this.props, this.state);

        // List view that supports selection should auto update its selected ids state in case that the current
        // selected ids contain ids of non existing items.
        if (this.getSelectionType() !== ListViewSelectionType.None) {
            const currentItemsIds = this.state.listViewDataItemsArray.map(listViewDataItem => listViewDataItem.id);
            const zombieSelectedIds = arraySubtract(currentListViewState.selectedIds ?? [], currentItemsIds);
            if (zombieSelectedIds.length > 0) {
                this.updateState(stateController => {
                    for (const zombieSelectedId of zombieSelectedIds) {
                        stateController.removeItemFromSelection(zombieSelectedId)
                    }
                });

                return;
            }
        }

        const forceRenderNavigatableItem = this.props.forceRenderNavigatableItem;

        if (forceRenderNavigatableItem) {
            const prevCurrentNavigatableItemId = prevListViewState.currentNavigatableItemId;
            const updatedCurrentNavigatableItemId = currentListViewState.currentNavigatableItemId;

            if (updatedCurrentNavigatableItemId && prevCurrentNavigatableItemId !== updatedCurrentNavigatableItemId) {
                const activeElement = document.activeElement;

                if (activeElement instanceof HTMLElement) {
                    const navigationListId = this.navigationListId;

                    if (findEnclosingNavigatableItemElement(activeElement, navigationListId)) {
                        this.itemShouldBeFocusedWhenRendered = updatedCurrentNavigatableItemId;
                        forceRenderNavigatableItem(updatedCurrentNavigatableItemId);
                    }
                }
            }
        }

        if (typeAheadStrategy && typeAheadStrategy.typeAheadClearTimeout !== undefined) {
            if (prevListViewState.typeAheadValue !== currentListViewState.typeAheadValue) {
                window.clearTimeout(this.currentTypeAheadTimeOut);
                this.currentTypeAheadTimeOut = window.setTimeout(() => {
                    window.clearTimeout(this.currentTypeAheadTimeOut);
                    this.handleStateChange({
                        ...this.getListViewState(),
                        typeAheadValue: ''
                    });
                }, typeAheadStrategy.typeAheadClearTimeout);
            }
        }

        if (this.props.onDomReady) {
            if (prevState.clientRootAttributes === undefined && this.state.clientRootAttributes !== undefined) {
                this.props.onDomReady();
            }
        }


    }

    public getListViewItemById(listViewItemId: ListViewItemId) {
        return arrayFindValue(this.getListViewItemsArray(), item => item.id === listViewItemId);
    }

    public findItemIndex(itemId: ListViewItemId) {
        return arrayFindIndex(this.getListViewItemsArray(), item => item.id === itemId);
    }

    public focus() {
        focusListView(this);
    }

    public getItemsNodeList(): NodeListOf<HTMLElement> | undefined {
        const listViewRoot = this.listViewRoot.current;
        if (listViewRoot) {
            return listViewRoot.querySelectorAll(`[${ContainingListViewIdAttributeName}="${this.navigationListId}"]`);
        } else {
            return undefined;
        }
    }

    public getItemsElements(): HTMLElement[] {
        return domNodesListToArray(assertNotNullable(this.listViewRoot.current)
            .querySelectorAll(`[${ContainingListViewIdAttributeName}="${this.navigationListId}"]`))
    }

    public moveToNextSearchMatchingItem(searchText: string, selectItem?: boolean): void {
        this.updateState(stateController => {
            stateController.moveToNextSearchMatchingItem(searchText, selectItem);
        }, StateUpdateReason.Search);
    }

    public getTypeAheadStrategy() {
        return this.props.typeAheadStrategy;
    }

    public isCyclic(): boolean {
        return this.props.isCyclic;
    }

    public isMultiSelection(): boolean {
        return this.props.selectionType === ListViewSelectionType.Multiple;
    }

    public isSingleSelection(): boolean {
        return this.props.selectionType === ListViewSelectionType.Single;
    }

    public isNotSupportingSelection(): boolean {
        return this.props.selectionType === ListViewSelectionType.None;
    }

    public isSupportingSelection(): boolean {
        return this.props.selectionType !== ListViewSelectionType.None;
    }

    public handleKeyboardEvent(event: React.KeyboardEvent<Element>): void {
        this.handleKeyboardEventInternal(event, true);
    }

    private createListViewElementEventHandler = <
        EVENT_PROP extends keyof ListViewCommonProps,
        ITEM_EVENT_PROP extends keyof ListViewItemEventProps
    >(
        eventPropName: EVENT_PROP | null,
        itemEventPropsName: ArrayOrSingle<ITEM_EVENT_PROP>,
        customHandler?: EventHandler<any>
    ) => {
        return (event: React.BaseSyntheticEvent) => {

            if (customHandler) {
                customHandler(event)
            }

            if (eventPropName !== null) {
                const originalEvent = this.props[eventPropName] as React.EventHandler<any> | undefined;
                if (originalEvent) {
                    originalEvent(event);
                }
            }


            const itemEventPropsNamesArr = asArray(itemEventPropsName);
            for (const currentEventPropName of itemEventPropsNamesArr) {
                const itemEvent = this.props[currentEventPropName] as ListViewItemEventHandlerType<unknown>;
                if (itemEvent !== undefined) {

                    if (typeof itemEvent === 'function') {
                        listViewHandleListViewItemEvent(event, itemEvent);
                    } else {

                        for (const customItemEventHandler of asArray(itemEvent)) {
                            const {
                                eventHandler,
                                ignoredPartsSelector,
                                partsSelector
                            } = customItemEventHandler;

                            listViewHandleListViewItemEvent(event, eventHandler, {
                                ignoredPartsSelector,
                                partsSelector
                            });
                        }
                    }
                }
            }


        }
    };

    private listViewElementEventHandlers = evaluateFunction(() => {

        const onItemActionHandler = this.createListViewElementEventHandler(null, 'onItemAction')

        return {
            onClick: this.createListViewElementEventHandler(
                'onClick',
                'onItemClick',
                event => {
                    if (event.button === 0) {
                        onItemActionHandler(event);
                    }
                }
            ),
            onMouseMove: this.createListViewElementEventHandler('onMouseMove', 'onItemMouseMove'),
            onMouseOut: this.createListViewElementEventHandler('onMouseOut', 'onItemMouseOut'),
            onMouseDown: this.createListViewElementEventHandler('onMouseDown', ['onItemMouseDown', 'onItemPointerLikeEventStart']),
            onTouchStart: this.createListViewElementEventHandler('onTouchStart', ['onItemTouchStart', 'onItemPointerLikeEventStart']),
            onChange: this.createListViewElementEventHandler(null, 'onItemChange'),
            onKeyDown: this.createListViewElementEventHandler(
                'onKeyDown',
                'onItemKeyDown',
                (event) => {
                    this.handleKeyboardEventInternal(event, false)

                    if (keyCodeGetFromReactEvent(event) === KeyCodes.Enter) {
                        onItemActionHandler(event);
                    }
                }),
        }
    });

    private handleKeyboardEventInternal = (event: React.KeyboardEvent, forceHandling: boolean) => {
        if (!forceHandling) {
            const eventTarget = event.target as Element;
            const shouldHandleKeyboardEvent =
                (eventTarget.getAttribute(ContainingListViewIdAttributeName) === this.navigationListId ||
                    eventTarget.getAttribute('data-handle-keyboard-events') === this.navigationListId);

            if (!shouldHandleKeyboardEvent) {
                return;
            }
        }

        let isHandled = this.props.keyboardHandler(this, event);

        if (!isHandled) {
            const { keymapController, orientation, selectionType, isCyclic } = this.props;

            const { typeAheadValue } = this.getListViewState();

            this.updateState(stateController => {
                const navigatableItemsIds = stateController.getNavigationScopeItemsIds();

                const navigatableItemsIdsLength = navigatableItemsIds.length;

                if (navigatableItemsIdsLength > 0) {
                    const lastItemIndex = navigatableItemsIdsLength - 1;
                    const firstItemIndex = 0;

                    const currentItemId = stateController.getCurrentItemId();

                    const keyboardAction = keymapController.resolveKeyboardAction(
                        event,
                        this,
                        orientation,
                        selectionType,
                        currentItemId !== null
                            ? stateController.isSelectable(currentItemId)
                                ? CurrentItemType.Selectable
                                : CurrentItemType.NoneSelectable
                            : CurrentItemType.None,
                        this.isTypeAheadEnabled()
                    );

                    let updatedSelectionStartId;

                    if (keyboardAction) {
                        isHandled = true;

                        const [navigationAction, selectionAction] = keyboardAction;

                        const navigationActionType = navigationAction.type;

                        if (navigationActionType !== ListViewNavigationActionType.None) {
                            if (navigationActionType === ListViewNavigationActionType.MoveAccordingToTypeAhead) {
                                stateController
                                    .setTypeAheadValue((typeAheadValue ? typeAheadValue : '') + event.key)
                                    .navigateToItemBasedOnCurrentTypeAhead(true);
                            } else {
                                let targetItemId;

                                if (navigationActionType === ListViewNavigationActionType.MoveToLast) {
                                    targetItemId = navigatableItemsIds[lastItemIndex];

                                    if (
                                        !currentItemId &&
                                        !stateController.getSelectionStartId() &&
                                        selectionType === ListViewSelectionType.Multiple
                                    ) {
                                        updatedSelectionStartId = navigatableItemsIds[firstItemIndex];
                                    }
                                } else if (navigationActionType === ListViewNavigationActionType.MoveToFirst) {
                                    targetItemId = navigatableItemsIds[firstItemIndex];
                                } else {
                                    if (currentItemId === null) {
                                        if (navigationActionType === ListViewNavigationActionType.MoveToNext || navigationActionType === ListViewNavigationActionType.MoveForward) {
                                            targetItemId = navigatableItemsIds[firstItemIndex];
                                        } else if (navigationActionType === ListViewNavigationActionType.MoveToPrevious || navigationActionType === ListViewNavigationActionType.MoveBackward) {
                                            targetItemId = navigatableItemsIds[lastItemIndex];
                                        } else {
                                            throw new Error(`Unexpected navigation action type`);
                                        }
                                    } else {
                                        const currentItemIndex = navigatableItemsIds.indexOf(currentItemId);

                                        const delta = evaluateFunction(() => {
                                            switch (navigationAction.type) {
                                                case ListViewNavigationActionType.MoveForward:
                                                    return navigationAction.delta;
                                                case ListViewNavigationActionType.MoveBackward:
                                                    return -navigationAction.delta;
                                                case ListViewNavigationActionType.MoveToNext:
                                                    return 1;
                                                case ListViewNavigationActionType.MoveToPrevious:
                                                    return -1;
                                                default:
                                                    throw new Error(`Unexpected navigation action type`);
                                            }
                                        })

                                        let targetItemIndex = currentItemIndex + delta;

                                        if (targetItemIndex < firstItemIndex) {
                                            targetItemIndex = isCyclic ? lastItemIndex : firstItemIndex;
                                        } else if (targetItemIndex > lastItemIndex) {
                                            targetItemIndex = isCyclic ? firstItemIndex : lastItemIndex;
                                        }

                                        targetItemId = navigatableItemsIds[targetItemIndex];
                                    }
                                }

                                stateController.setCurrentItemId(targetItemId);
                            }
                        }

                        if (selectionAction !== ListViewSelectionAction.None) {
                            // NOTE: when there is a selection action, after navigation action the current item Id will never be null.
                            const updatedCurrentItemId = stateController.getCurrentItemId()!;

                            let selectionStartId = stateController.getSelectionStartId();
                            if (selectionStartId === null) {
                                if (!updatedSelectionStartId) {
                                    updatedSelectionStartId = updatedCurrentItemId;
                                }

                                selectionStartId = updatedSelectionStartId;

                                stateController.setSelectionStartId(updatedSelectionStartId);
                            }

                            const currentItemHasChanged = currentItemId !== updatedCurrentItemId;

                            switch (selectionAction) {
                                case ListViewSelectionAction.SelectRange:
                                    if (currentItemHasChanged) {
                                        stateController.selectItemsInRange(selectionStartId, updatedCurrentItemId);
                                    }
                                    break;
                                case ListViewSelectionAction.AddRangeToSelection:
                                    if (currentItemHasChanged) {
                                        stateController.addItemsInRangeToSelection(
                                            selectionStartId,
                                            updatedCurrentItemId
                                        );
                                    }
                                    break;
                                case ListViewSelectionAction.SelectAll:
                                    stateController.selectItemsInRange(
                                        navigatableItemsIds[firstItemIndex],
                                        navigatableItemsIds[lastItemIndex]
                                    );
                                    break;
                                case ListViewSelectionAction.UnselectAll:
                                    stateController.clearSelection();
                                    break;
                                case ListViewSelectionAction.MoveSelection:
                                    if (currentItemHasChanged) {
                                        stateController.selectItem(updatedCurrentItemId);
                                    }
                                    break;
                                case ListViewSelectionAction.ToggleCurrent:
                                    if (!stateController.isSelected(updatedCurrentItemId)) {
                                        if (this.isMultiSelection()) {
                                            stateController.addItemToSelection(updatedCurrentItemId);
                                        } else {
                                            stateController.selectItem(updatedCurrentItemId);
                                        }
                                    } else {
                                        stateController.removeItemFromSelection(updatedCurrentItemId);
                                    }
                                    break;
                                case ListViewSelectionAction.SelectCurrent:
                                    stateController.selectItem(updatedCurrentItemId);
                                    break;
                                case ListViewSelectionAction.AddCurrentToSelection:
                                    stateController.addItemToSelection(updatedCurrentItemId);
                                    break;
                                case ListViewSelectionAction.SelectRangeUntilCurrent:
                                    stateController.selectItemsInRange(selectionStartId, updatedCurrentItemId);
                                    break;
                                case ListViewSelectionAction.AddRangeUntilCurrentToSelection:
                                    stateController.addItemsInRangeToSelection(selectionStartId, updatedCurrentItemId);
                                    break;
                            }
                        }
                    }
                }
            }, StateUpdateReason.KeyboardNavigation);
        }

        if (isHandled) {
            event.preventDefault();
        }
    };

    public isTypeAheadEnabled() {
        return this.props.typeAheadStrategy !== null;
    }
}

function findEnclosingNavigatableItemElement(htmlElement: HTMLElement, navigationListId: string) {
    // return elementClosest(htmlElement, `[${ContainingListViewIdAttributeName}='${navigationListId}']`);

    return elementClosest(
        htmlElement,
        element => element.getAttribute(ContainingListViewIdAttributeName) === navigationListId
    );
}
