import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeContactCard} from './doppe-action-type-contact-card';

export const doppeActionTypeContactCardViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeContactCard, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeMembershipWidget: (await import('./doppe-action-type-contact-card-widget/doppe-action-type-contact-card-widget')).DoppeActionTypeContactCardWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeMembershipWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeMembershipWidget
                {...renderProps}
            />
        );
    },
})