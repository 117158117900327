const NotInitialized: unique symbol = Symbol('NotInitialized');
type NotInitialized = typeof NotInitialized;

export class InitializableValue<T> {

    private value: T | NotInitialized = NotInitialized;

    constructor(value?: T) {
        this.value = value ?? NotInitialized;
    }

    public initialize (value: T) {
        if (this.value !== NotInitialized) {
            throw new Error(`Value has already been initialized.`)
        }

        this.value = value;

        return value;
    }

    public getValue () : T {
        if (this.value === NotInitialized) {
            throw new Error(`Value hasn't been initialized`);
        }

        return this.value;
    }
}