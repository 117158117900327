import React, {RefObject, useContext, useEffect, useLayoutEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {ModalView} from './modal-view';
import {
    arrayLast,
    assertNotNullable,
    classNames,
    Evaluable,
    evaluateWhenFunction,
    EventEmitter,
    EventReference,
    IdGenerator,
    tsTypeAssert
} from '@wix/devzai-utils-common';
import {DomEventListener, elementClosest, keyCodeGetFromNativeEvent, KeyCodes} from '@wix/devzai-utils-dom';
import {
    IInnerWindowClosingController,
    innerWindowClosingControllerWaitForClosingComplete,
    isDynamicRef,
    reactIsRefObject,
    uiOverlayElementContainsElement,
    useRootWindow
} from '../index';
import {IInnerWindowController, InnerWindowContext} from '../windows';

export interface ModalInfo {
    content: ModalView.Props['content'];
    overlay?: ModalView.Props['overlay'];
    className?: string;
    isFullScreen?: boolean;
    onClosing?: (event: IModalClosingEvent) => void;
    onClosed?: () => void;
}

export interface IHostingModalController extends IInnerWindowController {}

interface OpenModalWindowController extends ModalInfo, IHostingModalController {
    overlayWindowClosingControllerRef: React.RefObject<IInnerWindowClosingController>;
    contentWindowClosingControllerRef: React.RefObject<IInnerWindowClosingController>;
    modalViewRef: React.RefObject<ModalView>;
    autoCloseModal: () => void;
    id: string;
}

export const ModalsScopeContext = React.createContext<ModalsScope | null>(null);

export interface IModalsController {
    showModal(modalWindowInfo: ModalInfo): IHostingModalController;
    getOpenModalsCount(): number;

    eventUpdated: EventReference<any, any>;
}

export namespace ModalsScope {
    export interface Props {
        targetContainer: ModalsContainer.Props['targetContainer'];
        zIndex?: number;
        modalsClassName?: string;
        onOpenModalsChange?: (openModals: OpenModalWindowController[]) => void;
    }
}

export class ModalsScope extends React.Component<ModalsScope.Props> implements IModalsController {
    protected eventsEmitter = new EventEmitter<{ eventUpdated: void }>();

    public get eventUpdated() {
        return this.eventsEmitter.createEventReference('eventUpdated');
    }

    private openModalWindows: OpenModalWindowController[] = [];

    private updateOpenModalWindows (openModalWindows: OpenModalWindowController[]) {

        const {
            onOpenModalsChange
        } = this.props;

        this.openModalWindows = openModalWindows;

        this.eventsEmitter.emit('eventUpdated');

        if (onOpenModalsChange) {
            onOpenModalsChange(openModalWindows);
        }

        this.forceUpdate();
    }

    public render() {
        const { targetContainer, children, zIndex, modalsClassName } = this.props;

        return (
            <ModalsScopeContext.Provider value={this}>
                {children}
                <ModalsContainer
                    targetContainer={targetContainer}
                    modalControllers={this.openModalWindows}
                    zIndex={zIndex}
                    modalsClassName={modalsClassName}
                />
            </ModalsScopeContext.Provider>
        );
    }

    public getOpenModalsCount() {
        return this.openModalWindows.length;
    }

    public getOpenModals() {
        return this.openModalWindows;
    }

    public showModal(modalWindowInfo: ModalInfo) {

        const overlayWindowClosingControllerRef = React.createRef<IInnerWindowClosingController>();
        const contentWindowClosingControllerRef = React.createRef<IInnerWindowClosingController>();

        const closeModal = () => {
            const openModalWindows = [...this.openModalWindows];
            const modalIndex = openModalWindows.indexOf(modalController);
            if (modalIndex >= 0) {
                openModalWindows.splice(modalIndex, 1);
            }

            this.updateOpenModalWindows(openModalWindows);

            modalController.onClosed?.();
        }

        const tryClosingModal = (isAutoClose: boolean, data?: any) => {
            const innerWindowClosingEvent = new ModalClosingEvent(isAutoClose, data);

            modalController.onClosing?.(innerWindowClosingEvent);

            if (!innerWindowClosingEvent.shouldPreventClosing) {

                innerWindowClosingControllerWaitForClosingComplete(
                    overlayWindowClosingControllerRef,
                    contentWindowClosingControllerRef
                )
                    .then(closeModal, () => {})
            }

            return innerWindowClosingEvent.preventClosingData;
        };

        const modalController = tsTypeAssert<OpenModalWindowController>({
            ...modalWindowInfo,
            id: IdGenerator.uniqueId(),
            modalViewRef: React.createRef<ModalView>(),
            overlayWindowClosingControllerRef: overlayWindowClosingControllerRef,
            contentWindowClosingControllerRef: contentWindowClosingControllerRef,
            closeWindow: (data?: any) => {
                return tryClosingModal(false, data);
            },
            closeWindowImmediately: () => {
                closeModal();
            },
            autoCloseModal: () => {
                tryClosingModal(true, undefined);
            }
        });

        this.updateOpenModalWindows([...this.openModalWindows, modalController]);

        return modalController;
    }
}

export class DefaultModalsController extends ModalsScope {
    componentDidMount() {
        const prevInstance = DefaultModalsController.instance;
        if (prevInstance !== undefined && prevInstance !== this) {
            throw new Error(`2 different instances of DefaultModalsController can't be rendered simultaneously`);
        }

        DefaultModalsController.instance = this;
    }

    componentWillUnmount() {
        DefaultModalsController.instance = undefined;
    }

    public static get eventUpdated() {
        return this.getInstance().eventUpdated;
    }

    public static showModal(modalWindowInfo: ModalInfo) {
        return this.getInstance().showModal(modalWindowInfo);
    }

    public static getOpenModalsCount() {
        return this.getInstance().getOpenModalsCount();
    }

    public static getInstance(): IModalsController {
        if (DefaultModalsController.instance === undefined) {
            throw new Error(`DefaultModalsController isn't rendered`);
        }

        return DefaultModalsController.instance;
    }

    public static getInstanceOrDefault(): IModalsController | undefined {
        return DefaultModalsController.instance;
    }

    protected static instance: DefaultModalsController | undefined = undefined;
}

namespace ModalsContainer {
    export interface Props {
        targetContainer: RefObject<HTMLElement> | Evaluable<() => HTMLElement>;
        modalControllers: OpenModalWindowController[];
        zIndex?: number;
        modalsClassName?: string;
    }
}

const ModalsContainer = React.memo(function ModalsContainer (props: ModalsContainer.Props) {
    const { targetContainer, modalControllers, zIndex, modalsClassName } = props;

    const [targetContainerElement, setTargetContainerElement] = useState<HTMLElement | null>(null);

    useLayoutEffect(() => {

        setTargetContainerElement(
            reactIsRefObject(targetContainer) ?
                targetContainer.current :
                evaluateWhenFunction(targetContainer)
        )

        if (isDynamicRef(targetContainer)) {

            const eventBinding = EventEmitter.on(targetContainer.eventChanged, () => {
                setTargetContainerElement(targetContainer.current);
            })

            return () => {
                eventBinding.dispose();
            }
        } else {
            return () => {}
        }

    }, [targetContainer]);

    if (targetContainerElement && modalControllers.length > 0) {
        return ReactDOM.createPortal(
            <ModalViews
                modalControllers={modalControllers}
                zIndex={zIndex}
                containerElement={targetContainerElement}
                modalsClassName={modalsClassName}
            />,
            targetContainerElement
        );
    } else {
        return null;
    }
});

const ModalViews = (props: {
    modalControllers: OpenModalWindowController[];
    zIndex?: number;
    modalsClassName?: string;
    containerElement: HTMLElement
}) => {
    const { modalControllers, zIndex, containerElement, modalsClassName } = props;

    const rootWindow = useRootWindow();

    useEffect(() => {

        const clickOutsideBinding = DomEventListener.bind((rootWindow ?? window).document, 'mousedown', event => {
            const eventTarget = event.target as HTMLElement;

            if (!uiOverlayElementContainsElement(eventTarget)) {
                if (containerElement.contains(eventTarget)) {
                    const topMostModalController = modalControllers[modalControllers.length - 1];

                    if (topMostModalController) {
                        const modalView = topMostModalController.modalViewRef.current;

                        if (!modalView || !modalView.getBodyElement().contains(eventTarget)) {
                            topMostModalController.autoCloseModal();
                        }
                    }
                }
            }
        });

        const autoCloseOnEscapeKeyBinding = DomEventListener.bind((rootWindow ?? window).document, 'keydown', (event: KeyboardEvent) => {
            const keyCode = keyCodeGetFromNativeEvent(event);

            if ( keyCode === KeyCodes.Escape) {
                if (!event.target || !elementClosest(event.target as Element, '[contenteditable],input', true)) {
                    arrayLast(modalControllers)?.autoCloseModal();
                }
            }
        })

        return () => {
            clickOutsideBinding.dispose();
            autoCloseOnEscapeKeyBinding.dispose();
        };
    }, [modalControllers, containerElement, rootWindow]);

    return (
        <>
            {modalControllers.map((modalController) => {
                return (
                    <InnerWindowContext.Provider value={modalController} key={modalController.id}>
                        <ModalView
                            content={modalController.content}
                            overlay={modalController.overlay}
                            ref={modalController.modalViewRef}
                            className={classNames(modalController.className, modalsClassName)}
                            zIndex={zIndex}
                            modalController={modalController}
                            overlayWindowClosingControllerRef={modalController.overlayWindowClosingControllerRef}
                            contentWindowClosingControllerRef={modalController.contentWindowClosingControllerRef}
                        />
                    </InnerWindowContext.Provider>
                );
            })}
        </>
    );
};

export interface IModalClosingEvent {
    data?: any;
    preventClosing(data?: any) : void;
    isAutoClose () : boolean;
}

class ModalClosingEvent implements IModalClosingEvent {
    public shouldPreventClosing = false;
    public preventClosingData?: any = undefined;
    private _isAutoClose: boolean;

    constructor(isAutoClose: boolean, public readonly data?: any) {
        this._isAutoClose = isAutoClose;
    }

    public preventClosing(data?: any) {
        this.shouldPreventClosing = true;
        this.preventClosingData = data;
    }

    public isAutoClose () {
        return this._isAutoClose;
    }
}

export function useModalController () {
    return assertNotNullable(useContext(InnerWindowContext));
}
