import {iterableAny, iterableFindAndSelect, objectGetValues, urlJoin} from "@wix/devzai-utils-common";

export type WixDynamicModel = {
    svSession: string;
    ctToken: string;
    clientSpecMap: Record<string, WixDynamicModel.ClientSpec>
}

export namespace WixDynamicModel {
    export type ClientSpec = {
        type: string;
        appDefinitionId: string;
        instance: string;
    }
}

export type WixDynamicModelV2 = {
    visitorId: string;
    svSession: string;
    ctToken: string;
    mediaAuthToken: string;
    apps: Partial<Record<string, WixDynamicModelV2.AppInfo>>
}

export namespace WixDynamicModelV2 {
    export type AppInfo = {
        instance: string;
        intId: number;
    }
}

export function wixDynamicModelResolveSignedInstance (wixDynamicModel: WixDynamicModel, appDefinitionId: string) {
    return iterableFindAndSelect(objectGetValues(wixDynamicModel.clientSpecMap), (clientSpec, skip) => {
        return clientSpec.appDefinitionId === appDefinitionId ? clientSpec.instance : skip;
    });
}

export function wixDynamicModelIsAppInstalled (wixDynamicModel: WixDynamicModel, appDefinitionId: string) {
    return iterableAny(objectGetValues(wixDynamicModel.clientSpecMap), clientSpec => {
        return clientSpec.appDefinitionId === appDefinitionId;
    });
}

export function wixDynamicModelResolveUrl (wixSiteUrl: string) {
    return urlJoin(wixSiteUrl, '/_api/dynamicmodel')
}

export function wixDynamicModelV2ResolveUrl (wixSiteUrl: string) {
    return urlJoin(wixSiteUrl, '/_api/v2/dynamicmodel')
}

export function wixDynamicModelV2ResolveSignedInstance (wixDynamicModel: WixDynamicModelV2, appDefinitionId: string) {
    return wixDynamicModel.apps[appDefinitionId]?.instance;
}

export function wixDynamicModelV2IsAppInstalled (wixDynamicModel: WixDynamicModelV2, appDefinitionId: string) {
    return wixDynamicModel.apps[appDefinitionId] !== undefined;
}
