import {Values} from "../common-types";
import {
    regexExec,
    stringEnsurePrefix,
    stringTrim,
    stringTrimPrefix,
    urlGetHostname,
    urlGetPathname,
    urlGetQueryString,
    urlParseQueryString
} from "@wix/devzai-utils-common";

export const SocialLinksType = {
    YoutubeProfile: 'youtube',
    TwitterProfile: 'twitter',
    // PatreonProfile: 'patreon',
    // BehanceProfile: 'behance',
    // DribbbleProfile: 'dribbble',
    FacebookProfile: 'facebook',
    // GithubProfile: 'github',
    InstagramProfile: 'instagram',
    TikTokProfile: 'tiktok',
    YoutubeVideo: 'youtube-video'
} as const;

export type SocialLinksType = Values<typeof SocialLinksType>;

// Inspiration: https://webapps.stackexchange.com/questions/54443/format-for-id-of-youtube-video/101153#101153
const youtubeUrlRegex = /^https?:\/\/((www|m)\.)?youtube\.com\/(channel\/(?<channelId>UC[\w-]{21}[AQgw])|(c\/|user\/)?(?<profileId>[\w-]+)\/?)$/;
const facebookUrlRegex = /^(https?:\/\/)?((www|m)\.)?facebook\.com\/(?<profileId>[\w-]+)\/?$/;
const tikTokUrlRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/@(?<profileId>[\w-]+)\/?$/;
const twitterUrlRegex = /^(https?:\/\/)?(www\.)?twitter\.com\/@?(?<profileId>[\w-]+)\/?$/;
const instagramUrlRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/(?<profileId>[\w-]+)\/?$/;


export function socialLinkIsValid (linkType: SocialLinksType, url: string) {

    switch (linkType) {
        case SocialLinksType.YoutubeVideo: {

            const hostName = urlGetHostname(url);
            const pathname = stringTrim(urlGetPathname(url), '/');

            if (/^(www.)?youtu.be$/.test(hostName)) {
                return /^[^/]+$/.test(pathname)
            } else if (/^(www.)?youtube.com$/.test(hostName) && pathname === 'watch') {
                const parsedQueryString = urlParseQueryString(urlGetQueryString(url));

                return parsedQueryString['v'] !== undefined;
            }

            return false;
        }
        case SocialLinksType.YoutubeProfile: {
            return youtubeUrlRegex.test(url)
        }
        case SocialLinksType.InstagramProfile: {
            return instagramUrlRegex.test(url) || socialLinkIsValidUserNameHandle(url);
        }
        case SocialLinksType.TikTokProfile: {
            return tikTokUrlRegex.test(url) || socialLinkIsValidUserNameHandle(url);
        }
        case SocialLinksType.FacebookProfile: {
            return facebookUrlRegex.test(url) || socialLinkIsValidUserNameHandle(url);
        }
        case SocialLinksType.TwitterProfile: {
            return twitterUrlRegex.test(url) || socialLinkIsValidUserNameHandle(url);
        }
    }
}

export function socialLinkGetProfileId (linkType: SocialLinksType, url: string) : string | null {
    switch (linkType) {
        case SocialLinksType.YoutubeVideo: {
            throw new Error('Not Supported')
        }
        case SocialLinksType.YoutubeProfile: {
            const capturedGroups = regexExec(youtubeUrlRegex, url)?.groups;
            return capturedGroups?.profileId ?? capturedGroups?.channelId ?? null;
        }
        case SocialLinksType.TikTokProfile: {
            const capturedGroups = regexExec(tikTokUrlRegex, url)?.groups;
            return capturedGroups?.profileId ?? socialLinkGetUsernameFromHandle(stringEnsurePrefix(url, '@')) ?? null;
        }
        case SocialLinksType.InstagramProfile: {
            const capturedGroups = regexExec(instagramUrlRegex, url)?.groups;
            return capturedGroups?.profileId ?? socialLinkGetUsernameFromHandle(stringEnsurePrefix(url, '@')) ?? null;
        }
        case SocialLinksType.FacebookProfile: {
            const capturedGroups = regexExec(facebookUrlRegex, url)?.groups;
            return capturedGroups?.profileId ?? socialLinkGetUsernameFromHandle(stringEnsurePrefix(url, '@')) ?? null;
        }
        case SocialLinksType.TwitterProfile: {
            const capturedGroups = regexExec(twitterUrlRegex, url)?.groups;
            return capturedGroups?.profileId ?? socialLinkGetUsernameFromHandle(stringEnsurePrefix(url, '@')) ?? null;
        }
    }
}

export function socialLinkResolveTypeFromUrl (url: string) {

    for (const socialLinkType of Object.values(SocialLinksType)) {
        if (socialLinkIsValid(socialLinkType, url)) {
            return socialLinkType;
        }
    }

    return undefined;
}

export function socialLinkGetUsernameFromHandle (handle: string) {
    return /^@[\w._]+$/.test(handle) ? stringTrimPrefix(handle, '@') : undefined;
}

export function socialLinkIsValidUserNameHandle (handle: string) {
    return /^@?[\w._]+$/.test(handle);
}