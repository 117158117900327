import {bymoTemplateMetadataFactoryCreate} from "../../../doppe-sdk";
import {DoppeDtoAction} from "../../../client-server-common/types/doppe-dto-action";
import {DoppeBymoPageActionPreviewSettings, DoppeBymoPageActionsDividerSettings} from "../../../client-server-common";

export namespace BymoTemplateActionPreview {

    export interface TemplateProps {
        enableContentScrolling: boolean;
        actionsDivider: DoppeBymoPageActionsDividerSettings | null;
        defaultActionPreviewSettings: DoppeBymoPageActionPreviewSettings | null;
        actionsPreviewSettings: Record<DoppeDtoAction.Id, DoppeBymoPageActionPreviewSettings>;
        scaledContentTrimmingSettings: {
            maxContentHeight: number;
            easingGradientOverlayColor: string;
        } | null;
    }
}

export const BymoTemplateActionPreviewTemplateMetadata = bymoTemplateMetadataFactoryCreate<BymoTemplateActionPreview.TemplateProps>()({
    initialValues: {
        enableContentScrolling: true,
        actionsDivider: null,
        actionsPreviewSettings: {},
        defaultActionPreviewSettings: null,
        scaledContentTrimmingSettings: null
    } as BymoTemplateActionPreview.TemplateProps,
    features: ['hasActions', 'hasActionButton']
})