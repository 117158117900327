import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeLinksList} from "./doppe-action-type-links-list";

export const doppeActionTypeLinksListViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeLinksList, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeLinksListWidget: (await import('./doppe-action-type-links-list-widget/doppe-action-type-links-list-widget')).DoppeActionTypeLinksListWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeLinksListWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeLinksListWidget
                {...renderProps}
            />
        );

    }
})