import {Values} from "@wix/devzai-utils-common";

export const DoppeActionLayout = {
    Column: 'Column',
    Grid: 'Grid',
    Carousel: 'Carousel',
    CarouselSmall: 'CarouselSmall',
    Swiper: 'Swiper',
    ImagesGrid: 'ImagesGrid',
    LargeColumn: 'LargeColumn',
} as const;

export type DoppeActionLayout = Values<typeof DoppeActionLayout>;

export function doppeActionLayoutCanHideImage (doppeActionLayout: DoppeActionLayout) {
    switch (doppeActionLayout) {
        case DoppeActionLayout.Column: {
            return true;
        }
        default: {
            return false;
        }
    }
}

export function doppeActionLayoutCanHideDescription (doppeActionLayout: DoppeActionLayout) {
    switch (doppeActionLayout) {
        case DoppeActionLayout.LargeColumn:
        case DoppeActionLayout.Column: {
            return true;
        }
        default: {
            return false;
        }
    }
}

export function doppeActionLayoutSupportsDescription (doppeActionLayout: DoppeActionLayout) {
    switch (doppeActionLayout) {
        case DoppeActionLayout.LargeColumn:
        case DoppeActionLayout.Column: {
            return true;
        }
        default: {
            return false;
        }
    }
}

export function doppeActionLayoutCanHideTitle (doppeActionLayout: DoppeActionLayout) {
    switch (doppeActionLayout) {
        case DoppeActionLayout.LargeColumn:
        case DoppeActionLayout.Swiper:
        case DoppeActionLayout.CarouselSmall:
        case DoppeActionLayout.Carousel:
        case DoppeActionLayout.Grid: {
            return true;
        }
        default: {
            return false;
        }
    }
}


export function doppeActionLayoutCanHidePrice (doppeActionLayout: DoppeActionLayout) {
    switch (doppeActionLayout) {
        case DoppeActionLayout.Column: {
            return false;
        }
        default: {
            return true;
        }
    }
}

export function doppeActionLayoutCanHideDate (_doppeActionLayout: DoppeActionLayout) {
    return true;
}




