import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeLockActionTypeVideo} from './doppe-lock-action-type-video';

export const doppeLockActionTypeVideoViewer = doppeActionTypeDefineViewerMetadata(doppeLockActionTypeVideo, {

    loadViewerComponents: async () => {
        return {
            DoppeLockActionTypeWidgetVideo: (await import(
                './doppe-lock-action-type-widget-video/doppe-lock-action-type-widget-video'
            )).DoppeLockActionTypeWidgetVideo
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeLockActionTypeWidgetVideo
        } = viewerComponents

        return (
            <DoppeLockActionTypeWidgetVideo
                {...renderProps}
            />
        )
    },

    activateCTA: actionContext => {
        actionContext.unlockLockAction()
    }
})