import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {
    areaEqualityComparer,
    assertNotNullable,
    EqualityComparer,
    equalityComparerCompare,
    IArea,
    identity,
    objectShallowEqual,
    referencesEqualityComparer,
    returnFalse,
    Size,
    sizeEqualityComparer,
    sizeGetHeight,
    sizeGetWidth
} from '@wix/devzai-utils-common';
import {LayoutTracker, resizeObserverIsSupported, resizeObserverObserveElement} from '@wix/devzai-utils-dom';
import {useBoxedValue, useRenderState} from './use-boxed-value/use-boxed-value';
import {useStateWithCustomComparer} from './use-state-with-custom-comparer';

export function useElementSizeBinding(elementRef: React.RefObject<HTMLElement>, isEnabled = true) {

    if (resizeObserverIsSupported()) {
        return useElementSizeBindingWithResizeObserver(
            elementRef,
            isEnabled,
            identity,
            sizeEqualityComparer)
    } else {
        return useElementLayoutBinding(elementRef, getElementOuterSize, objectShallowEqual, isEnabled);
    }
}

export function useElementClientSizeBinding(elementRef: React.RefObject<HTMLElement>, isEnabled = true) {
    return useElementLayoutBinding(elementRef, getElementClientSize, objectShallowEqual, isEnabled);
}

export function useElementWidthBinding(elementRef: React.RefObject<HTMLElement>, isEnabled = true) {
    if (resizeObserverIsSupported()) {
        return useElementSizeBindingWithResizeObserver(
            elementRef,
            isEnabled,
            sizeGetWidth,
            referencesEqualityComparer)
    } else {
        return useElementLayoutBinding(elementRef, getElementOffsetWidth, referencesEqualityComparer, isEnabled);
    }
}

export function useElementHeightBinding(elementRef: React.RefObject<HTMLElement>, isEnabled = true) {

    if (resizeObserverIsSupported()) {
        return useElementSizeBindingWithResizeObserver(
            elementRef,
            isEnabled,
            sizeGetHeight,
            referencesEqualityComparer)
    } else {
        return useElementLayoutBinding(elementRef, getElementOffsetHeight, referencesEqualityComparer, isEnabled);
    }
}

export function useElementClientAreaBinding(elementRef: React.RefObject<HTMLElement>, isEnabled = true) {
    return useElementLayoutBinding(elementRef, getElementClientArea, areaEqualityComparer, isEnabled);
}

export function useElementClientAreaChangeCallback(
    elementRef: React.RefObject<HTMLElement>,
    callback: (value: IArea) => void,
    isEnabled = true
) {
    return useElementLayoutChangeCallback(elementRef, getElementClientArea, areaEqualityComparer, callback, isEnabled);
}

export function useElementLayoutChangeCallback<VALUE>(
    elementRef: React.RefObject<HTMLElement>,
    valueGetter: (element: HTMLElement) => VALUE,
    equalityComparer: EqualityComparer<VALUE>,
    callback: (value: VALUE) => void,
    isEnabled = true
) {
    return useLayoutChangeCallback(
        useCallback(() => {
            return valueGetter(elementRef.current!);
        }, [elementRef, valueGetter]),
        equalityComparer,
        callback,
        isEnabled
    );
}

export function useElementLayoutBinding<VALUE>(
    elementRef: React.RefObject<HTMLElement>,
    valueGetter: (element: HTMLElement) => VALUE,
    equalityComparer: EqualityComparer<VALUE>,
    isEnabled = true
) {
    return useLayoutBinding(
        useCallback(() => {
            return valueGetter(elementRef.current!);
        }, [elementRef, valueGetter]),
        equalityComparer,
        isEnabled
    );
}

export function useLayoutBinding<VALUE>(
    valueGetter: () => VALUE,
    equalityComparer: EqualityComparer<VALUE>,
    isEnabled = true
) {
    const [value, setValue] = useState<VALUE | null>(null);

    useLayoutChangeCallback(valueGetter, equalityComparer, setValue, isEnabled);

    return value;
}

export function useLayoutConditionMetCallback(
    conditionPredicate: () => boolean,
    callback: () => void,
    isEnabled = true
) {
    const renderState = useRenderState({
        callback: callback,
        isEnabled: isEnabled
    });

    const valueChangeCallback = useCallback((isConditionMet) => {
        if (isConditionMet && renderState.current.isEnabled) {
            renderState.current.callback();
        }
    }, [renderState]);

    useEffect(() => {
        const layoutTracker = isEnabled
            ? new LayoutTracker(() => conditionPredicate(), {
                equalityComparer: returnFalse,
                valueChangeCallback: valueChangeCallback
            }).activate()
            : undefined;

        return () => {
            if (layoutTracker) {
                layoutTracker.deactivate();
            }
        };
    }, [conditionPredicate, isEnabled, valueChangeCallback]);
}

export function useLayoutChangeCallback<VALUE>(
    valueGetter: () => VALUE,
    equalityComparer: EqualityComparer<VALUE>,
    callback: (value: VALUE) => void,
    isEnabled = true
) {
    useLayoutEffect(() => {
        const layoutTracker = isEnabled
            ? new LayoutTracker(() => valueGetter(), {
                  equalityComparer: equalityComparer,
                  valueChangeCallback: value => {
                      callback(value);
                  }
              }).activate()
            : undefined;

        return () => {
            if (layoutTracker) {
                layoutTracker.deactivate();
            }
        };
    }, [valueGetter, equalityComparer, isEnabled, callback]);
}

function getElementClientArea(element: HTMLElement) {
    return element.getBoundingClientRect();
}

function getElementOffsetHeight(element: HTMLElement) {
    return element.offsetHeight;
}

function getElementOffsetWidth(element: HTMLElement) {
    return element.offsetWidth;
}

function getElementOuterSize(element: HTMLElement) : Size {
    return {
        width: element.offsetWidth,
        height: element.offsetHeight
    };
}

function getElementClientSize(element: HTMLElement) {
    const boundingClientRect = element.getBoundingClientRect();

    return {
        width: boundingClientRect.width,
        height: boundingClientRect.height
    };
}

function useElementSizeBindingWithResizeObserver<VALUE> (
    elementRef: React.RefObject<HTMLElement>,
    isEnabled: boolean,
    valueSelector: (size: Size) => VALUE,
    equalityComparer: EqualityComparer<VALUE>
) {
    const [currentValue, setCurrentValue] = useStateWithCustomComparer<VALUE | null>(
        null,
        ((value1, value2) => {
            return equalityComparerCompare(equalityComparer, value1, value2)
        }))

    const renderState = useBoxedValue({
        valueSelector,
        setCurrentValue
    })

    useLayoutEffect(() => {

        const {
            valueSelector,
            setCurrentValue
        } = renderState.current;

        if (isEnabled) {

            const element = assertNotNullable(elementRef.current);

            const binding = resizeObserverObserveElement(
                element,
                size => {
                    setCurrentValue(valueSelector(size))
                })

            return () => {
                binding.dispose();
            }
        } else {
            return undefined;
        }

    }, [elementRef, isEnabled, renderState]);

    return currentValue;
}
