export type HashFunction<T> = (value: T) => HashValue<T>;

export type HashingPrimitive = string | number | boolean | undefined | null | HashingPrimitive[];

type HashRules<T> = (ObjectHashRules<T> | (keyof T)[]);

export type HashValue<_T> = string;

export const primitivesHashingFunction: HashFunction<HashingPrimitive> = (value: HashingPrimitive) => {
    switch (typeof value) {
        case 'number':
        case 'boolean':
        case 'string':
            return value.toString();
        default:
            return JSON.stringify(value);
    }
};

export const defaultHashFunction: HashFunction<unknown> = (value: unknown) => {
    switch (typeof value) {
        case 'number':
        case 'boolean':
        case 'string':
            return value.toString();
        default:
            return JSON.stringify(value);
    }
};

export function hashFunctionCreate<T extends HashingPrimitive> () : HashFunction<T>;
export function hashFunctionCreate<T> (hashFunction: HashFunction<T>) : HashFunction<T>;
export function hashFunctionCreate<T> (hashRules: HashRules<T>) : HashFunction<T>;
export function hashFunctionCreate<T> (
    hashFunctionOrHashRules?: T extends Record<string, unknown> ? HashRules<T> : HashFunction<T>
) : HashFunction<T> {

    if (typeof hashFunctionOrHashRules === 'function') {
        return hashFunctionOrHashRules;
    } else if (hashFunctionOrHashRules === undefined) {
        return defaultHashFunction;
    } else {

        const objectHashRules = hashFunctionOrHashRules as HashRules<any>;

        if (Array.isArray(objectHashRules)) {
            return ((value: T) => {
                return JSON.stringify(
                    objectHashRules.map(key => defaultHashFunction((value as Record<string, unknown>)?.[key as string]))
                );
            })
        } else {
            const objectKeys = Object.keys(objectHashRules).sort();

            return ((value: T) => {
                return JSON.stringify(
                    objectKeys.map(key => objectHashRules[key]((value as Record<string, unknown>)?.[key]))
                );
            })
        }
    }
}

export type ObjectHashRules<T> = {
    [key in keyof T]: HashFunction<T[key]>;
}
