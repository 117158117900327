export function pathBasename (path: string, pathSeparator = '/') {
    return path.substring(path.lastIndexOf(pathSeparator) + 1);
}

export function pathDirname (path: string, pathSeparator = '/') {
    return path.substring(0, path.lastIndexOf(pathSeparator) + 1);
}

export function pathJoin (parts: string[], pathSeparator = '/'){
    const regExp = new RegExp(pathSeparator + '{1,}', 'g');
    return parts.join(pathSeparator).replace(regExp, pathSeparator);
}

export function pathIsAbsolute (path: string) {
    if ('/' === path[0]) {
        return true;
    } else if (':' === path[1] && ('\\' === path[2] || '/' === path[2])) { // Windows device path
        return true;
    } else if ('\\\\' === path.substring(0, 2)) { // Microsoft Azure absolute path
        return true;
    } else {
        return false;
    }
}

export function pathNormalizeFileImport (filePath: string) {
    const normalizedPath = filePath.replace(/\\/g, '/');
    if (pathIsAbsolute(normalizedPath) || normalizedPath.startsWith('.')) {
        return normalizedPath;
    } else {
        return './' + normalizedPath;
    }
}

export function pathIsRelativeOuter (path: string) {
    return /^\.\.?[/\\]/.test(path);
}

export function pathGetExtension (path: string) {
    return path.substring(path.lastIndexOf('.'));
}

export function pathRemoveExtension (path: string) {
    return path.substring(0, path.lastIndexOf('.'));
}