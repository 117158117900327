import { EqualityComparer, EventEmitter, EventReference, referencesEqualityComparer } from '@wix/devzai-utils-common';
import { ReadWriteCoordinator, ReadWriteFunctionsEntry } from '../index';

interface LayoutTracker_EventTypes<T> {
    updated: T;
}

export class LayoutTracker<T> {
    private eventEmitter = new EventEmitter<LayoutTracker_EventTypes<T>>();

    private value?: T = undefined;
    private valueChangeCallback?: (value: T) => void;
    private equalityComparer: EqualityComparer<T>;
    private readWriteEntry?: ReadWriteFunctionsEntry<T> = undefined;

    constructor(
        private valueReader: () => T,
        options: {
            valueChangeCallback?: (value: T) => void;
            equalityComparer?: EqualityComparer<T>;
        } = {}
    ) {
        const { valueChangeCallback, equalityComparer = referencesEqualityComparer } = options;

        this.valueChangeCallback = valueChangeCallback;
        this.equalityComparer = equalityComparer;
    }

    public get eventUpdated(): EventReference<LayoutTracker_EventTypes<T>, 'updated'> {
        return this.eventEmitter.createEventReference('updated');
    }

    public getValue() {
        return this.value;
    }

    public activate() {
        if (this.readWriteEntry === undefined) {
            this.readWriteEntry = ReadWriteCoordinator.coordinateReadWrite(this.valueReader, this._testValue);
            this._testValue(this.valueReader());
        }

        return this;
    }

    public deactivate() {
        const readWriteEntry = this.readWriteEntry;
        if (readWriteEntry !== undefined) {
            ReadWriteCoordinator.unregisterEntry(readWriteEntry);
        }

        return this;
    }

    public testValue() {
        this._testValue(this.valueReader());

        return this.value;
    }

    private _testValue = (value: T) => {
        const prevValue = this.value;

        if (prevValue === undefined || !this.equalityComparer(value, prevValue)) {
            this.value = value;
            this.eventEmitter.emit('updated' as any, value);
            const valueChangeCallback = this.valueChangeCallback;
            if (valueChangeCallback) {
                valueChangeCallback(value);
            }
        }
    };
}
