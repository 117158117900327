import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeContactCardList} from './doppe-action-type-contact-card-list';

export const doppeActionTypeContactCardListViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeContactCardList, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeContactCardListWidget: (await import(
                './doppe-action-type-contact-card-list-widget/doppe-action-type-contact-card-list-widget'
                )).DoppeActionTypeContactCardListWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeContactCardListWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeContactCardListWidget
                {...renderProps}
            />
        );

    }
})