import React from 'react';
import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {doppeLockActionTypeContactForm} from './doppe-lock-action-type-contact-form';

export const doppeLockActionTypeContactFormViewer = doppeActionTypeDefineViewerMetadata(doppeLockActionTypeContactForm, {

    loadViewerComponents: async () => {
        return {
            DoppeLockActionTypeContactFormWidget: (await import('./doppe-lock-action-type-contact-form-widget/doppe-lock-action-type-contact-form-widget')).DoppeLockActionTypeContactFormWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeLockActionTypeContactFormWidget
        } = viewerComponents;

        return (
            <DoppeLockActionTypeContactFormWidget
                {...renderProps}
            />
        )
    }
})