import {
    AllDirectionsFlags,
    assertDefined,
    createDirectionsValues,
    Direction,
    DirectionsValues,
    EventListenersGroup,
    generateAllDirectionsValues,
    generateDirectionsFlags,
    IEventListener,
    mathNormalizeToRange,
    Point,
    setMissingDirectionsValues
} from '@wix/devzai-utils-common';
import {
    BrowserAnimationFrameScheduler,
    DomEventListener,
    IAnimationFrameScheduler,
    PointerLikeEvent,
    pointerLikeEventGetPoint,
    pointerLikeEventIsTouchEvent
} from '../index';

const Config = {
    swipeMilliseconds: 300,
    defaultInitiationDistance: 30,
    swipeDistance: 30
};

export interface ActivePointerInteractionCallbacks {
    moveCallback?: MouseInteractionCallback;
    endCallback?: MouseInteractionCallback;
}

export interface MouseInteractionOptions {
    touchMoveTarget?: Element;
    distance?: Partial<DirectionsValues<number>>;
    maxDistance?: Partial<DirectionsValues<number>>;
    abortDistance?: Partial<DirectionsValues<number>>;
    allowedDirections?: Direction[];
    restrictToStartingDirection?: boolean;
    useFixedOffsetForEvents?: boolean;
    startCallback?: MouseInteractionStartCallback;
    cancelCallback?: MouseInteractionCallback;
    preStartMoveCallback?: MouseInteractionCallback;
    syncWithAnimationFrame?: boolean | IAnimationFrameScheduler;
    /**
     * When 'useFixedOffsetForEvents' is false, this is the element which scroll value will
     * affect the total interaction delta. The default value of the scroll parent is the document.
     */
    scrollParent?: Element | null;
}

const noopMouseInteractionCallback = () => {};

export type MouseInteractionCallback = (interaction: PointerInteraction, event?: Event) => void;
export type MouseInteractionStartCallback = (
    interaction: PointerInteraction,
    event?: Event
) => ActivePointerInteractionCallbacks;

const ZeroDistance = generateAllDirectionsValues(0);
const InfiniteDistance = generateAllDirectionsValues(Infinity);

export class PointerInteraction {
    private static animationFrameScheduler: IAnimationFrameScheduler = BrowserAnimationFrameScheduler;

    /**
     * The horizontal difference between current mouse offset and the starting mouse offset.
     * Note: Since the interaction might not start immediately, this value can be undefined.
     */
    private _deltaX?: number;
    /**
     * The vertical difference between current mouse offset and the starting mouse offset.
     * Note: Since the interaction might not start immediately, this value can be undefined.
     */
    private _deltaY?: number;

    public startingX?: number = undefined;
    public startingY?: number = undefined;

    public prevX?: number = undefined;
    public prevY?: number = undefined;

    private _isAborted = false;
    public interactionStarted = false;

    public startingReachedDirections?: DirectionsValues<boolean> = undefined;
    private _distance: DirectionsValues<number>;
    private _maxDistance: DirectionsValues<number>;
    private _abortDistance?: DirectionsValues<number>;

    public initialX: number;
    public initialY: number;
    public currentX: number;
    public currentY: number;

    private _initialTime: number;
    public lastMovingTime?: number = undefined;
    public endingTime?: number = undefined;

    private _allowedDirections!: DirectionsValues<boolean>;
    private _minDeltaX!: number;
    private _maxDeltaX!: number;
    private _minDeltaY!: number;
    private _maxDeltaY!: number;

    private _restrictToStartingDirection: boolean;
    private _startCallback?: MouseInteractionStartCallback;
    private _moveCallback?: MouseInteractionCallback = undefined;
    private _endCallback?: MouseInteractionCallback = undefined;
    private _cancelCallback: MouseInteractionCallback;
    private _preStartMoveCallback: MouseInteractionCallback;

    private _useFixedOffsetForEvents: boolean;

    private scrollParent;
    private lastKnownInteractionFixedOffset?: Point = undefined;
    private initialInteractionFixedOffset?: Point = undefined;

    private _touchMoveTarget?: Element = undefined;

    public _lastInteractionEvent?: Event = undefined;
    public _startingEvent?: Event = undefined;

    private _interactionListener: IEventListener;

    private _syncWithAnimationFrame: boolean | IAnimationFrameScheduler;
    private _moveCallbackAnimationFrame?: () => void = undefined;

    constructor(
        initiatingEvent: PointerLikeEvent,
        startImmediately: boolean,
        options: MouseInteractionOptions = {}
    ) {
        const {
            distance,
            allowedDirections,
            maxDistance,
            abortDistance,
            touchMoveTarget,
            syncWithAnimationFrame = true,
            useFixedOffsetForEvents = false,
            scrollParent = null,
            restrictToStartingDirection = false,
            startCallback,
            cancelCallback = noopMouseInteractionCallback,
            preStartMoveCallback = noopMouseInteractionCallback
        } = options;

        this._touchMoveTarget = touchMoveTarget;
        this._useFixedOffsetForEvents = useFixedOffsetForEvents;
        this._restrictToStartingDirection = restrictToStartingDirection;
        this._startCallback = startCallback;
        this._cancelCallback = cancelCallback;
        this._preStartMoveCallback = preStartMoveCallback;
        this._syncWithAnimationFrame = syncWithAnimationFrame;
        this.scrollParent = scrollParent;

        this._distance =
            startImmediately || distance === undefined ? ZeroDistance : setMissingDirectionsValues(distance, 0);

        this._maxDistance =
            maxDistance !== undefined ? setMissingDirectionsValues(maxDistance, Infinity) : InfiniteDistance;

        this._abortDistance =
            abortDistance !== undefined ? setMissingDirectionsValues(abortDistance, Infinity) : undefined;

        this.setAllowedDirections(allowedDirections ? generateDirectionsFlags(allowedDirections) : AllDirectionsFlags);

        const nativeInteractionEvent = initiatingEvent;

        const initialFixedOffset = this.initialInteractionFixedOffset = this.lastKnownInteractionFixedOffset =
            pointerLikeEventGetPoint(nativeInteractionEvent, true);

        const initialOffset = this.resolveInteractionOffset(initialFixedOffset);
        const initialX = (this.initialX = this.currentX = initialOffset.x);
        const initialY = (this.initialY = this.currentY = initialOffset.y);

        this._initialTime = initiatingEvent.timeStamp;

        this._interactionListener = this.createInteractionListener(nativeInteractionEvent).activate();

        if (startImmediately) {
            this._startInteraction(nativeInteractionEvent, initialX, initialY, true, true, true, true);
        }
    }

    public getAnimationFrameScheduler(): IAnimationFrameScheduler | undefined {
        const syncWithAnimationFrame = this._syncWithAnimationFrame;

        if (syncWithAnimationFrame === false) {
            return undefined;
        } else if (syncWithAnimationFrame === true) {
            return PointerInteraction.animationFrameScheduler;
        } else {
            return syncWithAnimationFrame;
        }
    }

    public getScrollParent () {
        return this.scrollParent;
    }

    private setAllowedDirections(allowedDirections: DirectionsValues<boolean>) {
        this._allowedDirections = allowedDirections;

        const maxDistance = this._maxDistance;

        this._minDeltaX = Math.max(allowedDirections[Direction.Left] ? -Infinity : 0, -maxDistance[Direction.Left]);
        this._maxDeltaX = Math.min(allowedDirections[Direction.Right] ? Infinity : 0, maxDistance[Direction.Right]);
        this._minDeltaY = Math.max(allowedDirections[Direction.Up] ? -Infinity : 0, -maxDistance[Direction.Up]);
        this._maxDeltaY = Math.min(allowedDirections[Direction.Down] ? Infinity : 0, maxDistance[Direction.Down]);
    }

    private resolveInteractionOffset (interactionFixedOffset: Point) {

        if (this._useFixedOffsetForEvents) {
            return interactionFixedOffset;
        } else {
            const scrollParent = this.scrollParent ?? document.documentElement;

            return {
                x: interactionFixedOffset.x + scrollParent.scrollLeft,
                y: interactionFixedOffset.y + scrollParent.scrollTop
            }
        }
    }

    private _startInteraction(
        startingEvent: Event,
        currentX: number,
        currentY: number,
        reachedUpDistance: boolean,
        reachedRightDistance: boolean,
        reachedDownDistance: boolean,
        reachedLeftDistance: boolean
    ) {
        const distance = this._distance;
        const initialX = this.initialX;
        const initialY = this.initialY;

        this.interactionStarted = true;
        this._startingEvent = startingEvent;

        if (reachedRightDistance) {
            this.startingX = Math.min(initialX + distance[Direction.Right], currentX);
        } else if (reachedLeftDistance) {
            this.startingX = Math.max(initialX - distance[Direction.Left], currentX);
        } else {
            this.startingX = currentX;
        }

        if (reachedDownDistance) {
            this.startingY = Math.min(initialY + distance[Direction.Down], currentY);
        } else if (reachedUpDistance) {
            this.startingY = Math.max(initialY - distance[Direction.Up], currentY);
        } else {
            this.startingY = currentY;
        }

        this.startingReachedDirections = createDirectionsValues(
            reachedUpDistance,
            reachedRightDistance,
            reachedDownDistance,
            reachedLeftDistance
        );

        if (this._restrictToStartingDirection) {
            if (reachedLeftDistance) {
                this.setAllowedDirections(generateDirectionsFlags([Direction.Left]));
            } else if (reachedRightDistance) {
                this.setAllowedDirections(generateDirectionsFlags([Direction.Right]));
            } else if (reachedUpDistance) {
                this.setAllowedDirections(generateDirectionsFlags([Direction.Up]));
            } else if (reachedDownDistance) {
                this.setAllowedDirections(generateDirectionsFlags([Direction.Down]));
            }
        }

        const startCallback = this._startCallback;
        if (startCallback) {
            const { moveCallback, endCallback } = startCallback(this, startingEvent);

            this._moveCallback = moveCallback;
            this._endCallback = endCallback;
        }
    }

    private createInteractionListener(initiatingNativeEvent: PointerLikeEvent): IEventListener {
        const eventListener = new EventListenersGroup();

        const isInitiatingTouchEvent = pointerLikeEventIsTouchEvent(initiatingNativeEvent);
        const interactionTouchMoveTarget = this._touchMoveTarget;
        const touchMoveTargetElement =
            interactionTouchMoveTarget && isInitiatingTouchEvent ? interactionTouchMoveTarget : document;

        if (!this._useFixedOffsetForEvents) {
            eventListener.add(
                new DomEventListener(
                    !this.scrollParent || this.scrollParent === document.documentElement ?
                        window :
                        this.scrollParent,
                    'scroll',
                    (event) => {
                        this.handleInteractionEvent(event);
                    }
                )
            )
        }

        eventListener.add(
            new DomEventListener(
                touchMoveTargetElement,
                isInitiatingTouchEvent ? 'touchmove' : 'mousemove',
                (e: PointerLikeEvent) => {
                    /**
                     * TODO: I commented out the logic below since I don't know what was its purpose.
                     * TODO: With the logic below, in IOS initiated interactions are being canceled since the touchMove
                     * TODO: event in IOS is cancelable.
                     */
                    // if (!e.cancelable) {
                    //     if (!this.interactionStarted) {
                    //         this.abort();
                    //
                    //         return;
                    //     } else {
                    //         console.warn(
                    //             'TouchInteraction: non cancelable touchmove event was fired after the interaction was started.'
                    //         );
                    //     }
                    // }

                    this.lastKnownInteractionFixedOffset = pointerLikeEventGetPoint(e, true);

                    const ignoreMove = pointerLikeEventIsTouchEvent(e) && e.touches.length > 1;
                    if (!ignoreMove) {
                        this.handleInteractionEvent(e);
                    }

                    if (isInitiatingTouchEvent) {
                        e.preventDefault();
                    }

                    // Only after the interaction is started we can stop propagation, since until then all the interaction
                    // initiators should get the move event to determine whether or not to start an interaction.
                    if (this.interactionStarted) {
                        e.stopPropagation();
                    }
                }
            )
        );

        eventListener.add(
            new DomEventListener(
                document,
                isInitiatingTouchEvent ? 'touchend' : 'mouseup',
                (e: PointerLikeEvent) => {
                    const ignoreEnd = pointerLikeEventIsTouchEvent(e) && e.touches.length > 1;
                    if (!ignoreEnd) {
                        this._handleEnd(e);
                    }
                }
            )
        );

        if (isInitiatingTouchEvent) {
            eventListener.add(
                new DomEventListener(document, 'touchcancel', (e: PointerLikeEvent) => {
                    this._handleEnd(e);
                })
            );
        }

        return eventListener;
    }

    public isAborted() {
        return this._isAborted;
    }

    public getCurrentFixedY () {
        return assertDefined(this.lastKnownInteractionFixedOffset).y;
    }

    public getCurrentFixedX () {
        return assertDefined(this.lastKnownInteractionFixedOffset).x;
    }

    public getCurrentOffset() {
        return {
            top: this.currentY,
            left: this.currentX
        };
    }

    public handleInteractionEvent(interactionEvent?: Event) {

        if (this.isAborted()) {
            return;
        }

        const lastKnownInteractionFixedOffset = assertDefined(this.lastKnownInteractionFixedOffset);

        const normalizedOffset = this.resolveInteractionOffset(lastKnownInteractionFixedOffset);
        const currentX = normalizedOffset.x;
        const currentY = normalizedOffset.y;

        this.prevX = this.currentX;
        this.prevY = this.currentY;

        this.currentX = currentX;
        this.currentY = currentY;
        this.lastMovingTime = interactionEvent?.timeStamp ?? Date.now();

        let interactionStarted = this.interactionStarted;
        if (!interactionStarted) {
            const initialX = this.initialX;
            const initialY = this.initialY;

            const preStartDeltaX = currentX - initialX;
            const preStartDeltaY = currentY - initialY;

            const abortDistance = this._abortDistance;

            if (abortDistance !== undefined) {
                const shouldAbortInteraction =
                    hasPassedDistanceInDirection(
                        Direction.Left,
                        abortDistance[Direction.Left],
                        preStartDeltaX,
                        preStartDeltaY
                    ) ||
                    hasPassedDistanceInDirection(
                        Direction.Right,
                        abortDistance[Direction.Right],
                        preStartDeltaX,
                        preStartDeltaY
                    ) ||
                    hasPassedDistanceInDirection(
                        Direction.Up,
                        abortDistance[Direction.Up],
                        preStartDeltaX,
                        preStartDeltaY
                    ) ||
                    hasPassedDistanceInDirection(
                        Direction.Down,
                        abortDistance[Direction.Down],
                        preStartDeltaX,
                        preStartDeltaY
                    );

                if (shouldAbortInteraction) {
                    this.abort();

                    return;
                }
            }

            const distance = this._distance;
            const reachedRightDistance =
                this._allowedDirections[Direction.Right] && preStartDeltaX > distance[Direction.Right];
            const reachedLeftDistance =
                this._allowedDirections[Direction.Left] && preStartDeltaX < -distance[Direction.Left];
            const reachedDownDistance =
                this._allowedDirections[Direction.Down] && preStartDeltaY > distance[Direction.Down];
            const reachedUpDistance = this._allowedDirections[Direction.Up] && preStartDeltaY < -distance[Direction.Up];

            if (reachedRightDistance || reachedLeftDistance || reachedDownDistance || reachedUpDistance) {
                interactionStarted = true;

                this._startInteraction(
                    assertDefined(interactionEvent),
                    currentX,
                    currentY,
                    reachedUpDistance,
                    reachedRightDistance,
                    reachedDownDistance,
                    reachedLeftDistance
                );
            } else {
                this._preStartMoveCallback(this, interactionEvent);
            }
        }

        if (interactionStarted) {
            const deltaX = mathNormalizeToRange(currentX - this.startingX!, this._minDeltaX, this._maxDeltaX);
            const deltaY = mathNormalizeToRange(currentY - this.startingY!, this._minDeltaY, this._maxDeltaY);

            if (deltaX !== this._deltaX || deltaY !== this._deltaY) {
                this._deltaX = deltaX;
                this._deltaY = deltaY;
                this._lastInteractionEvent = interactionEvent;

                // Clear previous scheduled animation frame.
                const moveCallbackAnimationFrame = this._moveCallbackAnimationFrame;
                if (moveCallbackAnimationFrame) {
                    moveCallbackAnimationFrame();
                }

                const moveCallback = this._moveCallback;
                if (moveCallback) {
                    const animationFrameScheduler = this.getAnimationFrameScheduler();
                    if (animationFrameScheduler) {
                        this._moveCallbackAnimationFrame = animationFrameScheduler.requestAnimationFrame(() => {
                            moveCallback(this, interactionEvent);
                        });
                    } else {
                        moveCallback(this, interactionEvent);
                    }
                }
            }
        }

        return interactionStarted;
    }

    public abort() {
        if (!this._isAborted) {
            this._isAborted = true;

            this._handleEnd();
        }
    }

    public isAllowedDirection(direction: Direction) {
        return this._allowedDirections[direction];
    }

    public getTotalDuration() {
        const endingTime = this.endingTime;

        return endingTime !== undefined ? endingTime - this._initialTime : undefined;
    }

    /**
     * Returns the horizontal distance (in pixels) that the interaction has crossed since its INITIATION.
     * Important: The distance calculation doesn't consider the allowed directions of the interaction, which
     * means that even if horizontal direction isn't allowed, the distance won't be 0.
     * @returns {number}
     */
    public getTotalDeltaX() {
        return mathNormalizeToRange(this.currentX - this.initialX, this._minDeltaX, this._maxDeltaX);
    }

    public getTotalDeltaY() {
        return mathNormalizeToRange(this.currentY - this.initialY, this._minDeltaY, this._maxDeltaY);
    }

    public getTotalFixedDeltaX () {
        return assertDefined(this.lastKnownInteractionFixedOffset).x - assertDefined(this.initialInteractionFixedOffset).x;
    }

    public getTotalFixedDeltaY () {
        return assertDefined(this.lastKnownInteractionFixedOffset).y - assertDefined(this.initialInteractionFixedOffset).y;
    }

    private _handleEnd(event?: PointerLikeEvent) {
        this._interactionListener.deactivate();

        this.endingTime = event ? event.timeStamp : Date.now();

        // We'll call the endCallback only if the interaction has actually started.
        if (this.interactionStarted) {
            const endCallback = this._endCallback;

            if (endCallback) {
                const animationFrameScheduler = this.getAnimationFrameScheduler();
                if (animationFrameScheduler) {
                    animationFrameScheduler.requestAnimationFrame(() => {
                        endCallback(this, event);
                    });
                } else {
                    endCallback(this, event);
                }
            }
        } else {
            this._cancelCallback(this, event);
        }
    }

    public static initiate(initiatingEvent: PointerLikeEvent, options?: MouseInteractionOptions) {
        return new PointerInteraction(initiatingEvent, false, options);
    }

    public static start(initiatingEvent: PointerLikeEvent, options?: MouseInteractionOptions) {
        return new PointerInteraction(initiatingEvent, true, options);
    }

    public static setDefaultAnimationFrameScheduler(animationFrameScheduler: IAnimationFrameScheduler) {
        this.animationFrameScheduler = animationFrameScheduler;
    }

    public static resetDefaultAnimationFrameScheduler() {
        this.animationFrameScheduler = BrowserAnimationFrameScheduler;
    }
}

function hasPassedDistanceInDirection(direction: Direction, distance: number, deltaX: number, deltaY: number) {
    switch (direction) {
        case Direction.Up:
            return deltaY < -distance;
        case Direction.Down:
            return deltaY > distance;
        case Direction.Left:
            return deltaX < -distance;
        case Direction.Right:
            return deltaX > distance;
        default:
            throw new Error(`Not supported direction value '${direction}'`);
    }
}

export function pointerInteractionGetLastStepY (pointerInteraction: PointerInteraction) {
    const prevY = pointerInteraction.prevY;

    return prevY !== undefined ? pointerInteraction.currentY - prevY : undefined;
}

export function pointerInteractionGetLastStepX (pointerInteraction: PointerInteraction) {
    const prevX = pointerInteraction.prevX;

    return prevX !== undefined ? pointerInteraction.currentX - prevX : undefined;
}

export function pointerInteractionGetLastHorizontalDirection (pointerInteraction: PointerInteraction) {
    const prevX = pointerInteraction.prevX;

    if (prevX !== undefined) {
        return pointerInteraction.currentX > prevX ? Direction.Down : Direction.Up;
    } else {
        return undefined;
    }
}

export function pointerInteractionGetLastVerticalDirection (pointerInteraction: PointerInteraction) {
    const prevY = pointerInteraction.prevY;

    if (prevY !== undefined) {
        return pointerInteraction.currentY > prevY ? Direction.Down : Direction.Up;
    } else {
        return undefined;
    }
}

export function pointerInteractionIsStartingDirection(pointerInteraction: PointerInteraction, direction: Direction) {
    const startingReachedDirections = pointerInteraction.startingReachedDirections;

    if (!startingReachedDirections) {
        return false;
    }

    return startingReachedDirections[direction];
}

export function pointerInteractionIsHorizontalStartingDirection(pointerInteraction: PointerInteraction) {
    return pointerInteractionIsStartingDirection(pointerInteraction, Direction.Left) ||
        pointerInteractionIsStartingDirection(pointerInteraction, Direction.Right);
}

export function pointerInteractionIsVerticalStartingDirection(pointerInteraction: PointerInteraction) {
    return pointerInteractionIsStartingDirection(pointerInteraction, Direction.Down) ||
        pointerInteractionIsStartingDirection(pointerInteraction, Direction.Up);
}

export function pointerInteractionIsSwipe(pointerInteraction: PointerInteraction, direction: Direction) {
    if (direction === undefined) {
        return (
            _isSwipeTimeSatisfied(pointerInteraction) &&
            (_isSwipeDistanceSatisfied(pointerInteraction, Direction.Left) ||
                _isSwipeDistanceSatisfied(pointerInteraction, Direction.Right) ||
                _isSwipeDistanceSatisfied(pointerInteraction, Direction.Up) ||
                _isSwipeDistanceSatisfied(pointerInteraction, Direction.Down))
        );
    } else {
        return _isSwipeTimeSatisfied(pointerInteraction) && _isSwipeDistanceSatisfied(pointerInteraction, direction);
    }
}

function _isSwipeDistanceSatisfied(pointerInteraction: PointerInteraction, direction: Direction) {
    if (!pointerInteraction.isAllowedDirection(direction)) {
        return false;
    }

    const swipeDistance = Config.swipeDistance;

    if (direction === Direction.Left) {
        return pointerInteraction.getTotalDeltaX() < -swipeDistance;
    } else if (direction === Direction.Right) {
        return pointerInteraction.getTotalDeltaX() > swipeDistance;
    } else if (direction === Direction.Up) {
        return pointerInteraction.getTotalDeltaY() < -swipeDistance;
    } else if (direction === Direction.Down) {
        return pointerInteraction.getTotalDeltaY() > swipeDistance;
    }

    return false;
}

function _isSwipeTimeSatisfied(pointerInteraction: PointerInteraction) {
    const totalDuration = pointerInteraction.getTotalDuration();

    return totalDuration !== undefined && totalDuration < Config.swipeMilliseconds;
}
