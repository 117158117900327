import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeAutoBlogPost} from "./doppe-action-type-auto-blog-post";

export const doppeActionTypeAutoBlogPostViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeAutoBlogPost, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeAutoBlogPostWidget: (await import(/* webpackChunkName: "doppe-action-type-auto-blog-post-widget" */ './doppe-action-type-auto-blog-post-widget/doppe-action-type-auto-blog-post-widget')).DoppeActionTypeAutoBlogPostWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {
        const {
            DoppeActionTypeAutoBlogPostWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeAutoBlogPostWidget
                {...renderProps}
            />
        );
    }
})