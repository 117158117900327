export function validateIsByteLength(
    str: string,
    options: {
        min?: number;
        max?: number;
    } = {}
) {
    const {
        min = 0,
        max = undefined
    } = options;

    const len = encodeURI(str).split(/%..|./).length - 1;
    return len >= min && (typeof max === 'undefined' || len <= max);
}