import {bymoTemplateMetadataFactoryCreate} from "../../../doppe-sdk";
import {WixMediaResource} from "@wix/devzai-common-wix";

export namespace BymoTemplatePreRoll {

    export interface TemplateProps {
        preRollMedia: WixMediaResource | null;
    }
}

export const BymoTemplatePreRollTemplateMetadata = bymoTemplateMetadataFactoryCreate<BymoTemplatePreRoll.TemplateProps>()({
    initialValues: {
        preRollMedia: null,
    },
    features: [
        'canBeSkipped',
        'hasVisibleBackground',
    ]
})
