import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import {doppeActionTypeLinkView} from "./doppe-action-type-link-view";
import React from 'react';

export const doppeActionTypeLinkViewViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeLinkView, {
    loadViewerComponents: async () => {
        return {
            DoppeActionTypeLinkViewWidget: (await import('./doppe-action-type-link-view-widget/doppe-action-type-link-view-widget')).DoppeActionTypeLinkViewWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeLinkViewWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeLinkViewWidget
                {...renderProps}
            />
        );

    },

    getActionButtonProps: _actionContext => {

        // const action = actionContext.action;

        return {
            // href: action.settings.url,
            // target: '_blank',
            // rel: 'noreferrer noopener',
        }
    },

    activateCTA: actionContext => {
        actionContext.navigateToUrl(actionContext.action.settings.url, true);
    }
})