import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeHideableValue} from '../../client-server-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {asArray, immerProduce} from '@wix/devzai-utils-common';
import {RichTextContent} from "@wix/devzai-utils-react";


export namespace DoppeActionTypeContent {

    export interface ActionSettings {
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        image: WixMediaResource[] | null;
    }
}

export const doppeActionTypeContent = doppeActionTypeDefineMetadata<DoppeActionTypeContent.ActionSettings>({
    id: 'io.bymo.action/content',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        title: 'Title',
        description: 'Description',
        image: null,
    }),
    hasNoGoalMeasuring: true,
    hasNoLockInnerCTA: true,
    isLinkOpener: false,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image) : [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image)[0] : null
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.image) {
                actionSettings.image = asArray(actionSettings.image).map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
})