import {IDisposable, Size} from '@wix/devzai-utils-common';

export function resizeObserverCreate (callback: ResizeObserverCallback) : ResizeObserver {

    if (typeof ResizeObserver === 'undefined') {
        throw new Error(`ResizeObserver isn't supported`)
    }

    return new ResizeObserver(callback);
}

export function resizeObserverIsSupported () {
    return typeof ResizeObserver !== 'undefined';
}

export function resizeObserverObserveElement (element: Element, callback: (size: Size) => void) : IDisposable {

    const resizeObserver = resizeObserverCreate((entries) => {
        const entry = [...entries][0];

        callback(resolveUpdatedElementSize(entry, element as HTMLElement));
    })

    resizeObserver.observe(element);

    return {
        dispose(): void {
            resizeObserver.disconnect();
        }
    }
}

interface CrossBrowserResizeObserverEntry {
    contentRect?: ResizeObserverEntry['contentRect'];
    contentBoxSize?: ResizeObserverEntry['contentBoxSize'];
    borderBoxSize?: ResizeObserverEntry['borderBoxSize'];
}

function resolveUpdatedElementSize (entry: CrossBrowserResizeObserverEntry, element: HTMLElement) {

    if (entry.borderBoxSize !== undefined) {
        const borderBoxSize = Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0] : entry.borderBoxSize;

        return {
            width: borderBoxSize.inlineSize,
            height: borderBoxSize.blockSize
        }
    } else {
        return {
            width: element.offsetWidth,
            height: element.offsetHeight
        };
    }
}
