import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import {doppeActionTypeContent} from "./doppe-action-type-content";
import React from 'react';

export const doppeActionTypeContentViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeContent, {
    loadViewerComponents: async () => {
        return {
            DoppeActionTypeContentWidget: (await import('./doppe-action-type-content-widget/doppe-action-type-content-widget')).DoppeActionTypeContentWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeContentWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeContentWidget
                {...renderProps}
            />
        );

    },

})