import {DynamicRef} from "../../dynamic-ref/dynamic-ref";
import {useEffect, useLayoutEffect, useMemo, useState} from "react";
import {elementFindViewportRoot, elementGetRootViewport} from "@wix/devzai-utils-dom";
import {areaIntersect, arrayFindValue, Edges, evaluateFunction} from "@wix/devzai-utils-common";

export function useVisibleInViewport<E extends Element> (
    elementRef: DynamicRef<E>,
    options: {
        frameShrinking?: Edges<number>;
        enabled?: boolean;
    } = {}
) {
    const {
        frameShrinking,
        enabled = true
    } = options;

    const marginStr = frameShrinking ? `${-frameShrinking.top}px ${-frameShrinking.right}px ${-frameShrinking.bottom}px ${-frameShrinking.left}px` : undefined;

    const [isVisible, setIsVisible] = useState<boolean | null>(null);
    const [targetElement, setTargetElement] = useState<E | null>(elementRef.current);

    elementRef.onRefWasChanged(element => {
        setTargetElement(element);
    })

    const intersectionObserverElements = useMemo(() => {

        if (targetElement) {
            return {
                observerRoot: evaluateFunction(() => {

                    const viewportRoot = elementFindViewportRoot(targetElement);

                    return viewportRoot !== targetElement.ownerDocument.documentElement ?
                        viewportRoot :
                        targetElement.ownerDocument;
                }),
                targetElementViewportRoot: elementFindViewportRoot(targetElement),
                targetElement: targetElement
            };
        } else {
            return undefined;
        }

    }, [targetElement]);

    useEffect(() => {

        if (!enabled) {
            setIsVisible(null);

            return undefined;
        } else if (intersectionObserverElements) {

            const {
                targetElement,
                observerRoot,
                targetElementViewportRoot
            } = intersectionObserverElements;

            const observer = new IntersectionObserver((entries) => {
                const targetElementEntry = arrayFindValue(entries, entry => entry.target === targetElement);

                if (targetElementEntry) {
                    setIsVisible(targetElementEntry.isIntersecting);
                }
            }, {
                root: observerRoot,
                rootMargin: marginStr,
                threshold: 0.01
            });

            observer.observe(targetElement);

            if (areaIntersect(
                targetElement.getBoundingClientRect(),
                targetElementViewportRoot === null ?
                    elementGetRootViewport(targetElement) :
                    targetElementViewportRoot.getBoundingClientRect())
            ) {
                setIsVisible(true);
            }

            return () => {
                observer.disconnect()
            }

        } else {
            return undefined;
        }

    }, [intersectionObserverElements, marginStr, enabled]);

    return isVisible;
}

export function useWasVisibleInViewport<E extends Element> (
    elementRef: DynamicRef<E>,
    options: {
        frameShrinking?: Edges<number>;
    } = {}
) {
    const [wasVisibleInViewport, setWasVisibleInViewport] = useState(false);

    const isVisibleInViewport = useVisibleInViewport(elementRef, {
        frameShrinking: options.frameShrinking,
        enabled: !wasVisibleInViewport
    });

    useLayoutEffect(() => {
        if (isVisibleInViewport) {
            setWasVisibleInViewport(true);
        }
    }, [isVisibleInViewport]);

    return wasVisibleInViewport;
}