import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeActionTypeCouponViewerLang} from './doppe-action-type-coupon-viewer.lang';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {arrayRemoveNullValues, immerProduce} from '@wix/devzai-utils-common';
import {
    DoppeExternalCouponProvider,
    doppeExternalCouponProviderResolveBusinessEntityReference
} from '../../client-server-common/types/doppe-external-coupon';
import {DoppeDtoCoupon} from '../../client-server-common/types/doppe-dto-coupon';
import {RichTextContent} from '@wix/devzai-utils-react';

export namespace DoppeActionTypeCoupon {

    export interface ActionSettings {
        coupon: string;
        couponPageLink: string;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        couponButtonText: DoppeHideableValue<string>;
        couponImage: WixMediaResource | null;
        couponProvider: DoppeExternalCouponProvider | null;
        couponProviderId: string | null;
        showCouponButtonLink: boolean;
        ribbon: DoppeHideableValue<string>;
    }
}

export const doppeActionTypeCoupon = doppeActionTypeDefineMetadata<DoppeActionTypeCoupon.ActionSettings>({
    id: 'co.hopp.action/coupon',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        coupon: 'Coupon code',
        couponButtonText: DoppeActionTypeCouponViewerLang.defaultTexts.couponButtonText,
        description: 'Describe the coupon you want to give',
        couponImage: null,
        couponPageLink: 'www.coupon-website-url.com',
        title: 'Coupon title',
        showCouponButtonLink: true,
        couponProvider: null,
        couponProviderId: null,
        ribbon: doppeHideableValueCreateHidden('SALE'),
    }),
    isWidgetOpener: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    canRenderInPage: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([actionSettings.couponImage]);
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.couponImage;
    },
    resolveCouponData: actionSettings => {
        return {
            showCouponCode: true,
            couponCode: actionSettings.coupon,
            couponDescription: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.description, '') ? actionSettings.description : '',
            couponTitle: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.title, '') ? actionSettings.title : '',
        }
    },
    resolveMainLink: actionSettings => {
        return actionSettings.couponPageLink;
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.couponProvider !== null && actionSettings.couponProviderId !== null ?
                doppeExternalCouponProviderResolveBusinessEntityReference(
                    actionSettings.couponProvider,
                    actionSettings.couponProviderId
                ) :
                null
        ])
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.couponImage) {
                actionSettings.couponImage =
                    wixMediaResourceReplaceExternalImage(actionSettings.couponImage, externalImagesUrlToImageResourceMap)
            }
        })
    },

})

export function doppeActionTypeCouponResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeCoupon.ActionSettings,
    doppeDtoCoupon: DoppeDtoCoupon
): DoppeActionTypeCoupon.ActionSettings {
    return {
        ...actionSettings,
        coupon: doppeDtoCoupon.couponCode ?? actionSettings.coupon
    }
}