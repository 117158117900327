import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueModifyValue
} from './doppe-hideable-value';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    DoppeFormActionSettings,
    getDefaultDoppeFormActionSettings,
    prepareFormSettingsForViewer
} from './doppe-form-action-settings';

export interface DoppeCheckoutActionSettings extends DoppeFormActionSettings {
    showEmailInput: boolean;
    showAddressInput: boolean;
    showPhoneInput: boolean;
    sendReceipt: boolean;
    emailConfirmationMessage: DoppeHideableValue<RichTextContent>;
    customConfirmationMail: DoppeHideableValue<string>;
    confirmationPhone: DoppeHideableValue<string>;
    showConsent: boolean;
    fulfillmentDetails: DoppeHideableValue<RichTextContent>;
    previewCheckoutButtonText: string;
    showConsentCheckbox: boolean;
    customConsentText: DoppeHideableValue<string>;
}

export function getDefaultDoppeCheckoutActionSettings(overrides?: Partial<DoppeCheckoutActionSettings>) {
    return {
        showEmailInput: true,
        showAddressInput: false,
        showPhoneInput: true,

        sendReceipt: true,
        customConfirmationMail: doppeHideableValueCreateHidden(''),
        confirmationPhone: doppeHideableValueCreateHidden(''),
        emailConfirmationMessage: doppeHideableValueCreateHidden(''),
        showConsent: true,
        fulfillmentDetails: doppeHideableValueCreateHidden(''),
        ...getDefaultDoppeFormActionSettings({
            withEmailNotification: true
        }),
        ...overrides,
    }
}

export function prepareCheckoutSettingsForViewer (settings: DoppeCheckoutActionSettings) {
    prepareFormSettingsForViewer(settings);
    settings.customConfirmationMail = doppeHideableValueModifyValue(settings.customConfirmationMail, '');
}

