import {
    ArrayOrSingle,
    asArray,
    CurrencyCode, DateTimeValue,
    LongNumber,
    numberConvertLongToInteger,
    numberCreateUnsignedLong,
    objectCreateFromEntries,
    objectMapValues,
    objectOmit,
    OmitStrict,
    PartiallyRequired,
    Values
} from '@wix/devzai-utils-common';

export const WixPremiumPlanProductTypeId = '8b1b47e6-9f58-4e59-b147-cac894bec8da';

export const WixPremiumPaymentBrand = {
    VISA: 'VISA',
    MASTERCARD: 'MASTERCARD',
    AMEX: 'AMEX',
    DISCOVER: 'DISCOVER',
    JCB: 'JCB',
    ENROUTE: 'ENROUTE',
    DINERS: 'DINERS',
    SOLO: 'SOLO',
    MAESTRO: 'MAESTRO',
    BANCARD: 'BANCARD',
    CARTE_BLUE: 'CARTE_BLUE',
    ELO: 'ELO',
    UNIONPAY: 'UNIONPAY',
    SEPA_DIRECT_DEBIT: 'SEPA_DIRECT_DEBIT',
    MP_OXXO: 'MP_OXXO',
    MP_BANAMEX: 'MP_BANAMEX',
    MP_BANCOMER: 'MP_BANCOMER',
    MP_SERFIN: 'MP_SERFIN',
    BOLETO: 'BOLETO',
    BANK_TRANSFER: 'BANK_TRANSFER',
    YANDEX: 'YANDEX',
    MERCADO_PAGO_RECURRING: 'MERCADO_PAGO_RECURRING',
    CREDITS_PREPAID_CARD: 'CREDITS_PREPAID_CARD',
    REG_DOT_RU: 'REG_DOT_RU',
    SOFORT: 'SOFORT',
    PAYPAL: 'PAYPAL',
    SEPA_OFFLINE: 'SEPA_OFFLINE',
} as const;

export type WixPremiumPaymentBrand = Values<typeof WixPremiumPaymentBrand>;

export const WixPremiumCycleIntervalUnit = {
    Day: 'DAY',
    Week: 'WEEK',
    Month: 'MONTH',
    Year: 'YEAR',
    Unknown: 'UNKNOWN'
} as const;

export type WixPremiumCycleIntervalUnit = Values<typeof WixPremiumCycleIntervalUnit>;

export const WixPremiumBillingCycle = {
    Unknown: 'UNKNOWN',
    OneTime: 'ONE_TIME',
    Monthly: 'MONTHLY',
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Yearly: 'YEARLY',
    TwoYears: 'TWO_YEARS',
} as const;

export type WixPremiumBillingCycle = Values<typeof WixPremiumBillingCycle>;

export namespace WixPremiumBillingCycle {
    export type KnownCycle = Exclude<WixPremiumBillingCycle, typeof WixPremiumBillingCycle.Unknown>
}

export namespace WixPremiumCycleIntervalUnit {
    export type KnownIntervalUnit = Exclude<WixPremiumCycleIntervalUnit, typeof WixPremiumCycleIntervalUnit.Unknown>
}

export type WixBillingDetails = {
    billingCycle: WixPremiumBillingCycle,
    periodEndDate: DateTimeValue;
    autoRenewDate: DateTimeValue | null;
    paymentBrand: WixPremiumPaymentBrand | null;
    creditCardType: string | null;
    paymentMethodLabel: string | null;
}

export type WixPremiumProductId = string;

export type WixPremiumSubscriptionInfo = {
    subscriptionId: string;
    productId: WixPremiumProductId;
    productName: string;
    wixBillingDetails?: WixBillingDetails;
}

export namespace WixPremiumSubscriptionInfo {
    export type BilledOnWix = PartiallyRequired<WixPremiumSubscriptionInfo, 'wixBillingDetails'>;
}

export function wixPremiumSubscriptionInfoIsBilledOnWix (wixPremiumSubscription: WixPremiumSubscriptionInfo) : wixPremiumSubscription is WixPremiumSubscriptionInfo.BilledOnWix {
    return wixPremiumSubscription.wixBillingDetails !== undefined
}

export type WixPremiumProductCurrencySettings = {
    code: string;
    fractionSize: number;
    decimalSep: string;
    groupSep: string;
    negPrefix: string;
    negSuffix: string;
    posPrefix: string;
    posSuffix: string;
    symbol: string;
}

export type WixPremiumProductCurrencySettingsMap = Record<string, OmitStrict<WixPremiumProductCurrencySettings, 'code'>>;

export type WixPremiumProductsPricingInfo<PRODUCT_ID extends string, CYCLE extends WixPremiumBillingCycle> =
    Record<PRODUCT_ID, Record<CYCLE, {
        price: number;
        priceWithTax: number;
        monthlyPrice: number | null;
        monthlyPriceWithTax: number | null;
        discountPrice: number | null;
        discountPriceWithTax: number | null;
        discountMonthlyPrice: number | null;
        discountMonthlyPriceWithTax: number | null;
        discountPercentage: number;
        currencyCode: CurrencyCode;
    }>>

// TODO: Should be renamed to something like WixPremiumUserOffering
export type WixPremiumProductsPricing<PRODUCT_ID extends WixPremiumProductId = WixPremiumProductId, CYCLE extends WixPremiumBillingCycle = WixPremiumBillingCycle> = {
    productsPricingData: WixPremiumProductsPricingInfo<PRODUCT_ID, CYCLE>;
    currencySettings: WixPremiumProductCurrencySettings;
    purchasableCycles: WixPremiumProductsPricing.PurchasableProductCycleInfo[];
    currentPurchasedCycle: WixPremiumProductsPricing.PurchasableProductCycleInfo | null
}

export namespace WixPremiumProductsPricing {
    export type PurchasableProductCycleInfo<CYCLE extends WixPremiumBillingCycle = WixPremiumBillingCycle> = {
        productId: string;
        billingCycle: CYCLE;
    }
}

export function wixPremiumProductCurrencySettingsMapCreate (
    currencySettings: ArrayOrSingle<WixPremiumProductCurrencySettings>
) : WixPremiumProductCurrencySettingsMap {
    return objectCreateFromEntries(asArray(currencySettings).map(currencySetting => {
        return [currencySetting.code, objectOmit(currencySetting, ['code'])]
    }))
}

export const WixPremiumFeatureType = {
    Boolean: 'boolean',
    Quota: 'quota'
} as const;

export type WixPremiumFeatureType = Values<typeof WixPremiumFeatureType>;

export namespace WixPremiumFeatureStatus {

    type Common = {
        uniqueName: string;
    }

    export type BooleanFeature = Common & {
        type: typeof WixPremiumFeatureType.Boolean;
        enabled: boolean;
    }

    export type QuotaFeature = Common & {
        type: typeof WixPremiumFeatureType.Quota;
        limit: LongNumber | null;
        currentUsage: LongNumber;
        remainingUsage: LongNumber | null;
    }
}

export type WixPremiumFeatureStatus =
    | WixPremiumFeatureStatus.QuotaFeature
    | WixPremiumFeatureStatus.BooleanFeature

export type WixPremiumFeaturesSpec<T extends string> = Record<T, WixPremiumFeatureType>

export type WixPremiumFeaturesStatus<T extends WixPremiumFeaturesSpec<string> = WixPremiumFeaturesSpec<string>> = {
    [K in keyof T]: T[K] extends typeof WixPremiumFeatureType.Boolean ?
        WixPremiumFeatureStatus.BooleanFeature :
        T[K] extends typeof WixPremiumFeatureType.Quota ?
            WixPremiumFeatureStatus.QuotaFeature :
            WixPremiumFeatureStatus
}

export function wixPremiumFeaturesStatusCreateFullyEnabled<T extends WixPremiumFeaturesSpec<string>> (featuresSpec: T) : WixPremiumFeaturesStatus<T> {
    return objectMapValues(featuresSpec, (featureType: WixPremiumFeatureType, featureName) => {
        switch (featureType) {
            case WixPremiumFeatureType.Quota: {
                return wixPremiumQuotaFeatureStatusCreateUnlimited(featureName)
            }
            case WixPremiumFeatureType.Boolean: {
                return wixPremiumBooleanFeatureStatusCreateEnabled(featureName)
            }
        }
    }) as WixPremiumFeaturesStatus<T>
}

export function wixPremiumFeaturesStatusCreateFullyDisabled<T extends WixPremiumFeaturesSpec<string>> (featuresSpec: T) : WixPremiumFeaturesStatus<T> {
    return objectMapValues(featuresSpec, (featureType: WixPremiumFeatureType, featureName) => {
        switch (featureType) {
            case WixPremiumFeatureType.Quota: {
                return wixPremiumQuotaFeatureStatusCreate(featureName, {
                    limit: 0
                })
            }
            case WixPremiumFeatureType.Boolean: {
                return wixPremiumBooleanFeatureStatusCreate(featureName, false)
            }
        }
    }) as WixPremiumFeaturesStatus<T>
}

export function wixPremiumFeatureStatusIsForQuotaFeature (featureStatus: WixPremiumFeatureStatus) :
    featureStatus is WixPremiumFeatureStatus.QuotaFeature
{
    return featureStatus.type === WixPremiumFeatureType.Quota;
}

export function wixPremiumFeatureStatusIsForBooleanFeature (featureStatus: WixPremiumFeatureStatus) :
    featureStatus is WixPremiumFeatureStatus.BooleanFeature
{
    return featureStatus.type === WixPremiumFeatureType.Boolean;
}

export function wixPremiumQuotaFeatureStatusCreateUnlimited (featureName: string) : WixPremiumFeatureStatus.QuotaFeature {
    return {
        type: WixPremiumFeatureType.Quota,
        uniqueName: featureName,
        limit: null,
        remainingUsage: null,
        currentUsage: numberCreateUnsignedLong(0)
    }
}

export function wixPremiumQuotaFeatureStatusCreate (
    featureName: string,
    options: {
        limit: number | null;
        usage?: number;
    }
) : WixPremiumFeatureStatus.QuotaFeature {

    const {
        limit,
        usage = 0
    } = options;

    return {
        type: WixPremiumFeatureType.Quota,
        uniqueName: featureName,
        limit: limit === null ? null : numberCreateUnsignedLong(limit),
        remainingUsage: limit === null ? null : numberCreateUnsignedLong(limit - usage),
        currentUsage: numberCreateUnsignedLong(usage)
    }
}

export function wixPremiumBooleanFeatureStatusCreate (featureName: string, enabled: boolean) : WixPremiumFeatureStatus.BooleanFeature {
    return {
        type: WixPremiumFeatureType.Boolean,
        uniqueName: featureName,
        enabled: enabled
    }
}

export function wixPremiumBooleanFeatureStatusCreateEnabled (featureName: string) : WixPremiumFeatureStatus.BooleanFeature {
    return wixPremiumBooleanFeatureStatusCreate(featureName, true);
}

export function wixPremiumBooleanFeatureIsEnabled (booleanFeatureStatus: WixPremiumFeatureStatus.BooleanFeature) {
    return booleanFeatureStatus.enabled;
}

export function wixPremiumBooleanFeatureIsDisabled (booleanFeatureStatus: WixPremiumFeatureStatus.BooleanFeature) {
    return !wixPremiumBooleanFeatureIsEnabled(booleanFeatureStatus);
}

export function wixPremiumQuotaFeatureGetRemainedQuota (quotaFeatureStatus: WixPremiumFeatureStatus.QuotaFeature) {
    return quotaFeatureStatus.remainingUsage === null ? Infinity : numberConvertLongToInteger(quotaFeatureStatus.remainingUsage)
}

export function wixPremiumQuotaFeatureGetLimit (quotaFeatureStatus: WixPremiumFeatureStatus.QuotaFeature, defaultLimit = 5) {
    const limit = quotaFeatureStatus.limit === null ? Infinity : numberConvertLongToInteger(quotaFeatureStatus.limit)
    return limit === 0 ? defaultLimit : limit;
}

export function wixGetPremiumProductPurchasableCycles<PRODUCT_ID extends string, CYCLE extends WixPremiumBillingCycle>(
    premiumProductId: PRODUCT_ID,
    premiumProductsPricing: WixPremiumProductsPricing<PRODUCT_ID, CYCLE>
) {
    return premiumProductsPricing.purchasableCycles.filter(item => {
        return item.productId === premiumProductId;
    })
}

export function wixPremiumBillingCycleCanUpgradeToAnotherBillingCycle (
    billingCycle: WixPremiumBillingCycle,
    targetBillingCycle: WixPremiumBillingCycle
) {
    switch (billingCycle) {
        case WixPremiumBillingCycle.Monthly: {
            return [WixPremiumBillingCycle.Monthly, WixPremiumBillingCycle.Yearly, WixPremiumBillingCycle.TwoYears].includes(targetBillingCycle as any);
        }
        case WixPremiumBillingCycle.Yearly: {
            return [WixPremiumBillingCycle.Yearly, WixPremiumBillingCycle.TwoYears].includes(targetBillingCycle as any);
        }
        default: {
            return false;
        }
    }

}