import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, asArray, immerProduce, PartiallyOptional} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    DoppeListActionSettings
} from '../../client-server-common';
import {doppeHideableValueCreateHidden} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeActionTypeLinkView} from '../doppe-action-type-link-view/doppe-action-type-link-view';
import {doppeDtoActionGetDefaultSearchListSettingsProps} from '../../client-server-common/types/doppe-dto-action';

export namespace DoppeActionTypeLinksList {

    export interface LinkSettings extends  DoppeActionTypeLinkView.ActionSettings {
        id: string;
        title: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour
    {
        links: LinkSettings[];
        previewLinkButtonText: string;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowCoupon: boolean;
    }
}


export const doppeActionTypeLinksList = doppeActionTypeDefineMetadata<DoppeActionTypeLinksList.ActionSettings>({
    id: 'io.bymo.action/links-list',
    resolveActionSettings: action => {

        const {
            links = [],
            ...restSettings
        } = action.settings;

        return {
            links: links.map(link => {
                return {
                    linkButtonText: 'Continue',
                    description: doppeHideableValueCreateHidden(''),
                    couponCode: doppeHideableValueCreateHidden(''),
                    ribbon: doppeHideableValueCreateHidden(''),
                    ...link as PartiallyOptional<DoppeActionTypeLinksList.LinkSettings, 'couponCode' | 'linkButtonText' | 'description' | 'ribbon'>,
                    image: link.image ? asArray(link.image) : null,
                }
            }),
            itemClickBehaviour: DoppeActionListItemClickBehaviour.OpenLink,
            layout: DoppeActionLayout.Column,
            showButton: false,
            buttonText: 'See More Links',
            buttonUrl: 'https://www.website.com',
            previewLinkButtonText: 'Continue',
            previewShowTitle: true,
            previewShowDescription: true,
            previewShowCoupon: true,
            itemShowTitle: true,
            itemShowImage: true,
            itemShowDescription: true,
            itemShowTextOnImage: false,
            listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),
            mainImage: doppeHideableValueCreateHidden(null),
            mainTitle: doppeHideableValueCreateHidden('My Links'),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(actionSettings.links.map(link => {
            return link.image ? asArray(link.image) : []
        }));
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.links[0]?.image ? actionSettings.links[0]?.image[0] : null;
    },
    resolveMainLink: actionSettings => {
        return actionSettings.showButton ? actionSettings.buttonUrl : null;
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.links.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.links.map(item => {
            return {
                title: item.title,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.image?.[0] ?? null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.url
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const link of actionSettings.links) {
                if (link.image) {
                    link.image = asArray(link.image).map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }
        })
    },
});
