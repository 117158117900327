/**
 * inlined Object.is polyfill to avoid requiring consumers ship their own
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
 */
export function sameValue(x: any, y: any) {
    // SameValue algorithm
    if (x === y) {
        // Steps 1-5, 7-10
        // Steps 6.b-6.e: +0 != -0
        // Added the nonzero y check to make Flow happy, but it is redundant
        return x !== 0 || y !== 0 || 1 / x === 1 / y;
    } else {
        // Step 6.a: NaN == NaN
        return x !== x && y !== y;
    }
}

/**
 * @deprecated use compareNumbers instead
 */
export function numberComparer(value1: number, value2: number): number {
    return value1 - value2;
}

/**
 * @deprecated use compareStringsCaseInsensitive instead
 */
export function stringCaseInsensitiveComparer(str1: string, str2: string): number {
    const upperCase1 = str1.toUpperCase();
    const upperCase2 = str2.toUpperCase();
    if (upperCase1 < upperCase2) {
        return -1;
    }
    if (upperCase1 > upperCase2) {
        return 1;
    }

    return 0;
}
