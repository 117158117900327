import {Values, VideoServices, videoServicesResolveVideoSource} from "@wix/devzai-utils-common";
import {wixVideoInfoGetVideoUrl, WixVideoInfoQualitySelector, WixVideoResource} from "@wix/devzai-common-wix";

export const DoppeBymoPageVideoServices = {
    ...VideoServices,
    Wix: 'Wix'
} as const;

export type DoppeBymoPageVideoServices = Values<typeof DoppeBymoPageVideoServices>;

export type DoppeBymoPageVideoSource = string | WixVideoResource;

export function doppeBymoPageVideoSourceResolveVideoUrl (videoSource: DoppeBymoPageVideoSource) {
    if (typeof videoSource === 'string') {
        return videoSource;
    } else {
        return wixVideoInfoGetVideoUrl(videoSource, {
            qualitySelector: WixVideoInfoQualitySelector.Highest
        })
    }
}

export function doppeBymoPageVideoSourceResolveVideoService (videoSource: DoppeBymoPageVideoSource) : DoppeBymoPageVideoServices | null {
    return typeof videoSource === 'string' ? (videoServicesResolveVideoSource(videoSource)?.videoService ?? null) : DoppeBymoPageVideoServices.Wix;
}