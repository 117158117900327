import {
    stringTrimPrefix,
    validateIsEmail,
    validateIsMobilePhone,
    validateIsUrl,
    Values
} from '@wix/devzai-utils-common';
import {DoppeHideableValue} from './doppe-hideable-value';
import {WixMediaResource} from '@wix/devzai-common-wix';

export const DoppeDtoContactMethods = {
    Location: 'Location', // location
    FacebookMessenger: 'FacebookMessenger', // https://facebook.com/facebookpageurl
    Facebook: 'Facebook', // https://facebook.com/facebookpageurl
    Instagram: 'Instagram', // @instrumentalist
    Apple: 'Apple',
    Discord: 'Discord', // https://discord.com/invite/yourchannel
    LinkedIn: 'LinkedIn', // https://linkedin.com/in/username
    Pinterest: 'Pinterest', // https://pinterest.com/
    Reddit: 'Reddit',  // https://reddit.com/
    Snapchat: 'Snapchat',
    Spotify: 'Spotify',
    Telegram: 'Telegram', // https://t.me/username
    TikTok: 'TikTok', // @tiktokusername
    X: 'X',
    Threads: 'Threads',
    YouTube: 'YouTube',
    Email: 'Email',
    WhatsApp: 'WhatsApp',
    Website: 'Website',
    Phone: 'Phone',
} as const;

export type DoppeDtoContactMethods = Values<typeof DoppeDtoContactMethods>;

export interface DoppeDtoContactMethod {
    type: DoppeDtoContactMethods;
    title: DoppeHideableValue<string>;
    displayValue: string;
    media: WixMediaResource | null;
    hidden: boolean;
    id: string;
}

export function doppeDtoContactMethodGetAllSupportedMethods () : DoppeDtoContactMethods[] {
    return Object.values(DoppeDtoContactMethods);
}

export function doppeDtoContactMethodGetRecommendedSupportedMethods () : DoppeDtoContactMethods[] {
    return [
        DoppeDtoContactMethods.Phone,
        DoppeDtoContactMethods.Email,
        DoppeDtoContactMethods.Website,
        DoppeDtoContactMethods.WhatsApp,
        DoppeDtoContactMethods.Location,
        DoppeDtoContactMethods.LinkedIn,
    ];
}

export function doppeDtoContactMethodGetNotRecommendedSupportedMethods () : DoppeDtoContactMethods[] {
    return doppeDtoContactMethodGetAllSupportedMethods().filter(method => !doppeDtoContactMethodGetRecommendedSupportedMethods().includes(method));
}

export function doppeDtoContactMethodLinkResolveValue (contactMethod: {
    type: DoppeDtoContactMethods;
    displayValue: string
}) {

    switch (contactMethod.type) {
        case DoppeDtoContactMethods.FacebookMessenger: {

            const facebookPageName = contactMethod.displayValue;

            return facebookPageName !== undefined ?
                `https://www.m.me/${facebookPageName}/` :
                undefined;
        }
        case DoppeDtoContactMethods.Email: {

            if (!validateIsEmail(contactMethod.displayValue)) {
                return undefined;
            }

            return `mailto:${contactMethod.displayValue}`;
        }
        case DoppeDtoContactMethods.WhatsApp: {

            if (!validateIsMobilePhone(contactMethod.displayValue)) {
                return undefined;
            }

            return `https://api.whatsapp.com/send?phone=${stringTrimPrefix(contactMethod.displayValue, '+')}`;
        }
        case DoppeDtoContactMethods.Phone: {

            if(!contactMethod.displayValue) {
                return undefined;
            }

            return `tel:${contactMethod.displayValue}`;
        }
        case DoppeDtoContactMethods.Location: {
            if(!contactMethod.displayValue) {
                return undefined;
            }

            return `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(contactMethod.displayValue)}`
        }
    }

    if (validateIsUrl(contactMethod.displayValue, {
        protocols: ['http', 'https'],
        require_protocol: true
    })) {
        return contactMethod.displayValue;
    } else {
        return undefined;
    }

}

