import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeEmbedIframe} from "./doppe-action-type-embed-iframe";

export const doppeActionTypeEmbedIframeViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeEmbedIframe, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeEmbedIframeWidget: (await import('./doppe-action-type-embed-iframe-widget/doppe-action-type-embed-iframe-widget')).DoppeActionTypeEmbedIframeWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeEmbedIframeWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeEmbedIframeWidget
                {...renderProps}
            />
        )
    }
})