import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeSubscribeEmbed} from "./doppe-action-type-subscribe-embed";

export const doppeActionTypeSubscribeEmbedViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeSubscribeEmbed, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeSubscribeEmbedWidget: (await import('./doppe-action-type-subscribe-embed-widget/doppe-action-type-subscribe-embed-widget')).DoppeActionTypeSubscribeEmbedWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeSubscribeEmbedWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeSubscribeEmbedWidget
                {...renderProps}
            />
        )
    }
})