import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {doppeActionTypeSaveContact} from './doppe-action-type-save-contact';
import {downloadContactCard} from '../../client-server-common/types/doppe-dto-contact-details';
import {doppeHideableValueGetVisibleValue} from '../../client-server-common';
import {asyncExecuteInBackground} from '@wix/devzai-utils-common';

export const doppeActionTypeSaveContactViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeSaveContact, {

    getActionButtonProps: actionContext => {

        const action = actionContext.action;

        const {
            name,
            contactMethods,
            jobTitle,
            company,
            notes,
            media,
        } = action.settings;

        return {
            onClick: () => {
                asyncExecuteInBackground(async () => {
                    await downloadContactCard({
                        name: doppeHideableValueGetVisibleValue(name, null),
                        company: company,
                        contactMethods: contactMethods,
                        jobTitle: jobTitle,
                        media: media,
                        notes,
                    })
                }, {});

            }
        }
    }
})