import {DoppeViewerAppClientServiceBase} from "./doppe-viewer-app-client-service-base";
import {DoppeDtoBymoLinkViewerData} from "../../client-server-common/types/doppe-dto-bymo-link-viewer-data";
import {
    browserHistorySupportedWithoutUserGesture,
    DomEventListener,
    PointerInteraction, SessionStorageAccessor,
    UserGestureTracker
} from "@wix/devzai-utils-dom";
import {Direction, Timer} from '@wix/devzai-utils-common';
import {DoppeBiEmptyTargetUrl} from "../../client-server-common";

const DisableBackTrapSessionStorageKey = 'doppe.disable-back-trap';
const backTrapStateValue = 'bymo-viewer-back-trap';

export class DoppeViewerAppBymoService extends DoppeViewerAppClientServiceBase {

    private skippedInPageView = false;

    private bymoLinkViewerData?: DoppeDtoBymoLinkViewerData.WithConnectedPage = undefined;

    initialize (
        options: {
            bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithConnectedPage;
        }
    ) {
        this.bymoLinkViewerData = options.bymoLinkViewerData;
    }

    public getBymoPageProps () {
        return this.bymoLinkViewerData?.pageData?.bymoPageProps;
    }

    public getBymoLinkViewerData () {
        return this.bymoLinkViewerData;
    }

    initializePageListeners (
        options: {
            livePageData?: DoppeDtoBymoLinkViewerData.LivePageData;
            logUserActivityEvent?: boolean;
            targetUrl: string | null;
        }
    ) {
        const {
            livePageData,
            logUserActivityEvent = false,
            targetUrl,
        } = options;

        if(livePageData) {
            const activityTimer = new Timer(1000, () => {
                this.clientServices.viewerAnalyticsClient.logEvent('uouActivity', {
                    pageId: livePageData.bymoPageId,
                    hypeUserId: livePageData.doppeUserId,
                    linkId: livePageData.linkId,
                    destinationUrl: targetUrl ?? DoppeBiEmptyTargetUrl,
                });
            })

            if (document.visibilityState === 'visible') {
                this.clientServices.viewerAnalyticsClient.logEvent('uouPageVisibilityChange', {
                    pageId: livePageData.bymoPageId,
                    hypeUserId: livePageData.doppeUserId,
                    linkId: livePageData.linkId,
                    state: document.visibilityState,
                    destinationUrl: targetUrl ?? DoppeBiEmptyTargetUrl,
                    pageType: livePageData.pageType,
                });

                if (logUserActivityEvent) {
                    activityTimer.activate();
                }

                const pageUnloadDate = SessionStorageAccessor.getItem<number>(
                    `pageUnloadDate: ${livePageData.doppeUserId} ${livePageData.bymoPageId} ${livePageData.linkId}`
                );

                const skippedDate = SessionStorageAccessor.getItem<number>(
                    `skippedDate: ${livePageData.doppeUserId} ${livePageData.bymoPageId} ${livePageData.linkId}`
                );

                const currentDate = Date.now();
                if(!skippedDate && pageUnloadDate !== undefined && (currentDate - pageUnloadDate) < 5000) {
                    this.clientServices.viewerAnalyticsClient.logEvent('uouPageRefresh', {
                        pageId: livePageData.bymoPageId,
                        hypeUserId: livePageData.doppeUserId,
                        linkId: livePageData.linkId,
                        destinationUrl: targetUrl ?? DoppeBiEmptyTargetUrl,
                        pageType: livePageData.pageType,
                    });
                }
            }

            // Visibility Changed - Focus Changed
            const domEventListener = new DomEventListener(
                () => document,
                'visibilitychange',
                () => {

                    const visibilityState = document.visibilityState;

                    if (logUserActivityEvent) {
                        if (visibilityState === 'visible') {
                            activityTimer.activate();
                        } else {
                            activityTimer.deactivate();
                        }
                    }

                    this.clientServices.viewerAnalyticsClient.logEvent('uouPageVisibilityChange', {
                        pageId: livePageData.bymoPageId,
                        hypeUserId: livePageData.doppeUserId,
                        linkId: livePageData.linkId,
                        state: document.visibilityState,
                        destinationUrl: targetUrl ?? DoppeBiEmptyTargetUrl,
                        pageType: livePageData.pageType,
                    });

                    this.clientServices.viewerAnalyticsClient.flushLogs();
                }
            );

            domEventListener.activate();

            // page unload
            const domEventListenerUnload = new DomEventListener(
                () => window,
                'beforeunload',
                () => {
                    this.clientServices.viewerAnalyticsClient.logEvent('uouPageUnload', {
                        pageId: livePageData.bymoPageId,
                        hypeUserId: livePageData.doppeUserId,
                        linkId: livePageData.linkId,
                        destinationUrl: targetUrl ?? DoppeBiEmptyTargetUrl,
                        pageType: livePageData.pageType,
                    });

                    SessionStorageAccessor.updateItem<number>(
                        `pageUnloadDate: ${livePageData.doppeUserId} ${livePageData.bymoPageId} ${livePageData.linkId}`,
                        () => Date.now()
                    );

                    if(!this.skippedInPageView) {

                        SessionStorageAccessor.updateItem<number | null>(
                            `skippedDate: ${livePageData.doppeUserId} ${livePageData.bymoPageId} ${livePageData.linkId}`, () => null
                        );

                        this.clientServices.viewerAnalyticsClient.logEvent('uouBounced', {
                            pageId: livePageData.bymoPageId,
                            hypeUserId: livePageData.doppeUserId,
                            linkId: livePageData.linkId,
                            destinationUrl: targetUrl ?? DoppeBiEmptyTargetUrl,
                            pageType: livePageData.pageType,
                        });
                    } else {
                        this.clientServices.viewerAnalyticsClient.logEvent('uouProceeded', {
                            pageId: livePageData.bymoPageId,
                            hypeUserId: livePageData.doppeUserId,
                            linkId: livePageData.linkId,
                            destinationUrl: targetUrl ?? DoppeBiEmptyTargetUrl,
                            pageType: livePageData.pageType,
                        });
                    }

                    this.clientServices.viewerAnalyticsClient.flushLogs();
                }
            );

            domEventListenerUnload.activate();


        }
    }

    initializeSkipOnBackBehaviour (
        options: {
            targetUrl: string;
            livePageData?: DoppeDtoBymoLinkViewerData.LivePageData
        }
    ) {
        const {
            targetUrl,
            livePageData
        } = options;

        const disableBackTrapForCurrentSession = SessionStorageAccessor.getItem<boolean>(DisableBackTrapSessionStorageKey) ?? false;

        if (!disableBackTrapForCurrentSession) {
            UserGestureTracker.ensureInitialized();

            const supportedWithoutUserGesture = browserHistorySupportedWithoutUserGesture();

            if (history.state !== backTrapStateValue) {

                const setBackTrap = () => {
                    history.replaceState(backTrapStateValue, '');
                    history.pushState(null, '');
                }

                if (supportedWithoutUserGesture) {
                    setBackTrap();
                } else {

                    // A hack that starts the page with manual scrolling only until the first touch down.
                    document.body.style.touchAction = 'none';
                    DomEventListener.one(document.documentElement, 'touchstart', (evt: TouchEvent) => {
                        document.body.style.touchAction = 'auto';
                        const initialScrollTop = document.documentElement.scrollTop;

                        PointerInteraction.start(evt, {
                            allowedDirections: [Direction.Up, Direction.Down],
                            startCallback: interaction => {
                                return {
                                    moveCallback: () => {
                                        document.documentElement.scrollTop = initialScrollTop - interaction.getTotalFixedDeltaY();
                                    }
                                }
                            }
                        })
                    })

                    UserGestureTracker.executeWhenUserGestureBlockedFeaturesAreEnabled(setBackTrap)
                }
            }
        }


        new DomEventListener(window, 'popstate', () => {
            if (history.state === backTrapStateValue) {

                if (disableBackTrapForCurrentSession) {
                    history.back();
                } else {
                    if (livePageData) {
                        this.clientServices.viewerAnalyticsClient.logEvent('uouDestinationRedirectedOnBack', {
                            pageId: livePageData.bymoPageId,
                            hypeUserId: livePageData.doppeUserId,
                            linkId: livePageData.linkId,
                            afterUserGesture: UserGestureTracker.userGestureOccurred,
                            destinationUrl: targetUrl,
                        });

                        this.clientServices.viewerAnalyticsClient.logEvent('uouEngagementClicked', {
                            pageId: livePageData.bymoPageId,
                            hypeUserId: livePageData.doppeUserId,
                            linkId: livePageData.linkId,
                            destinationUrl: targetUrl,
                        });
                    }



                    this.skipToTargetUrl({
                        targetUrl: targetUrl,
                        livePageData: livePageData,
                        replaceCurrentPage: true
                    })
                }
            }
        }).activate()
    }

    skipToTargetUrl (
        options: {
            targetUrl: string;
            replaceCurrentPage?: boolean;
            livePageData?: DoppeDtoBymoLinkViewerData.LivePageData;
        }
    ) {
        const {
            replaceCurrentPage = false,
            targetUrl,
            livePageData
        } = options;


        if (livePageData) {
            this.clientServices.viewerAnalyticsClient.logEvent('uouDestinationRedirected', {
                pageId: livePageData.bymoPageId,
                hypeUserId: livePageData.doppeUserId,
                linkId: livePageData.linkId,
                passThrough: false,
                destinationUrl: targetUrl,
            });

            SessionStorageAccessor.updateItem<number>(
                `skippedDate: ${livePageData.doppeUserId} ${livePageData.bymoPageId} ${livePageData.linkId}`,
                () => Date.now()
            );
        }

        SessionStorageAccessor.setItem(DisableBackTrapSessionStorageKey, true);

        this.redirectToUrl({
            targetUrl: targetUrl,
            replaceCurrentPage: replaceCurrentPage
        })
    }

    redirectToUrl(
        options: {
            targetUrl: string,
            replaceCurrentPage: boolean
        }
    ) {

        const {
            replaceCurrentPage,
            targetUrl,
        } = options;

        this.skippedInPageView = true;

        if (replaceCurrentPage) {
            location.replace(targetUrl);
        } else {
            location.href = targetUrl;
        }
    }

}