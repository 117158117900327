import {DoppeViewerAppClientServiceBase} from './doppe-viewer-app-client-service-base';
import {assertDefined, Dictionary, ObservableValue} from '@wix/devzai-utils-common';
import {DoppeDtoAction} from '../../client-server-common/types/doppe-dto-action';
import {SupportedCookieNames} from '../../client-server-common';

export class DoppeViewerAppLockService extends DoppeViewerAppClientServiceBase {

    private readonly cachedLockedActionsObservable = new Map<DoppeDtoAction.Id, ObservableValue<boolean>>();

    public getActionLockObservable(action: DoppeDtoAction<any>) {
        const isActionSettingsLocked = action.lockSettings.enableLock && action.lockSettings.lockActions.length > 0;

        if (!isActionSettingsLocked) {
            if (this.cachedLockedActionsObservable.get(action.id)?.getValue() === false) {
                this.clientServices.doppeViewerAppCookieService.consentSafeCookieStorageAccessor?.updateItem(SupportedCookieNames.UnlockedActions, (value) => {
                    if (value === undefined) {
                        return undefined
                    }
                    else {
                        return {
                            ...value,
                            [action.id]: undefined
                        };
                    }
                })
            }

            this.cachedLockedActionsObservable.delete(action.id);

            return undefined
        }

        if (this.cachedLockedActionsObservable.has(action.id)) {
            return assertDefined(this.cachedLockedActionsObservable.get(action.id));
        }
        else {
            const unlockedCookieValue = this.clientServices.doppeViewerAppCookieService.consentSafeCookieStorageAccessor?.getItem<Dictionary<boolean>>(SupportedCookieNames.UnlockedActions);
            const isUnlockedFromCookie = unlockedCookieValue ? unlockedCookieValue[action.id] : false;
            const booleanObservableValue = new ObservableValue(!isUnlockedFromCookie && isActionSettingsLocked, {
                onUpdate: (lockedValue) => {
                    if (!lockedValue) {
                        this.clientServices.doppeViewerAppCookieService.consentSafeCookieStorageAccessor?.updateItem(SupportedCookieNames.UnlockedActions, (value) => {
                            if (value === undefined) {
                                return {
                                    [action.id]: true
                                };
                            }
                            else {
                                return {
                                    ...value,
                                    [action.id]: true
                                };
                            }
                        })
                    }
                }
            });
            this.cachedLockedActionsObservable.set(action.id, booleanObservableValue);
            return booleanObservableValue;
        }
    }
}
