import {enumGetValues, evaluateFunction, Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity, DoppeBusinessEntityReference} from "./doppe-business-entity";

/**
 * An enum of all the external providers that can provide a product data.
 * For example: Wix, Amazon, Shopify.
 */
export const DoppeExternalMembershipPlanProvider = {
    Wix: 'wix',
} as const;

export type DoppeExternalMembershipPlanProvider = Values<typeof DoppeExternalMembershipPlanProvider>;

// TODO: This field should include all the possible SEMANTIC fields that an external MembershipPlan providers can provide
// TODO: for a MembershipPlan.
export const DoppeExternalMembershipPlanField = {
    MembershipPlanPageUrl: 'MembershipPlanPageUrl',
} as const;

export type DoppeExternalMembershipPlanField = Values<typeof DoppeExternalMembershipPlanField>;

const AllExternalMembershipPlanProviders = enumGetValues(DoppeExternalMembershipPlanProvider);

const externalMembershipPlanFieldsToSupportedProviders = {
    [DoppeExternalMembershipPlanField.MembershipPlanPageUrl]: AllExternalMembershipPlanProviders,
} satisfies {
    [field in DoppeExternalMembershipPlanField]: DoppeExternalMembershipPlanProvider[]
}

export function doppeExternalMembershipPlanProviderIsFieldProvided (
    externalMembershipPlanProvider: DoppeExternalMembershipPlanProvider | null | undefined,
    externalMembershipPlanField: DoppeExternalMembershipPlanField
) {
    if (externalMembershipPlanProvider === null || externalMembershipPlanProvider === undefined) {
        return false;
    }

    return externalMembershipPlanFieldsToSupportedProviders[externalMembershipPlanField].includes(externalMembershipPlanProvider);
}

export function doppeExternalMembershipPlanProviderResolveBusinessEntityReference (
    externalItemProvider: DoppeExternalMembershipPlanProvider,
    externalItemId: string
) {
    return evaluateFunction(() => {
        switch (externalItemProvider) {
            case DoppeExternalMembershipPlanProvider.Wix: {
                return {
                    businessEntity: DoppeBusinessEntity.WixMembershipPlan,
                    id: externalItemId
                }
            }
        }
    }) satisfies DoppeBusinessEntityReference
}