export enum Direction {
    Left = 1,
    Right,
    Up,
    Down
}

export const HorizontalDirections = [Direction.Left, Direction.Right];
export const VerticalDirections = [Direction.Up, Direction.Down];
export const AllDirections = [Direction.Up, Direction.Right, Direction.Down, Direction.Left];

export type DirectionsValues<T> = { [direction in Direction]: T };

export function generateHorizontalDirectionsValues<T>(value: T): Partial<DirectionsValues<T>> {
    return {
        [Direction.Up]: undefined,
        [Direction.Right]: value,
        [Direction.Down]: undefined,
        [Direction.Left]: value
    };
}

export function generateVerticalDirectionsValues<T>(value: T): Partial<DirectionsValues<T>> {
    return {
        [Direction.Up]: value,
        [Direction.Right]: undefined,
        [Direction.Down]: value,
        [Direction.Left]: undefined
    };
}

export function generateAllDirectionsValues<T>(value: T): DirectionsValues<T> {
    return {
        [Direction.Up]: value,
        [Direction.Right]: value,
        [Direction.Down]: value,
        [Direction.Left]: value
    };
}

export function setMissingDirectionsValues<T>(
    directionsValues: Partial<DirectionsValues<T>>,
    defaultValue: T
): DirectionsValues<T> {
    const upValue = directionsValues[Direction.Up];
    const rightValue = directionsValues[Direction.Right];
    const downValue = directionsValues[Direction.Down];
    const leftValue = directionsValues[Direction.Left];

    return {
        [Direction.Up]: upValue !== undefined ? upValue : defaultValue,
        [Direction.Right]: rightValue !== undefined ? rightValue : defaultValue,
        [Direction.Down]: downValue !== undefined ? downValue : defaultValue,
        [Direction.Left]: leftValue !== undefined ? leftValue : defaultValue
    };
}

export function generateDirectionsFlags(directions: Direction[]): DirectionsValues<boolean> {
    const directionsFlags: DirectionsValues<boolean> = {
        [Direction.Up]: false,
        [Direction.Right]: false,
        [Direction.Down]: false,
        [Direction.Left]: false
    };

    for (const direction of directions) {
        directionsFlags[direction] = true;
    }

    return directionsFlags;
}

export function createDirectionsValues<T>(upValue: T, rightValue: T, downValue: T, leftValue: T): DirectionsValues<T> {
    return {
        [Direction.Up]: upValue,
        [Direction.Right]: rightValue,
        [Direction.Down]: downValue,
        [Direction.Left]: leftValue
    };
}

export function mapDirectionsValues<T, S>(
    directionsValues: DirectionsValues<T>,
    mappingFunction: (value: T) => S
): DirectionsValues<S> {
    return {
        [Direction.Up]: mappingFunction(directionsValues[Direction.Up]),
        [Direction.Right]: mappingFunction(directionsValues[Direction.Right]),
        [Direction.Down]: mappingFunction(directionsValues[Direction.Down]),
        [Direction.Left]: mappingFunction(directionsValues[Direction.Left])
    };
}

export const AllDirectionsFlags: DirectionsValues<boolean> = {
    [Direction.Up]: true,
    [Direction.Right]: true,
    [Direction.Down]: true,
    [Direction.Left]: true
};
