export class IdGenerator {
    private lastNow = 0;
    private nowCounter = 0;
    private static instance = new IdGenerator();

    private constructor() {}

    private uniqueId(prefix: string, delimiter: string) {
        const now = Date.now();

        if (now === this.lastNow) {
            this.nowCounter++;
        } else {
            this.nowCounter = 0;
        }

        this.lastNow = now;

        const nowCounter = this.nowCounter;

        return prefix + now + (nowCounter > 0 ? delimiter + nowCounter : '');
    }

    static uniqueId(prefix = 'id_', delimiter = '_'): string {
        return IdGenerator.instance.uniqueId(prefix, delimiter);
    }
}
