export function domNodeDetach (node: Node) {
    node.parentElement?.removeChild(node);

    return node;
}

export function domNodeWrap (node: Node, wrapper: Node) {
    node.parentNode?.insertBefore(wrapper, node);

    wrapper.appendChild(node);

    return node;
}

export function domNodeUnwrap (node: Node) {
    const wrapper = node.parentNode;
    if (wrapper) {
        wrapper.parentNode?.insertBefore(node, node.parentNode);
        domNodeDetach(wrapper);
    }
}

export function domNodesListToArray<T extends Node> (nodesList: NodeListOf<T>) : T[] {
    const result: T[] = [];
    for (let i = 0; i < nodesList.length; i++) {
        result.push(nodesList[i]);
    }
    return result;
}
