import {domNodeDetach} from '../dom-node-utils/dom-node-utils';

export function clipboardCopy (text: string) {
    const offscreenTextArea = document.createElement('textarea');
    offscreenTextArea.value = text;
    document.body.appendChild(offscreenTextArea);
    offscreenTextArea.select();
    document.execCommand("copy");
    domNodeDetach(offscreenTextArea);
}
