import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {immerProduce} from '@wix/devzai-utils-common';
import {DoppeActionTypeContactCardViewerLang} from './doppe-action-type-contact-card-viewer.lang';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeDtoContactMethod} from '../../client-server-common/types/doppe-dto-contact-method';
import {DoppeActionFullName} from '../../client-server-common/types/doppe-action-full-name';

export namespace DoppeActionTypeContactCard {

    export interface ActionSettings {
        media: WixImageResource[] | null;
        description: DoppeHideableValue<RichTextContent>;
        name: DoppeHideableValue<DoppeActionFullName>;
        jobTitle: DoppeHideableValue<string>;
        contactMethods: DoppeDtoContactMethod[];
        buttonText: DoppeHideableValue<string>;
        company: DoppeHideableValue<string>;
        notes: string;
        saveContactProfileImage: DoppeHideableValue<WixImageResource | null>
    }
}

export const doppeActionTypeContactCard = doppeActionTypeDefineMetadata<DoppeActionTypeContactCard.ActionSettings>({
    id: 'io.bymo.action/contact-card',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        contactMethods: [],
        buttonText: DoppeActionTypeContactCardViewerLang.defaultTexts.linkButtonText,
        media: null,
        name: doppeHideableValueCreateHidden({
            firstName: '',
            lastName: ''
        }),
        tagLine: doppeHideableValueCreateHidden(''),
        company: doppeHideableValueCreateHidden(''),
        jobTitle: doppeHideableValueCreateHidden(''),
        description: doppeHideableValueCreateHidden(''),
        notes: '',
        saveContactProfileImage: doppeHideableValueCreateHidden(null)
    }),
    isWidgetOpener: true,
    isLinkOpener: false,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.media ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.media?.[0] ?? null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.media) {
                actionSettings.media = actionSettings.media.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
});
