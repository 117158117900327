import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeSocialIcon} from "./doppe-action-type-social-icon";

export const doppeActionTypeSocialIconViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeSocialIcon, {
    renderWidget: () => {

        return (
            <div/>
        )
    }
})