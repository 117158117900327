import React, {useImperativeHandle, useLayoutEffect, useRef} from 'react';
import { style, classes } from './modal-view.st.css';
import { IHostingModalController } from './modals-scope';
import {DomElementResolver, IInnerWindowClosingController, RenderFunction} from '../index';
import {Evaluable} from '@wix/devzai-utils-common';
import {evaluateWhenFunction} from '@wix/devzai-utils-common';
import {assertNotNullable} from '@wix/devzai-utils-common';

export const ModalView = React.memo(React.forwardRef((props: ModalView.Props, ref: React.Ref<ModalView>) => {
    const {
        overlay,
        content,
        zIndex,
        modalController,
        overlayWindowClosingControllerRef,
        contentWindowClosingControllerRef
    } = props;

    const bodyElementResolverRef = useRef<DomElementResolver>(null);

    useLayoutEffect(() => {
        const bodyElement = assertNotNullable(bodyElementResolverRef.current).getDomElement();

        bodyElement.setAttribute('bodyElement', 'true');
    }, [bodyElementResolverRef]);

    useImperativeHandle(ref, () => {
        return {
            getBodyElement() {
                return assertNotNullable(bodyElementResolverRef.current).getDomElement();
            }
        }
    });

    return (
        <div
            className={style(classes.root, props.className)}
            style={{
                zIndex: zIndex
            }}
        >
            {evaluateWhenFunction(overlay, {
                modalController: modalController,
                innerWindowClosingControllerRef: overlayWindowClosingControllerRef
            })}
            <DomElementResolver
                ref={bodyElementResolverRef}
            >
                {evaluateWhenFunction(content, {
                    modalController: modalController,
                    innerWindowClosingControllerRef: contentWindowClosingControllerRef
                })}
            </DomElementResolver>
        </div>
    );
}));

export interface ModalView {
    getBodyElement () : Element;
}


export namespace ModalView {

    export interface PartRenderProps {
        modalController: IHostingModalController;
        innerWindowClosingControllerRef: React.Ref<IInnerWindowClosingController>;
    }

    export type PartRenderFunction = RenderFunction<PartRenderProps, React.ReactElement>;

    export interface Props {
        content: Evaluable<PartRenderFunction>;
        overlay?: Evaluable<PartRenderFunction>;
        zIndex?: number;
        className?: string;
        modalController: IHostingModalController;
        overlayWindowClosingControllerRef: React.RefObject<IInnerWindowClosingController>;
        contentWindowClosingControllerRef: React.RefObject<IInnerWindowClosingController>;
    }
}