import {Edge, Edges, edgesCreate} from '../edges';
import {Orientation} from '../orientation';
import {Offset} from '../offset';
import {evaluateFunction} from "../evaluable";
import {HorizontalAlignment, VerticalAlignment} from "../alignment";

export interface IArea {
    top: number;
    left: number;
    height: number;
    width: number;
}

export function areaClone (area: IArea) {
    return areaCreate(area.top, area.left, area.height, area.width);
}

export function areaCreate(top: number, left: number, height: number, width: number, isExtended: false): IArea;
export function areaCreate(
    top: number,
    left: number,
    height: number,
    width: number,
    isExtended: true
): IExtendedAreaInfo;
export function areaCreate(top: number, left: number, height: number, width: number): IArea;
export function areaCreate(
    top: number,
    left: number,
    height: number,
    width: number,
    isExtended = false
): IArea | IExtendedAreaInfo {
    if (isExtended) {
        return {
            top: top,
            left: left,
            height: height,
            width: width,
            right: left + width,
            bottom: top + height
        };
    } else {
        return {
            top: top,
            left: left,
            height: height,
            width: width
        };
    }
}

export function areaTranslateByDelta(area: IArea, delta: Partial<Offset>): IArea {
    let deltaX = delta.left;
    let deltaY = delta.top;

    if (deltaX === undefined) {
        deltaX = 0;
    }

    if (deltaY === undefined) {
        deltaY = 0;
    }

    if (deltaX !== 0 || deltaY !== 0) {
        return areaCreate(area.top + deltaY, area.left + deltaX, area.height, area.width);
    } else {
        return area;
    }
}

export function extendAreaInfo(area: IArea): IExtendedAreaInfo {
    const { top, left, width, height } = area;

    return {
        top: top,
        left: left,
        height: height,
        width: width,
        right: left + width,
        bottom: top + height
    };
}

export function areaShrink(area: IArea, edges: Partial<Edges<number>> | number): IArea {
    const { top = 0, left = 0, right = 0, bottom = 0 } =
        typeof edges === 'number' ? {top: edges, left: edges, right: edges, bottom: edges} : edges;

    return areaCreate(area.top + top, area.left + left, area.height - top - bottom, area.width - right - left);
}

export function areaExpand(area: IArea, edges: Partial<Edges<number>> | number): IArea {
    const { top = 0, left = 0, right = 0, bottom = 0 } = typeof edges === 'number' ? edgesCreate(edges) : edges;

    if (top !== 0 || left !== 0 || right !== 0 || bottom !== 0) {
        return areaCreate(area.top - top, area.left - left, area.height + top + bottom, area.width + right + left);
    } else {
        return area;
    }
}

export function areaGetTop (area: IArea) {
    return area.top;
}

export function areaGetLeft (area: IArea) {
    return area.left;
}

export function areaGetBottom(area: IArea) {
    if (area.height === Infinity) {
        return Infinity;
    }

    return area.top + area.height;
}

export function areaGetRight(area: IArea) {
    if (area.width === Infinity) {
        return Infinity;
    }

    return area.left + area.width;
}

export function areaGetVerticalCenter(area: IArea) {
    return area.top + area.height / 2;
}

export function areaGetHorizontalCenter(area: IArea) {
    return area.left + area.width / 2;
}

export function areaGetCenter(area: IArea): Offset {
    return {
        top: areaGetVerticalCenter(area),
        left: areaGetHorizontalCenter(area)
    };
}

export function areaIntersectsWith (area1: IArea, area2: IArea) {
    return !(
        areaGetRight(area1) <= area2.left ||
        area1.left >= areaGetRight(area2) ||
        areaGetBottom(area1) <= area2.top ||
        area1.top >= areaGetBottom(area2)
    );
}

export function areaIntersect (area1: IArea, area2: IArea) : IArea | undefined {
    const left = Math.max(areaGetLeft(area1), areaGetLeft(area2));
    const right = Math.min(areaGetRight(area1), areaGetRight(area2));
    const top = Math.max(areaGetTop(area1), areaGetTop(area2));
    const bottom = Math.min(areaGetBottom(area1), areaGetBottom(area2));

    if (left >= right || top >= bottom) {
        return undefined;
    } else {
        return areaCreate(top, left, bottom - top, right - left);
    }

}

export function areaIsContained(area: IArea, containingArea: IArea, orientation?: Orientation) {
    const checkForVerticalContainment = orientation === undefined || orientation === Orientation.Vertical;
    const checkForHorizontalContainment = orientation === undefined || orientation === Orientation.Horizontal;

    return (
        (!checkForVerticalContainment ||
            (area.top >= containingArea.top && areaGetBottom(area) <= areaGetBottom(containingArea))) &&
        (!checkForHorizontalContainment ||
            (area.left >= containingArea.left && areaGetRight(area) <= areaGetRight(containingArea)))
    );
}

export function areaTranslateToOffset (
    area: IArea,
    offset: Offset
) {
    return areaCreate(offset.top, offset.left, area.height, area.width)
}

/**
 * Calculates the largest area (of type IArea) that is centered at the specified `areaCenter`,
 * has the specified `widthHeightRatio`, and is contained within the given `targetArea`.
 *
 * @param options.targetArea - The target area within which the largest area should be contained.
 * @param options.widthHeightRatio - The desired width-to-height ratio of the resulting area.
 * @param options.areaCenter - The center point of the resulting area.
 * @returns The largest possible area that meets the given conditions.
 */
export function areaCalculateLargestContainedArea(
    options: {
        targetArea: IArea;
        widthHeightRatio: number;
        areaCenter: Offset;
    }
) {
    const {
        targetArea,
        widthHeightRatio,
        areaCenter
    } = options;

    const targetCenterX = areaCenter.left;
    const targetCenterY = areaCenter.top;

    const maxWidthFromCenter = Math.min(targetCenterX - targetArea.left, targetArea.left + targetArea.width - targetCenterX);
    const maxHeightFromCenter = Math.min(targetCenterY - targetArea.top, targetArea.top + targetArea.height - targetCenterY);

    let width = maxWidthFromCenter * 2;
    let height = maxHeightFromCenter * 2;

    if (width / height > widthHeightRatio) {
        width = height * widthHeightRatio;
    } else {
        height = width / widthHeightRatio;
    }

    const top = targetCenterY - height / 2;
    const left = targetCenterX - width / 2;

    return {
        top: Math.max(targetArea.top, top),
        left: Math.max(targetArea.left, left),
        width: Math.min(width, targetArea.width),
        height: Math.min(height, targetArea.height)
    };
}

export function areaAlignToArea (
    area: IArea,
    targetArea: IArea,
    options: {
        horizontalAlignment?: HorizontalAlignment;
        verticalAlignment?: VerticalAlignment
    }
) {
    const {
        horizontalAlignment,
        verticalAlignment
    } = options;


    return areaTranslateToOffset(area, {
        left: evaluateFunction(() => {
            if (horizontalAlignment === undefined) {
                return area.left;
            } else {
                switch (horizontalAlignment) {
                    case HorizontalAlignment.Left: return targetArea.left;
                    case HorizontalAlignment.Right: return areaGetRight(targetArea) - area.width;
                    case HorizontalAlignment.Center: return areaGetHorizontalCenter(targetArea) - area.width / 2;
                }
            }
        }),
        top: evaluateFunction(() => {
            if (verticalAlignment === undefined) {
                return area.top;
            } else {
                switch (verticalAlignment) {
                    case VerticalAlignment.Top: return targetArea.top;
                    case VerticalAlignment.Bottom: return areaGetBottom(targetArea) - area.height;
                    case VerticalAlignment.Center: return areaGetVerticalCenter(targetArea) - area.height / 2;
                }
            }
        })
    })
}

export function areaPlaceInsideArea(area: IArea, targetArea: IArea, orientation?: Orientation) {

    const placeVertically = orientation === undefined || orientation === Orientation.Vertical;
    const placeHorizontally = orientation === undefined || orientation === Orientation.Horizontal;

    const topDelta = evaluateFunction(() => {
        if (placeVertically) {
            const topDelta = targetArea.top - area.top;
            if (topDelta < 0) {
                const bottomDelta = areaGetBottom(targetArea) - areaGetBottom(area);

                return bottomDelta > 0 ? 0 : Math.max(topDelta, bottomDelta);
            } else {
                return topDelta;
            }
        } else {
            return 0;
        }
    })

    const leftDelta = evaluateFunction(() => {
        if (placeHorizontally) {
            const leftDelta = targetArea.left - area.left;
            if (leftDelta < 0) {
                const rightDelta = areaGetRight(targetArea) - areaGetRight(area);

                return rightDelta > 0 ? 0 : Math.max(leftDelta, rightDelta);
            } else {
                return leftDelta
            }
        } else {
            return 0;
        }
    })


    if (topDelta === 0 && leftDelta === 0) {
        return area;
    } else {
        return areaTranslateByDelta(area, {
            top: topDelta,
            left: leftDelta
        });
    }
}

export function areaContainsOffset (area: IArea, offset: Offset, orientation?: Orientation) {
    const checkForVerticalContainment = orientation === undefined || orientation === Orientation.Vertical;
    const checkForHorizontalContainment = orientation === undefined || orientation === Orientation.Horizontal;

    return (
        (!checkForVerticalContainment || (offset.top >= area.top && offset.top <= areaGetBottom(area))) &&
        (!checkForHorizontalContainment || (offset.left >= area.left && offset.left <= areaGetRight(area)))
    );
}

export function areaIsOffsetInside(area: IArea, offset: Offset) {
    return !areaIsOffsetOutside(area, offset);
}

export function areaIsOffsetOutside(area: IArea, offset: Offset) {
    return (
        offset.top < area.top ||
        offset.left < area.left ||
        offset.top > areaGetBottom(area) ||
        offset.left > areaGetRight(area)
    );
}

export function areaGetClosestEdge(area: IArea, offset: Offset, orientation: Orientation) {
    if (orientation === Orientation.Vertical) {
        return offset.top > areaGetVerticalCenter(area) ? Edge.Bottom : Edge.Top;
    } else {
        return offset.left > areaGetHorizontalCenter(area) ? Edge.Right : Edge.Left;
    }
}

export function areaUnion(areas: IArea[]) {
    let minLeft = Infinity;
    let minTop = Infinity;
    let maxRight = -Infinity;
    let maxBottom = -Infinity;

    for (const area of areas) {
        minLeft = Math.min(minLeft, area.left);
        minTop = Math.min(minTop, area.top);
        maxRight = Math.max(maxRight, areaGetRight(area));
        maxBottom = Math.max(maxBottom, areaGetBottom(area));
    }

    return {
        left: minLeft,
        top: minTop,
        width: maxRight - minLeft,
        height: maxBottom - minTop
    };
}

export function areaEqualityComparer(area1: IArea, area2: IArea) {
    return (
        area1.left === area2.left &&
        area1.top === area2.top &&
        area1.width === area2.width &&
        area1.height === area2.height
    );
}

export interface IExtendedAreaInfo extends IArea {
    right: number;
    bottom: number;
}
