import {doppeActionTypeDefineViewerMetadata} from '../../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeSpotify} from '../doppe-action-type-spotify';


export const doppeActionTypeSpotifyViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeSpotify, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeSpotifyWidget: (await import(
                '../doppe-action-type-spotify-widget/doppe-action-type-spotify-widget'
                )).DoppeActionTypeSpotifyWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeSpotifyWidget
        } = viewerComponents

        return (
            <DoppeActionTypeSpotifyWidget
                {...renderProps}
            />
        )
    },

    activateCTA: actionContext => {
        actionContext.navigateToUrl(actionContext.action.settings.url, true);
    }
})