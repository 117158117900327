export interface IAnimationFrameScheduler {
    requestAnimationFrame(callback: FrameRequestCallback): () => void;
}

export const BrowserAnimationFrameScheduler: IAnimationFrameScheduler = {
    requestAnimationFrame(callback: FrameRequestCallback) {
        const handle = requestAnimationFrame(callback);

        return () => {
            cancelAnimationFrame(handle);
        };
    }
};
