import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import {doppeActionTypeSkip} from "./doppe-action-type-skip";

export const doppeActionTypeSkipViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeSkip, {
    getActionButtonProps: actionContext => {
        return {
            onClick: async () => {
                actionContext.redirectToTargetUrl({
                    additionalMessage: actionContext.action.settings.message,
                    countdownSeconds: actionContext.action.settings.countdownSeconds
                });
            }
        }
    }
})