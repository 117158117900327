import {
    FontDisplay,
    objectRemoveEntriesWithUndefinedValues,
    urlCreateQueryStringFromRecord
} from "@wix/devzai-utils-common";
import {FontWeight} from "@wix/devzai-utils-common";

export function wixFontResolveGoogleFontStyleSheetUrl (
    options: {
        fontFamily: string;
        fontWeights: FontWeight[];
        text?: string;
        fontDisplay?: FontDisplay
    }
) {
    const {
        fontFamily,
        fontWeights,
        text,
        fontDisplay = FontDisplay.Block
    } = options;

    const queryStr = urlCreateQueryStringFromRecord(objectRemoveEntriesWithUndefinedValues({
        family: `${encodeURIComponent(fontFamily)}:${fontWeights.join(',')}`,
        display: fontDisplay,
        text: text ? encodeURIComponent(text) : text
    }), {
        encodeParams: false,
        addPrefixWhenNotEmpty: true
    })

    return `https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googleAPI/css${queryStr}`;
}