import {Evaluable, evaluateWhenFunction, HashFunction, HashValue} from '../index';
import {assertDefined} from '../assertions';

interface AsyncCachedValueEntry<VALUE> {
    value: VALUE | undefined;
    valuePromise?: Promise<VALUE>;
    autoInvalidationTimeout?: any;
}

export interface IAsyncCachedValue<VALUE> {

    hasPendingOrResolvedValue () : boolean;

    getCachedValue () : VALUE | undefined;

    getResolvedValue () : Promise<VALUE | undefined>;

    getValue () : Promise<VALUE>;

    setValue (value: VALUE) : void;

    invalidate () : void;

    prefetchValue () : void;
}

export interface IAsyncCachedValueWithParams<VALUE, P extends Record<string, unknown>> {

    hasPendingOrResolvedValue (params: P) : boolean;

    getCachedValue (params: P) : VALUE | undefined;

    getResolvedValue (params: P) : Promise<VALUE | undefined>;

    tryGetValue<F> (params: P, fallbackValue: F) : Promise<VALUE | F>;

    getValue (params: P) : Promise<VALUE>;

    setValue (value: VALUE, params: P) : void;

    invalidate (params: P) : void;

    prefetchValue (params: P) : void;

    invalidateAll () : void;
}

const NoParamsKey = 'default';

export namespace AsyncCachedValue {
    export type Options<P extends Record<string, unknown>> = {
        autoInvalidationMilliseconds?: Evaluable<(params: P) => number>;
    }
}

export class AsyncCachedValue<VALUE, P extends Record<string, unknown>> {

    private cache = new Map<HashValue<P>, AsyncCachedValueEntry<VALUE>>();
    private autoInvalidationMilliseconds;

    constructor(
        private paramsHashFunction: HashFunction<P>,
        private valueFunction: (params?: P) => Promise<VALUE>,
        options: AsyncCachedValue.Options<P> = {}
    ) {
        this.autoInvalidationMilliseconds = options.autoInvalidationMilliseconds;
    }

    private getCacheKey (params?: P) {
        return params !== undefined ? this.paramsHashFunction(params) : NoParamsKey;
    }

    public getCachedValue (params?: P) : VALUE | undefined {
        const paramsHash = this.getCacheKey(params);
        const cache = this.cache;

        return cache.get(paramsHash)?.value;
    }

    public hasPendingOrResolvedValue (params?: P) : boolean {
        const paramsHash = this.getCacheKey(params);
        const cache = this.cache;
        return cache.get(paramsHash) !== undefined;
    }

    public prefetchValue (params?: P) {
        this.getValue(params).catch(() => {})
    }

    public async getResolvedValue (params?: P) : Promise<VALUE | undefined> {
        try {
            return (await this.getValue(params));
        } catch (error) {
            return undefined;
        }
    }

    public async tryGetValue<F> (params: P, fallbackValue: F) : Promise<VALUE | F> {
        try {
            return await this.getValue(params);
        } catch (error) {
            return fallbackValue;
        }
    }

    public async getValue(params?: P) : Promise<VALUE> {

        const paramsHash = this.getCacheKey(params);
        const cache = this.cache;

        if (cache.has(paramsHash)) {

            const cacheEntry = assertDefined(cache.get(paramsHash));

            const valuePromise = cacheEntry.valuePromise;
            if (valuePromise) {
                return valuePromise;
            }

            return cacheEntry.value as VALUE;
        } else {
            const newCacheEntry: AsyncCachedValueEntry<VALUE> = {
                value: undefined
            };

            this.cache.set(paramsHash, newCacheEntry);

            return newCacheEntry.valuePromise = this.valueFunction(params)
                .then(value => {
                    const cacheEntry = assertDefined(cache.get(paramsHash));

                    if (cacheEntry === newCacheEntry) {
                        cacheEntry.value = value;
                        cacheEntry.valuePromise = undefined;
                    }

                    const evaluatedAutoInvalidationMilliseconds =
                        evaluateWhenFunction(this.autoInvalidationMilliseconds, params as P)

                    if (evaluatedAutoInvalidationMilliseconds) {
                        
                        cacheEntry.autoInvalidationTimeout = setTimeout(() => {
                            const cacheEntryDuringInvalidation = cache.get(paramsHash);
                            
                            if (cacheEntryDuringInvalidation === cacheEntry) {
                                cache.delete(paramsHash)
                            }
                        }, evaluatedAutoInvalidationMilliseconds)
                    }
                    
                    return value;
                })
                .catch(error => {

                    const cacheEntry = assertDefined(cache.get(paramsHash));

                    if (cacheEntry === newCacheEntry) {
                        cache.delete(paramsHash);
                    }

                    throw error;
                });
        }
    }

    public setValue (value: VALUE, params?: P) {
        const paramsHash = this.getCacheKey(params);
        this.cache.set(paramsHash, {
            value: value,
            valuePromise: undefined
        });
    }

    public invalidate (params?: P) {

        const paramsHash = this.getCacheKey(params);

        const cache = this.cache;

        if (cache.has(paramsHash)) {
            cache.delete(paramsHash)
        }
    }

    public invalidateAll () {
        this.cache.clear();
    }
}

export function asyncCachedValueCreateFactory<P extends Record<string, unknown>> (
    paramsHashFunction: HashFunction<P>
) {
    return {
        create<VALUE> (
            valueFunction: (params: P) => Promise<VALUE>,
            options: AsyncCachedValue.Options<P> = {}
        ) {
            return asyncCachedValueCreateWithParams<VALUE, P>(paramsHashFunction, valueFunction, options)
        }
    }
}

export function asyncCachedValueCreateWithParams<VALUE, P extends Record<string, unknown>> (
    paramsHashFunction: HashFunction<P>,
    valueFunction: (params: P) => Promise<VALUE>,
    options: AsyncCachedValue.Options<P> = {}
) : IAsyncCachedValueWithParams<VALUE, P> {
    return new AsyncCachedValue(
        paramsHashFunction,
        valueFunction as (params?: P) => Promise<VALUE>,
        options
    );
}

export function asyncCachedValueCreate<VALUE> (
    valueFunction: () => Promise<VALUE>,
    options: AsyncCachedValue.Options<{}> = {}
) : IAsyncCachedValue<VALUE> {
    return new AsyncCachedValue<VALUE, {}>(
        () => 'value',
        valueFunction,
        options
    );
}
