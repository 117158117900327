export function assertDefined<T>(value: T | undefined, errorMessage?: string): T;
export function assertDefined<T>(value: T | undefined, isEnabled: true, errorMessage?: string): T;
export function assertDefined<T>(value: T | undefined, isEnabled: false, errorMessage?: string): T | undefined;
export function assertDefined<T>(
    value: T | undefined,
    errorMessageOrIsEnabled?: string | boolean,
    errorMessage?: string
): T | undefined {
    let isEnabled = true;

    if (typeof errorMessageOrIsEnabled === 'boolean') {
        isEnabled = errorMessageOrIsEnabled;
    } else if (typeof errorMessageOrIsEnabled === 'string') {
        errorMessage = errorMessageOrIsEnabled;
    }

    if (isEnabled && value === undefined) {
        throw new Error(errorMessage !== undefined ? errorMessage : `defined value assertion failed`);
    }

    return value;
}

export function assertNotNullable<T>(value: T | undefined | null, errorMessage?: string): T;
export function assertNotNullable<T>(value: T | undefined | null, isEnabled: true, errorMessage?: string): T;
export function assertNotNullable<T>(
    value: T | undefined | null,
    isEnabled: false,
    errorMessage?: string
): T | undefined | null;
export function assertNotNullable<T>(
    value: T | undefined,
    errorMessageOrIsEnabled?: string | boolean,
    errorMessage?: string
): T | undefined {
    let isEnabled = true;

    if (typeof errorMessageOrIsEnabled === 'boolean') {
        isEnabled = errorMessageOrIsEnabled;
    } else if (typeof errorMessageOrIsEnabled === 'string') {
        errorMessage = errorMessageOrIsEnabled;
    }

    if (isEnabled && (value === undefined || value === null)) {
        throw new Error(errorMessage !== undefined ? errorMessage : `not nullable value assertion failed`);
    }

    return value;
}
