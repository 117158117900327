import {Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity} from './doppe-business-entity';
import {DoppeAutoProductType} from './doppe-auto-product-type';

export const DoppeBlogPostsExternalDataSourceType = {
    LatestWixBlogPosts: 'LatestWixBlogPosts'
} as const;

export type DoppeBlogPostsExternalDataSourceType = Values<typeof DoppeBlogPostsExternalDataSourceType>;

export type DoppeBlogPostsExternalDataSource =
    | {
    type: typeof DoppeBlogPostsExternalDataSourceType.LatestWixBlogPosts;
};

export function doppeBlogPostsExternalDataSourceResolveBusinessEntityReferences(
    externalDataSource: DoppeBlogPostsExternalDataSource
) {
    switch (externalDataSource.type) {
        case DoppeBlogPostsExternalDataSourceType.LatestWixBlogPosts: {
            return {businessEntity: DoppeBusinessEntity.WixAutoBlogPostsList, id: DoppeAutoProductType.Latest}
        }
    }
}