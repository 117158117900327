import {
    assertNotNullable,
    EqualityComparer, Evaluable, evaluateWhenFunction,
    IObservable,
    IObservableValue,
    ObservableValue,
    referencesEqualityComparer
} from '@wix/devzai-utils-common';
import {useValueBinding} from '../use-value-binding';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useInstanceValue} from "../use-data-member";

export function useObservable<T extends IObservable<any> | null | undefined> (
    target: T
) : T;
export function useObservable<T extends IObservable<any> | null | undefined, V> (
    target: T,
    selector: (observable: T) => V,
    equalityComparer?: EqualityComparer<V>
) : V;
export function useObservable<T extends IObservable<any> | null | undefined, V> (
    target: T,
    selector?: (observable: T) => V,
    equalityComparer?: EqualityComparer<V>
) : T | V {

    return useValueBinding(
        target?.eventUpdated,
        useCallback(() => {
            return selector ? selector(target) : target;
        }, [target, selector]) as any,
        selector ? (equalityComparer ?? referencesEqualityComparer) : () => false
    )
}

export function useObservableValue<T> (target: IObservableValue<T, any>) : T;
export function useObservableValue<T> (target: IObservableValue<T, any> | undefined) : T | undefined;
export function useObservableValue<T> (target: IObservableValue<T, any> | undefined) {
    return useObservable(
        target,
        useCallback(() => {
            return target?.getValue();
        }, [target]))
}

export function useComponentObservable<C, T extends IObservable<any> | null | undefined> (
    ref: React.RefObject<C>,
    target: (component: C) => T
) : T;
export function useComponentObservable<C, T extends IObservable<any> | null | undefined, V> (
    ref: React.RefObject<C>,
    target: (component: C) => T,
    selector: (observable: T) => V,
    equalityComparer?: EqualityComparer<V>
) : V;
export function useComponentObservable<C, T extends IObservable<any> | null | undefined, V> (
    ref: React.RefObject<C>,
    targetSelector: (component: C) => T,
    selector?: (observable: T) => V,
    equalityComparer?: EqualityComparer<V>
) : T | V {

    const [target, setTarget] = useState<T>();

    useLayoutEffect(() => {
        setTarget(targetSelector(assertNotNullable(ref.current)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return useValueBinding(
        target?.eventUpdated,
        useCallback(() => {
            return selector && target ? selector(target) : target;
        }, [target, selector]) as any,
        selector ? (equalityComparer ?? referencesEqualityComparer) : () => false
    )
}

export function useComponentObservableValue<C, V> (
    ref: React.RefObject<C>,
    targetSelector: (component: C) => (ObservableValue<V> | undefined),
    assertComponentAvailable = true
) : V | undefined {

    const [target, setTarget] = useState<ObservableValue<V>>();

    useLayoutEffect(() => {
        const component = ref.current;

        if (assertComponentAvailable) {
            assertNotNullable(component);
        }

        setTarget(component ? targetSelector(component) : undefined)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return useValueBinding(
        target?.eventUpdated,
        useCallback(() => {
            return target ? target.getValue() : undefined;
        }, [target])
    )
}

export function useObservableCalculatedValue<T> (valueFunction: Evaluable<() => T>) {
    const observableValue = useInstanceValue(() => {
        return new ObservableValue<T>(evaluateWhenFunction(valueFunction))
    });

    useEffect(() => {
        observableValue.setValue(evaluateWhenFunction(valueFunction))
    }, [valueFunction])

    return observableValue;
}