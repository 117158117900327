import {CookieAttributes, cookieGenerateString, cookieParse} from "@wix/devzai-utils-common";

export function cookiesGetItem(key: string): string | undefined {
    return cookieParse(document.cookie)?.[key];
}

export function cookiesSetItem(key: string, value: string, attributes?: CookieAttributes): void {
    document.cookie = cookieGenerateString(key, value, attributes);
}

export function cookiesRemoveItem(key: string): void {
    const attr: CookieAttributes = { expires: new Date(0) }; // Set to a past date
    document.cookie = cookieGenerateString(key, '', attr);
}
