export interface Size {
    width: number;
    height: number;
}

export function sizeCalculateWidthHeightRatio(size: Size) {
    return size.width / size.height;
}

export function sizeCalculateContainedSize(size: Size, surfaceSize: Size): Size {
    const sizeWidth = size.width;
    const sizeHeight = size.height;

    if (sizeWidth === 0 || sizeHeight === 0) {
        return {
            width: 0,
            height: 0
        };
    }

    const surfaceWidth = surfaceSize.width;
    const surfaceHeight = surfaceSize.height;

    const widthRatio = surfaceWidth / sizeWidth;
    const heightRatio = surfaceHeight / sizeHeight;

    const ratio = Math.min(widthRatio, heightRatio);

    let scaledWidth = sizeWidth * ratio;
    let scaledHeight = sizeHeight * ratio;

    if (Math.ceil(scaledWidth) <= surfaceWidth && Math.ceil(scaledHeight) <= surfaceHeight) {
        scaledWidth = Math.ceil(scaledWidth);
        scaledHeight = Math.ceil(scaledHeight);
    } else {
        scaledWidth = Math.floor(scaledWidth);
        scaledHeight = Math.floor(scaledHeight);
    }

    return {
        width: scaledWidth,
        height: scaledHeight
    };
}

export function sizeCalculateContainedSizeScaling(size: Size, surfaceSize: Size) {
    const surfaceWidth = surfaceSize.width;
    const surfaceHeight = surfaceSize.height;

    if (surfaceWidth === Infinity && surfaceHeight === Infinity) {
        return 1;
    } else {
        return Math.min(surfaceWidth / size.width, surfaceHeight / size.height);
    }
}

export function sizeCalculateCoverSizeScaling(size: Size, surfaceSize: Size) {
    const surfaceWidth = surfaceSize.width;
    const surfaceHeight = surfaceSize.height;

    if (surfaceWidth === Infinity && surfaceHeight === Infinity) {
        return 1;
    } else {
        return Math.max(surfaceWidth / size.width, surfaceHeight / size.height);
    }
}

export function sizeScaleToFit (size: Size, maxValue: number) {
    if (size.width <= maxValue && size.height <= maxValue) {
        return size; // Size fits within the maximum value, return as is
    }

    const scaleFactor = Math.min(maxValue / size.width, maxValue / size.height);
    const scaledSize: Size = {
        width: size.width * scaleFactor,
        height: size.height * scaleFactor,
    };

    return scaledSize;
}

export function sizeCalculateCoverSize(size: Size, surfaceSize: Size) {
    const scaling = sizeCalculateCoverSizeScaling(size, surfaceSize);

    return {
        width: scaling * size.width,
        height: scaling * size.height
    }
}

export function sizeEqualityComparer (size1: Size, size2: Size) {
    return size1.width === size2.width && size1.height === size2.height;
}

export function sizeGetWidth (size: Size) {
    return size.width;
}

export function sizeGetHeight (size: Size) {
    return size.height;
}
