import {doppeActionTypeDefineViewerMetadata} from '../../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeSoundcloud} from '../doppe-action-type-sound-cloud';


export const doppeActionTypeSoundcloudViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeSoundcloud, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeSoundcloudWidget: (await import(
                '../doppe-action-type-soundcloud-widget/doppe-action-type-soundcloud-widget'
                )).DoppeActionTypeSoundcloudWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeSoundcloudWidget
        } = viewerComponents

        return (
            <DoppeActionTypeSoundcloudWidget
                {...renderProps}
            />
        )
    },

    activateCTA: actionContext => {
        actionContext.navigateToUrl(actionContext.action.settings.url, true);
    }
})