import {
    convertCurrencyFromSmallestUnit,
    convertCurrencyToSmallestUnit,
    CurrencyCode,
    Price,
} from '@wix/devzai-utils-common';

export function convertToStripeAmount(price: Price): StripePrice {
    const { currencyCode } = price;
    const amount = convertCurrencyToSmallestUnit(price);
    return {
        amount: currencyCode === 'ISK' ? amount * 100 : amount,
        currencyCode: currencyCode.toLowerCase() as StripeCurrencyCode,
    };
}

export function convertFromStripeAmount(stripePrice: StripePrice): Price {
    const currencyCode = stripePrice.currencyCode.toUpperCase() as CurrencyCode;

    const amount = convertCurrencyFromSmallestUnit({
        currencyCode,
        amount: stripePrice.amount,
    });
    return {
        amount: currencyCode === 'ISK' ? amount / 100 : amount,
        currencyCode: currencyCode,
    };
}

export type StripeCurrencyCode = Lowercase<CurrencyCode>;
export type StripePrice = {
    amount: number;
    currencyCode: StripeCurrencyCode;
};

export const minStripeChargeAmounts: Partial<Record<CurrencyCode, number>> = {
    USD: 0.5,
    EUR: 0.5,
    GBP: 0.3,
    JPY: 5,
    CAD: 0.5,
    AUD: 0.5,
    CHF: 0.5,
    SGD: 0.5,
    INR: 0.5,
    AED: 2,
    BGN: 1,
    BRL: 0.5,
    CZK: 15,
    DKK: 2.5,
    HKD: 4,
    HUF: 175,
    MXN: 1,
    MYR: 2,
    NOK: 3,
    NZD: 0.5,
    PLN: 2,
    RON: 2,
    SEK: 3,
    THB: 10,
} as const;

export const currencyLocales: Partial<Record<CurrencyCode, string>> = {
    USD: 'en-US',
    EUR: 'en-EU',
    GBP: 'en-GB',
    JPY: 'ja-JP',
    CAD: 'en-CA',
    AUD: 'en-AU',
    CHF: 'de-CH',
    SGD: 'en-SG',
    INR: 'en-IN',
    AED: 'ar-AE',
    BGN: 'bg-BG',
    BRL: 'pt-BR',
    CZK: 'cs-CZ',
    DKK: 'da-DK',
    HKD: 'zh-HK',
    HUF: 'hu-HU',
    MXN: 'es-MX',
    MYR: 'ms-MY',
    NOK: 'nb-NO',
    NZD: 'en-NZ',
    PLN: 'pl-PL',
    RON: 'ro-RO',
    SEK: 'sv-SE',
    THB: 'th-TH',
} as const;

export const stripeCurrencyCodes = Object.keys(minStripeChargeAmounts) as CurrencyCode[]; //todo: remove as casting

export function isPriceAboveStripeMinimum(price: Price): boolean | null {
    const {
        currencyCode,
        amount,
    } = price;
    const minAmount = minStripeChargeAmounts[currencyCode];
    return minAmount ? amount >= minAmount : null;
}

export function isPriceBelowStripeMaximum(price: Price): boolean {
    const digits = convertToStripeAmount(price).amount.toString().length;
    const maxDigits = price.currencyCode === 'IDR' ? 12 : 8;
    return digits <= maxDigits;
}

export function getMinStripeChargeAmount(currencyCode: CurrencyCode): number {
    const min = minStripeChargeAmounts[currencyCode];
    return min ? Number((min * 1.3).toFixed(1)) : 1;
}

export function getMaxStripeChargeAmount(currencyCode: CurrencyCode): number {
    const digits = currencyCode === 'IDR' ? 12 : 8;
    const max = Number('9'.repeat(digits));
    return convertFromStripeAmount({
        currencyCode: currencyCode.toLowerCase() as StripeCurrencyCode,
        amount: max,
    }).amount;
}
