import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, immerProduce} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    doppeHideableValueCreateHidden,
    DoppeListActionSettings
} from '../../client-server-common';
import {doppeDtoActionGetDefaultSearchListSettingsProps} from '../../client-server-common/types/doppe-dto-action';
import {DoppeActionTypeContactCard} from '../doppe-action-type-contact-card/doppe-action-type-contact-card';
import {DoppeActionFullName, doppeActionFullNameGetName} from '../../client-server-common/types/doppe-action-full-name';


export namespace DoppeActionTypeContactCardList {

    export interface ContactCardSettings extends DoppeActionTypeContactCard.ActionSettings {
        id: string;
        name: DoppeActionFullName;
    }

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour {
        contacts: ContactCardSettings[];
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewSaveButtonText: string;
    }
}


export const doppeActionTypeContactCardList = doppeActionTypeDefineMetadata<DoppeActionTypeContactCardList.ActionSettings>({
    id: 'io.bymo.action/contact-card-list',
    resolveActionSettings: action => {

        const {
            contacts = [],
            ...restSettings
        } = action.settings;

        return {
            contacts: contacts.map(contact => {
                return contact;
            }),
            layout: DoppeActionLayout.Grid,
            showButton: false,
            buttonText: 'See all contacts',
            buttonUrl: 'https://www.website.com',
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
            itemShowDescription: true,
            itemShowTitle: true,
            itemShowImage: true,
            itemShowTextOnImage: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewSaveButtonText: 'Save as contact',
            listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),
            mainImage: doppeHideableValueCreateHidden(null),
            mainTitle: doppeHideableValueCreateHidden('Team members'),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: false,
    supportsListLayout: true,
    resolveListItemsCount: actionSettings => {
        return actionSettings.contacts.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.contacts.map(item => {
            return {
                title: doppeActionFullNameGetName(item.name),
                action: action,
                description: item.description,
                image: item.media?.[0] ?? null,
                itemId: item.id,
                url: null,
                couponCode: doppeHideableValueCreateHidden(''),
                ribbon: doppeHideableValueCreateHidden(''),
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(actionSettings.contacts.map(contact => {
            return contact.media ?? []
        }));
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.contacts[0]?.media?.[0] ?? null;
    },
    resolveMainLink: actionSettings => {
        return actionSettings.showButton ? actionSettings.buttonUrl : null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const contact of actionSettings.contacts) {
                if (contact.media) {
                    contact.media = contact.media.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }
        })
    },
});

