import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeAutoBlogPostType, DoppeBusinessEntity, DoppeExternalBlogPostProvider} from "../../client-server-common";

export namespace DoppeActionTypeAutoBlogPost {
    export interface ActionSettings {
        showTitle: boolean;
        showDescription: boolean;
        showBlogPostDate: boolean;
        showBlogWebsite: boolean;
        blogPostButtonText: string;
        blogPostProvider: DoppeExternalBlogPostProvider;
        autoPostType: DoppeAutoBlogPostType;
    }
}

export const doppeActionTypeAutoBlogPost = doppeActionTypeDefineMetadata<DoppeActionTypeAutoBlogPost.ActionSettings>({
    id: 'io.bymo.action/auto-blog-post',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        showTitle: true,
        showDescription: true,
        showBlogPostDate: true,
        showBlogWebsite: true,
        blogPostButtonText: 'Read Now',
        blogPostProvider: DoppeExternalBlogPostProvider.Wix,
        autoPostType: DoppeAutoBlogPostType.Latest,
    }),
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedBusinessEntities: actionSettings => {
        return [
            {businessEntity: DoppeBusinessEntity.WixAutoBlogPost, id: actionSettings.autoPostType}
        ]
    },
});