interface VcfGenerateOptions {
    firstName: string;
    lastName: string;
    organization?: string;
    jobTitle?: string;
    emails?: { email: string; type?: string }[]; // Allow multiple emails with optional types
    phoneNumbers?: { phoneNumber: string; type?: string }[]; // Allow multiple phone numbers with optional types
    urls?: { url: string; type?: string }[]; // Allow multiple URLs
    address?: string; // Assuming one address for simplicity, can be expanded
    note?: string;
    photoUrl?: string; // URL of the profile image
}

export function vcfGenerate(options: VcfGenerateOptions): string {
    let vCardString = `BEGIN:VCARD\nVERSION:3.0\n`;

    // Name
    vCardString += `N:${options.lastName};${options.firstName};;;\n`;
    vCardString += `FN:${options.firstName} ${options.lastName}\n`;

    // Organization
    if (options.organization) {
        vCardString += `ORG:${options.organization}\n`;
    }

    // Job Title
    if (options.jobTitle) {
        vCardString += `TITLE:${options.jobTitle}\n`;
    }

    // Emails
    options.emails?.forEach(({ email, type }) => {
        const typeStr = type ? `;TYPE=${type.toUpperCase()}` : '';
        vCardString += `EMAIL${typeStr}:${email}\n`;
    });

    // Phone Numbers
    options.phoneNumbers?.forEach(({ phoneNumber, type }) => {
        const typeStr = type ? `;TYPE=${type.toUpperCase()}` : '';
        vCardString += `TEL${typeStr}:${phoneNumber}\n`;
    });

    // URLs
    options.urls?.forEach(({url, type}) => {
        const typeStr = type ? `;TYPE=${type.toUpperCase()}` : '';
        vCardString += `URL${typeStr}:${url}\n`;
    });

    // Address
    if (options.address) {
        vCardString += `ADR:;;${options.address};;;;;\n`;
    }

    // Note
    if (options.note) {
        vCardString += `NOTE:${options.note}\n`;
    }

    // Photo
    if (options.photoUrl) {
        // Check if it's a base64 encoded image
        if (options.photoUrl.startsWith('data:image')) {
            // Extract the content type and base64 data from the data URI
            const matches = options.photoUrl.match(/^data:(image\/\w+);base64,(.*)$/);
            if (matches) {
                const contentType = matches[1];
                const base64Data = matches[2];
                const type = contentType.split('/')[1].toUpperCase();
                vCardString += `PHOTO;ENCODING=b;TYPE=${type}:${base64Data}\n`;
            }
        } else {
            // If it's not base64, add the URL
            vCardString += `PHOTO;VALUE=URL:${options.photoUrl}\n`;
        }
    }

    vCardString += `END:VCARD`;

    return vCardString;
}
