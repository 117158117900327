import {
    CurrentItemType,
    IListViewKeymapController, ListViewNavigationAction,
    ListViewNavigationActionType,
    ListViewSelectionAction,
    ListViewSelectionType,
    NavigationOrientation
} from './list-view-types';
import React from 'react';
import {ListViewComposable} from "./list-view-composable";
import {Values} from "@wix/devzai-utils-common";
import {KeyCodes} from "@wix/devzai-utils-dom";
import {keyCodeGetFromReactEvent} from "../react-events/react-events";

export const ListViewDefaultKeymapControllerSpaceKeyBehaviour = {
    None: 'none',
    Default: 'default',
    ToggleSelection: 'toggle'
} as const;

export type ListViewDefaultKeymapControllerSpaceKeyBehaviour = Values<typeof ListViewDefaultKeymapControllerSpaceKeyBehaviour>;

export class ListViewDefaultKeymapController implements IListViewKeymapController {
    private ctrlKeyType;
    private allowNavigationWithoutChangingSelection;
    private supportGridKeyboardNavigation;
    private moveSelectionDuringNavigation;
    private spaceKeyBehaviour;
    private selectionKeyCode;

    constructor(options: {
        ctrlKeyType?: CtrlKeyType;
        allowNavigationWithoutChangingSelection?: boolean;
        supportGridKeyboardNavigation?: boolean;
        moveSelectionDuringNavigation?: boolean;
        spaceKeyBehaviour?: ListViewDefaultKeymapControllerSpaceKeyBehaviour;
        selectionKeyCode?: KeyCodes;
    } = {}) {

        const {
            ctrlKeyType = CtrlKeyType.CtrlOrMetaKey,
            allowNavigationWithoutChangingSelection = true,
            supportGridKeyboardNavigation = true,
            moveSelectionDuringNavigation = true,
            spaceKeyBehaviour = ListViewDefaultKeymapControllerSpaceKeyBehaviour.Default,
            selectionKeyCode = KeyCodes.Space
        } = options;

        this.ctrlKeyType = ctrlKeyType;
        this.selectionKeyCode = selectionKeyCode;
        this.allowNavigationWithoutChangingSelection = allowNavigationWithoutChangingSelection;
        this.supportGridKeyboardNavigation = supportGridKeyboardNavigation;
        this.moveSelectionDuringNavigation = moveSelectionDuringNavigation;
        this.spaceKeyBehaviour = spaceKeyBehaviour;
    }

    resolveKeyboardAction(
        event: React.KeyboardEvent,
        listViewComposable: ListViewComposable,
        navigationOrientation: NavigationOrientation,
        selectionType: ListViewSelectionType,
        currentItemType: CurrentItemType,
        supportTypeAhead: boolean
    ): [ListViewNavigationAction, ListViewSelectionAction] | null {

        const eventKeyCode = keyCodeGetFromReactEvent(event);
        const isCtrlKey = this.isCtrlKey(event);
        const isShiftKey = event.shiftKey;

        if (eventKeyCode === KeyCodes.Home) {
            return [{type: ListViewNavigationActionType.MoveToFirst}, this.resolveSelectionAction(event, selectionType)];
        } else if (eventKeyCode === KeyCodes.End) {
            return [{type: ListViewNavigationActionType.MoveToLast}, this.resolveSelectionAction(event, selectionType)];
        } else if (eventKeyCode === this.selectionKeyCode) {
            if (selectionType !== ListViewSelectionType.None) {
                if (isCtrlKey || isShiftKey) {
                    return [
                        {type: ListViewNavigationActionType.None},
                        currentItemType === CurrentItemType.Selectable ||
                        (currentItemType === CurrentItemType.NoneSelectable && isShiftKey)
                            ? this.resolveCurrentSelectionAction(event, selectionType)
                            : ListViewSelectionAction.None
                    ];
                } else if (this.spaceKeyBehaviour !== ListViewDefaultKeymapControllerSpaceKeyBehaviour.None && currentItemType === CurrentItemType.Selectable) {
                    return [
                        {type: ListViewNavigationActionType.None},
                        this.spaceKeyBehaviour === ListViewDefaultKeymapControllerSpaceKeyBehaviour.Default ?
                            this.resolveCurrentSelectionAction(event, selectionType) :
                            ListViewSelectionAction.ToggleCurrent
                    ];
                }
            }
        } else if (
            selectionType === ListViewSelectionType.Multiple &&
            isCtrlKey &&
            !isShiftKey &&
            eventKeyCode === KeyCodes.KeyA
        ) {
            return [{type: ListViewNavigationActionType.None}, ListViewSelectionAction.SelectAll];
        } else if (isArrowKey(event)) {

            if (this.supportGridKeyboardNavigation) {
                const listViewRootElement = listViewComposable.getRootElement();
                if (listViewRootElement) {
                    const computedStyle = window.getComputedStyle(listViewRootElement);
                    if (computedStyle.display === 'grid') {
                        switch (event.key) {
                            case 'ArrowRight':
                                return [{type: ListViewNavigationActionType.MoveToNext}, this.resolveSelectionAction(event, selectionType)];
                            case 'ArrowLeft':
                                return [{type: ListViewNavigationActionType.MoveToPrevious}, this.resolveSelectionAction(event, selectionType)];
                            default:
                            {
                                const gridTemplateColumnsStyleStr = computedStyle.gridTemplateColumns;

                                const columnsCount = gridTemplateColumnsStyleStr.split(' ').length;

                                return [
                                    {
                                        type: event.key === 'ArrowDown' ?
                                            ListViewNavigationActionType.MoveForward :
                                            ListViewNavigationActionType.MoveBackward,
                                        delta: columnsCount
                                    },
                                    this.resolveSelectionAction(event, selectionType)
                                ];
                            }
                        }
                    }
                }
            }

            const moveToNextKey = navigationOrientation === NavigationOrientation.Horizontal ?
                'ArrowRight' :
                'ArrowDown';

            const moveToPrevKey = navigationOrientation === NavigationOrientation.Horizontal ?
                'ArrowLeft' :
                'ArrowUp';

            switch (event.key) {
                case moveToNextKey:
                    return [{type: ListViewNavigationActionType.MoveToNext}, this.resolveSelectionAction(event, selectionType)]
                case moveToPrevKey:
                    return [{type: ListViewNavigationActionType.MoveToPrevious}, this.resolveSelectionAction(event, selectionType)];
                default:
                    return null;
            }

        } else if (supportTypeAhead && !isCtrlKey && !isShiftKey && event.key.length === 1) {
            return [
                {type: ListViewNavigationActionType.MoveAccordingToTypeAhead},
                this.resolveSelectionAction(event, selectionType)
            ];
        }

        return null;
    }

    private resolveCurrentSelectionAction(
        event: React.KeyboardEvent,
        selectionType: ListViewSelectionType
    ): ListViewSelectionAction {
        const isCtrlKey = this.isCtrlKey(event);
        const isShiftKey = event.shiftKey;

        if (selectionType === ListViewSelectionType.None) {
            return ListViewSelectionAction.None;
        } else if (selectionType === ListViewSelectionType.Single) {
            if (isCtrlKey && !isShiftKey) {
                return ListViewSelectionAction.ToggleCurrent;
            } else {
                return ListViewSelectionAction.SelectCurrent;
            }
        } else {
            if (!isCtrlKey && !isShiftKey) {
                return ListViewSelectionAction.AddCurrentToSelection;
            } else if (isCtrlKey && !isShiftKey) {
                return ListViewSelectionAction.ToggleCurrent;
            } else if (!isCtrlKey && isShiftKey) {
                return ListViewSelectionAction.SelectRangeUntilCurrent;
            } // if (isCtrlKey && isShiftKey)
            else {
                return ListViewSelectionAction.AddRangeUntilCurrentToSelection;
            }
        }
    }

    private resolveSelectionAction(
        event: React.KeyboardEvent,
        selectionType: ListViewSelectionType
    ): ListViewSelectionAction {
        if (selectionType === ListViewSelectionType.None || !this.moveSelectionDuringNavigation) {
            return ListViewSelectionAction.None;
        } else {
            const isCtrlKey = this.isCtrlKey(event);
            const isShiftKey = event.shiftKey;

            if (selectionType === ListViewSelectionType.Single) {
                if (isCtrlKey && !isShiftKey && this.allowNavigationWithoutChangingSelection) {
                    return ListViewSelectionAction.None;
                } else {
                    return ListViewSelectionAction.MoveSelection;
                }
            } else {
                if (isCtrlKey && !isShiftKey && this.allowNavigationWithoutChangingSelection) {
                    return ListViewSelectionAction.None;
                } else if (isCtrlKey && isShiftKey) {
                    return ListViewSelectionAction.AddRangeToSelection;
                } else if (!isCtrlKey && isShiftKey) {
                    return ListViewSelectionAction.SelectRange;
                }

                return ListViewSelectionAction.MoveSelection;
            }
        }
    }

    public isCtrlKey(event: React.KeyboardEvent | React.MouseEvent) {
        switch (this.ctrlKeyType) {
            case CtrlKeyType.CtrlKey:
                return event.ctrlKey && !event.metaKey;
            case CtrlKeyType.MetaKey:
                return !event.ctrlKey && event.metaKey;
            case CtrlKeyType.CtrlOrMetaKey:
                return event.ctrlKey || event.metaKey;
        }

        return false;
    }

    public isShiftKey(event: React.KeyboardEvent | React.MouseEvent): boolean {
        return event.shiftKey;
    }
}

export enum CtrlKeyType {
    CtrlKey,
    MetaKey,
    CtrlOrMetaKey
}

function isArrowKey (event: React.KeyboardEvent) {
    switch (event.key) {
        case 'ArrowRight':
        case 'ArrowLeft':
        case 'ArrowDown':
        case 'ArrowUp':
            return true;
        default:
            return false;
    }
}
