import {
    DoppeFormActionSettings,
    getDefaultDoppeFormActionSettings,
    prepareFormSettingsForViewer
} from '../../client-server-common/types/doppe-form-action-settings';
import {DoppeHideableValue, doppePremiumFeatureIsDisabled, DoppePremiumFeatures} from '../../client-server-common';
import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {Values} from '@wix/devzai-utils-common';
import {
    DoppeActionTypeSubscribeViewerLang
} from '../doppe-action-type-subscribe/doppe-action-type-subscribe-viewer.lang';
import {
    SubscribeActionFormSubscriptionOptions
} from '../doppe-action-type-subscribe/doppe-action-type-subscribe-metadata';

export const LockSubscribeActionFormSubscriptionOptions = {
    Email: 'Email',
    Phone: 'Phone'
} as const;

export type LockSubscribeActionFormSubscriptionOptions = Values<typeof LockSubscribeActionFormSubscriptionOptions>;

export namespace DoppeLockActionTypeSubscribe {

    export interface ActionSettings extends DoppeFormActionSettings{
        subscribeButtonText: DoppeHideableValue<string>;
        enableEmailSubscribeOption: boolean;
        enablePhoneSubscribeOption: boolean;
        defaultSubscribeOption: LockSubscribeActionFormSubscriptionOptions;
    }
}

export const doppeLockActionTypeSubscribe = doppeActionTypeDefineMetadata<DoppeLockActionTypeSubscribe.ActionSettings>({
    id: 'co.hopp.lockAction/subscribe-form-lock',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        enableEmailSubscribeOption: true,
        enablePhoneSubscribeOption: false,
        defaultSubscribeOption: SubscribeActionFormSubscriptionOptions.Email,
        subscribeButtonText: DoppeActionTypeSubscribeViewerLang.defaultTexts.subscribeButtonText,
        ...getDefaultDoppeFormActionSettings()
    }),
    isWidgetOpener: true,
    isFormAction: true,
    prepareForViewer: actionSettings => {
        prepareFormSettingsForViewer(actionSettings);
    },
    isPremiumAction: (premiumFeaturesStatus) => {
        return doppePremiumFeatureIsDisabled(premiumFeaturesStatus, DoppePremiumFeatures.creators_form_submission)
    },
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    canRenderInPage: true,
})