export function jsonTryParse<T> (
    str: string | null | undefined,
    errorCallback?: (error: any) => void
) : T | undefined {

    if (str === null || str === undefined) {
        return undefined;
    }

    try {
        return JSON.parse(str);
    } catch (error) {

        if (errorCallback) {
            try {
                errorCallback(error)
            } catch (_error) {
                // Ignore
            }
        }

        return undefined;
    }
}

export function jsonEncodeToBase64String (obj: object) {
    return btoa(JSON.stringify(obj));
}

export function jsonTryDecodeBase64String<T> (
    str: string | null | undefined,
    errorCallback?: (error: any) => void
) : T | undefined {
    if (str === null || str === undefined) {
        return undefined;
    }

    try {
        return jsonTryParse<T>(atob(str), errorCallback)
    } catch (error) {
        if (errorCallback) {
            try {
                errorCallback(error)
            } catch (_error) {
                // Ignore
            }
        }

        return undefined;
    }
}

export function jsonParseWithFallback<T, F> (
    str: string | null | undefined, fallbackValue: F
) : T | F {
    if (str === null || str === undefined) {
        return fallbackValue;
    }
    try {
        return JSON.parse(str);
    } catch (error) {
        return fallbackValue
    }
}

export function jsonStringify<T> (obj: T) : string {
    return JSON.stringify(obj);
}