import {
    AssertionLogicFunction,
    assertType,
    Evaluable, evaluateWhenFunction,
    NarrowedNonUnionTypeAssertController
} from "@wix/devzai-utils-common";

export type DoppeHideableValueHiddenState<V> = {
    __hidden: true;
    value: V;
};

export type DoppeHideableValue<V> = V | DoppeHideableValueHiddenState<V>

export function doppeHideableValueIsVisibleAndNotEqualValue<V> (value: any, comparedValue: V) : value is V {
    return !doppeHideableValueIsHidden(value) && doppeHideableValueGetValue(value) !== comparedValue;
}

export function doppeHideableValueIsVisible<V> (value: DoppeHideableValue<V>) : value is V {
    return !doppeHideableValueIsHidden(value);
}

export function doppeHideableValueIsHidden<V> (value: any) : value is DoppeHideableValueHiddenState<V> {
    return typeof value === 'object' && value !== null && (value as DoppeHideableValueHiddenState<V>).__hidden === true;
}

export function doppeHideableValueGetValue<V> (value: DoppeHideableValue<V>) : V {
    if (doppeHideableValueIsHidden<V>(value)) {
        return value.value;
    } else {
        return value;
    }
}

export function doppeHideableValueModifyValue<V> (
    hideableValue: DoppeHideableValue<V>,
    modificationFunction: Evaluable<(value: V) => V>
) : DoppeHideableValue<V> {
    const value = doppeHideableValueGetValue(hideableValue);

    const modifiedValue = evaluateWhenFunction(modificationFunction, value);

    if (doppeHideableValueIsHidden(hideableValue)) {
        return doppeHideableValueCreateHidden(modifiedValue);
    } else {
        return modifiedValue;
    }
}

export function doppeHideableValueModifyValueWhenVisible<V> (
    hideableValue: DoppeHideableValue<V>,
    modificationFunction: (value: V) => V
) : DoppeHideableValue<V> {
    if (doppeHideableValueIsHidden(hideableValue)) {
        return hideableValue;
    } else {
        return modificationFunction(hideableValue)
    }
}

export function doppeHideableValueGetVisibleValue<V, H> (value: DoppeHideableValue<V>, hiddenValue: H) : V | H {
    if (doppeHideableValueIsHidden<V>(value)) {
        return hiddenValue;
    } else {
        return value;
    }
}

export function doppeHideableValueCreateHidden<V> (value: V) : DoppeHideableValueHiddenState<V> {
    return {
        __hidden: true,
        value: value
    }
}

export function doppeHideableValueEnsureHidden<V> (value: DoppeHideableValue<V>) : DoppeHideableValueHiddenState<V> {
    if (doppeHideableValueIsHidden(value)) {
        return value;
    } else {
        return doppeHideableValueCreateHidden(value);
    }
}

export function doppeHideableValueToggleVisible<V> (
    value: DoppeHideableValue<V>,
    isVisible: Evaluable<(isVisible: boolean) => boolean>
) : DoppeHideableValue<V> {
    return doppeHideableValueToggleHidden(value, wasHidden => !evaluateWhenFunction(isVisible, !wasHidden))
}

export function doppeHideableValueToggleHidden<V> (
    value: DoppeHideableValue<V>,
    isHidden: Evaluable<(isHidden: boolean) => boolean>
) : DoppeHideableValue<V> {
    const wasHidden = doppeHideableValueIsHidden(value);

    const evaluatedIsHidden = evaluateWhenFunction(isHidden, wasHidden);

    if (wasHidden !== evaluatedIsHidden) {
        return wasHidden ? value.value : doppeHideableValueCreateHidden(value);
    } else {
        return value;
    }
}

export function assertDoppeHideableValue<V> (doppeHideableValue: DoppeHideableValue<V>, assertionLogic: AssertionLogicFunction<V>) : asserts doppeHideableValue is DoppeHideableValue<V>;
export function assertDoppeHideableValue<V> (doppeHideableValue: unknown, assertionLogic: AssertionLogicFunction<V>) : asserts doppeHideableValue is DoppeHideableValue<V>;
export function assertDoppeHideableValue<V> (doppeHideableValue: unknown, assertionLogic: AssertionLogicFunction<V>) : asserts doppeHideableValue is DoppeHideableValue<V> {
    assertType<DoppeHideableValue<V>>(doppeHideableValue, assert => {
        (assert as any).isUnion(
            assertionLogic,
            (assert: NarrowedNonUnionTypeAssertController<DoppeHideableValueHiddenState<V>>) => assert.isObject({
                __hidden: assert => assert.isOneOfValues([true]),
                value: assertionLogic
            })
        )
    })
}