const SkippableSkipToken: unique symbol = Symbol('SkippableSkipToken');

export type SkippableSkipToken = typeof SkippableSkipToken;

export function skippableGetSkipToken () : SkippableSkipToken {
    return SkippableSkipToken;
}

export function skippableIsNotSkipped<V>(value: V | SkippableSkipToken) : value is V {
    return value !== SkippableSkipToken;
}

export function skippableIsSkipped<V>(value: V | SkippableSkipToken) : value is SkippableSkipToken {
    return value === SkippableSkipToken;
}