import React from 'react';
import {vars, style, classes} from './button.st.css';
import {MouseCursor} from '@wix/devzai-utils-dom';
import {Values} from "@wix/devzai-utils-common";

export const ButtonType = {
    NormalButton: 'button',
    SubmitButton: 'submit',
    ResetButton: 'reset'
} as const;

export type ButtonType = Values<typeof ButtonType>;

export const Button = React.memo(React.forwardRef(function Button (props: Button.Props, ref: React.Ref<HTMLElement>) {
    const {
        cursor = 'pointer',
        disabled = false,
        children,
        tagName = 'button',
        buttonType = ButtonType.NormalButton,

        className,
        style: cssProps,
        ...htmlAttributes
    } = props;

    return React.createElement(tagName, {
        ...htmlAttributes,
        ref: ref,
        className: style(classes.root, props.className),
        style: {
            ...cssProps,
            [vars.mouseCursor]: cursor
        },
        disabled: disabled ? true : undefined,
        'aria-disabled': disabled ? true : undefined,
        type: tagName === 'button' ? buttonType : undefined,
        children: children
    })
}));

export namespace Button {

    export type HtmlAttributes =
        React.ButtonHTMLAttributes<any> &
        Omit<React.AnchorHTMLAttributes<any>, keyof React.ButtonHTMLAttributes<any>>

    export interface Props extends HtmlAttributes {
        cursor?: MouseCursor;
        disabled?: boolean;
        tagName?: 'button' | 'a' | 'div';
        buttonType?: ButtonType
    }
}
