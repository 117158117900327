import {classes} from './flex-layout.st.css';

export const FlexLayout = {
    column: {
        alignToStart: classes.flexColumnAlignToStart,
        alignToEnd: classes.flexColumnAlignToEnd,
        alignToCenter: classes.flexColumnAlignToCenter,
        default: classes.flexColumnDefault
    },
    row: {
        alignToStart: classes.flexRowAlignToStart,
        alignToTop: classes.flexRowAlignToStart,
        alignToEnd: classes.flexRowAlignToEnd,
        alignToBottom: classes.flexRowAlignToEnd,
        alignToCenter: classes.flexRowAlignToCenter,
        stretch: classes.flexRowStretch,
    },
    wrap: classes.flexWrap,
    centerContent: classes.flexCenterContent,
    fillRemainingHorizontalSpace: classes.flexFillRemainingSpace,
    fillRemainingVerticalSpace: classes.flexFillRemainingVerticalSpace,

    shrinkInRemainingHorizontalSpace: classes.shrinkInRemainingHorizontalSpace,
    shrinkInRemainingVerticalSpace: classes.shrinkInRemainingVerticalSpace,

    horizontallyJustifyToEnd: classes.flexHorizontallyJustifyToEnd,
    verticallyJustifyToEnd: classes.verticallyJustifyToEnd,

    justifyContent: {
        spaceBetween: classes.justifyContentSpaceBetween,
        toCenter: classes.justifyContentCenter,
        toEnd: classes.justifyContentToEnd,
        toStart: classes.justifyContentToStart,
    },

    noShrink: classes.noShrink,
    alignSelf: {
        flexStart: classes.flexAlignSelfFlexStart,
        flexEnd: classes.flexAlignSelfFlexEnd,
        center: classes.flexAlignSelfCenter,
        stretch: classes.flexAlignSelfStretch,
    },
    justifySelf: {
        flexStart: classes.flexJustifySelfFlexStart,
        flexEnd: classes.flexJustifySelfFlexEnd,
        center: classes.flexJustifySelfCenter,
        stretch: classes.flexJustifySelfStretch,
    }
};

export enum FlexAlignment {
    FlexStart = 'flexStart',
    FlexEnd = 'flexEnd',
    Center = 'center',
    Stretch = 'stretch',
}
