import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {asArray, immerProduce, iterableMapToArray, OmitStrict, PartiallyRequired} from '@wix/devzai-utils-common';
import {
    DoppeActionTypeVideo,
    doppeActionTypeVideoGetDefaultSettings
} from '../doppe-action-type-video/doppe-action-type-video';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    doppeBymoPageVideoSourceResolveVideoUrl,
    doppeHideableValueCreateHidden,
    DoppeListActionSettings
} from '../../client-server-common';
import {doppeDtoActionGetDefaultSearchListSettingsProps} from '../../client-server-common/types/doppe-dto-action';


export namespace DoppeActionTypeVideosList {

    export interface VideoSettings extends DoppeActionTypeVideo.ActionSettings {
        id: string;
        title: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour
    {
        videos: VideoSettings[];
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewButtonText: string;
    }

    export type PartialActionSettings = Partial<OmitStrict<DoppeActionTypeVideosList.ActionSettings, 'videos'>> & {
        videos?: PartiallyRequired<Partial<DoppeActionTypeVideosList.VideoSettings>, 'id'>[]
    }
}

export const doppeActionTypeVideosList = doppeActionTypeDefineMetadata<
    DoppeActionTypeVideosList.ActionSettings,
    DoppeActionTypeVideosList.PartialActionSettings
>({
    id: 'io.bymo.action/videos-list',
    resolveActionSettings: action => {

        const {
            videos = [],
            ...restSettings
        } = action.settings;

        return {
            videos: videos.map(video => {
                return {
                    ...doppeActionTypeVideoGetDefaultSettings(),
                    title: 'Video Title',
                    ...video
                }
            }),
            layout: DoppeActionLayout.Grid,
            showButton: false,
            buttonText: '',
            buttonUrl: 'https://www.website.com',
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
            listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),
            itemShowTitle: true,
            itemShowDescription: true,
            itemShowImage: true,
            itemShowTextOnImage: false,
            previewShowDescription: true,
            previewShowTitle: true,
            previewButtonText: '',
            mainImage: doppeHideableValueCreateHidden(null),
            mainTitle: doppeHideableValueCreateHidden(''),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return iterableMapToArray(actionSettings.videos, (video, skip) => {
            return video.videoImage ?? skip
        });
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.videos[0]?.videoImage ?? null;
    },
    resolveMainLink: actionSettings => {
        return actionSettings.showButton ? actionSettings.buttonUrl : null;
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.videos.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.videos.map(item => {
            return {
                title: item.title,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.videoImage ? asArray(item.videoImage)[0] : null,
                itemId: item.id,
                ribbon: doppeHideableValueCreateHidden(''),
                url: doppeBymoPageVideoSourceResolveVideoUrl(item.externalVideoUrl)
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.videos) {
                if (item.videoImage) {
                    item.videoImage =
                        wixMediaResourceReplaceExternalImage(item.videoImage, externalImagesUrlToImageResourceMap)
                }
            }
        })
    },
});