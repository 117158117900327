import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {DoppeActionTypeEventViewerLang} from './doppe-action-type-event-viewer.lang';
import {
    arrayRemoveNullValues,
    dateResolveFromDateTimeValue,
    immerProduce,
    stringIsNotNullableOrWhiteSpace
} from '@wix/devzai-utils-common';
import {
    BymoPageProps,
    DoppeActionPriceData,
    DoppeExternalEventProvider,
    doppeExternalEventProviderResolveBusinessEntityReference
} from '../../client-server-common';
import {DoppeDtoEvent} from '../../client-server-common/types/doppe-dto-event';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';

export namespace DoppeActionTypeEvent {
    export interface ActionSettings {
        eventLink: string;
        eventTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        eventButtonText: DoppeHideableValue<string>;
        eventImages: WixMediaResource[] | null;
        eventDate: DoppeHideableValue<Date>;
        eventTime: DoppeHideableValue<string>;
        eventLocation: DoppeHideableValue<string>;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
        eventProvider: DoppeExternalEventProvider | null;
        eventProviderId: string | null,
    }
}

export const doppeActionTypeEvent = doppeActionTypeDefineMetadata<DoppeActionTypeEvent.ActionSettings>({
    id: 'io.bymo.action/event',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        eventLink: '',
        eventTitle: DoppeActionTypeEventViewerLang.defaultTexts.eventTitle,
        description: DoppeActionTypeEventViewerLang.defaultTexts.description,
        eventButtonText: DoppeActionTypeEventViewerLang.defaultTexts.eventButtonText,
        eventImages: null,
        eventDate: new Date(),
        eventLocation: '',
        priceData: {
            actualPrice: 'Starting from 50$'
        },
        eventTime: doppeHideableValueCreateHidden('00:00'),
        couponCode: doppeHideableValueCreateHidden('CouponCode'),
        ribbon: doppeHideableValueCreateHidden('SALE'),
        eventProvider: null,
        eventProviderId: null,
    }),
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.eventImages ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.eventImages?.[0] ?? null;
    },
    resolveMainLink: actionSettings => {
        return actionSettings.eventLink;
    },
    resolveCouponData: actionSettings => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.couponCode, ''),
            couponCode: doppeHideableValueGetValue(actionSettings.couponCode),
            couponDescription: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.description, '') ? actionSettings.description : '',
            couponTitle: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.eventTitle, '') ? actionSettings.eventTitle : '',
        }
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.eventImages) {
                actionSettings.eventImages = actionSettings.eventImages.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.eventProvider !== null && actionSettings.eventProviderId !== null ?
                doppeExternalEventProviderResolveBusinessEntityReference(
                    actionSettings.eventProvider,
                    actionSettings.eventProviderId
                ) :
                null
        ])
    },
});

export function doppeActionTypeEventResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeEvent.ActionSettings,
    doppeDtoService: DoppeDtoEvent,
    _bymoPageProps: BymoPageProps,
): DoppeActionTypeEvent.ActionSettings {

    // const location = arrayFirst(doppeDtoService.locations);

    return {
        ...actionSettings,
        eventLink: stringIsNotNullableOrWhiteSpace(doppeDtoService.eventPageUrl) ?
            doppeDtoService.eventPageUrl : 'about:blank',
    }
}

export function convertDoppeWixEventToEventSettings(event: DoppeDtoEvent, settings?: DoppeActionTypeEvent.ActionSettings) {
    return {
        eventTime: doppeHideableValueCreateHidden('20:00'),
        couponCode: doppeHideableValueCreateHidden(''),
        ribbon: doppeHideableValueCreateHidden(''),
        eventButtonText: '',
        ...settings,
        eventImages: event.media,
        eventLink: event.eventPageUrl ?? '',
        description: event.description ? event.description :  doppeHideableValueCreateHidden(''),
        eventTitle: event?.name ? event.name : '',
        eventLocation: event?.location ? event.location : doppeHideableValueCreateHidden(''),
        eventDate: event.eventDates?.eventStartDate ? dateResolveFromDateTimeValue(event.eventDates?.eventStartDate) : doppeHideableValueCreateHidden(new Date(Date.now())),
        priceData: calculateActualPriceForEvent(event),
        eventProviderId: event.id,
        eventProvider: DoppeExternalEventProvider.Wix,
    }
}

export function populateEventActionSettingsDefaultTexts(options: {
    event: DoppeActionTypeEvent.ActionSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}) : DoppeActionTypeEvent.ActionSettings {

    const {
        event,
        pageContentLocales,
    } = options;

    return  {
        ...event,
        eventTitle: doppeHideableValueGetValue(event.eventTitle) !== '' ? event.eventTitle : pageContentLocales["event.actionSettings.title"],
        description: doppeHideableValueModifyValue(event.description, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.description"];
        }),
        couponCode: doppeHideableValueModifyValue(event.couponCode, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.couponCode"];
        }),
        ribbon: doppeHideableValueModifyValue(event.ribbon, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.ribbon"];
        }),
        eventLocation: doppeHideableValueModifyValue(event.eventLocation, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.location"];
        }),
        eventButtonText: doppeHideableValueIsVisibleAndNotEqualValue(event.eventButtonText , '') ? event.eventButtonText :  pageContentLocales["event.actionSettings.buttonText"],
    }
}



export function calculateActualPriceForEvent(event: DoppeDtoEvent) {
    const formattedPrice = getFormattedEventPrice(event);

    return event.lowestPrice || event.highestPrice ? {
        actualPrice: formattedPrice
    } : doppeHideableValueCreateHidden({
        actualPrice: formattedPrice
    });
}

export function getFormattedEventPrice(event: DoppeDtoEvent) {
    if (event.lowestPrice !== event.highestPrice && event.lowestPrice && event.highestPrice) {
        return  `${event.lowestPrice} - ${event.highestPrice}`;

    }
    else if (event.lowestPrice) {
        return event.lowestPrice;
    }
    else if (event.highestPrice) {
        return event.highestPrice;
    }
    else {
        return '---';
    }
}