import {MutableRefObject, useEffect, useRef} from 'react';
import {evaluateWhenFunction} from '@wix/devzai-utils-common';

const NotAssigned = Symbol('NotAssigned');

export function useInstanceValue<T>(initializer: () => T) : T {
    const ref = useRef<T | typeof NotAssigned>(NotAssigned);

    const value = ref.current;
    if (value === NotAssigned) {
        return ref.current = initializer();
    } else {
        return value;
    }

}

export function useRefWithInitializer<T>(initializer: T | (() => T)) : MutableRefObject<T> {
    const ref = useRef<T | typeof NotAssigned>(NotAssigned);

    if (ref.current === NotAssigned) {
        ref.current = evaluateWhenFunction(initializer);
    }

    return ref as MutableRefObject<T>
}

export type DisposableInstanceValueInfo<T> = [T, () => void];

export function useDisposableInstanceValue<T>(initializer: () => DisposableInstanceValueInfo<T>) : T {
    const ref = useRef<DisposableInstanceValueInfo<T> | typeof NotAssigned>(NotAssigned);

    if (ref.current === NotAssigned) {
        ref.current = initializer();
    }

    useEffect(() => {
        return () => {

            const [,dispose] = ref.current as DisposableInstanceValueInfo<T>;

            dispose();
        }
    }, [ref]);

    const [value] = ref.current;
    return value;
}

export function useInstanceValueWithDisposing<T>(
    initializer: () => T,
    disposingFunction: (value: T) => void
) : T {
    const ref = useRef<T | typeof NotAssigned>(NotAssigned);

    if (ref.current === NotAssigned) {
        ref.current = initializer();
    }

    useEffect(() => {
        return () => {
            if (disposingFunction) {
                disposingFunction(ref.current as T)
            }
        }
    }, [ref]);

    return ref.current;
}
