import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeProduct} from "./doppe-action-type-product";

export const doppeActionTypeProductViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeProduct, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeProductWidget: (await import(
                './doppe-action-type-product-widget/doppe-action-type-product-widget'
            )).DoppeActionTypeProductWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeProductWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeProductWidget
                {...renderProps}
            />
        );
    },

    activateCTA: actionContext => {
        actionContext.navigateToUrl(actionContext.action.settings.productPageLink, true);
    }
})