import {DoppeViewerAppClientServiceBase} from './doppe-viewer-app-client-service-base';
import {DoppeViewerAnalyticsEvents} from '../../client-server-common';
import {
    arrayOrderBy,
    assertNotNullable,
    asyncCachedValueCreate,
    compareStringsCaseInsensitive,
    evaluateFunction,
    OmitStrict,
    tsTypeAssert
} from '@wix/devzai-utils-common';
import type {DoppeViewerBiEvents} from '../doppe-viewer-wix-web-bi-logger/doppe-viewer-wix-web-bi-logger';
import {
    ConsentPreferences,
    gaClientIdentify,
    gaClientTrackEvent,
    gaInitialize,
    gaUpdateConsent
} from '@wix/devzai-utils-dom';
import {
    GoogleAnalyticsEventMap
} from '../../client-server-common/doppe-viewer-google-analytics-events/doppe-viewer-google-analytics-events';
import {DoppeDtoBymoLinkViewerData} from '../../client-server-common/types/doppe-dto-bymo-link-viewer-data';

export class DoppeViewerAppAnalyticsClient extends DoppeViewerAppClientServiceBase {

    private bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithConnectedPage | null = null;
    private indexInSessionStore:Record<string,number> = {};
    private isInitialized = false;
    private asyncCachedWixBiWebLogger = asyncCachedValueCreate(async () => {
        const {
            doppeViewerWixWebBiLoggerCreate,
            doppeViewerBiEvents
        } = await import (/* webpackChunkName: "doppe-viewer-wix-web-bi-logger" */ '../doppe-viewer-wix-web-bi-logger/doppe-viewer-wix-web-bi-logger');

        return {
            logger: doppeViewerWixWebBiLoggerCreate(),
            doppeViewerBiEvents
        }
    })

    public initialize(options: {
        bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithConnectedPage | null;
    }) {
        if (this.isInitialized) {
            throw new Error(`DoppeRecorderClientService has already been initialized.`)
        }

        this.isInitialized = true;

        this.bymoLinkViewerData = options.bymoLinkViewerData;

        const consentManager = assertNotNullable(this.clientServices.doppeViewerAppCookieService?.cookieConsentManager);
        const userGASettings = this.clientServices.bymoService.getBymoLinkViewerData()?.userGoogleAnalyticsSettings;

        if (userGASettings?.tagId !== undefined) {
            gaInitialize(userGASettings.tagId, {
                consent: {
                    analyticsStorage: consentManager.hasConsent('anl') ? 'granted' : 'denied',
                }
            });
            
            gaClientIdentify(userGASettings.tagId, this.clientServices.doppeViewerClientData.clientId);
            consentManager.eventEmitter.on('consentChanged', (newConsent:  Partial<ConsentPreferences>) => {
                if (userGASettings.tagId) {
                    gaUpdateConsent({ analyticsStorage: newConsent.anl === 1 ? 'granted' : 'denied' });
                }
            });
        }

        const cookieAccessor = assertNotNullable(this.clientServices.doppeViewerAppCookieService.consentSafeCookieStorageAccessor);
        const userAccountName = assertNotNullable(this.clientServices.bymoService.getBymoLinkViewerData()?.userAccountName);

        return cookieAccessor.updateItem<string>(
            `pagePath`,
            pagePath => pagePath ?? userAccountName
        )
    }

    public flushLogs() {
        const cachedWixBiWebLogger = this.asyncCachedWixBiWebLogger.getCachedValue();
        if (cachedWixBiWebLogger) {
            cachedWixBiWebLogger.logger.flush().catch(() => {

            })
        }
    }

    public logEvent<EVENT_NAME extends DoppeViewerAnalyticsEvents.Events['eventName']>(
        eventName: EVENT_NAME,
        params: OmitStrict<DoppeViewerAnalyticsEvents.EventParams<EVENT_NAME>, keyof DoppeViewerAnalyticsEvents.ImplicitParams>
    ) {
        const sessionId = this.getSessionId();
        const clientId = this.getClientId();

        const paramsHash = JSON.stringify(arrayOrderBy(Object.entries(params), ([key]) => key, compareStringsCaseInsensitive))

        const uouIndexInSession = this.indexInSessionStore[`indexInSession.${eventName}.${sessionId}.${paramsHash}`] ?? 0;
        this.indexInSessionStore[`indexInSession.${eventName}.${sessionId}.${paramsHash}`] = uouIndexInSession + 1;

        this.apiClient.reportAnalyticsEvent({
            eventName: eventName,
            eventParams: {
                ...params,
                ...tsTypeAssert<DoppeViewerAnalyticsEvents.DoppeServerImplicitParams>({
                    uouIndexInSession: uouIndexInSession,
                })
            } as DoppeViewerAnalyticsEvents.DoppeServerEventParams<EVENT_NAME>
        })
            .catch(() => {
                console.warn(`Failed reporting analytics event '${eventName}' to doppe analytics.`)
            });

        evaluateFunction(async () => {

            const {
                doppeViewerBiEvents,
                logger
            } = await this.asyncCachedWixBiWebLogger.getValue();

            const biEventLogObjectFactory = doppeViewerBiEvents[eventName as DoppeViewerBiEvents];

            if (biEventLogObjectFactory === undefined) {
                console.warn(`Event '${eventName}' isn't available on bi-logger`)
            } else {
                await logger.report(doppeViewerBiEvents[eventName as DoppeViewerBiEvents]?.({
                    ...params,
                    ...tsTypeAssert<DoppeViewerAnalyticsEvents.ImplicitParams>({
                        uouReferrer: document.referrer,
                        uouIndexInSession: uouIndexInSession,
                        uouSessionId: sessionId,
                        uouClientId: clientId,
                        _msid: this.bymoLinkViewerData?.creatorsMetaSiteId ?? null
                    })
                }));
            }
        })
            .catch(() => {
                console.warn(`Failed logging bi event '${eventName}'`)
            });

        try {
            if (GoogleAnalyticsEventMap[eventName]) {
                gaClientTrackEvent(GoogleAnalyticsEventMap[eventName]!.name, {});
            }
        } catch (error) {
            console.warn(`Failed reporting analytics event '${eventName}' to google analytics`)
        }
    }
}