import {DomEventListener} from "../dom-event-listener/dom-event-listener";

class UserGestureTrackerSingleton {

    private isInitialized = false;
    private pendingCallbacks: (() => void)[] = [];
    private _userGestureOccurred = false;

    public ensureInitialized () {
        if (!this.isInitialized) {
            this.isInitialized = true;

            // https://html.spec.whatwg.org/multipage/interaction.html#user-activation-processing-model
            const listeners = ['mousedown', 'keydown', 'pointerdown', 'pointerup', 'touchend'].map(eventName => {
                return new DomEventListener(document, eventName, () => {
                    this.onUserGesture();

                    listeners.forEach(listener => {
                        listener.deactivate();
                    })
                }).activate();
            })
        }
    }

    public get userGestureOccurred () {
        return this._userGestureOccurred;
    }

    public executeWhenUserGestureBlockedFeaturesAreEnabled (
        callback: () => void
    ) {
        if (this._userGestureOccurred) {
            callback();
        } else {
            this.pendingCallbacks.push(callback);
        }
    }

    private onUserGesture () {

        this._userGestureOccurred = true;

        const callbacks = [...this.pendingCallbacks];
        this.pendingCallbacks = [];

        for (const callback of callbacks) {
            callback();
        }
    }

}

export const UserGestureTracker = new UserGestureTrackerSingleton();