import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeArticlesList} from "./doppe-action-type-articles-list";

export const doppeActionTypeArticlesListViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeArticlesList, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeArticlesListWidget: (await import(/* webpackChunkName: "doppe-action-type-articles-list-widget" */ './doppe-action-type-articles-list-widget/doppe-action-type-articles-list-widget')).DoppeActionTypeArticlesListWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeArticlesListWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeArticlesListWidget
                {...renderProps}
            />
        );

    }
})