import {
    arrayDistinct,
    arrayFindValue, arrayFlatten, arrayGenerate,
    arrayOrderBy,
    arrayRemoveUndefinedValues,
    assertDefined,
    compareNumbers,
    iterableMapToArray
} from "@wix/devzai-utils-common";

export interface HtmlImageSizesAttributeData {
    defaultSize: string;
    additionalSizes?: string[];
}

export function htmlImageSizesAttributeCreate (data: HtmlImageSizesAttributeData) {
    return [...(data.additionalSizes ?? []), data.defaultSize].join(',');
}

const SkipToken: unique symbol = Symbol('SkipToken');
type SkipToken = typeof SkipToken;

/**
 * Source:
 * - https://gs.statcounter.com/screen-resolution-stats/mobile/worldwide
 * - https://devhints.io/resolutions
 */
// const mobileDevicesResolutions = [
//     {width: 360, height: 800, dpr: 4}, /* Samsung Galaxy S20 */
//     {width: 360, height: 640, dpr: 4}, /* Samsung Galaxy S6-S7 */
//     {width: 414, height: 896, dpr: 2}, /* Iphone XR */
//     {width: 360, height: 780, dpr: 4}, /* LG G8 */
//     {width: 375, height: 667, dpr: 2}, /* Iphone SE */
//     {width: 375, height: 812, dpr: 3}, /* Iphone X */
//     {width: 412, height: 915, dpr: 3.5}, /* Samsung Galaxy S20 Ultra */
//     {width: 360, height: 760, dpr: 4}, /* Samsung Galaxy S10 */
//     {width: 390, height: 844, dpr: 3}, /* Iphone 12 Pro */
//     {width: 393, height: 851, dpr: 2.8}, /* Pixel 5 */
//     {width: 412, height: 892, dpr: 3.5}, /* OnePlus 7 Pro */
//     {width: 360, height: 740, dpr: 4}, /* Samsung Galaxy S8+ */
//
//     {width: 820, height: 1180, dpr: 2}, /* Ipad Air */
//     {width: 768, height: 1024, dpr: 2}, /* Ipad Mini */
//     {width: 912, height: 1368, dpr: 2}, /* Surface Pro 7 */
//     {width: 540, height: 720, dpr: 1}, /* Surface Duo */
//     {width: 280, height: 652, dpr: 1}, /* Galaxy Fold */
// ]

export function htmlImageSrcSetGenerateWidthsForCommonDevices () {
    return [...new Set([
        1902, 1080,
        1366, 768,
        360, 800,
        1536, 864,
        414, 896,
        360, 640,

        // Ipads
        2732, 2048,
        1024, 768,
        2048, 1536,

        375, 667,
        414, 896,
        390, 844,
        360, 740,
        412, 915
    ])].sort(compareNumbers);
}

export function htmlImageSrcSetGenerateForResponsiveImage (
    widths: number[],
    resolveImageUrl: (width: number, skipToken: SkipToken) => string | SkipToken
) {
    return iterableMapToArray(widths, (width, skip) => {
        const url = resolveImageUrl(width, SkipToken);

        if (url === SkipToken) {
            return skip;
        } else {
            return `${url} ${width}w`;
        }
    }).join(', ')
}

export type HtmlImageSrcByDensity = {
    x1: string;
    x2?: string;
    x3?: string;
}

export function htmlImageSrcSetGenerateForDisplayDensity (
    densityToSrc: HtmlImageSrcByDensity
) {
    const {
        x1,
        x2,
        x3
    } = densityToSrc;

    if (x2 === undefined && x3 === undefined) {
        return undefined;
    } else {
        return arrayRemoveUndefinedValues([
            x1,
            x2 !== undefined ? `${x2} 2x` : undefined,
            x3 !== undefined ? `${x3} 3x` : undefined
        ]).join(', ')
    }
}

const transparentPixelSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

export namespace HtmlImageResponsiveImageRenderingSpec {

    export type ScreenWidthRenderingSpec = { screenMaxWidth?: number; } & (
        | { relativeWidth: number; }
        | { width: number; }
        | { empty: true }
    );
}

export function htmlImageGenerateResponsiveImageAttributes (
    options: {
        renderingSizeSpec: HtmlImageResponsiveImageRenderingSpec.ScreenWidthRenderingSpec[];
        supportedScreenWidth: number[];
        resolveResponsiveImageUrl: (width: number, skipToken: SkipToken) => string | SkipToken;
        maxDpr?: number;
    }
) {

    const {
        renderingSizeSpec,
        supportedScreenWidth,
        resolveResponsiveImageUrl,
        maxDpr = 1
    } = options;

    const sortedRenderingSizeSpec = arrayOrderBy(renderingSizeSpec, spec => spec.screenMaxWidth ?? Infinity, compareNumbers);

    const getSpecSizeStr = (spec: HtmlImageResponsiveImageRenderingSpec.ScreenWidthRenderingSpec) => {

        if ('relativeWidth' in spec) {
            return `${Math.floor(spec.relativeWidth * 100)}vw`;
        } else if ('width' in spec) {
            return `${spec.width}px`
        } else {
            return `1px`
        }
    }

    const targetImageWidths = arrayDistinct(arrayFlatten(iterableMapToArray(supportedScreenWidth, (screenWidth, skip) => {
        const matchingSpec = assertDefined(arrayFindValue(
            sortedRenderingSizeSpec,
            spec => (spec.screenMaxWidth ?? Infinity) * maxDpr >= screenWidth)
        );

        if ('relativeWidth' in matchingSpec) {
            return [Math.floor(matchingSpec.relativeWidth * screenWidth)];
        } else if ('width' in matchingSpec) {
            return arrayGenerate(maxDpr, index => matchingSpec.width * (index + 1));
        } else {
            return skip;
        }
    }))).sort(compareNumbers)

    return {
        src: transparentPixelSrc,
        sizes: sortedRenderingSizeSpec.map(
            spec => `${spec.screenMaxWidth !== undefined ? `(max-width: ${spec.screenMaxWidth}px) ` : ``}${getSpecSizeStr(spec)}`
        ).join(', '),
        srcSet: htmlImageSrcSetGenerateForResponsiveImage([1, ...targetImageWidths], (width, skip) => {

            if (width === 1) {
                return transparentPixelSrc;
            }

            return resolveResponsiveImageUrl(width, skip);
        })
    }
}