import {
    stringIsNotNullableOrWhiteSpace, stringTrimWhiteSpaces,
    validateIsHexColor,
    validateIsUrl,
    validateIsUuid
} from '@wix/devzai-utils-common';
import {
    DoppeSubdomainMaxLength,
    DoppeSubdomainMinLength,
    DoppeUserDisplayNameMaxLength,
    DoppeUserDisplayNameMinLength
} from '../constants/doppe-constants';

export function doppeValidateUuid (value: string) {
    return validateIsUuid(value, 'all');
}

export function doppeValidatePaletteColor (value: string) {
    return validateIsHexColor(value);
}

export function doppeValidateLinkUrl (value: string) {
    return validateIsUrl(value, {
        protocols: ['http', 'https'],
        require_protocol: true
    })
}

export function doppeValidateReferencedExternalAssetUrl (value: string) {
    return validateIsUrl(value, {
        protocols: ['http', 'https'],
        require_protocol: true
    })
}

export function doppeValidateLinkPath (value: string) {
    return !!value && value !== '';
}

export function doppeValidateSpeedDialCode (value: string) {
    return stringIsNotNullableOrWhiteSpace(value);
}

export function doppeSanitizeSpeedDialCode (value: string) {
    return value.trim();
}

export function doppeSanitizeUserPageSlug (value: string) {
    return stringTrimWhiteSpaces(value);
}

export function doppeSanitizeUserPageDisplayName (value: string) {
    return stringTrimWhiteSpaces(value);
}

export function doppeIsLookalikeSubdomain (value: string) {
    return ['linktree', 'linkstree', 'linktr', 'lnktree'].includes(value);
}

export function doppeValidateUserSubdomain (value: string) {
    const regexp = new RegExp(`^[a-z0-9][a-z0-9-]{${DoppeSubdomainMinLength - 2},${DoppeSubdomainMaxLength - 2}}[a-z0-9]$`);

    return regexp.test(value)
}

export function doppeSanitizeUserSubdomain (value: string) {
    return value.trim().toLowerCase()
}

export function doppeValidateLinkDisplayName (_value: string) {
    return true;
}

export function doppeValidateUserDisplayName (value: string) {
    return value.length >= DoppeUserDisplayNameMinLength && value.length <= DoppeUserDisplayNameMaxLength;
}

export function doppeSanitizeUserDisplayName (value: string) {
    return value.trim();
}

export function doppeValidateBymoPageName (_value: string) {
    return true;
}

export function doppeValidateListDisplayName (_value: string) {
    return true;
}


