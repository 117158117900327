import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeOutfit} from "./doppe-action-type-outfit";

export const doppeActionTypeOutfitViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeOutfit, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeOutfitWidget: (await import(
                './doppe-action-type-outfit-widget/doppe-action-type-outfit-widget'
            )).DoppeActionTypeOutfitWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeOutfitWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeOutfitWidget
                {...renderProps}
            />
        );

    }
})