import {enumGetValues, evaluateFunction, Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity, DoppeBusinessEntityReference} from './doppe-business-entity';

/**
 * An enum of all the external providers that can provide a product data.
 * For example: Wix, Amazon, Shopify.
 */
export const DoppeExternalVideoProvider = {
    Wix: 'wix',
} as const;

export type DoppeExternalVideoProvider = Values<typeof DoppeExternalVideoProvider>;

// TODO: This field should include all the possible SEMANTIC fields that an external product providers can provide
// TODO: for a product.
export const DoppeExternalVideoField = {
    VideoUrl: 'VideoUrl',
} as const;

export type DoppeExternalVideoField = Values<typeof DoppeExternalVideoField>;

const AllExternalVideoProviders = enumGetValues(DoppeExternalVideoProvider);

const externalVideoFieldsToSupportedProviders = {
    [DoppeExternalVideoField.VideoUrl]: AllExternalVideoProviders,
} satisfies {
    [field in DoppeExternalVideoField]: DoppeExternalVideoProvider[]
}

export function doppeExternalVideoProviderIsFieldProvided(
    externalVideoProvider: DoppeExternalVideoProvider | null | undefined,
    externalVideoField: DoppeExternalVideoField
) {
    if (externalVideoProvider === null || externalVideoProvider === undefined) {
        return false;
    }

    return externalVideoFieldsToSupportedProviders[externalVideoField].includes(externalVideoProvider);
}

export function doppeExternalVideoProviderResolveBusinessEntityReference(
    externalItemProvider: DoppeExternalVideoProvider,
    externalItemId: string
) {
    return evaluateFunction(() => {
        switch (externalItemProvider) {
            case DoppeExternalVideoProvider.Wix: {
                return {
                    businessEntity: DoppeBusinessEntity.WixVideo,
                    id: externalItemId
                } as DoppeBusinessEntityReference<typeof DoppeBusinessEntity.WixVideo>
            }
        }
    }) satisfies DoppeBusinessEntityReference
}