import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeDevModals} from "./doppe-action-type-dev-modals";

export const doppeActionTypeDevModalsViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeDevModals, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeDevModalsWidget: (await import(
                './doppe-action-type-dev-modals-widget/doppe-action-type-dev-modals-widget'
            )).DoppeActionTypeDevModalsWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {
        const {
            DoppeActionTypeDevModalsWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeDevModalsWidget
                {...renderProps}
            />
        );
    }
})