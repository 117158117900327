import {
    DoppeViewerAppIconContactAppleMedium,
    DoppeViewerAppIconContactDiscordMedium,
    DoppeViewerAppIconContactEmailMedium,
    DoppeViewerAppIconContactFacebookMedium,
    DoppeViewerAppIconContactFacebookMessangerMedium,
    DoppeViewerAppIconContactInstagramMedium,
    DoppeViewerAppIconContactLinkedinMedium,
    DoppeViewerAppIconContactMapMedium,
    DoppeViewerAppIconContactPhoneMedium,
    DoppeViewerAppIconContactPinterestMedium,
    DoppeViewerAppIconContactRedditMedium,
    DoppeViewerAppIconContactSnapchatMedium,
    DoppeViewerAppIconContactSpotifyMedium,
    DoppeViewerAppIconContactTelegramMedium,
    DoppeViewerAppIconContactThreadsMedium,
    DoppeViewerAppIconContactTiktokMedium,
    DoppeViewerAppIconContactWebMedium,
    DoppeViewerAppIconContactWhatsappMedium,
    DoppeViewerAppIconContactXMedium,
    DoppeViewerAppIconContactYoutubeMedium
} from '../doppe-viewer-icons/doppe-viewer-icons';
import type {Icon} from '@wix/devzai-utils-react';
import {evaluateFunction} from '@wix/devzai-utils-common';
import {DoppeDtoContactMethods} from '../../client-server-common/types/doppe-dto-contact-method';

export function doppeViewerContactMethodResolveIcon (contactMethod: DoppeDtoContactMethods) : Icon.Src {

    const icon = evaluateFunction(() => {
        switch (contactMethod) {
            case DoppeDtoContactMethods.Facebook: return DoppeViewerAppIconContactFacebookMedium;
            case DoppeDtoContactMethods.Instagram: return DoppeViewerAppIconContactInstagramMedium;
            case DoppeDtoContactMethods.Apple: return DoppeViewerAppIconContactAppleMedium;
            case DoppeDtoContactMethods.Discord: return DoppeViewerAppIconContactDiscordMedium;
            case DoppeDtoContactMethods.LinkedIn: return DoppeViewerAppIconContactLinkedinMedium;
            case DoppeDtoContactMethods.Pinterest: return DoppeViewerAppIconContactPinterestMedium;
            case DoppeDtoContactMethods.Reddit: return DoppeViewerAppIconContactRedditMedium;
            case DoppeDtoContactMethods.Snapchat: return DoppeViewerAppIconContactSnapchatMedium;
            case DoppeDtoContactMethods.Spotify: return DoppeViewerAppIconContactSpotifyMedium;
            case DoppeDtoContactMethods.Telegram: return DoppeViewerAppIconContactTelegramMedium;
            case DoppeDtoContactMethods.TikTok: return DoppeViewerAppIconContactTiktokMedium;
            case DoppeDtoContactMethods.X: return DoppeViewerAppIconContactXMedium;
            case DoppeDtoContactMethods.Threads: return DoppeViewerAppIconContactThreadsMedium;
            case DoppeDtoContactMethods.YouTube: return DoppeViewerAppIconContactYoutubeMedium;
            case DoppeDtoContactMethods.Email: return DoppeViewerAppIconContactEmailMedium;
            case DoppeDtoContactMethods.Website: return DoppeViewerAppIconContactWebMedium;
            case DoppeDtoContactMethods.Phone: return DoppeViewerAppIconContactPhoneMedium;
            case DoppeDtoContactMethods.WhatsApp: return DoppeViewerAppIconContactWhatsappMedium;
            case DoppeDtoContactMethods.FacebookMessenger: return DoppeViewerAppIconContactFacebookMessangerMedium;
            case DoppeDtoContactMethods.Location: return DoppeViewerAppIconContactMapMedium;

        }
    });

    return {
        imageUrl: icon,
        widthHeightRatio: 1
    }
}

export function doppeViewerContactMethodIsPhoneNumber (contactMethod: DoppeDtoContactMethods) : boolean {

    if (contactMethod === DoppeDtoContactMethods.Phone) {
        return true;
    }

    return false;
}

export function doppeViewerContactMethodIsEmail (contactMethod: DoppeDtoContactMethods) : boolean {

    if (contactMethod === DoppeDtoContactMethods.Email) {
        return true;
    }

    return false;
}


export function doppeViewerContactMethodIsLocation (contactMethod: DoppeDtoContactMethods) : boolean {

    if (contactMethod === DoppeDtoContactMethods.Location) {
        return true;
    }

    return false;
}

