import {Values} from "../common-types";

export const TypefaceClassification = {
    serif: 'serif',
    sansSerif: 'sans-serif',
    cursive: 'cursive',
    monospace: 'monospace',
    fantasy: 'fantasy'
} as const;

export type TypefaceClassification = Values<typeof TypefaceClassification>;

export const FontWeight = {
    Thin: '100',
    ExtraLight: '200',
    Light: '300',
    Normal: '400',
    Medium: '500',
    SemiBold: '600',
    Bold: '700',
    ExtraBold: '800',
    Black: '900',
} as const;

export type FontWeight = Values<typeof FontWeight>;

export const FontDisplay = {
    Swap: 'swap',
    Block: 'block',
    Auto: 'auto'
} as const;

export type FontDisplay = Values<typeof FontDisplay>;

