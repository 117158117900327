import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeHideableValue} from '../../client-server-common';
import {Values} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';


export const AppleMusicWidgetSize = {
    Normal: 'Normal',
    Compact: 'Compact',
} as const;

export namespace DoppeActionTypeAppleMusic {

    export type Size = Values<typeof AppleMusicWidgetSize>;

    export interface ActionSettings {
        url: string;
        size: Size;
        buttonText: DoppeHideableValue<string>;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
    }
}

export const doppeActionTypeAppleMusic = doppeActionTypeDefineMetadata<DoppeActionTypeAppleMusic.ActionSettings>({
    id: 'io.bymo.action/apple-music',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        url: '',
        buttonText: 'Listen on Apple Music',
        title: 'Title',
        description: 'Description',
        size: AppleMusicWidgetSize.Compact
    }),
    hasNoGoalMeasuring: false,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
})

