import {cachedValueCreate} from '@wix/devzai-utils-common';

export async function domLoadScript (
    src: string,
    options: {
        skipIfAlreadyLoaded?: boolean;
        rootWindow?: Window | null;
    } = {}
) {
    const {
        skipIfAlreadyLoaded = false,
        rootWindow = null
    } = options;

    const document = (rootWindow ?? window).document;

    if (skipIfAlreadyLoaded && document.querySelector(`script[src="${src}"]`)) {
        // TODO: There is a bug here - if a script element was added but hasn't finished loading, then we should
        // TODO: wait for its loading instead of just returning Promise.resolve();
        // TODO: In case that this is the case we should register to this script load event.
        return Promise.resolve();
    }

    const head = document.head || document.getElementsByTagName('head')[0];
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = src;

    return new Promise<void>(function (resolve, reject) {
        script.onload = function () {
            script.onerror = script.onload = null;
            resolve();
        };

        script.onerror = function () {
            script.onerror = script.onload = null;
            reject(new Error('Failed to load ' + src));
        };

        head.appendChild(script);
    });
}

const cachedScrollbarSize = cachedValueCreate(() => {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    (outer.style as any).msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    document.body.removeChild(outer);

    return scrollbarWidth;
});

export function domGetScrollbarSize () {
    return cachedScrollbarSize.getValue();
}

export async function domLoadCSS (
    url: string,
    options: {
        forceLoad?: boolean;
        rootWindow?: Window | null;
    } = {}
): Promise<void> {

    const {
        forceLoad = false,
        rootWindow = null,
    } = options;

    const document = (rootWindow ?? window).document;

    if (!forceLoad && document.querySelector(`link[href="${url}"]`)) {
        return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        link.onload = () => {
            resolve();
        };
        link.onerror = reject;
        document.head.appendChild(link);
    });
}