import {DoppeDtoBymoLinkViewerData} from "../../client-server-common/types/doppe-dto-bymo-link-viewer-data";
import React, {useContext} from "react";
import {assertNotNullable, Values} from "@wix/devzai-utils-common";
import {IBymoPagePreviewHostServiceApiClient} from "../../client/bymo-page-preview-service/bymo-page-preview-host-service-api";

export const BymoPageViewerMode = {
    LivePreview: 'LivePreview',
    /**
     * Used to render the viewer in the preview environment without editing/interaction capabilities.
     */
    Preview: 'Preview',
    Live: 'Live',
    /**
     * Used when the viewer is rendered normally but on the app's domain for a real preview purpose.
     */
    LiveInApp: 'LiveInApp',
} as const;

export type BymoPageViewerMode = Values<typeof BymoPageViewerMode>;

export type BymoPageViewerLiveMode = typeof BymoPageViewerMode.Live | typeof BymoPageViewerMode.LiveInApp;

export type BymoPageViewerContextData =
    (
        {
            mode: BymoPageViewerLiveMode;
        } & DoppeDtoBymoLinkViewerData.LivePageData
        | {
            mode: typeof BymoPageViewerMode.Preview;
        }
        | {
            mode: typeof BymoPageViewerMode.LivePreview;
            bymoPagePreviewHostServiceApiClient: IBymoPagePreviewHostServiceApiClient;
        }
    )

export const BymoPageViewerContext = React.createContext<BymoPageViewerContextData | null>(null);

export function useBymoPageViewerContext () {
    return assertNotNullable(useContext(BymoPageViewerContext));
}

export function bymoPageViewerModeIsPreview (viewerMode: BymoPageViewerMode) {
    return viewerMode === BymoPageViewerMode.Preview || viewerMode === BymoPageViewerMode.LivePreview;
}