import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeHideableValue, doppeHideableValueCreateHidden} from '../../client-server-common';

export namespace DoppeActionTypeCode {

    export interface ActionSettings {
        code: string;
        buttonText: DoppeHideableValue<string>;
    }
}

export const doppeLockActionTypeCode = doppeActionTypeDefineMetadata<DoppeActionTypeCode.ActionSettings>({
    id: 'io.bymo.action/code',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        buttonText: doppeHideableValueCreateHidden(''),
        media: null,
        code: '',
    }),
    isWidgetOpener: true,
    isLinkOpener: false,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    prepareForViewer: actionSettings => {
        const codeLength = Math.max(4, Math.min(actionSettings.code.length, 8))
        actionSettings.code = "x".repeat(codeLength);
    },
});
