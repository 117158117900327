import {assertDefined} from "../assertions";

export class Timer {

    private interval?: any = undefined;
    private ms;
    private callback;
    private lastTime?: number;

    private intervalCallback = () => {
        const prevTime = assertDefined(this.lastTime);
        const currentTime = this.lastTime = Date.now();
        this.callback(currentTime - prevTime);
    }


    constructor (ms: number, callback: (passedMilliseconds: number) => void) {
        this.callback = callback;
        this.ms = ms;
    }

    activate () {
        if (this.interval === undefined) {

            this.lastTime = Date.now();

            this.interval = setInterval(this.intervalCallback, this.ms);
        }
    }

    deactivate () {
        if (this.interval !== undefined) {
            clearInterval(this.interval);
            this.interval = undefined;
        }
    }

}