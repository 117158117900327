import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeDevMediaView} from "./doppe-action-type-dev-media-view";

export const doppeActionTypeDevMediaViewViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeDevMediaView, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeDevMediaViewWidget: (await import(
                './doppe-action-type-dev-media-view-widget/doppe-action-type-dev-media-view-widget'
            )).DoppeActionTypeDevMediaViewWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {
        const {
            DoppeActionTypeDevMediaViewWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeDevMediaViewWidget
                {...renderProps}
            />
        );
    }
})