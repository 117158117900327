import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata,
    DoppeActionTypeRenderingInPageSupport
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppePremiumFeatureIsDisabled,
    DoppePremiumFeaturesAlias
} from '../../client-server-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {asArray, immerProduce} from '@wix/devzai-utils-common';


export namespace DoppeActionTypeBanner {

    export interface ActionSettings {
        url: DoppeHideableValue<string>;
        image: WixMediaResource | null;
    }
}

export const doppeActionTypeBanner = doppeActionTypeDefineMetadata<DoppeActionTypeBanner.ActionSettings>({
    id: 'io.bymo.action/banner',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        url: doppeHideableValueCreateHidden('Banner Link'),
        image: null,
    }),
    hasNoGoalMeasuring: false,
    isLinkOpener: true,
    isWidgetOpener: false,
    hasNoLockInnerCTA: true,
    canRenderInPage: DoppeActionTypeRenderingInPageSupport.AsCardOnly,
    skipReportingOnGoalAchievedOnActionButtonClick: false,
    isPremiumAction: (premiumFeaturesStatus) => {
        return doppePremiumFeatureIsDisabled(premiumFeaturesStatus, DoppePremiumFeaturesAlias.bannerAction)
    },
    resolveUsedMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image) : [];
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.image) {
                actionSettings.image = wixMediaResourceReplaceExternalImage(actionSettings.image, externalImagesUrlToImageResourceMap);
            }
        })
    },
})