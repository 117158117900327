import React from 'react';
import ReactDOM from 'react-dom';

export class DomElementResolver extends React.Component<{
    children: React.ReactElement;
    onMount?: (element: HTMLElement) => void;
}> {
    public render() {
        return this.props.children;
    }

    public getDomElement() {
        // eslint-disable-next-line react/no-find-dom-node
        return ReactDOM.findDOMNode(this) as Element;
    }

    public componentDidMount() {
        this.props.onMount?.(this.getDomElement() as HTMLElement)
    }
}
