import React, {useMemo} from 'react';
import {classes, style, vars} from './wix-video-resource-view.st.css';
import {
    cssAddUnitsToNumericValue,
    HtmlObjectHorizontalPosition,
    HtmlObjectResizeMode,
    HtmlObjectVerticalPosition,
    OmitStrict
} from '@wix/devzai-utils-common';
import {
    WixVideoInfoCustomQualitySelector,
    wixVideoInfoGetVideoUrl,
    WixVideoInfoQualitySelector,
    WixVideoResource
} from "@wix/devzai-common-wix";

export const WixVideoResourceView = React.memo(function WixVideoResourceView(props: WixVideoResourceView.Props) {

    const {
        resizeMode = HtmlObjectResizeMode.Contain,
        positionVertical = HtmlObjectVerticalPosition.Top,
        positionHorizontal = HtmlObjectHorizontalPosition.Center,
        videoElementRef,
        src,
        quality,
        showControls = false,

        style: cssProps,
        className,
        ...htmlAttributes

    } = props;

    const videoSrc = useMemo(() => {
        if (src) {
            return wixVideoInfoGetVideoUrl(src, {
                qualitySelector: quality
            })
        } else {
            return undefined;
        }

    }, [src, quality])

    return (
        <video
            ref={videoElementRef}
            {...htmlAttributes}
            controls={showControls}
            className={style(classes.root, className)}
            style={{
                [vars.objectFit]: resizeMode,
                [vars.objectPositionVertical]: typeof positionVertical === 'number' ?
                    cssAddUnitsToNumericValue(positionVertical, '%') : positionVertical,
                [vars.objectPositionHorizontal]: typeof positionHorizontal === 'number' ?
                    cssAddUnitsToNumericValue(positionHorizontal, '%') : positionHorizontal,
                ...cssProps
            }}
            src={videoSrc}
        />
    )
});


export namespace WixVideoResourceView {

    export interface Props extends OmitStrict<React.VideoHTMLAttributes<HTMLVideoElement>, 'src'>{
        resizeMode?: HtmlObjectResizeMode;
        positionVertical?: HtmlObjectVerticalPosition | number;
        positionHorizontal?: HtmlObjectHorizontalPosition | number;
        videoElementRef?: React.Ref<HTMLVideoElement>;

        src?: WixVideoResource;
        quality?: WixVideoInfoQualitySelector | WixVideoInfoCustomQualitySelector;
        showControls?: boolean;
    }
}