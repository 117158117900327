import {Ref, useEffect, useState} from 'react';
import {EventEmitter, EventReference} from '@wix/devzai-utils-common';
import {useInstanceValue} from '../index';

const { hasOwnProperty } = Object.prototype;

export type RefChangeCallback<T> = (instance: T | null) => void;

export interface SingleDynamicRefProps<T> {
    changeCallback?: RefChangeCallback<T>;
}

export type RefId = string | number | symbol;

export interface DynamicRef_EventTypes<T> {
    eventChanged: T | null;
}

export interface IObservableRef<T = any> {
    readonly eventChanged: EventReference<DynamicRef_EventTypes<T>, 'eventChanged'>;
    readonly current: T | null;
}

export class DynamicRef<T = any> implements IObservableRef<T> {
    private associatedRefs = new Map<RefId, DynamicRef<T> | Ref<T>>();
    private changeCallback?: RefChangeCallback<T>;
    private eventEmitter = new EventEmitter<DynamicRef_EventTypes<T>>();

    public get eventChanged(): EventReference<DynamicRef_EventTypes<T>, 'eventChanged'> {
        return this.eventEmitter.createEventReference('eventChanged');
    }

    private constructor(props: SingleDynamicRefProps<T> = {}) {
        this.changeCallback = props.changeCallback;

        // NOTE: We're defining this property on the instance, so react will be able to set the current
        // value of the ref when the dynamic ref is passed without `assign`.
        // React expects the ref instance to have 'current' as own property,
        // i.e. calling ref.hasOwnProperty('current') should return true.
        Object.defineProperty(this, 'current', {
            set: instance => {
                this._assign(instance);
            },
            get: () => this._current
        });
    }

    /**
     * Ref will become initialized after the first assignment.
     */
    protected _isInitialized = false;
    protected _current: T | null = null;

    private _assign = (instance: T | null) => {
        const prevInstance = this._current;
        if (prevInstance !== null && instance !== null && prevInstance !== instance) {
            throw new Error(`Trying to assign two instances to the same ref`);
        }

        if (prevInstance !== instance) {
            this._current = instance;

            this.associatedRefs.forEach(associatedRef => {
                assignInstanceToRef(associatedRef, instance);
            });

            const changeCallback = this.changeCallback;
            if (changeCallback !== undefined) {
                changeCallback(instance);
            }

            this.eventEmitter.emit('eventChanged' as any, instance);

            this._isInitialized = true;
        }
    };

    public get current() {
        return this._current;
    }
    public set current(instance) {
        this._assign(instance);
    }

    public onRefWasChanged(callback: RefChangeCallback<T>) {
        this.eventEmitter.on('eventChanged', callback);

        return this;
    }

    public associateRefs(refs: { [refId: string]: DynamicRef<T> | Ref<T> | undefined }): this {
        for (const refId in refs) {
            if (hasOwnProperty.call(refs, refId)) {
                this.associateRef(refId, refs[refId]);
            }
        }

        return this;
    }

    public associateRef(refId: RefId, ref: DynamicRef<T> | Ref<T> | undefined): this {
        const associatedRefsMap = this.associatedRefs;
        const associatedRef = associatedRefsMap.get(refId);

        if (associatedRef !== ref) {
            if (associatedRef) {
                assignInstanceToRef(associatedRef, null);
            }

            if (ref) {
                associatedRefsMap.set(refId, ref);

                const currentInstance = this.current;
                if (currentInstance !== null) {
                    assignInstanceToRef(ref, currentInstance);
                }
            }

            if (!ref) {
                associatedRefsMap.delete(refId);
            }
        }

        return this;
    }

    public static create<T>(props?: SingleDynamicRefProps<T>): DynamicRef<T> {
        return new DynamicRef<T>(props);
    }

    public static combineRefs<T>(refs: { [refId: string]: DynamicRef<T> | Ref<T> | undefined }): DynamicRef<T> {
        const combiningRef = new DynamicRef<T>();

        return combiningRef.associateRefs(refs);
    }
}

export function isDynamicRef<T = any>(ref: unknown): ref is DynamicRef<T> {
    return ref instanceof DynamicRef;
}

export function dynamicRefCreate<T>(props?: SingleDynamicRefProps<T>): DynamicRef<T> {
    return DynamicRef.create(props);
}

function assignInstanceToRef<T>(ref: DynamicRef<T> | Ref<T>, instance: T | null) {
    if (typeof ref === 'function') {
        ref(instance);
    } else {
        (ref as any).current = instance;
    }
}

export function useDynamicRef<T>() {
    return useInstanceValue(() => DynamicRef.create<T>());
}

export function useDynamicRefCurrentInstance<T> (dynamicRef: DynamicRef<T>) {

    const [currentInstance, setCurrentInstance] = useState(dynamicRef.current);

    useEffect(() => {
        setCurrentInstance(dynamicRef.current);

        const eventChangedBinding = EventEmitter.on(dynamicRef.eventChanged, () => {
            setCurrentInstance(dynamicRef.current);
        });

        return () => {
            eventChangedBinding.dispose();
        }
    }, [dynamicRef]);

    return currentInstance;
}