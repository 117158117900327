import {bymoTemplateMetadataFactoryCreate} from "../../../doppe-sdk";


export namespace BymoTemplateActionInLandingPagePreview {

    export interface TemplateProps {

    }
}

export const BymoTemplateActionInLandingPagePreviewMetadata = bymoTemplateMetadataFactoryCreate<BymoTemplateActionInLandingPagePreview.TemplateProps>()({
    initialValues: {},
    features: [
        'hasActions', 'hasActionButton' , 'hasProfileContent' ,'hasCustomTextualContent'
    ]
})