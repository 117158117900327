import React, {useEffect, useRef} from 'react';
import {style, classes} from './doppe-page-video-media-view.st.css';
import {WixVideoResourceView} from "@wix/devzai-common-client";
import {useDynamicRef, useDynamicRefCurrentInstance, useRefsUnion} from "@wix/devzai-utils-react";
import {domEventOnceOneOfEventsEmitted} from "@wix/devzai-utils-dom";
import {IDisposable} from "@wix/devzai-utils-common";

export const DoppePageVideoMediaView = React.memo(function DoppePageVideoMediaView (props: DoppePageVideoMediaView.Props) {
    const {
        autoPlay,
        videoElementRef,

        className,
        ...restProps
    } = props;

    const videoElementInternalRef = useDynamicRef<HTMLVideoElement>();
    const videoElementRefsUnion = useRefsUnion([videoElementInternalRef, videoElementRef]);
    const videoElement = useDynamicRefCurrentInstance(videoElementInternalRef);

    const userGestureEventBindingRef = useRef<IDisposable>();

    const tryPlayingVideoAfterUserGestureEvent = (videoElement: HTMLVideoElement) => {
        userGestureEventBindingRef.current = domEventOnceOneOfEventsEmitted(
            document,
            ['mousedown', 'keydown', 'pointerdown', 'pointerup', 'touchend', 'touchdown'],
            (event) => {
                videoElement.play().catch(() => {
                    console.warn(`Failed playing video element after user gesture event ${event.type}`);

                    tryPlayingVideoAfterUserGestureEvent(videoElement);
                })
            }
        )
    }

    useEffect(() => {
        if (autoPlay && videoElement) {

            videoElement.play().catch((error) => {
                console.warn(`Failed auto playing video element`, error);
                tryPlayingVideoAfterUserGestureEvent(videoElement);
            });

            return () => {
                userGestureEventBindingRef.current?.dispose();
            }
        }

        return undefined;
    }, [autoPlay, videoElement]);

    return (
        <WixVideoResourceView
            {...restProps}
            autoPlay={autoPlay}
            videoElementRef={videoElementRefsUnion}
            className={style(classes.root, className)}
        />
    );
});

export namespace DoppePageVideoMediaView {

    export interface Props extends WixVideoResourceView.Props {

    }
}