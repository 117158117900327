import {vars, classes, style} from './icon.st.css';
import React, {HTMLAttributes} from 'react';
import {Values} from "@wix/devzai-utils-common";

const IconTypes = {
    Image: 'image',
    ComponentIcon: 'componentIcon',
    ImageIcon: 'imageIcon',
    FontIcon: 'fontIcon'
} as const;

export type IconTypes = Values<typeof IconTypes>;

export const Icon = React.memo<Icon.Props>(props => {

    const {
        icon,
        overrideFill = true,
        style: styleProp,

        className,
        ...htmlAttributes
    } = props;

    if (typeof icon === 'string') {
        return (
            <img
                className={style(classes.root, {type: IconTypes.Image}, className)}
                style={styleProp}
                src={icon}
                {...htmlAttributes}
            />
        )
    } else if (isFontIcon(icon)) {
        return (
            <i
                className={style(classes.root, {type: IconTypes.FontIcon}, icon.className, className)}
                style={styleProp}
                {...htmlAttributes}
            />
        )
    } else if (isImageIcon(icon)) {

        if (overrideFill) {
            return (
                <i
                    className={style(classes.root, {type: IconTypes.ImageIcon, overrideFill: true}, className)}
                    style={{
                        [vars.imageIconUrl]: `url(${icon.imageUrl})`,
                        [vars.imageIconWidth]: `${icon.widthHeightRatio}em`,
                        ...styleProp
                    }}
                    {...htmlAttributes}
                />
            )
        } else {
            return (
                <img
                    className={style(classes.root, {type: IconTypes.ImageIcon}, className)}
                    src={icon.imageUrl}
                    style={{
                        [vars.imageIconWidth]: `${icon.widthHeightRatio}em`,
                        ...styleProp
                    }}
                    {...htmlAttributes}
                />
            )
        }


    } else {
        const IconComponent = icon;

        return (
            <IconComponent
                className={style(classes.root, {type: IconTypes.ComponentIcon, overrideFill: overrideFill}, className)}
                style={styleProp}
                {...htmlAttributes}
            />
        )
    }
});

export namespace Icon {

    export type DynamicSizeIcon<SIZE> = {
        resolveIcon: (size: SIZE) => Src;
    }

    export interface ImageIcon {
        imageUrl: string;
        widthHeightRatio: number;
    }

    export interface FontIcon {
        className: string;
    }

    export type Src = string | FontIcon | ImageIcon | React.ComponentType<HTMLAttributes<any>>;

    export interface Props extends HTMLAttributes<any> {
        icon: Src;
        overrideFill?: boolean;
    }
}

function isImageIcon (icon: Icon.Src) : icon is Icon.ImageIcon {
    return !!icon && (icon as Icon.ImageIcon).imageUrl !== undefined;
}

function isFontIcon (icon: Icon.Src) : icon is Icon.FontIcon {
    return !!icon && (icon as Icon.FontIcon).className !== undefined;
}

export function iconIsDynamicSizeIcon<SIZE> (value: unknown) : value is Icon.DynamicSizeIcon<SIZE> {
    return 'resolveIcon' in (value as Icon.DynamicSizeIcon<SIZE>);
}

export function iconResolveDynamicSizeIconSrc<SIZE> (icon: Icon.Src | Icon.DynamicSizeIcon<SIZE>, size: SIZE) : Icon.Src {
    return iconIsDynamicSizeIcon<SIZE>(icon) ? icon.resolveIcon(size) : icon;
}