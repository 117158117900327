import {stringCollapseWhitespaces} from "../string-utils";

type ClassNamesArg = string | number | undefined | null | ClassNamesArg[];

export function classNames(...classNamesArr: ClassNamesArg[]) : string {
    const classesStringBuilder = [];

    for (const arg of classNamesArr) {
        if (arg) {
            const argType = typeof arg;

            if (argType === 'string' || argType === 'number') {
                classesStringBuilder.push(arg);
            } else if (Array.isArray(arg)) {
                if (arg.length) {
                    const inner = classNames(...arg);
                    if (inner) {
                        classesStringBuilder.push(inner);
                    }
                }
            }
        }
    }

    return classesStringBuilder.join(' ');
}

export function classNamesSplit (classNamesStr: string) : string[] {

    const trimmedStr = stringCollapseWhitespaces(classNamesStr.trim());
    if (trimmedStr === '') {
        return [];
    }

    return trimmedStr.split(' ');
}