import React, {useCallback, useRef} from 'react';
import {Evaluable, evaluateFunction, evaluateWhenFunction, tsTypeAssert} from "@wix/devzai-utils-common";
import {reactCreateMemo} from "../react-utils/react-utils";
import {DomElementAnimator} from "../dom-element-animator/dom-element-animator";

export const CollapsibleCard = reactCreateMemo(function CollapsibleCard (props: CollapsibleCard.Props) {
    const {
        transitionDuration = 200,
        children,
        header,
        subContent,
        isOpen,
        onBeforeTogglingAnimationStart,
        onTogglingAnimationEnd,

        className,
        ...htmlAttributes
    } = props;

    const lastHeightRef = useRef<number>();
    const animationSpecResolver = useCallback((domElement: HTMLElement) => {
        const lastHeight = lastHeightRef.current;
        lastHeightRef.current = domElement.offsetHeight;

        if (!lastHeight) {
            return undefined;
        }

        return tsTypeAssert<DomElementAnimator.AnimationSpec>({
            animation: [
                {height: `${lastHeight}px`, easing: 'ease-out'},
                {height: `${domElement.offsetHeight}px`, easing: 'ease-out'}
            ],
            durationMilliseconds: transitionDuration,
            onBeforeAnimationStart: onBeforeTogglingAnimationStart,
            onAnimationEnd: onTogglingAnimationEnd
        })
    }, [transitionDuration, onBeforeTogglingAnimationStart, onTogglingAnimationEnd]);

    return (
        <div
            {...htmlAttributes}
        >
            {evaluateFunction(() => {
                if (isOpen) {
                    return (
                        <>
                            <DomElementAnimator
                                key={'cardContent'}
                                autoAnimateOnMount={animationSpecResolver}
                            >
                                {evaluateWhenFunction(children)}
                            </DomElementAnimator>
                            {evaluateWhenFunction(subContent)}
                        </>
                    )
                } else {

                    return (
                        <DomElementAnimator
                            key={'cardHeader'}
                            autoAnimateOnMount={animationSpecResolver}
                        >
                            {evaluateWhenFunction(header)}
                        </DomElementAnimator>
                    )
                }
            })}
        </div>
    );
});

export namespace CollapsibleCard {

    export interface Props extends React.HTMLAttributes<any> {
        transitionDuration?: number;
        isOpen: boolean;
        header: Evaluable<() => React.ReactElement>;
        children: Evaluable<() => React.ReactElement>;
        subContent?: Evaluable<() => React.ReactElement | null>;
        onBeforeTogglingAnimationStart?: (element: HTMLElement) => void;
        onTogglingAnimationEnd?: (element: HTMLElement) => void;
    }
}
