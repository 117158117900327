import {colorHexToRgb, colorResolveLegibleForegroundColor, Values} from "@wix/devzai-utils-common";
import {DoppeColorPalette, doppeColorPaletteResolveColor, DoppePaletteColorType} from "./doppe-color-palette";

export const DoppePaletteBoundColorTransformationType = {
    Legible: 'Legible'
} as const;

export type DoppePaletteBoundColorTransformationType = Values<typeof DoppePaletteBoundColorTransformationType>;

export type DoppePaletteBoundColorTransformation =
    | {
        type: typeof DoppePaletteBoundColorTransformationType.Legible
    };

export interface DoppePaletteBoundColor {
    colorIndex: DoppePaletteColorType;
    colorVariationIndex: number;
    transformation?: DoppePaletteBoundColorTransformation;
}

export type DoppeTemplateColor = string | DoppePaletteBoundColor;

export function doppeTemplateColorCreatePaletteBoundColor (paletteColorIndex: DoppePaletteColorType) {
    return {
        colorIndex: paletteColorIndex,
        colorVariationIndex: 0
    }
}

export function doppeTemplateColorCreateLegibleColor (paletteColorIndex: DoppePaletteColorType) {
    return {
        colorIndex: paletteColorIndex,
        colorVariationIndex: 0,
        transformation: {
            type: DoppePaletteBoundColorTransformationType.Legible
        }
    }
}


export function doppeTemplateColorResolve (doppeTemplateColor: DoppeTemplateColor, colorPalette: DoppeColorPalette) {
    if (typeof doppeTemplateColor === 'string') {
        return doppeTemplateColor;
    } else {

        const paletteColor = doppeColorPaletteResolveColor(
            colorPalette,
            doppeTemplateColor.colorIndex
        )

        const transformation = doppeTemplateColor.transformation;
        if (transformation === undefined) {
            return paletteColor;
        } else {
            switch (transformation.type) {
                case DoppePaletteBoundColorTransformationType.Legible: {
                    const rgbColor = colorHexToRgb(paletteColor);
                    if (rgbColor) {
                        return colorResolveLegibleForegroundColor(rgbColor);
                    } else {
                        console.warn(`Failed converting a color to RGB '${paletteColor}'`);
                        return paletteColor;
                    }
                }
                default: {
                    console.warn(`Unknown palette color transformation '${transformation.type}'`);
                    return paletteColor;
                }
            }
        }
    }
}