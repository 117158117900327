import {
    Dictionary,
    Evaluable,
    evaluateFunction,
    evaluateWhenFunction, identity, objectRemoveEntriesWithUndefinedValues,
    Promisable,
    ReplaceMethodsReturnType,
    RestfulApiHttpMethod,
    urlJoin
} from '../';
import type {RestfulApiManager} from '../restful-api-manager/restful-api-manager'

export type RestfulApiHttpClient<API> = API & {
    withHeaders (headers: Dictionary<string>) : API;
    getApiEndpointUrl () : ReplaceMethodsReturnType<API, Promise<string>>;
}

export type RestfulApiHttpClientErrorProcessor = (axiosResponse: any) => Error | undefined;

type ApiMethodCallInfo<API> = {
    [METHOD in keyof API & string]: {
        methodName: METHOD;
        params: API[METHOD] extends (...args: any) => any ? Parameters<API[METHOD]>[0] : never;
    };
}[keyof API & string]

type ApiMethodCallResponseInfo<API> = {
    [METHOD in keyof API & string]: {
        methodName: METHOD;
        params: API[METHOD] extends (...args: any) => any ? Parameters<API[METHOD]>[0] : never;
        result: API[METHOD] extends (...args: any) => any ? ReturnType<API[METHOD]> : never
    };
}[keyof API & string]

type ApiMethodCallErrorInfo<API> = ApiMethodCallInfo<API> & {
    error: Error;
}

export namespace RestfulApiHttpClient {

    export interface HttpClientRequestConfig {
        url: string;
        method:
            | 'get' | 'GET'
            | 'delete' | 'DELETE'
            | 'head' | 'HEAD'
            | 'options' | 'OPTIONS'
            | 'post' | 'POST'
            | 'put' | 'PUT'
            | 'patch' | 'PATCH'
            | 'purge' | 'PURGE'
            | 'link' | 'LINK'
            | 'unlink' | 'UNLINK';
        headers: any;
        withCredentials: boolean;
        data: any;
    }

    export interface HttpClient {
        request (requestConfig: HttpClientRequestConfig) : any;
    }

}

export interface RestfulApiHttpClientOptions<API> {

    httpClient: Evaluable<() => Promisable<RestfulApiHttpClient.HttpClient>>

    baseUrl?: string;

    /**
     * When true, the user cookies will be sent in the request.
     */
    withCredentials?: boolean;

    onResponse?: (axiosResponse: any) => void;
    onRequest?: (axiosRequestConfig: RestfulApiHttpClient.HttpClientRequestConfig) => void;

    onApiMethodCalled?: (methodCallInfo: ApiMethodCallInfo<API>) => Promisable<void>;
    onApiMethodCompleted?: (methodCallInfo: ApiMethodCallResponseInfo<API>) => void;
    onApiMethodFailed?: (methodCallInfo: ApiMethodCallErrorInfo<API>) => void;

    commonHeaders?: Evaluable<() => Promisable<Dictionary<string | undefined>>>;
    errorsProcessor?: RestfulApiHttpClientErrorProcessor;
    processEndpointUrl?: (url: string) => Promisable<string>;
}

export function restfulApiHttpClientCreate<API> (
    restfulApiManager: Evaluable<() => Promisable<RestfulApiManager<API>>>,
    options: RestfulApiHttpClientOptions<API>
) : RestfulApiHttpClient<API> {
    const {
        httpClient,
        baseUrl = '',
        withCredentials = false,
        commonHeaders,
        onResponse,
        onRequest,
        errorsProcessor,
        processEndpointUrl = identity,

        onApiMethodCalled,
        onApiMethodCompleted,
        onApiMethodFailed
    } = options;

    const createApiUrlsClient = () => {
        return new Proxy({}, {
            get (_obj: any, propName: string) {

                return async (params: any) => {

                    const restfulApiManagerInstance = await evaluateWhenFunction(restfulApiManager);

                    const apiMethodsInfo = restfulApiManagerInstance.getApiMethodsInfo();

                    const apiMethodInfo = apiMethodsInfo.get(propName as keyof API & string);

                    if (!apiMethodInfo) {
                        throw new Error(`Restful API method '${propName}' isn't registered.`);
                    }

                    const resolvedPath = restfulApiManagerInstance.paths[propName as keyof API](params);

                    return baseUrl ? urlJoin(baseUrl, resolvedPath) : resolvedPath;
                }
            }
        })
    }

    const createApiClient = (apiCallHeaders?: Dictionary<string>) => {
        return new Proxy({}, {
            get (_obj: any, propName: string) {

                if (propName === 'then') {
                    return undefined;
                } else if (propName === 'withHeaders') {
                    return function (headers: Dictionary<string>) : API {
                        return createApiClient(headers);
                    }
                } else if (propName === 'getApiEndpointUrl') {
                    return function () {
                        return createApiUrlsClient();
                    }
                }

                return async (params: any) => {

                    const restfulApiManagerInstance = await evaluateWhenFunction(restfulApiManager);

                    const apiMethodsInfo = restfulApiManagerInstance.getApiMethodsInfo();

                    const apiMethodName = propName as keyof API & string;

                    const apiMethodInfo = apiMethodsInfo.get(apiMethodName);

                    if (!apiMethodInfo) {
                        throw new Error(`Restful API method '${apiMethodName}' isn't registered.`);
                    }

                    const {
                        bodyParams,
                        auth
                    } = apiMethodInfo.paramsResolver(params);

                    const resolvedPath = restfulApiManagerInstance.paths[apiMethodName](params);

                    const endpointUrl = await processEndpointUrl(baseUrl ? urlJoin(baseUrl, resolvedPath) : resolvedPath);

                    if (apiMethodInfo.useBeacon && typeof navigator !== 'undefined' && navigator.sendBeacon) {

                        navigator.sendBeacon(endpointUrl, JSON.stringify(bodyParams))

                        return undefined;
                    } else {
                        const headers: Dictionary<string> = objectRemoveEntriesWithUndefinedValues({
                            ...(await evaluateWhenFunction(commonHeaders)),
                            ...apiCallHeaders,
                            'Content-Type': 'application/json'
                        })

                        if (auth !== undefined) {
                            headers['Authorization'] = auth;
                        }

                        const axiosRequestConfig: RestfulApiHttpClient.HttpClientRequestConfig = {
                            url: endpointUrl,
                            method: evaluateFunction(() => {
                                switch (apiMethodInfo.method) {
                                    case RestfulApiHttpMethod.Get:
                                        return 'get'
                                    case RestfulApiHttpMethod.Post:
                                        return 'post';
                                    default:
                                        return 'post';
                                }
                            }),
                            headers: headers,
                            withCredentials: withCredentials,
                            data: bodyParams
                        };

                        const apiMethodCallInfo = {
                            methodName: apiMethodName,
                            params: params
                        };

                        await onApiMethodCalled?.(apiMethodCallInfo);

                        onRequest?.(axiosRequestConfig);

                        let httpClientResponse: any;

                        const evaluatedHttpClient = await evaluateWhenFunction(httpClient)

                        try {
                            httpClientResponse = await evaluatedHttpClient.request(axiosRequestConfig)
                        } catch (error: any) {

                            const normalizedError = evaluateFunction(() => {
                                if (error.response) {
                                    onResponse?.(error.response);

                                    return errorsProcessor?.(error.response) ?? new RestfulApiHttpClientServerError({
                                        message: error.response.data.message,
                                        status: error.response.status,
                                        requestId: error.response.headers?.['x-wix-request-id']
                                    })
                                } else if (error.request) {
                                    return new RestfulApiHttpClientServerUnavailableError();
                                } else {
                                    console.error(error);
                                    return new RestfulApiHttpClientRequestFailedError();
                                }
                            })

                            onApiMethodFailed?.({
                                ...apiMethodCallInfo,
                                error: normalizedError
                            });

                            throw normalizedError;
                        }


                        onResponse?.(httpClientResponse);

                        const result = evaluateFunction(() => {
                            if (httpClientResponse.headers['content-type'] === undefined) {
                                return undefined;
                            }

                            if (apiMethodInfo.isLongTimeOperation) {

                                const responseData = httpClientResponse.data;
                                if (typeof responseData === 'string') {
                                    const jsonResultStartIndex = responseData.indexOf('{');
                                    if (jsonResultStartIndex < 0) {
                                        return undefined;
                                    } else {
                                        return JSON.parse(httpClientResponse.data.substr(jsonResultStartIndex))
                                    }
                                } else {
                                    return responseData;
                                }
                            }

                            return httpClientResponse.data;
                        })

                        onApiMethodCompleted?.({
                            ...apiMethodCallInfo,
                            result: result
                        })

                        return result;
                    }
                }
            }
        })
    }

    return createApiClient();
}

export class RestfulApiHttpClientServerUnavailableError extends Error {
    constructor() {
        super(`Server unavailable`);
    }
}

export class RestfulApiHttpClientRequestFailedError extends Error {
    constructor() {
        super(`Failed sending a request`);
    }
}

export class RestfulApiHttpClientServerError extends Error {

    public readonly status;
    public readonly requestId;

    constructor(
        options: {
            message?: string;
            status: number;
            requestId?: string;
        }
    ) {
        super(options.message ?? `Server Error (${options.status})`);

        this.status = options.status;
        this.requestId = options.requestId;
    }
}

