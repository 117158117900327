import type DoppeViewerLocalesMessagesJsonEn from '../locales-viewer/messages_en.json';
import {I18n, i18nGenerateTranslator, i18nInitialize, I18nMessageType} from "@wix/devzai-common-wix";
import {DoppeViewerLang} from "../../client-server-common/doppe-viewer/doppe-viewer-lang";
import React, {useContext, useMemo} from "react";
import {I18nReact, i18nReactProcessMarkupString} from "@wix/devzai-utils-react";
import {DoppeViewerClientServices, useDoppeViewerClientServices} from "../doppe-user-app/doppe-viewer-client-services";
import {assertNotNullable, evaluateWhenFunction} from "@wix/devzai-utils-common";
import {DoppeAppLocales} from "../doppe-app-locales/doppe-app-locales";

export type DoppeViewerLocalesMessagesJson = typeof DoppeViewerLocalesMessagesJsonEn;

export const DoppeViewerLocalesMessagesObjectSpec = {
    'autoRedirectBannerRedirectingText': (_: {normalizedHostName: string; countdown: number;}) => I18nMessageType.Text,
    'subscribeCheckBoxText': (_: {username: string;}) => I18nMessageType.Text,
    'thankyouModal.title': (_: {name: string;}) => I18nMessageType.Text,
    'thankyouModal.text': (_: {amount: string;}) => I18nMessageType.Text,
    'subscribeConsentText': (_: {username: string;}) => I18nMessageType.Text,
    'redirectingIn': (_: {seconds: number;}) => I18nMessageType.Text,
    'pageTermsOfServiceAndPrivacyPolicy.termsOfServiceOnly': (_: {displayName: string;}) => I18nMessageType.Text,
    'pageTermsOfServiceAndPrivacyPolicy.privacyPolicyOnly': (_: {displayName: string;}) => I18nMessageType.Text,
    'pageTermsOfServiceAndPrivacyPolicy.termsOfServiceAndPrivacyPolicy': (_: {displayName: string;}) => I18nMessageType.Text,
    'donate.errors.validation.selectAmountBetween': (_: {min: string; max: string;}) => I18nMessageType.Text,
    'unlockButtonDisabledByTime': (_: {countdown: number;}) => I18nMessageType.Text,
} satisfies I18n.MessagesObjectSpec<DoppeViewerLocalesMessagesJson>

export type DoppeViewerLocalesMessagesObjectSpec = typeof DoppeViewerLocalesMessagesObjectSpec;

export type DoppeViewerLocales = DoppeViewerLocalesMessagesJson &
    {
        [K in keyof DoppeViewerLocalesMessagesObjectSpec]:
            DoppeViewerLocalesMessagesObjectSpec[K] extends (params: infer P) => any ? (params: P) => string : string
    };

export type DoppeViewerLocalesParams = {
    [K in keyof DoppeViewerLocalesMessagesObjectSpec]:
        DoppeViewerLocalesMessagesObjectSpec[K] extends (params: infer P) => any ? P : never
};

export async function doppeViewerLocalesCreateTranslator (lang: DoppeViewerLang) {

    const fallbackMessages = lang === DoppeViewerLang.English ? {} : (await import(`../locales-viewer/messages_en.json`)).default
    const messages = (await import(`../locales-viewer/messages_${lang}.json`)).default

    return doppeViewerLocalesCreateTranslatorFromMessages(lang, {
        ...fallbackMessages,
        ...messages
    });
}

export async function doppeViewerLocalesCreateTranslatorFromMessages (lang: DoppeViewerLang, messages: DoppeViewerLocalesMessagesJson) {
    const i18n = await i18nInitialize({
        locale: lang,
        messages: messages
    });

    return i18nGenerateTranslator({
        i18n: i18n,
        parameterizedKeys: DoppeViewerLocalesMessagesObjectSpec
    }) as DoppeViewerLocales
}

export function doppeViewerProcessLocalesMarkupString (
    clientServices: DoppeViewerClientServices,
    input: string,
    options: doppeViewerProcessLocalesMarkupString.Options = {}
) {
    const {
        tagRenderFunctions
    } = options;

    return i18nReactProcessMarkupString(input, {
        ...tagRenderFunctions,
        mark: text => (<mark>{text}</mark>),
        b: text => (<strong>{text}</strong>),
        strong: text => (<strong>{text}</strong>)
    }, clientServices)
}

export namespace doppeViewerProcessLocalesMarkupString {

    export type TagRenderFunctions = I18nReact.TagRenderFunction<DoppeViewerClientServices>

    export type Options = {
        tagRenderFunctions?: TagRenderFunctions;
    }

}

export type DoppeViewerLocalesAccessor = {
    $html<K extends keyof DoppeViewerLocalesParams>(key: K, params: DoppeViewerLocalesParams[K] & doppeViewerProcessLocalesMarkupString.Options) : React.ReactElement;
    $html<K extends Exclude<keyof DoppeViewerLocales, keyof DoppeViewerLocalesParams>>(key: K, options?: doppeViewerProcessLocalesMarkupString.Options) : React.ReactElement;
} & DoppeViewerLocales;

export const DoppeViewerLocalesContext = React.createContext<DoppeViewerLocales | null>(null);

export function useDoppeViewerLocales () : DoppeViewerLocalesAccessor {

    const clientServices = useDoppeViewerClientServices();

    const doppeViewerLocales = assertNotNullable(useContext(DoppeViewerLocalesContext));

    return useMemo(() => {
        return new Proxy({}, {
            get: (_obj: any, propName: string) => {

                switch (propName) {
                    case '$html': {
                        return function (key: keyof DoppeAppLocales, paramsOrOptions: any) {

                            const value = (doppeViewerLocales as any)[key];

                            const {
                                tagRenderFunctions,
                                ...params
                            } = (paramsOrOptions ?? {});

                            return doppeViewerProcessLocalesMarkupString(clientServices, evaluateWhenFunction(value, params), {
                                tagRenderFunctions: tagRenderFunctions
                            })
                        }
                    }
                    default: {
                        return (doppeViewerLocales as any)[propName];
                    }
                }
            }
        });
    }, [clientServices, doppeViewerLocales])
}

