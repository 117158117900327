import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {urlRemoveQueryString, Values} from '@wix/devzai-utils-common';
import {DoppeHideableValue} from '../../client-server-common';
import {RichTextContent} from '@wix/devzai-utils-react';


export const SpotifyWidgetSize = {
    Normal: 'Normal',
    Compact: 'Compact',
} as const;

export const SpotifyWidgetTheme = {
    Default: 'Default',
    Dark: 'Dark',
} as const;


export namespace DoppeActionTypeSpotify {

    export type Size = Values<typeof SpotifyWidgetSize>;
    export type Theme = Values<typeof SpotifyWidgetTheme>;

    export interface ActionSettings {
        url: string;
        theme: Theme;
        size: Size;
        buttonText: DoppeHideableValue<string>;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
    }
}

export const doppeActionTypeSpotify = doppeActionTypeDefineMetadata<DoppeActionTypeSpotify.ActionSettings>({
    id: 'io.bymo.action/spotify',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        url: '',
        theme: SpotifyWidgetTheme.Default,
        size: SpotifyWidgetSize.Normal,
        buttonText: 'Listen on Spotify',
        title: 'Title',
        description: 'Description',
    }),
    hasNoGoalMeasuring: false,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
})

export function doppeValidateSpotifyPlaylistURL(url: string) {
    const pattern = /^https:\/\/open\.spotify\.com\/(?:playlist|embed\/playlist)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyTrackURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/track\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:track|embed\/track)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyShowURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/show\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:show|embed\/show)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyEpisodeURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/episode\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:episode|embed\/episode)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyArtistURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/artist\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:artist|embed\/artist)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyURL(url: string) {
    const pattern = /^https:\/\/open\.spotify\.com\/([A-Za-z]+)\/([A-Za-z0-9_-]+)(\?.*)?$/;
    return pattern.test(urlRemoveQueryString(url));
}