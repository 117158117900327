import {ArrayValues, Nullable, PickByValue} from "../common-types";
import {iterableFindAndSelect, iterableMapToArray} from "../iterable-utils/iterable-utils";
import {objectCreateFromEntries, objectCreateFromKeys} from "../object-utils";
import {identity} from "../common-functions";

export function enumGetValues<V> (enumObj: Record<string, V>) : V[] {
    return Object.values(enumObj);
}

export function enumGetEntries<K extends string, V> (enumObj: Record<K, V>) : [K, V][] {
    return Object.entries(enumObj) as [K, V][];
}

export function enumGetKeys<K extends string> (enumObj: Record<K, string>) : K[] {
    return Object.keys(enumObj) as K[];
}

export function enumGetKeyByValue<V> (enumObj: Record<string, V>, value: V) : string | undefined {
    return iterableFindAndSelect(enumGetEntries(enumObj), ([key, entryValue], skip) => entryValue === value ? key : skip);
}

export function enumValueOrDefault<V, T, D> (enumObj: Record<string, V>, value: T, defaultValue: D) :
    T extends V ? T : D
{
    return (enumGetValues(enumObj).includes(value as any) ? value : defaultValue) as any;
}

export function enumNullableValueOrDefault<V, T, D> (enumObj: Record<string, V>, value: Nullable<T>, defaultValue: D) :
    T extends Nullable<V> ? T : D
{
    if (value === null || value === undefined) {
        return value as any;
    }

    return (enumGetValues(enumObj).includes(value as any) ? value : defaultValue) as any;
}

export function enumIsEnumValue<V> (enumObj: Record<string, V>, value: any) : value is V {
    return enumGetValues(enumObj).includes(value)
}

export function enumReduce<V, E extends Record<string, V>, RV extends V> (enumObj: E, values: RV[]) {

    return objectCreateFromEntries(iterableMapToArray(enumGetEntries(enumObj), ([key, value], skip) => {
        return values.includes(value as any) ? [key, value as RV] : skip;
    })) as PickByValue<E, RV>;
}

export function enumCreateFromKeys<K extends string[]> (keys: K) {
    return objectCreateFromKeys(keys, identity) as {
        [KEY in ArrayValues<K>]: KEY
    }
}