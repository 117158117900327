import {bymoTemplateMetadataFactoryCreate} from "../../../doppe-sdk";


export namespace BymoTemplateActionInPagePreview {

    export interface TemplateProps {

    }
}

export const BymoTemplateActionInPagePreviewMetadata = bymoTemplateMetadataFactoryCreate<BymoTemplateActionInPagePreview.TemplateProps>()({
    initialValues: {},
    features: [
        'hasActions', 'hasActionButton'
    ]
})