import {ListViewDataSourceDataItem} from './list-view-types';

export type SearchMatchingFunction = (
    searchText: string,
    dataSourceDataItem: ListViewDataSourceDataItem<any>
) => boolean;

export const searchMatchByTypeAheadPrefix = createSearchByPrefixMatchingFunction(
    dataSourceDataItem => dataSourceDataItem.typeAheadText
);

export type SearchTextSelector = (dataSourceDataItem: ListViewDataSourceDataItem<any>) => string | null | undefined;

export function createSearchByPrefixMatchingFunction(searchTextSelector: SearchTextSelector): SearchMatchingFunction {
    return (searchText: string, dataSourceDataItem: ListViewDataSourceDataItem<any>): boolean => {
        const itemTypeAheadText = searchTextSelector(dataSourceDataItem);

        return (
            !!searchText &&
            itemTypeAheadText !== null &&
            itemTypeAheadText !== undefined &&
            itemTypeAheadText.toLowerCase().startsWith(searchText.toLowerCase())
        );
    };
}

export enum NavigationScopeReducingBySearchStrategy {
    NeverReduce,
    ReduceOnlyWhenHasMatches,
    ReduceOnlyWhenHasMultipleMatches,
    ReduceAlways
}
