import {EventEmitter} from '@wix/devzai-utils-common';
import {jsonTryParse, validateType, Values} from "@wix/devzai-utils-common";
import {CookieStorage, ICookieStorage} from "../cookie-storage/cookie-storage";

export const ConsentPolicyCookieName = 'consent-policy';

export const ConsentPreferencesKeyValues = {
    ess: 'ess',
    func: 'func',
    anl: 'anl',
    adv: 'adv',
} as const;

export type ConsentPreferencesKeys = Values<typeof ConsentPreferencesKeyValues>;
export type ConsentPreferences = Record<ConsentPreferencesKeys, 0 | 1>;

export interface ConsentCookie extends ConsentPreferences {
    ts: number;
}

export const approveAllConsentPreferences: ConsentPreferences= {
    ess: 1,
    func: 1,
    anl: 1,
    adv: 1,
};

export const denyAllConsentPreferences: ConsentPreferences = {
    ess: 1,
    func: 0,
    anl: 0,
    adv: 0,
};

export const defaultConsentPreferences = denyAllConsentPreferences;

export interface GlobalCookieAttributes {
    path?: string;
    domain?: string;
}

export const ConsentManagerState = {
    Active: 'active',
    Inactive: 'inactive',
} as const;

export type ConsentManagerState = Values<typeof ConsentManagerState>;

export class CookieConsentManager {
    private globalCookieAttributes: GlobalCookieAttributes;
    private cookieStorage: ICookieStorage;
    public eventEmitter = new EventEmitter<{ consentChanged: Partial<ConsentPreferences> }>();
    public state: ConsentManagerState;


    constructor(config: {
        globalCookieAttributes: GlobalCookieAttributes,
        cookieStorage?: ICookieStorage,
        consentState: ConsentManagerState,
    }) {
        this.globalCookieAttributes = config.globalCookieAttributes;
        this.cookieStorage = config.cookieStorage ?? new CookieStorage();
        this.state = config.consentState;
    }

    public setState(state: ConsentManagerState) {
        this.state = state;
        this.eventEmitter.emit('consentChanged', this.getCookieConsent());
    }

    private setConsentCookie(consentCookiePreferences: ConsentPreferences) {
        const preferenceDiff = this.getDifferentPreferenceKeys(this.getCookieConsent(), consentCookiePreferences);

        const consentCookie = {
            ...consentCookiePreferences,
            ess: 1,
            ts: new Date().getTime()
        };

        this.cookieStorage.setItem(ConsentPolicyCookieName, JSON.stringify(consentCookie), {
            path: this.globalCookieAttributes?.path || '/',
            domain: this.globalCookieAttributes?.domain,
            sameSite: 'strict',
            maxAge: 2147483647,
        });

        this.eventEmitter.emit('consentChanged', preferenceDiff);
    }

    private getDifferentPreferenceKeys(previousPreferences: ConsentPreferences, newPreferences: ConsentPreferences): Partial<ConsentPreferences> {
        const differences: Partial<ConsentPreferences> = {};

        for (const key of Object.keys(ConsentPreferencesKeyValues) as ConsentPreferencesKeys[]) {
            if (previousPreferences[key] !== newPreferences[key]) {
                differences[key] = newPreferences[key];
            }
        }

        return differences;
    }

    private loadConsentFromCookieStore(): ConsentCookie | null {
        try {
            const consentCookieString = this.cookieStorage.getItem(ConsentPolicyCookieName);
            const cookieConsent = jsonTryParse<ConsentCookie>(consentCookieString);

            if (cookieConsent && this.validateConsentCookie(cookieConsent)) {
                return cookieConsent;
            }
        } catch (error) {
            console.error('Error parsing cookie consent:', error);
        }

        return null;
    }

    private validateConsentCookie(consentCookie: any): consentCookie is ConsentCookie {
        return validateType<ConsentCookie>(consentCookie, assert => {
            assert.isObject({
                ess: assert => assert.isOneOfValues([0, 1]),
                func: assert => assert.isOneOfValues([0, 1]),
                anl: assert => assert.isOneOfValues([0, 1]),
                adv: assert => assert.isOneOfValues([0, 1]),
                ts: assert => assert.isNumber(),
            })
        })
    }

    public getGlobalCookieAttributes(): GlobalCookieAttributes {
        return this.globalCookieAttributes;
    }

    public setGlobalCookieAttributes(attributes: GlobalCookieAttributes) {
        this.globalCookieAttributes = attributes;
    }

    public getCookieConsent () {
        switch (this.state) {
            case 'active':
                return this.loadConsentFromCookieStore() || defaultConsentPreferences
            case 'inactive':
                return approveAllConsentPreferences;
        }
    }

    public shouldAskForConsent() { return this.state === 'active' && this.loadConsentFromCookieStore() === null}

    public approveAll() {
        this.setConsentCookie(approveAllConsentPreferences);
    }

    public denyAll() {
        this.setConsentCookie(denyAllConsentPreferences);
    }

    public partialApprove(newConsent: Partial<ConsentPreferences>) {
        this.setConsentCookie({
            ...denyAllConsentPreferences,
            ...newConsent,
        });
    }

    public hasConsent(key: ConsentPreferencesKeys): boolean {
        return this.getCookieConsent()[key] === 1;
    }
}
