import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeCouponsList} from "./doppe-action-type-coupons-list";

export const doppeActionTypeCouponsListViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeCouponsList, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeCouponsListWidget: (await import('./doppe-action-type-coupons-list-widget/doppe-action-type-coupons-list-widget')).DoppeActionTypeCouponsListWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeCouponsListWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeCouponsListWidget
                {...renderProps}
            />
        );

    }
})