export function regexMatchAll (regex: RegExp, str: string) : RegExpExecArray[]
export function regexMatchAll (regex: RegExp, str: string, groupIndex: number) : string[]
export function regexMatchAll (regex: RegExp, str: string, groupIndex?: number) : RegExpExecArray[] | string[] {

    const matchingRegex = new RegExp(regex);

    const result = [] as RegExpExecArray[];
    let regexExecResult: RegExpExecArray | null;

    while ((regexExecResult = matchingRegex.exec(str)) !== null) {
        result.push(regexExecResult);
    }

    if (groupIndex !== undefined) {
        return result.map(match => match[groupIndex]);
    } else {
        return result;

    }

}

export function regexExec (regex: RegExp, str: string) {
    regex.lastIndex = 0;

    return regex.exec(str);
}

/**
 * Taken from: https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
 */
export function regexEscapeRegExpString (regExpString: string) {
    return regExpString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

