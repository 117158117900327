import {Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity} from './doppe-business-entity';
import {DoppeAutoEventType} from './doppe-auto-event-type';

export const DoppeEventsExternalDataSourceType = {
    UpcomingAndOldWixEvents: 'LatestWixEvents',
    UpcomingWixEvents: 'UpcomingWixEvents',
    OldWixEvents: 'OldWixEvents',
} as const;

export type DoppeEventsExternalDataSourceType = Values<typeof DoppeEventsExternalDataSourceType>;

export type DoppeEventsExternalDataSource =
    | {
    type: typeof DoppeEventsExternalDataSourceType.UpcomingAndOldWixEvents;
} | {
    type: typeof DoppeEventsExternalDataSourceType.UpcomingWixEvents;
} | {
    type: typeof DoppeEventsExternalDataSourceType.OldWixEvents;
};


export function doppeEventsExternalDataSourceResolveBusinessEntityReferences(
    externalDataSource: DoppeEventsExternalDataSource
) {
    switch (externalDataSource.type) {
        case DoppeEventsExternalDataSourceType.UpcomingAndOldWixEvents: {
            return {businessEntity: DoppeBusinessEntity.WixAutoEventsList, id: DoppeAutoEventType.UpcomingAndOld}
        }
        case DoppeEventsExternalDataSourceType.UpcomingWixEvents: {
            return {businessEntity: DoppeBusinessEntity.WixAutoEventsList, id: DoppeAutoEventType.Upcoming}
        }
        case DoppeEventsExternalDataSourceType.OldWixEvents: {
            return {businessEntity: DoppeBusinessEntity.WixAutoEventsList, id: DoppeAutoEventType.Old}
        }
    }
}