import {enumGetValues, evaluateFunction, Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity, DoppeBusinessEntityReference} from "./doppe-business-entity";

/**
 * An enum of all the external providers that can provide a blog post data.
 * For example: Wix, Medium, etc.
 */
export const DoppeExternalBlogPostProvider = {
    Wix: 'wix',
} as const;

export type DoppeExternalBlogPostProvider = Values<typeof DoppeExternalBlogPostProvider>;

// TODO: This field should include all the possible SEMANTIC fields that an external blog post providers can provide
// TODO: for a blog post.
export const DoppeExternalBlogPostField = {
    PostUrl: 'PostUrl',
    FirstPublishDate: 'FirstPublishDate',
} as const;

export type DoppeExternalBlogPostField = Values<typeof DoppeExternalBlogPostField>;

const AllExternalBlogPostProviders = enumGetValues(DoppeExternalBlogPostProvider);

const externalProductFieldsToSupportedProviders = {
    [DoppeExternalBlogPostField.PostUrl]: AllExternalBlogPostProviders,
    [DoppeExternalBlogPostField.FirstPublishDate]: AllExternalBlogPostProviders,
} satisfies {
    [field in DoppeExternalBlogPostField]: DoppeExternalBlogPostProvider[]
}

export function doppeExternalBlogPostProviderIsFieldProvided (
    externalBlogPostProvider: DoppeExternalBlogPostProvider | null | undefined,
    externalProductField: DoppeExternalBlogPostField
) {
    if (externalBlogPostProvider === null || externalBlogPostProvider === undefined) {
        return false;
    }

    return externalProductFieldsToSupportedProviders[externalProductField].includes(externalBlogPostProvider);
}

export function doppeExternalBlogPostProviderResolveBusinessEntityReference (
    externalItemProvider: DoppeExternalBlogPostProvider,
    externalItemId: string
) {
    return evaluateFunction(() => {
        switch (externalItemProvider) {
            case DoppeExternalBlogPostProvider.Wix: {
                return {
                    businessEntity: DoppeBusinessEntity.WixBlogPost,
                    id: externalItemId
                }
            }
        }
    }) satisfies DoppeBusinessEntityReference
}