import {WixImageInfo} from '@wix/devzai-common-wix';
import {WixMembershipPlan} from '@wix/devzai-common-wix';
import {CurrencyCode, currencyGetSymbolForDisplay} from '@wix/devzai-utils-common';


export interface DoppeDtoMembershipPlan {
    id: string;
    name: string;
    description?: string;
    media: WixImageInfo | null;
    membershipPageUrl?: string;
    createdDate: Date | null;
    perks: string[];
    pricing: WixMembershipPlan.Pricing | null;

}

export namespace DoppeDtoMembershipPlan {
    export type Id = DoppeDtoMembershipPlan['id'];
}

export function doppeDtoMembershipPlanPaymentConvertToActionFormattedPrice(pricing: WixMembershipPlan.Pricing): string {
    return pricing.price?.formattedValue ? pricing.price?.formattedValue : `${currencyGetSymbolForDisplay((pricing.price?.currency ?? 'USD') as CurrencyCode)}${pricing.price?.value}`
}
