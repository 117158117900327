import {stringExtractSubstringUntilMatch} from "@wix/devzai-utils-common";

export function browserHistorySupportedWithoutUserGesture (userAgent?: string) {

    const resolvedUserAgent = userAgent ?? navigator.userAgent;

    const chromeVersion = /Chrome\/([0-9.]+)/.exec(resolvedUserAgent) ?? /CriOS\/([0-9.]+)/.exec(resolvedUserAgent);

    if (chromeVersion === null) {
        return true;
    } else {
        return parseInt(stringExtractSubstringUntilMatch(chromeVersion[1], '.')) < 75
    }
}