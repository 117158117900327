import {Values} from "@wix/devzai-utils-common";
import {WixPremiumFeaturesSpec, WixPremiumFeatureType} from "@wix/devzai-common-wix";

export const DoppeAllSupportedPremiumFeatures = {
    identity_collaborators: 'identity_collaborators',
    hopp_remove_banner: 'hopp_remove_banner',
    hopp_latest_items_action: 'hopp_latest_items_action',
    hopp_on_page_display: 'hopp_on_page_display',
    hopp_indexed_links_in_search: 'hopp_indexed_links_in_search',
    hopp_search_shortcuts: 'hopp_search_shortcuts',
    hopp_contact_management: 'hopp_contact_management',
    hopp_banner_display: 'hopp_banner_display',
    creators_favicon_: 'creators_favicon_',
    hopp_ai_media: 'hopp_ai_media',
    hopp_automations: 'hopp_automations',
    creators_pre_rolls_count: 'creators_pre_rolls_count',
    connect_your_domain: 'connect_your_domain',
    hopp_verified_badge: 'hopp_verified_badge',
    hopp_full_screen_view: 'hopp_full_screen_view',
    hopp_add_items_to_list_action: 'hopp_add_items_to_list_action',
    hopp_unlimited_pages: 'hopp_unlimited_pages',
    hopp_contacts_subscription_action: 'hopp_contacts_subscription_action',
    hopp_marketing_pixels: 'hopp_marketing_pixels',
    creators_advanced_search_settings: 'creators_advanced_search_settings',
    hopp_pre_roll_limits: 'hopp_pre_roll_limits',
    hopp_brand_redirects: 'hopp_brand_redirects',
    hopp_meta_pixel: 'hopp_meta_pixel',
    hopp_import_links_from_youtube: 'hopp_import_links_from_youtube',
    hopp_crm_integration_wix: 'hopp_crm_integration_wix',
    hopp_list_premium_styles: 'hopp_list_premium_styles',
    hopp_customize_privacy_policy: 'hopp_customize_privacy_policy',
    hopp_advanced_design_options: 'hopp_advanced_design_options',
    creators_monetization_actions: 'creators_monetization_actions',
    hopp_allow_connect_subdomain: 'hopp_allow_connect_subdomain',
    hopp_full_analytics: 'hopp_full_analytics',
    hopp_allow_pre_rolls: 'hopp_allow_pre_rolls',
    hopp_allow_landing_pages: 'hopp_allow_landing_pages',
    hopp_shortened_links_bulk_actions: 'hopp_shortened_links_bulk_actions',
    hopp_phone_number_collection: 'hopp_phone_number_collection',
    hopp_advanced_page_presets: 'hopp_advanced_page_presets',
    creators_form_submission: 'creators_form_submission',
    creators_social_analytics: 'creators_social_analytics',
    hopp_allow_short_links: 'hopp_allow_short_links',
    hopp_change_link_destination: 'hopp_change_link_destination',
    media_storage_total: 'media_storage_total',
    hopp_custom_terms_and_conditions: 'hopp_custom_terms_and_conditions',
    hopp_subdomain_edits: 'hopp_subdomain_edits',
    hopp_bulk_connect_to_pre_roll: 'hopp_bulk_connect_to_pre_roll',
    hopp_create_short_links: 'hopp_create_short_links',
    hopp_seo_settings: 'hopp_seo_settings',
    hopp_social_share_settings_editing: 'hopp_social_share_settings_editing',
    hopp_lock_action: 'hopp_lock_action',
    hopp_google_analytics: 'hopp_google_analytics',
    vip_support: 'vip_support',
} as const;

export type DoppeAllSupportedPremiumFeatures = Values<typeof DoppeAllSupportedPremiumFeatures>;

export const DoppeAllSupportedPremiumFeaturesSpec = {
    [DoppeAllSupportedPremiumFeatures.identity_collaborators]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_remove_banner]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_latest_items_action]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_on_page_display]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_indexed_links_in_search]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_search_shortcuts]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_contact_management]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_banner_display]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.creators_favicon_]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_ai_media]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_automations]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.creators_pre_rolls_count]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.connect_your_domain]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_verified_badge]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_full_screen_view]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_add_items_to_list_action]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_unlimited_pages]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_contacts_subscription_action]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_marketing_pixels]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.creators_advanced_search_settings]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_pre_roll_limits]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_brand_redirects]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_meta_pixel]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_import_links_from_youtube]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_crm_integration_wix]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_list_premium_styles]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_customize_privacy_policy]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_advanced_design_options]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.creators_monetization_actions]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_allow_connect_subdomain]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_full_analytics]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_allow_pre_rolls]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_allow_landing_pages]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_shortened_links_bulk_actions]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_phone_number_collection]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_advanced_page_presets]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.creators_form_submission]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.creators_social_analytics]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_allow_short_links]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_change_link_destination]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.media_storage_total]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_custom_terms_and_conditions]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_subdomain_edits]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_bulk_connect_to_pre_roll]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_create_short_links]: WixPremiumFeatureType.Quota,
    [DoppeAllSupportedPremiumFeatures.hopp_seo_settings]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_social_share_settings_editing]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_lock_action]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.hopp_google_analytics]: WixPremiumFeatureType.Boolean,
    [DoppeAllSupportedPremiumFeatures.vip_support]: WixPremiumFeatureType.Boolean,
} satisfies WixPremiumFeaturesSpec<DoppeAllSupportedPremiumFeatures>;