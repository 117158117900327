import {evaluateFunction, IDisposable} from "@wix/devzai-utils-common";

class DomImageAsyncLoader implements IDisposable {

    private image: HTMLImageElement | null = null;
    private disposed = false;

    constructor (
        options: DomImageAsyncLoader.Options
    ) {
        const {
            src,
            onLoad,
            onError,
            decodeImage = true
        } = options;

        const image = this.image = new Image();
        image.onload = () => {

            evaluateFunction(async () => {
                if (!this.disposed && decodeImage) {
                    await image.decode?.()
                }

                if (!this.disposed) {
                    onLoad?.({
                        imageElement: image,
                        src: src,
                        naturalWidth: image.naturalWidth,
                        naturalHeight: image.naturalHeight
                    })
                }
            }).catch(error => {
                if (!this.disposed) {
                    onError?.(error)
                }
            })
        }

        image.onerror = (error) => {
            if (!this.disposed) {
                onError?.(error)
            }
        }

        image.src = src;
    }

    public dispose () {
        this.disposed = true;
        const image = this.image;
        if (image !== null) {
            image.onload = null;
            image.onerror = null;
            image.src = '';
            this.image = null;
        }
    }

}


export function domImageAsyncLoaderLoad (options: DomImageAsyncLoader.Options) : IDisposable {
    return new DomImageAsyncLoader(options);
}

export namespace DomImageAsyncLoader {

    export interface LoadedImageInfo {
        imageElement: HTMLImageElement;
        src: string;
        naturalWidth: number;
        naturalHeight: number;
    }

    export interface Options {
        src: string;
        decodeImage?: boolean;
        onLoad?: (loadedImageInfo: LoadedImageInfo) => void;
        onError?: (error: any) => void;
    }

}