import {
    CurrentItemChangeEvent,
    IListView,
    IListViewCommon,
    ListViewDataSourceDataItem,
    listViewIsNotPointerInteractionStateUpdate,
    ListViewItemId,
    ListViewRenderItemProps,
    ListViewSelectionType,
    StateUpdateReason
} from './list-view-types';
import React from 'react';
import {getItemElementById} from './list-view-utils';
import {ComputeScrollIntoViewOptions, domNodesListToArray, elementClosest, scrollIntoView} from '@wix/devzai-utils-dom';
import {assertNotNullable} from "@wix/devzai-utils-common";

export const ContainingListViewIdAttributeName = 'data-navigatable-item';
export const ListViewItemIdAttributeName = 'data-list-view-item-id';
export const ListViewIdAttributeName = 'data-list-view-id';
export const ListViewInstanceElementPropName = 'suiListViewInstance';

export interface ListViewItemInfo<T> {
    dataItem: T;
    element: Element;
    listView: IListView;
    dataItemId: ListViewItemId;
    listViewItem: ListViewDataSourceDataItem<T>;
}

export interface ListViewItemEventData<T, EVENT extends React.BaseSyntheticEvent = React.BaseSyntheticEvent> extends ListViewItemInfo<T> {
    event: EVENT;
}

export type ListViewItemEventHandler<T, EVENT extends React.BaseSyntheticEvent = React.BaseSyntheticEvent> = (
    eventData: ListViewItemEventData<T, EVENT>
) => void;

export interface ListViewItemEventHandlerOptions {
    partsSelector?: string;
    ignoredPartsSelector?: string;
}

export function listViewResolveInnerFocusableItemAttributes (
    renderItemProps: ListViewRenderItemProps<any>,
    handleListViewKeyboardEventsWhenFocused = false
) {
    const {
        isDisabled,
        tabIndex,
        listViewId
    } = renderItemProps;

    return {
        disabled: isDisabled ? true : undefined,
        tabIndex: tabIndex,
        'data-handle-keyboard-events': handleListViewKeyboardEventsWhenFocused ? listViewId : undefined
    };
}

/**
 * Returns an array of the list-view items' attached dom elements for the specified list-view instance.
 * The order of the elements in the array is is their order in the DOM.
 */
export function listViewGetItemsElements (listView: IListViewCommon) : HTMLElement[] {
    const itemsElements: HTMLElement[] = [];

    const listViewRootElement = listView.getRootElement();

    if (listViewRootElement) {
        const listViewItemsElements = listViewRootElement.querySelectorAll<HTMLElement>(
            `[${ContainingListViewIdAttributeName}="${listView.getId()}"]`
        );

        listViewItemsElements.forEach(item => itemsElements.push(item));
    }

    return itemsElements;
}

export function listViewGetListViewItemIdFromElement (element: Element): ListViewItemId | undefined {
    const idAttrValue = element.getAttribute(ListViewItemIdAttributeName);

    if (idAttrValue !== null) {
        try {
            return JSON.parse(idAttrValue);
        } catch (_error) {
            return undefined;
        }
    }

    return undefined;
}

export function listViewFindListViewItemElement (listView: IListViewCommon, itemId: ListViewItemId) : HTMLElement | undefined {
    const listViewRootElement = listView.getRootElement();

    if (listViewRootElement) {
        const listViewItemsElements = domNodesListToArray(listViewRootElement.querySelectorAll<HTMLElement>(
            `[${ContainingListViewIdAttributeName}="${listView.getId()}"]`
        ));

        return listViewItemsElements.find(element => listViewGetListViewItemIdFromElement(element) === itemId)
    }

    return undefined;
}

export function listViewResolveItemInfoFromElement<T = unknown>(partElement: Element): ListViewItemInfo<T> | undefined {
    const listView = listViewFindContainingListView<IListView>(partElement, false);

    if (listView) {
        const listViewId = listView.getId();

        const itemElement = partElement.closest(`[${ContainingListViewIdAttributeName}="${listViewId}"]`);

        if (itemElement) {
            const itemId = listViewGetListViewItemIdFromElement(itemElement)!;

            const listViewItem = listView.getListViewItemById(itemId) as ListViewDataSourceDataItem<T>;

            return {
                listView: listView,
                listViewItem: listViewItem,
                dataItem: listViewItem.dataItem,
                dataItemId: itemId,
                element: itemElement
            };
        }
    }

    return undefined;
}

export function listViewHandleListViewItemEvent<
    T = unknown,
    EVENT extends React.BaseSyntheticEvent = React.BaseSyntheticEvent
>(event: EVENT, callback: ListViewItemEventHandler<T, EVENT>, options: ListViewItemEventHandlerOptions = {}) {
    const { partsSelector, ignoredPartsSelector } = options;

    const eventTarget = event.target;

    const customTarget = partsSelector ? eventTarget.closest(partsSelector) : eventTarget;

    if (!customTarget) {
        return;
    }

    if (ignoredPartsSelector) {
        const ignoredElement = eventTarget.closest(ignoredPartsSelector);

        if (ignoredElement && listViewGetItemInfoFromListViewEvent(event, ignoredElement)) {
            return;
        }
    }

    const itemInfo = listViewGetItemInfoFromListViewEvent(event, customTarget);

    if (itemInfo) {
        callback({
            event: event,
            ...itemInfo,
        } as ListViewItemEventData<T, EVENT>);
    }
}

export function listViewCreateListViewItemEventHandler<
    T = unknown,
    EVENT extends React.BaseSyntheticEvent = React.BaseSyntheticEvent
    >(callback: ListViewItemEventHandler<T, EVENT>, options: ListViewItemEventHandlerOptions = {}) {
    return (event: EVENT) => {
        listViewHandleListViewItemEvent(event, callback, options);
    };
}

export function listViewGetItemInfoFromListViewEvent<T = unknown>(
    listViewEvent: React.BaseSyntheticEvent,
    customTarget?: Element
): ListViewItemInfo<T> | undefined {
    const listView = listViewGetListViewFromElement<IListView>(listViewEvent.currentTarget as HTMLElement);

    if (!listView) {
        throw new Error(`The event handler isn't attached to a ListView component`);
    }

    return listViewGetItemInfoFromEvent<T>(listView, listViewEvent, customTarget);
}

export function listViewGetListViewFromElement<LIST_VIEW extends IListViewCommon>(element: HTMLElement): LIST_VIEW | undefined {
    return (element as any)[ListViewInstanceElementPropName];
}

export function listViewFindContainingListView<LIST_VIEW extends IListViewCommon>(
    element: Element,
    includeSelf = true
): LIST_VIEW | undefined {
    const listViewElement = elementClosest(element, `[${ListViewIdAttributeName}]`, includeSelf);

    return listViewElement ? listViewGetListViewFromElement(listViewElement as HTMLElement) : undefined;
}

export function listViewDelegateKeyboardNavigationEvent(
    listView: IListViewCommon<any>,
    event: React.KeyboardEvent<Element>,
    options: {
        enableSelectionDelegation?: boolean;
    } = {
        enableSelectionDelegation: true
    }
) {
    const { enableSelectionDelegation } = options;

    const eventKey = event.key;

    let delegateEvent = false;

    if (eventKey === 'ArrowDown' || eventKey === 'ArrowUp') {
        delegateEvent = true;
    } else {
        if (enableSelectionDelegation) {
            const selectionType = listView.getSelectionType();

            if (selectionType !== ListViewSelectionType.None) {
                delegateEvent =
                    (eventKey === ' ' && (event.ctrlKey || event.shiftKey)) ||
                    ((eventKey === 'Home' || eventKey === 'End') && (event.ctrlKey || event.shiftKey));
            }
        }
    }

    if (delegateEvent) {
        listView.handleKeyboardEvent(event);
    }

    return delegateEvent;
}

export function listViewHandleListViewItemMouseEvent<T = unknown>(
    event: React.MouseEvent,
    callback: ListViewItemEventHandler<T, React.MouseEvent>,
    options: ListViewItemEventHandlerOptions = {}
) {
    return listViewHandleListViewItemEvent<T, React.MouseEvent>(event, callback, options);
}

export function listViewCreateListViewItemMouseEventHandler<T = unknown>(
    handler: ListViewItemEventHandler<T, React.MouseEvent>,
    options?: ListViewItemEventHandlerOptions
): React.MouseEventHandler {
    return listViewCreateListViewItemEventHandler<T, React.MouseEvent>(handler, options);
}

export function listViewGetDataItemFromEvent<T = unknown> (listViewEvent: Event) {
    const partElement = listViewEvent.target;

    if (partElement instanceof Element) {
        const listView = listViewFindContainingListView<IListView>(partElement);

        if (listView) {
            const listViewId = listView.getId();

            const itemElement = partElement.closest(`[${ContainingListViewIdAttributeName}="${listViewId}"]`) ?? undefined;

            if (itemElement) {
                return listViewGetDataItemFromElement<T>(listView, itemElement)
            }
        }
    }

    return undefined;
}

export function listViewGetDataItemFromElement<T = unknown>(
    listView: IListView,
    itemElement: Element
) : T | undefined {
    const itemId = listViewGetListViewItemIdFromElement(itemElement);

    if (itemId !== undefined) {
        const listViewItem = listView.getListViewItemById(itemId);

        return listViewItem?.dataItem;
    }

    return undefined;
}

export function listViewGetItemInfoFromElement<T = unknown>(
    listView: IListView,
    itemElement: Element
) : ListViewItemInfo<T> | undefined {
    const itemId = listViewGetListViewItemIdFromElement(itemElement);

    if (itemId) {
        const listViewItem = listView.getListViewItemById(itemId);

        if (listViewItem) {
            return {
                listView: listView,
                listViewItem: listViewItem,
                dataItem: listViewItem.dataItem,
                dataItemId: itemId,
                element: itemElement
            };
        }
    }

    return undefined;
}

export function listViewGetItemElementFromEvent (listViewEvent: Event) {

    const partElement = listViewEvent.target;

    if (partElement instanceof Element) {
        const listView = listViewFindContainingListView<IListView>(partElement);

        if (listView) {
            const listViewId = listView.getId();

            return partElement.closest(`[${ContainingListViewIdAttributeName}="${listViewId}"]`) ?? undefined;
        }
    }

    return undefined;
}

export function listViewGetItemInfoFromEvent<T = unknown>(
    listView: IListView,
    listViewEvent: React.BaseSyntheticEvent,
    customTarget?: Element
) : ListViewItemInfo<T> | undefined {

    const targetElement = customTarget ? customTarget : (listViewEvent.target as Element);

    const listViewId = listView.getId();

    const itemElement = targetElement.closest(`[${ContainingListViewIdAttributeName}="${listViewId}"]`);

    if (itemElement) {
        const itemId = listViewGetListViewItemIdFromElement(itemElement)!;

        const listViewItem = listView.getListViewItemById(itemId) as ListViewDataSourceDataItem<T>;

        return {
            listView: listView,
            listViewItem: listViewItem,
            dataItem: listViewItem.dataItem,
            dataItemId: itemId,
            element: itemElement
        };
    }

    return undefined;
}

export function listViewAutoScrollOnCurrentItemChange (
    currentItemChangeEvent: CurrentItemChangeEvent,
    options: {
        frameShrinking?: ComputeScrollIntoViewOptions['frameShrinking'];
        triggeringActionPredicate?: (triggeringAction: StateUpdateReason) => boolean;
    } = {}
) {
    const {
        frameShrinking ,
        triggeringActionPredicate = listViewIsNotPointerInteractionStateUpdate
    } = options;

    const currentItemId = currentItemChangeEvent.currentItemId;

    if (currentItemId !== null) {
        if (triggeringActionPredicate(currentItemChangeEvent.triggeringAction)) {
            const itemElement = getItemElementById(currentItemChangeEvent.itemsList, currentItemId);

            if (itemElement) {
                const isSearchAction = currentItemChangeEvent.triggeringAction === StateUpdateReason.Search;

                scrollIntoView(itemElement, {
                    behavior: 'instant',
                    scrollMode: isSearchAction ? 'always' : 'if-needed',
                    block: isSearchAction ? 'center' : 'nearest',
                    frameShrinking: frameShrinking
                });
            }
        }
    }
}

export function listViewCreateItemSelectorFromItemElement (itemElement: Element) {
    const containingListViewId = assertNotNullable(itemElement.getAttribute(ContainingListViewIdAttributeName));
    const listViewItemId = assertNotNullable(itemElement.getAttribute(ListViewItemIdAttributeName));

    return `[${ContainingListViewIdAttributeName}="${containingListViewId}"][${ListViewItemIdAttributeName}="${listViewItemId.replace(/"/g, '\\"')}"]`;
}

export function listViewCreateRootElementSelector (listView: IListViewCommon) {
    return `[${ListViewIdAttributeName}="${listView.getId()}"]`
}