import {elementClosest} from "@wix/devzai-utils-dom";

export const UIOverlayElementAttribute = 'data-ui-overlay-element';

export function uiOverlayElementAttributes () {
    return {
        [UIOverlayElementAttribute]: 'true'
    }
}

export function uiOverlayElementContainsElement (element: Element) {
    return elementClosest(element, `[${UIOverlayElementAttribute}]`, true) !== null;
}