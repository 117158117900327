import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeSpeedDial} from "./doppe-action-type-speed-dial";

export const doppeActionTypeSpeedDialViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeSpeedDial, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeSpeedDialWidget: (
                await import('./doppe-action-type-speed-dial-widget/doppe-action-type-speed-dial-widget-new')
            ).DoppeActionTypeSpeedDialWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeSpeedDialWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeSpeedDialWidget
                {...renderProps}
            />
        )
    }
})