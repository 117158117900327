import {doppeActionTypeDefineViewerMetadata} from '../../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeAppleMusic} from '../doppe-action-type-apple-music';


export const doppeActionTypeAppleMusicViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeAppleMusic, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeAppleMusicWidget: (await import(
                '../doppe-action-type-apple-music-widget/doppe-action-type-apple-music-widget'
                )).DoppeActionTypeAppleMusicWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeAppleMusicWidget
        } = viewerComponents

        return (
            <DoppeActionTypeAppleMusicWidget
                {...renderProps}
            />
        )
    },

    activateCTA: actionContext => {
        actionContext.navigateToUrl(actionContext.action.settings.url, true);
    }
})