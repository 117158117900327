import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';

export namespace DoppeActionTypeNavigateButton {


    export interface ActionSettings {
        location: string;
        showContactPanel: boolean
    }
}

export const doppeActionTypeNavigateButton = doppeActionTypeDefineMetadata<DoppeActionTypeNavigateButton.ActionSettings>({
    id: 'io.bymo.action/navigate-button',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        location: '',
        showContactPanel: false,
    }),
    isWidgetOpener: false,
    hasNoLockInnerCTA: true,
});
