import {domLoadScript} from '@wix/devzai-utils-dom';
import type {User} from '@sentry/browser';

declare const Sentry:typeof import("@sentry/browser");

export async function sentryUtilsLoadSentry(options: {
    scriptUrl: string;
    environment: string;
    appType: string;
    appVersion?: string;
    getInitialScope: () => {
        user: User;
        tags?: Record<string, string | undefined>;
    };
}) {
    const {
        scriptUrl,
        environment,
        appType,
        appVersion,
        getInitialScope,
    } = options;

    // @ts-ignore
    window.sentryOnLoad = () => {
        Sentry.init({
            environment: environment,
            attachStacktrace: true,
            release: `${appType}@${appVersion || 'unknown'}`,
            // beforeSend(event) {
            //     return event;
            // }
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications',
            ],
        });
        const initialScope = getInitialScope();
        Sentry.setUser(initialScope.user);
        Sentry.setTags(initialScope.tags ?? {});
    };

    await domLoadScript(scriptUrl);

    // const originalConsoleError = console.error.bind(console);
    // console.error = (...args) => {
    //     originalConsoleError(...args);
    //     Sentry.captureException(new Error(`Console Error: ${args.join(' ')}`));
    // };
}
