import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';

export namespace DoppeActionTypeCallButton {


    export interface ActionSettings {
        phone: string;
        showContactPanel: boolean
    }
}

export const doppeActionTypeCallButton = doppeActionTypeDefineMetadata<DoppeActionTypeCallButton.ActionSettings>({
    id: 'io.bymo.action/call-button',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        phone: '',
        showContactPanel: false,
    }),
    isWidgetOpener: false,
    hasNoLockInnerCTA: true,
});
