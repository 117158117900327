import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {asArray, immerProduce} from '@wix/devzai-utils-common';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {DoppeDtoContactMethod} from '../../client-server-common/types/doppe-dto-contact-method';
import {DoppeActionFullName} from '../../client-server-common/types/doppe-action-full-name';

export namespace DoppeActionTypeSaveContact {

    export interface ActionSettings {
        media: WixImageResource | null;
        name: DoppeActionFullName;
        jobTitle: string;
        contactMethods: DoppeDtoContactMethod[];
        company: string;
        notes: string;
    }
}

export const doppeActionTypeSaveContact = doppeActionTypeDefineMetadata<DoppeActionTypeSaveContact.ActionSettings>({
    id: 'io.bymo.action/save-contact',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        contactMethods: [],
        media: null,
        name: {
            firstName: '',
            lastName: ''
        },
        jobTitle: '',
        company: '',
        notes: '',
    }),
    resolveUsedMedia: actionSettings => {
        return actionSettings.media ? asArray(actionSettings.media) : [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.media;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.media) {
                actionSettings.media = wixMediaResourceReplaceExternalImage(actionSettings.media, externalImagesUrlToImageResourceMap)
            }
        })
    },
    isWidgetOpener: false,
    hasNoLockInnerCTA: true,
});
