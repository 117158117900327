import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {doppeActionTypeCallButton} from './doppe-action-type-call-button';
import {BymoPageLayout} from '../../components/bymo-page-style/bymo-page-style';
import {DoppeViewerModalPanelLayout} from '../../components/doppe-viewer-modal-panel/doppe-viewer-modal-panel';
import {
    DoppeViewerModalOpeningAnimation
} from '../../components/doppe-viewer-modals-controller/doppe-viewer-modals-controller';
import {
    DoppeViewerContactMethodActionsPanel
} from '../../components/doppe-viewer-contact-method-action-panel/doppe-viewer-contact-method-action-panel';
import React from 'react';
import {asyncExecuteInBackground} from '@wix/devzai-utils-common';
import {downloadContactCard} from '../../client-server-common/types/doppe-dto-contact-details';
import {
    doppeDtoContactMethodLinkResolveValue,
    DoppeDtoContactMethods
} from '../../client-server-common/types/doppe-dto-contact-method';

export const doppeActionTypeCallButtonViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeCallButton, {

    getActionButtonProps: actionContext => {

        const action = actionContext.action;

        const value = action.settings.phone;
        const type = DoppeDtoContactMethods.Phone

        const showContactPanel = action.settings.showContactPanel;

        if (showContactPanel) {
            return {
                onClick:  () => {
                    actionContext.modalsController.showInnerModal({
                        openingAnimation: bymoPageLayout => {
                            const modalPanelLayout = bymoPageLayout === BymoPageLayout.Wide ?
                                DoppeViewerModalPanelLayout.Inner :
                                DoppeViewerModalPanelLayout.Drawer;

                            switch (modalPanelLayout) {
                                case DoppeViewerModalPanelLayout.Drawer: {
                                    return DoppeViewerModalOpeningAnimation.SlideInFromBottom;
                                }
                                default: {
                                    return DoppeViewerModalOpeningAnimation.FadeIn;
                                }
                            }
                        },
                        content: <DoppeViewerContactMethodActionsPanel
                            title={action.name}
                            type={type}
                            displayValue={value}
                            showNotification={(text: string) => {
                                actionContext.showNotification({
                                    message: text
                                })
                            }}
                            onClick={() => {
                            }}
                            saveAsContact={()=>{
                                asyncExecuteInBackground(async () => {
                                    await downloadContactCard({
                                        media: null,
                                        name: null,
                                        company: '',
                                        notes: '',
                                        jobTitle: '',
                                        contactMethods: [{
                                            type: type,
                                            displayValue: value,
                                            title: '',
                                            media: null,
                                            hidden: false,
                                            id: ''
                                        }],
                                    })
                                }, {});
                            }}
                        />
                    });

                },
            }
        }
        else {
            return  {
                href: doppeDtoContactMethodLinkResolveValue({
                    type: DoppeDtoContactMethods.Phone,
                    displayValue: action.settings.phone
                }),
                target: '_blank',
                rel: 'noopener',
            }
        }

    }
})