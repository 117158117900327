import React from 'react';
import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {doppeLockActionTypeSubscribe} from './doppe-lock-action-type-subscribe';

export const doppeLockActionTypeSubscribeViewer = doppeActionTypeDefineViewerMetadata(doppeLockActionTypeSubscribe, {

    loadViewerComponents: async () => {
        return {
            DoppeLockActionTypeSubscribeWidget: (await import('./doppe-lock-action-type-subscribe-widget/doppe-lock-action-type-subscribe-widget')).DoppeLockActionTypeSubscribeWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeLockActionTypeSubscribeWidget
        } = viewerComponents;

        return (
            <DoppeLockActionTypeSubscribeWidget
                {...renderProps}
            />
        )
    }
})