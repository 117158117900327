import {
    arrayFlatten,
    cachedValueCreate,
    enumGetValues,
    enumReduce,
    LanguageCodes,
    LanguageScript,
    languageScriptFilterSupportedLanguageCodes,
    Values
} from "@wix/devzai-utils-common";

export const DoppeViewerLang = enumReduce(LanguageCodes, [
    LanguageCodes.English,
    LanguageCodes.Hebrew,

    LanguageCodes.Czech,
    LanguageCodes.Danish,
    LanguageCodes.German,
    LanguageCodes.Spanish,
    LanguageCodes.French,
    LanguageCodes.Hindi,
    LanguageCodes.Indonesian,
    LanguageCodes.Italian,
    LanguageCodes.Japanese,
    LanguageCodes.Korean,
    LanguageCodes.Lithuanian,
    LanguageCodes.Dutch,
    LanguageCodes.Norwegian,
    LanguageCodes.Polish,
    LanguageCodes.Portuguese,
    LanguageCodes.Russian,
    LanguageCodes.Swedish,
    LanguageCodes.Thai,
    LanguageCodes.Turkish,
    LanguageCodes.Ukrainian,
    LanguageCodes.Vietnamese,
    LanguageCodes.Chinese,
    LanguageCodes.Arabic,
    LanguageCodes.Bulgarian,
    LanguageCodes.Catalan,
    LanguageCodes.Greek,
    LanguageCodes.Finnish,
    LanguageCodes.Croatian,
    LanguageCodes.Hungarian,
    LanguageCodes.Latvian,
    LanguageCodes.Malay,
    LanguageCodes.Romanian,
    LanguageCodes.Slovak,
    LanguageCodes.Slovenian,
    LanguageCodes.Tagalog,
])

export type DoppeViewerLang = Values<typeof DoppeViewerLang>;

const LazyDoppeViewerSupportedLanguagesInApp = cachedValueCreate(() => {
    return arrayFlatten([
        LanguageScript.Latin,
        LanguageScript.Hebrew,
        LanguageScript.Arabic,
        LanguageScript.Vietnamese,
        LanguageScript.Devanagari,
        LanguageScript.Thai,
        LanguageScript.Chinese,
        LanguageScript.Cyrillic,
        LanguageScript.Greek,
        LanguageScript.Japanese,
        LanguageScript.Korean,
    ].map(languageScript => doppeViewerLangGetSupportedByLanguageScript(languageScript)));
})

export function doppeViewerLangGetSupportedLanguagesInApp () {
    return LazyDoppeViewerSupportedLanguagesInApp.getValue();
}

export function doppeViewerLangIsSupportedInApp (doppeViewerLang: DoppeViewerLang) {
    return doppeViewerLangGetSupportedLanguagesInApp().includes(doppeViewerLang);
}

const allDoppeViewerLangs = enumGetValues(DoppeViewerLang);

export function doppeViewerLangGetSupportedByLanguageScript (languageScript: LanguageScript) {
    return languageScriptFilterSupportedLanguageCodes(languageScript, allDoppeViewerLangs)
}