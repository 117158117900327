import React, {useContext} from "react";
import {DoppeActionType} from "../../client-server-common/doppe-action-types/doppe-action-type";
import {assertNotNullable} from "@wix/devzai-utils-common";

export type BymoPageViewerComponentsContextData = {
    actionTypesViewerComponents: Map<DoppeActionType<{}>['id'], unknown>;
}

export const BymoPageViewerComponentsContext = React.createContext<BymoPageViewerComponentsContextData | null>(null);

export function useBymoPageViewerComponentsContext () {
    return assertNotNullable(useContext(BymoPageViewerComponentsContext));
}