import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeEvents} from './doppe-action-type-events';

export const doppeActionTypeEventsViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeEvents, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeEventsWidget: (await import(
                './doppe-action-type-events-widget/doppe-action-type-events-widget'
                )).DoppeActionTypeEventsWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeEventsWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeEventsWidget
                {...renderProps}
            />
        );

    }
})