import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import {doppeActionTypeBanner} from "./doppe-action-type-banner";
import React from 'react';

export const doppeActionTypeBannerViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeBanner, {
    loadViewerComponents: async () => {
        return {
            DoppeActionTypeBannerWidget: (await import('./doppe-action-type-banner-widget/doppe-action-type-banner-widget')).DoppeActionTypeBannerWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeBannerWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeBannerWidget
                {...renderProps}
            />
        );

    },

})