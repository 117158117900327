import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeEmbedPageList} from './doppe-action-type-embed-page-list';

export const doppeActionTypeEmbedPageListViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeEmbedPageList, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeEmbedPageListWidget: (await import('./doppe-action-type-embed-page-list-widget/doppe-action-type-embed-page-list-widget')).DoppeActionTypeEmbedPageListWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeEmbedPageListWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeEmbedPageListWidget
                {...renderProps}
            />
        );

    }
})