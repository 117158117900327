import {CurrencyCode, currencyCodes} from "./currency-data";
import {proxyCreateEnumFromValues} from "../proxy-utils/proxy-utils";
import {currencyLocales} from '../stripe-currency-utils/stripe-currency-utils';

export type {CurrencyCode} from './currency-data'

export function currencyGetAllCodes () {
    return currencyCodes;
}

/**
 * ISO4217 currency codes.
 */
export const CurrencyCodes = proxyCreateEnumFromValues<CurrencyCode>();

export function currencyGetSymbolForDisplay (code: CurrencyCode) {
    const formatted = new Intl.NumberFormat(currencyLocales[code] || 'en-US', {
        style: 'currency',
        currency: code,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(0);

    return formatted.replace('0', '').trim();
}

export function currencyFormatPrice (
    options: {
        code: CurrencyCode;
        price: number;
        removeFractionWhenPossible?: boolean
        locale?: string
    }
) {
    const {
        code,
        price,
        removeFractionWhenPossible = true, //removes fraction when possible, e.g. 1.00 -> 1
        locale,
    } = options;

    return new Intl.NumberFormat(locale ?? 'en-US', {
        style: 'currency',
        currency: code,
        ...(removeFractionWhenPossible && (Math.round(price * 100) / 100) % 1 === 0 ? {minimumFractionDigits: 0, maximumFractionDigits: 0} : undefined)
    }).format(price)
}

export function convertCurrencyToSmallestUnit(
    options: {
        currencyCode: CurrencyCode;
        amount: number;
    }
) {
    const {
        currencyCode,
        amount
    } = options;
    const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
    }).format(amount);

    return Number(formattedValue.replace(/[^\d-]/g, ''));
}

export function convertCurrencyFromSmallestUnit(
    options: {
        currencyCode: CurrencyCode;
        amount: number;
    },
) {
    const {
        currencyCode,
        amount,
    } = options;

    const decimalPlaces = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
    }).resolvedOptions().maximumFractionDigits;

    return amount / (10 ** decimalPlaces);
}

export type Price = {
    amount: number;
    currencyCode: CurrencyCode;
}