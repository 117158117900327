import {EqualityComparer, equalityComparerCompare, evaluateWhenFunction} from '@wix/devzai-utils-common';
import {Dispatch, SetStateAction, useCallback} from 'react';
import {useRenderState} from './use-boxed-value/use-boxed-value';
import {useForceUpdate} from './use-force-update';
import {useRefWithInitializer} from './use-data-member';

export function useStateWithCustomComparer<S>(
    initialState: S | (() => S),
    equalityComparer: EqualityComparer<S>
): [S, Dispatch<SetStateAction<S>>] {

    const valueRef = useRefWithInitializer<S>(initialState);

    const forceUpdate = useForceUpdate();

    const renderState = useRenderState({
        equalityComparer: equalityComparer,
    })

    const setValueWithCustomComparer = useCallback(
        (newValue: S | ((prevState: S) => S)) => {

            const prevState = valueRef.current;
            const evaluatedNewValue = evaluateWhenFunction(newValue, prevState);

            if (!equalityComparerCompare(renderState.current.equalityComparer, evaluatedNewValue, prevState)) {
                valueRef.current = evaluatedNewValue;
                forceUpdate();
            }
        },
        [renderState, valueRef, forceUpdate]
    );

    return [valueRef.current, setValueWithCustomComparer];
}
