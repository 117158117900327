import React from 'react';
import {AnyObject} from '@wix/devzai-utils-common';
import {DoppeDtoAction} from '../../client-server-common/types/doppe-dto-action';
import {IDoppeViewerActionContext} from '../doppe-viewer-action-context/doppe-viewer-action-context';


export interface DoppeLockWizard {

    showWizard: () => void;

    closeWizard () : void;
}

export namespace DoppeLockWizard {

    export interface DoppeLockWizardContextProps {
        lockedAction: DoppeDtoAction;
        actionContext: IDoppeViewerActionContext<any>;
        unlockedCallback: () => void;
    }

    export interface Props<TEMPLATE_PROPS extends {} = Record<string, any>> extends DoppeLockWizardContextProps{
        templateProps: TEMPLATE_PROPS;
        enableAnimation: boolean;
    }
}

export interface DoppeLockWizardTemplateMetadata<PARAMS extends AnyObject> {
    initialValues: PARAMS;
}



export interface DoppeLockWizardTemplateDefinition<PARAMS extends AnyObject> extends DoppeLockWizardTemplateMetadata<PARAMS> {
    componentType: React.ComponentType<DoppeLockWizard.Props<PARAMS>>;
}

export function doppeLockWizardTemplateDefine<PARAMS extends AnyObject> (
    metadata: DoppeLockWizardTemplateMetadata<PARAMS>,
    componentType: React.ComponentType<DoppeLockWizard.Props<PARAMS>>
) : DoppeLockWizardTemplateDefinition<PARAMS> {
    return {
        ...metadata,
        componentType: componentType
    };
}

export function doppeLockWizardTemplateCreateMetadata<PARAMS extends AnyObject> (
    metadata: DoppeLockWizardTemplateMetadata<PARAMS>
) {
    return metadata;
}

export function dopeALockWizardTemplateResolveProps<PARAMS extends AnyObject> (
    actionButtonTemplateMetadata: DoppeLockWizardTemplateMetadata<PARAMS>,
    partialProps: Partial<PARAMS>
) : PARAMS {
    return {
        ...actionButtonTemplateMetadata.initialValues,
        ...partialProps
    }
}