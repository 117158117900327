import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypePaidMessage} from './doppe-action-type-paid-message';

export const doppeActionTypePaidMessageViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypePaidMessage, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypePaidMessageWidget: (await import('./doppe-action-type-paid-message-widget/doppe-action-type-paid-message-widget')).DoppeActionTypePaidMessageWidget
        }
    },
    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypePaidMessageWidget
        } = viewerComponents;

        return <DoppeActionTypePaidMessageWidget {...renderProps} />;
    }
})