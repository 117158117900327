import React, {ReactChild} from 'react';
import {ListViewStateController} from './list-view-state-controller';
import {ListViewDefaultKeymapController} from './list-view-default-keymap-controller';
import {NavigationScopeReducingBySearchStrategy, SearchMatchingFunction} from './list-view-search';
import {OmitStrict} from '@wix/devzai-utils-common';
import {ListViewComposable} from "./list-view-composable";
import {reactIsValidReactElement} from "../react-utils/react-utils";

export type ListViewItemId = string | number;

export function isValidListViewItemId(value: any): value is ListViewItemId {
    return typeof value === 'string' || typeof value === 'number';
}

export interface ListViewItemMetadata {
    id: ListViewItemId;
    typeAheadText?: string;
    isSelectable?: boolean;
    isExcluded?: boolean;
    isDisabled?: boolean;
}

export interface ListViewDataSourceDataItem<T> extends ListViewItemMetadata {
    dataItem: T;
}

export function listViewDataItemIsSelectable(listViewDataItem: ListViewDataSourceDataItem<any>): boolean {
    return listViewDataItem.isSelectable ?? true;
}

export type ListViewChild<T> =
    | ListViewDataSourceDataItem<T>
    | ReactChild
    | ReactChild[]
    | ListViewDataSourceDataItem<T>[];

export enum NavigationOrientation {
    Vertical = 1,
    Horizontal = 2
}

export enum TypeAheadNavigationType {
    None = 1,
    StayOnCurrent = 2,
    GoToNext = 3
}

export enum ListViewSelectionType {
    None = 1,
    Single = 2,
    Multiple = 3
}

export enum CurrentItemType {
    None = 1,
    Selectable = 2,
    NoneSelectable = 3
}

export interface TypeAheadStrategy {
    typeAheadNavigationType: TypeAheadNavigationType;
    typeAheadClearTimeout: number;
}

export const DefaultTypeAheadStrategy = {
    typeAheadNavigationType: TypeAheadNavigationType.StayOnCurrent,
    typeAheadClearTimeout: 1000
};

export const DefaultGoToNextTypeAheadStrategy = {
    typeAheadNavigationType: TypeAheadNavigationType.GoToNext,
    typeAheadClearTimeout: 1000
};

export const ListViewDefaultState: ListViewState = {
    selectedIds: null,
    disabledIds: null,
    selectionStartId: null,
    currentNavigatableItemId: null,
    typeAheadValue: ''
};

export interface ListViewState {
    selectedIds: Array<ListViewItemId> | null;
    disabledIds: Array<ListViewItemId> | null;
    currentNavigatableItemId: ListViewItemId | null;
    selectionStartId: ListViewItemId | null;
    typeAheadValue: string | null;
}

export enum StateUpdateReason {
    Manual,
    KeyboardNavigation,
    PointerInteraction,
    TypeAhead,
    Search
}

export interface CurrentItemChangeEvent<T = any> {
    currentItemId: ListViewItemId | null;
    itemsList: IListViewCommon<T>;
    triggeringAction: StateUpdateReason;
}

export interface ListViewSelectionChangeEvent {
    selectedIds: ListViewItemId[];
    itemsList: IListViewCommon;
    triggeringAction: StateUpdateReason;
}

export type ListViewSupportedHtmlAttributes = OmitStrict<React.HTMLAttributes<any>, 'onChange'>;

/**
 * Properties that are common to all the components that behave like a list view (e.g. ListView, TreeView, etc.)
 */
export interface ListViewBasedComponentsCommonProps<T = any> extends ListViewSupportedHtmlAttributes {
    selectionType: ListViewSelectionType;
    isFocusable: boolean;
    tagName: string;
    listViewElementRef?: React.Ref<HTMLElement>;
    isCyclic: boolean;
    autoFocus?: boolean;
    typeAheadStrategy: TypeAheadStrategy | null;
    searchMatchingFunction: SearchMatchingFunction | null;
    /**
     * Determines how navigation scope inside a list is reduced by a search text.
     * When the navigation scope is reduced to a set of items,
     * the keyboard navigation moves only between the items in this set.
     */
    navigationScopeReducingBySearchStrategy: NavigationScopeReducingBySearchStrategy;
    searchText: string | undefined;

    keymapController: IListViewKeymapController;

    forceRenderNavigatableItem?: (navigatableItemId: ListViewItemId) => void;

    onCurrentItemChange?: (event: CurrentItemChangeEvent<T>) => void;

    onSelectionChange?: (event: ListViewSelectionChangeEvent) => void;
}

export const DefaultCommonListViewProps = {
    isFocusable: true,
    selectionType: ListViewSelectionType.None,
    tagName: 'div',
    isCyclic: false,
    autoFocus: false,
    typeAheadStrategy: DefaultTypeAheadStrategy,
    keymapController: new ListViewDefaultKeymapController(),
    searchMatchingFunction: null,
    searchText: undefined,
    navigationScopeReducingBySearchStrategy: NavigationScopeReducingBySearchStrategy.NeverReduce
};

export interface ListViewRenderItemProps<T> {
    /**
     * Indicates of whether the rendered item is selected.
     */
    isSelected: boolean;
    /**
     * Indicates of whether the rendered item is the current item in its list view navigation.
     * There is at most one item in a list view that is marked as current.
     */
    isCurrent: boolean;
    /**
     * Indicates of whether the rendered item is disabled.
     */
    isDisabled: boolean;

    itemIndex: number;

    /**
     * Indicates of whether the rendered item is the one that's focused when the list view is focused.
     */
    isFocusingCursor: boolean;

    dataItemId: ListViewItemId;
    dataItem: T;

    tabIndex?: number;
    listViewId: string;

    listView: IListView;
}

export type ListViewStateUpdateFunction = (stateController: ListViewStateController) => void;

export function defaultDataItemsEqualityComparer<T>(dataItem1: T, dataItem2: T) {
    return dataItem1 === dataItem2;
}

export function isListViewDataSourceDataItem<T>(item: ListViewChild<T>): item is ListViewDataSourceDataItem<T> {
    return !reactIsValidReactElement(item) && typeof item !== 'string' && typeof item !== 'number';
}

/**
 * Should be implemented by all the components that provide functionality that's based on the ListView infrastructure.
 * For example, a component that uses ListView to provide Tree functionality should implement this interface.
 */
export interface IListViewCommon<T = any> {
    getRootElement(): HTMLElement | null;

    getId(): string;

    getDataItemById(itemId: ListViewItemId): T | undefined;

    getListViewItemById(itemId: ListViewItemId): ListViewDataSourceDataItem<T> | undefined;

    getOrderedListViewItemsIds(): ListViewItemId[];

    getListViewItemsArray () : ListViewDataSourceDataItem<T>[];

    handleKeyboardEvent(event: React.KeyboardEvent<Element>): void;

    moveToNextSearchMatchingItem(searchText: string, selectItem?: boolean): void;

    findItemIndex(itemId: ListViewItemId): number;

    getItemsNodeList(): NodeListOf<HTMLElement> | undefined;

    getItemsElements(): HTMLElement[];

    focus(): void;

    getListViewState(): ListViewState;

    handleItemPointerInteraction(listViewItemId: ListViewItemId, event: React.MouseEvent<Element>): void;

    getSelectionType(): ListViewSelectionType;
}

export interface IListView<T = any> extends IListViewCommon<T> {
    updateState(updateFunction: ListViewStateUpdateFunction, reason?: StateUpdateReason): boolean;
}

export interface IListViewKeymapController {
    resolveKeyboardAction(
        event: React.KeyboardEvent,
        listViewComposable: ListViewComposable,
        navigationOrientation: NavigationOrientation,
        selectionType: ListViewSelectionType,
        currentItemType: CurrentItemType,
        supportTypeAhead: boolean
    ): [ListViewNavigationAction, ListViewSelectionAction] | null;

    isCtrlKey(event: React.KeyboardEvent | React.MouseEvent): boolean;

    isShiftKey(event: React.KeyboardEvent | React.MouseEvent): boolean;
}

export enum ListViewNavigationActionType {
    None,
    MoveToNext,
    MoveToPrevious,
    MoveToFirst,
    MoveToLast,
    MoveAccordingToTypeAhead,

    MoveForward,
    MoveBackward
}

export type ListViewNavigationAction = {
    type: Exclude<ListViewNavigationActionType, ListViewNavigationActionType.MoveForward | ListViewNavigationActionType.MoveBackward>
} | {
    type: ListViewNavigationActionType.MoveForward | ListViewNavigationActionType.MoveBackward;
    delta: number;
}

export enum ListViewSelectionAction {
    None,
    SelectAll,
    UnselectAll,
    MoveSelection,
    AddCurrentToSelection,
    SelectCurrent,
    ToggleCurrent,
    SelectRange,
    AddRangeToSelection,
    SelectRangeUntilCurrent,
    AddRangeUntilCurrentToSelection
}

export function listViewIsNotPointerInteractionStateUpdate (triggeringAction: StateUpdateReason) {
    return triggeringAction !== StateUpdateReason.PointerInteraction;
}

export function listViewIsNotPointerOrManualInteractionStateUpdate (triggeringAction: StateUpdateReason) {
    return (
        triggeringAction !== StateUpdateReason.PointerInteraction &&
        triggeringAction !== StateUpdateReason.Manual
    )
}