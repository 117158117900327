// @ts-ignore
import {createBrowserHistory as createBrowserHistoryForked} from './history-forked';

export interface History<HistoryLocationState = LocationState> {
    length: number;
    action: History.Action;
    location: History.Location<HistoryLocationState>;
    push(path: Path, state?: HistoryLocationState): void;
    push(location: LocationDescriptor<HistoryLocationState>): void;
    replace(path: Path, state?: HistoryLocationState): void;
    replace(location: LocationDescriptor<HistoryLocationState>): void;
    go(n: number): void;
    goBack(): void;
    goForward(): void;
    block(prompt?: boolean | string | TransitionPromptHook<HistoryLocationState>): History.UnregisterCallback;
    listen(listener: LocationListener<HistoryLocationState>): History.UnregisterCallback;
    createHref(location: LocationDescriptorObject<HistoryLocationState>): Href;
}

interface LocationDescriptorObject<S = LocationState> {
    pathname?: Pathname | undefined;
    search?: Search | undefined;
    state?: S | undefined;
    hash?: Hash | undefined;
    key?: LocationKey | undefined;
}

export namespace History {
    export type LocationDescriptor<S = LocationState> = Path | LocationDescriptorObject<S>;
    export type LocationKey = string;
    export type LocationListener<S = LocationState> = (location: Location<S>, action: Action) => void;
    export type UnregisterCallback = () => void;
    export type Action = 'PUSH' | 'POP' | 'REPLACE';
    export interface Location<S = LocationState> {
        pathname: Pathname;
        search: Search;
        state: S;
        hash: Hash;
        key?: LocationKey | undefined;
    }

    export type LocationState = unknown;
    export type Path = string;
    export type Pathname = string;
    export type Search = string;
    export type TransitionHook<S = LocationState> = (location: Location<S>, callback: (result: any) => void) => any;
    export type TransitionPromptHook<S = LocationState> = (
        location: Location<S>,
        action: Action,
    ) => string | false | void;
    export type Hash = string;
    export type Href = string;
}

type LocationDescriptor<S = LocationState> = History.LocationDescriptor<S>;
type LocationKey = History.LocationKey;
type LocationListener<S = LocationState> = History.LocationListener<S>;
type LocationState = History.LocationState;
type Path = History.Path;
type Pathname = History.Pathname;
type Search = History.Search;
type TransitionPromptHook<S = LocationState> = History.TransitionPromptHook<S>;
type Hash = History.Hash;
type Href = History.Href;

export interface BrowserHistoryBuildOptions {
    basename?: string;
    forceRefresh?: boolean;
    getUserConfirmation?: (message: string, callback: (result: boolean) => void) => void;
    keyLength?: number;
}

export function browserHistoryCreate<S = LocationState>(options?: BrowserHistoryBuildOptions): History<S> {
    return (createBrowserHistoryForked as any)(options);
}