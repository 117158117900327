import React from "react";
import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import {doppeActionTypeContactFormMetadata} from "./doppe-action-type-contact-form-metadata";

export const doppeActionTypeContactFormViewerMetadata = doppeActionTypeDefineViewerMetadata(doppeActionTypeContactFormMetadata, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeContactFormWidget: (await import('./doppe-action-type-contact-form-widget/doppe-action-type-contact-form-widget')).DoppeActionTypeContactFormWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeContactFormWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeContactFormWidget
                {...renderProps}
            />
        )
    }
})