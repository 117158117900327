import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeProducts} from "./doppe-action-type-products";

export const doppeActionTypeProductsViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeProducts, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeProductsWidget: (await import(
                './doppe-action-type-products-widget/doppe-action-type-products-widget'
                )).DoppeActionTypeProductsWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeProductsWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeProductsWidget
                {...renderProps}
            />
        );
    }
})