export function mapFilter<K, V> (map: Map<K, V>, predicate: (key: K, value: V) => boolean) : Map<K, V> {


    const result = new Map<K, V>();

    for (const [key, value] of map) {
        if (predicate(key, value)) {
            result.set(key, value);
        }
    }

    return result;
}

export function mapPushToMultiValuesArray<K, V> (map: Map<K, V[]>, key: K, value: V) {
    mapAddToMultiValuesArray(map, key, value, (valuesArr, value) => valuesArr.push(value));
}

export function mapPushToDistinctMultiValuesArray<K, V> (map: Map<K, V[]>, key: K, value: V) {
    mapAddToMultiValuesArray(map, key, value, (valuesArr, value) => {
        if (!valuesArr.includes(value)) {
            valuesArr.push(value)
        }
    });
}

export function mapAddToMultiValuesArray<K, V> (
    map: Map<K, V[]>,
    key: K,
    value: V,
    insertionFunction: (valuesArr: V[], value: V) => void
) {
    let values = map.get(key);

    if (values === undefined) {
        values = [];
        map.set(key, values);
    }

    insertionFunction(values, value);
}

export function mapToObject<V> (map: Map<number | string | boolean, V>) : Record<string, V> {

    const result: Record<string, V> = {};

    for (const [key, value] of map) {
        result[key.toString()] = value;
    }

    return result;

}

export function mapFromObject<K extends string | number | symbol, V> (obj: Record<K, V>) : Map<K, V> {
    return new Map(Object.entries(obj) as [K, V][]);
}

export function mapCreateFromIterable<K, V> (
    iterable: Iterable<V>,
    keyFunction: (value: V) => K
) : Map<K, V>;
export function mapCreateFromIterable<K, V, V2> (
    iterable: Iterable<V>,
    keyFunction: (value: V) => K,
    valueFunction: (value: V) => V2
) : Map<K, V2>;
export function mapCreateFromIterable<K, V, V2> (
    iterable: Iterable<V>,
    keyFunction: (value: V) => K,
    valueFunction?: (value: V) => V2
) : Map<K, V | V2> {
    const result = new Map<K, V | V2>();

    for (const value of iterable) {
        const key = keyFunction(value);

        if (result.has(key)) {
            throw new Error(`Multiple values were mapped to the same key.`)
        }

        result.set(key, valueFunction ? valueFunction(value) : value);
    }

    return result;
}

export function mapCreateFromIterableWithDuplicationOverride<K, V> (
    iterable: Iterable<V>,
    keyFunction: (value: V) => K
) : Map<K, V>;
export function mapCreateFromIterableWithDuplicationOverride<K, V, V2> (
    iterable: Iterable<V>,
    keyFunction: (value: V) => K,
    valueFunction: (value: V) => V2
) : Map<K, V2>;
export function mapCreateFromIterableWithDuplicationOverride<K, V, V2> (
    iterable: Iterable<V>,
    keyFunction: (value: V) => K,
    valueFunction?: (value: V) => V2
) : Map<K, V | V2> {
    const result = new Map<K, V | V2>();

    for (const value of iterable) {
        const key = keyFunction(value);

        result.set(key, valueFunction ? valueFunction(value) : value);
    }

    return result;
}

export function mapCreateMultiValuesFromIterable<K, V> (
    iterable: Iterable<V>,
    keyFunction: (value: V) => K
) : Map<K, V[]>;
export function mapCreateMultiValuesFromIterable<K, V, V2> (
    iterable: Iterable<V>,
    keyFunction: (value: V) => K,
    valueFunction: (value: V) => V2
) : Map<K, V2[]>;
export function mapCreateMultiValuesFromIterable<K, V, V2> (
    iterable: Iterable<V>,
    keyFunction: (value: V) => K,
    valueFunction?: (value: V) => V2
) : Map<K, (V | V2)[]> {
    const result = new Map<K, (V | V2)[]>();

    for (const value of iterable) {
        const key = keyFunction(value);

        mapPushToMultiValuesArray(result, key, valueFunction ? valueFunction(value) : value);
    }

    return result;
}

