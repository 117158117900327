import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeEvent} from "./doppe-action-type-event";

export const doppeActionTypeEventViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeEvent, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeEventWidget: (await import('./doppe-action-type-event-widget/doppe-action-type-event-widget')).DoppeActionTypeEventWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeEventWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeEventWidget
                {...renderProps}
            />
        );

    },

    activateCTA: actionContext => {
        actionContext.navigateToUrl(actionContext.action.settings.eventLink, true);
    }
})