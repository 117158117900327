import {CookieConfigMap} from "@wix/devzai-utils-dom/src/cookie-storage-accessor/cookie-storage-accessor";
import {Values} from '@wix/devzai-utils-common';


export const SupportedCookieNames = {
    ClientId: 'clientId',
    SessionId: 'sessionId',
    ConsentPolicy: 'consent-policy',
    PagePath: 'pagePath',
    UnlockedActions: 'unlockedActions',
} as const;

export type SupportedCookieNames = Values<typeof SupportedCookieNames>;

export const MaxAgeMaxValue = 2147483647;

export const SupportedCookies: CookieConfigMap<SupportedCookieNames> = {
    'clientId': {
        maxAge: MaxAgeMaxValue,
        sameSite: 'strict',
        consentType: 'anl',
    },
    'sessionId': {
        maxAge: 1800, // 30 minutes
        sameSite: 'strict',
        consentType: 'anl',
    },
    'consent-policy': {
        maxAge: MaxAgeMaxValue,
        sameSite: 'strict',
        consentType: 'anl',
    },
    'pagePath': {
        maxAge: 3600,
        sameSite: 'strict',
        consentType: 'anl',
        path: '/',
        domain: ['hopp.bio', 'hopp.to', 'bymo.io', 'doppe-viewer-sec.com']
    },
    'unlockedActions': {
        maxAge: MaxAgeMaxValue,
        sameSite: 'strict',
        consentType: 'ess',
    }
} as const;
