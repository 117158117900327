import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeActionTypeSubscribeViewerLang} from './doppe-action-type-subscribe-viewer.lang';
import {immerProduce, Values} from '@wix/devzai-utils-common';
import {
    DoppeFormActionSettings,
    getDefaultDoppeFormActionSettings, prepareFormSettingsForViewer
} from '../../client-server-common/types/doppe-form-action-settings';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {doppePremiumFeatureIsDisabled, DoppePremiumFeatures} from '../../client-server-common';

export const SubscribeActionFormSubscriptionOptions = {
    Email: 'Email',
    Phone: 'Phone'
} as const;

export type SubscribeActionFormSubscriptionOptions = Values<typeof SubscribeActionFormSubscriptionOptions>;

export namespace DoppeActionTypeSubscribe {
    export interface ActionSettings extends DoppeFormActionSettings {
        enableEmailSubscribeOption: boolean;
        enablePhoneSubscribeOption: boolean;
        defaultSubscribeOption: SubscribeActionFormSubscriptionOptions;
        subscribeButtonText: string;
        images: WixMediaResource[] | null;
    }
}

export const doppeActionTypeSubscribeMetaData = doppeActionTypeDefineMetadata<DoppeActionTypeSubscribe.ActionSettings>({
    id: 'io.bymo.action/subscribe',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        enableEmailSubscribeOption: true,
        enablePhoneSubscribeOption: false,
        defaultSubscribeOption: SubscribeActionFormSubscriptionOptions.Email,
        subscribeButtonText: DoppeActionTypeSubscribeViewerLang.defaultTexts.subscribeButtonText,
        images: null,
        ...getDefaultDoppeFormActionSettings()
    }),
    isFormAction: true,
    isPremiumAction: (premiumFeaturesStatus) => {
        return doppePremiumFeatureIsDisabled(premiumFeaturesStatus, DoppePremiumFeatures.creators_form_submission)
    },
    isWidgetOpener: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    canRenderInPage: true,
    prepareForViewer: actionSettings => {
        prepareFormSettingsForViewer(actionSettings);
    },
    resolveUsedMedia: actionSettings => {
        return actionSettings.images ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.images ? actionSettings.images[0] ?? null : null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.images) {
                actionSettings.images = actionSettings.images.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
})