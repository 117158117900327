import {CookieAttributes} from "@wix/devzai-utils-common";
import {cookiesGetItem, cookiesRemoveItem, cookiesSetItem} from "@wix/devzai-utils-dom";

export interface ICookieStorage {
    getItem(key: string): string | undefined;
    setItem(key: string, value: string, attributes?: CookieAttributes): void;
    removeItem(key: string): void;
}

export class CookieStorage implements ICookieStorage {
    getItem(key: string): string | undefined {
        return cookiesGetItem(key);
    }

    setItem(key: string, value: string, attributes?: CookieAttributes): void {
        cookiesSetItem(key, value, attributes);
    }

    removeItem(key: string): void {
        cookiesRemoveItem(key);
    }
}

// Used to mimick cookie storage in tests and preview.
export class MemoryCookieStorage implements ICookieStorage {
    private storage: Record<string, string> = {};

    getItem(key: string): string | undefined {
        return this.storage[key];
    }

    // @ts-ignore
    setItem(key: string, value: string, attributes?: CookieAttributes): void {
        this.storage[key] = value;
    }

    removeItem(key: string): void {
        delete this.storage[key];
    }
}
