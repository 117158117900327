import {enumGetValues, evaluateFunction, Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity, DoppeBusinessEntityReference} from "./doppe-business-entity";

/**
 * An enum of all the external providers that can provide a product data.
 * For example: Wix, Amazon, Shopify.
 */
export const DoppeExternalEventProvider = {
    Wix: 'wix',
} as const;

export type DoppeExternalEventProvider = Values<typeof DoppeExternalEventProvider>;

// TODO: This field should include all the possible SEMANTIC fields that an external Event providers can provide
// TODO: for a Event.
export const DoppeExternalEventField = {
    EventPageUrl: 'EventPageUrl',
    EventStartDate: 'EventStartDate',
    Locations: 'Locations',
} as const;

export type DoppeExternalEventField = Values<typeof DoppeExternalEventField>;

const AllExternalEventProviders = enumGetValues(DoppeExternalEventProvider);

const externalEventFieldsToSupportedProviders = {
    [DoppeExternalEventField.Locations]: AllExternalEventProviders,
    [DoppeExternalEventField.EventPageUrl]: AllExternalEventProviders,
    [DoppeExternalEventField.EventStartDate]: AllExternalEventProviders,
} satisfies {
    [field in DoppeExternalEventField]: DoppeExternalEventProvider[]
}

export function doppeExternalEventProviderIsFieldProvided (
    externalEventProvider: DoppeExternalEventProvider | null | undefined,
    externalEventField: DoppeExternalEventField
) {
    if (externalEventProvider === null || externalEventProvider === undefined) {
        return false;
    }

    return externalEventFieldsToSupportedProviders[externalEventField].includes(externalEventProvider);
}

export function doppeExternalEventProviderResolveBusinessEntityReference (
    externalItemProvider: DoppeExternalEventProvider,
    externalItemId: string
) {
    return evaluateFunction(() => {
        switch (externalItemProvider) {
            case DoppeExternalEventProvider.Wix: {
                return {
                    businessEntity: DoppeBusinessEntity.WixEvent,
                    id: externalItemId
                }
            }
        }
    }) satisfies DoppeBusinessEntityReference
}