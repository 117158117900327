import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueModifyValue
} from './doppe-hideable-value';
import {DoppeDtoBymoSubscribeListId} from './doppe-dto-bymo-subscribe-list';

export interface DoppeFormActionSettings {
    listId: DoppeDtoBymoSubscribeListId | null;
    customThankYouMessage: DoppeHideableValue<string>;
    withEmailNotification: boolean;
    showConsentCheckbox: boolean;
    customConsentText: DoppeHideableValue<string>;
    customEmailForNotifications: DoppeHideableValue<string>;
}

export function getDefaultDoppeFormActionSettings(overrides?: Partial<DoppeFormActionSettings>) {
    return {
        listId: null,
        withEmailNotification: true,
        customThankYouMessage: doppeHideableValueCreateHidden(''),
        showConsentCheckbox: false,
        customConsentText: doppeHideableValueCreateHidden(''),
        customEmailForNotifications: doppeHideableValueCreateHidden(''),
        ...overrides,
    }
}

export function prepareFormSettingsForViewer (settings: DoppeFormActionSettings) {
    settings.customEmailForNotifications = doppeHideableValueModifyValue(settings.customEmailForNotifications, '');
}
