// Standard, based on CSSOM View spec
import { computeScrollIntoView, CustomScrollAction, ComputeScrollIntoViewOptions } from './compute-scroll-into-view';

export type ScrollBehavior = 'auto' | 'instant' | 'smooth';

export type CustomScrollBehaviorCallback<T> = (actions: CustomScrollAction[]) => T;

export interface StandardBehaviorOptions extends ComputeScrollIntoViewOptions {
    behavior?: ScrollBehavior;
}
export interface CustomBehaviorOptions<T> extends ComputeScrollIntoViewOptions {
    behavior: CustomScrollBehaviorCallback<T>;
}

function isOptionsObject<T>(options: any): options is T {
    return options === Object(options) && Object.keys(options).length !== 0;
}

function defaultBehavior(actions: CustomScrollAction[], behavior: ScrollBehavior = 'auto') {
    const canSmoothScroll = 'scrollBehavior' in document.body.style;

    actions.forEach(({ el, top, left }) => {
        // browser implements the new Element.prototype.scroll API that supports `behavior`
        // and guard window.scroll with supportsScrollBehavior
        if (el.scroll && canSmoothScroll) {
            el.scroll({ top, left, behavior: behavior !== 'instant' ? behavior : undefined });
        } else {
            el.scrollTop = top;
            el.scrollLeft = left;
        }
    });
}

function getOptions(options: any): StandardBehaviorOptions {
    // Handle alignToTop for legacy reasons, to be compatible with the spec
    if (options === false) {
        return { block: 'end', inline: 'nearest' };
    }

    if (isOptionsObject<StandardBehaviorOptions>(options)) {
        // computeScrollIntoView ensures the defaults are block: 'center' and inline: 'nearest', to conform to the spec
        return options;
    }

    // if options = {}, options = true or options = null, based on w3c web platform test
    return { block: 'start', inline: 'nearest' };
}

export namespace scrollIntoView {
    export interface Options<T = any> extends ComputeScrollIntoViewOptions {
        behavior?: ScrollBehavior | CustomScrollBehaviorCallback<T>;
    }
}

// Some people might use both "auto" and "ponyfill" modes in the same file, so we also provide a named export so
// that imports in userland code (like if they use native smooth scrolling on some browsers, and the ponyfill for everything else)
// the named export allows this `import {auto as autoScrollIntoView, ponyfill as smoothScrollIntoView} from ...`
export function scrollIntoView<T>(target: Element, options: CustomBehaviorOptions<T>): T;
export function scrollIntoView(target: Element, options?: scrollIntoView.Options | boolean): void;
export function scrollIntoView<T>(target: Element, options?: scrollIntoView.Options<T> | boolean) {
    // Browsers treats targets that aren't in the dom as a no-op and so should we
    const targetIsDetached = !target.ownerDocument!.documentElement.contains(target);

    if (isOptionsObject<CustomBehaviorOptions<T>>(options) && typeof options.behavior === 'function') {
        return options.behavior(targetIsDetached ? [] : computeScrollIntoView(target, options));
    }

    // Don't do anything if using a standard behavior on an element that is not in the document
    if (targetIsDetached) {
        return;
    }

    // @TODO see if it's possible to avoid this assignment
    const computeOptions = getOptions(options);
    return defaultBehavior(computeScrollIntoView(target, computeOptions), computeOptions.behavior);
}
