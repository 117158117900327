import {
    IInnerWindowClosingController,
    IInnerWindowController,
    InnerWindowAnimator,
    ModalsScope,
    ObservableRenderFunction,
    useInnerWindowController,
    useObservableRenderFunction,
    useRootWindow
} from '@wix/devzai-utils-react';
import {classes, style} from './doppe-viewer-modals-controller.st.css';
import React from 'react';
import {
    arrayReverse,
    assertNotNullable,
    Evaluable,
    evaluateFunction,
    evaluateWhenFunction, uiPartCreateMarking,
    Values
} from '@wix/devzai-utils-common';
import {BymoPageLayout, bymoPageLayoutResolveFromViewportWidth} from '../bymo-page-style/bymo-page-style';
import {DoppeViewerModalsControllerParts} from "./doppe-viewer-modals-controller.ui-parts";

export const DoppeViewerModalOpeningAnimation = {
    None: 'None',
    FadeIn: 'FadeIn',
    ScaleAndFadeIn: 'ScaleAndFadeIn',
    FlipIn: 'FlipIn',
    Elastic: 'Elastic',
    NudgeInFromLeft: 'NudgeInFromLeft',
    NudgeInFromRight: 'NudgeInFromRight',
    NudgeInFromBottom: 'NudgeInFromBottom',
    SlideInFromBottom: 'SlideInFromBottom',
    SlideInFromLeft: 'SlideInFromLeft',
    SlideInFromTop: 'SlideInFromTop',
    SlideInFromRight: 'SlideInFromRight',
} as const;

export type DoppeViewerModalOpeningAnimation = Values<typeof DoppeViewerModalOpeningAnimation>;

export class DoppeViewerModalsController {

    private modalsScope;

    constructor (
        options: {
            modalsScope: ModalsScope;
        }
    ) {
        this.modalsScope = options.modalsScope;
    }

    public showInnerModal (
        options: {
            content: DoppeViewerModalContent;
            openingAnimation?: EvaluableModalOpeningAnimation;
            withoutOverlay?: boolean;
            onClosed?: () => void;
        }
    ) {
        const {
            content,
            openingAnimation = DoppeViewerModalOpeningAnimation.FadeIn,
            onClosed,
            withoutOverlay = false
        } = options;

        this.modalsScope.showModal({
            onClosed: onClosed,
            content: (renderProps) => (
                <DoppeViewerModalAnimator
                    openingAnimation={openingAnimation}
                    windowClosingControllerRef={renderProps.innerWindowClosingControllerRef}
                >
                    <DoppeViewerModalContentRenderer>
                        {content}
                    </DoppeViewerModalContentRenderer>
                </DoppeViewerModalAnimator>
            ),
            overlay: withoutOverlay ? undefined : renderProps => (
                <DoppeViewerModalOverlayAnimator
                    windowClosingControllerRef={renderProps.innerWindowClosingControllerRef}
                    openingAnimation={openingAnimation}
                >
                    <div
                        className={style(classes.overlay, {})}
                        {...uiPartCreateMarking(DoppeViewerModalsControllerParts.Overlay)}
                    />
                </DoppeViewerModalOverlayAnimator>
            )
        })

    }

    public showToast (
        options: {
            content: DoppeViewerModalContent;
            showFromBottom: boolean;
            onClosed?: () => void;
        }
    ) {
        const {
            content,
            showFromBottom,
            onClosed,
        } = options;

        this.modalsScope.showModal({
            onClosed: onClosed,
            content: (renderProps) => (
                <DoppeViewerModalAnimator
                    openingAnimation={showFromBottom ? DoppeViewerModalOpeningAnimation.SlideInFromBottom : DoppeViewerModalOpeningAnimation.SlideInFromTop}
                    windowClosingControllerRef={renderProps.innerWindowClosingControllerRef}
                >
                    <DoppeViewerModalContentRenderer>
                        {content}
                    </DoppeViewerModalContentRenderer>
                </DoppeViewerModalAnimator>
            ),
        })
    }
}

type EvaluableModalOpeningAnimation = Evaluable<(layout: BymoPageLayout) => DoppeViewerModalOpeningAnimation>

export type DoppeViewerModalContent = Evaluable<(renderProps: {modalController: IInnerWindowController}) => React.ReactElement | ObservableRenderFunction>;

const DoppeViewerModalContentRenderer = React.memo(function DoppeViewerModalContentRenderer (
    props: {
        children: DoppeViewerModalContent;
    }
) {
    const {
        children
    } = props;

    const modalController = assertNotNullable(useInnerWindowController());

    const evaluatedContent = evaluateWhenFunction(children, {modalController: modalController})

    return useObservableRenderFunction(evaluatedContent);
});

const DoppeViewerModalOverlayAnimator = React.memo(function DoppeViewerModalOverlayAnimator (
    props: {
        openingAnimation: EvaluableModalOpeningAnimation;
        children: React.ReactElement;
        windowClosingControllerRef: React.Ref<IInnerWindowClosingController>;
    }
) {
    const {
        openingAnimation,
        children,
        windowClosingControllerRef
    } = props;

    const rootWindow = useRootWindow() ?? window;
    const evaluatedOpeningAnimation = evaluateWhenFunction(
        openingAnimation,
        bymoPageLayoutResolveFromViewportWidth(rootWindow.innerWidth)
    );

    const animationDuration = resolveAnimationDuration(evaluatedOpeningAnimation);

    return (
        <InnerWindowAnimator
            windowClosingControllerRef={windowClosingControllerRef}
            openTransition={evaluatedOpeningAnimation !== DoppeViewerModalOpeningAnimation.None ? {
                animation: [
                    {opacity: 0, easing: 'ease-in-out'},
                    {opacity: 1, easing: 'ease-in-out'},
                ],
                durationMilliseconds: animationDuration.openDuration
            } : undefined}
            closeTransition={evaluatedOpeningAnimation !== DoppeViewerModalOpeningAnimation.None ? {
                animation: [
                    {opacity: 1, easing: 'ease-in-out'},
                    {opacity: 0, easing: 'ease-in-out'},
                ],
                durationMilliseconds: animationDuration.closeDuration,
                remainInTheLastStateAfterCompletion: true
            } : undefined}
        >
            {children}
        </InnerWindowAnimator>
    );
});

const DoppeViewerModalAnimator = React.memo(function DoppeViewerModalAnimator (
    props: {
        openingAnimation: EvaluableModalOpeningAnimation;
        children: React.ReactElement;
        windowClosingControllerRef: React.Ref<IInnerWindowClosingController>;
    }
) {
    const {
        openingAnimation,
        children,
        windowClosingControllerRef
    } = props;

    const rootWindow = useRootWindow() ?? window;
    const evaluatedOpeningAnimation = evaluateWhenFunction(
        openingAnimation,
        bymoPageLayoutResolveFromViewportWidth(rootWindow.innerWidth)
    );

    const animationDuration = resolveAnimationDuration(evaluatedOpeningAnimation);

    const animationSpec = evaluateFunction(() => {
        switch (evaluatedOpeningAnimation) {
            case DoppeViewerModalOpeningAnimation.None: {
                return undefined;
            }
            case DoppeViewerModalOpeningAnimation.FadeIn: {
                return {
                    openingKeyFrames: [
                        {opacity: 0, easing: 'ease-in-out'},
                        {opacity: 1, easing: 'ease-in-out'}
                    ]
                }
            }
            case DoppeViewerModalOpeningAnimation.FlipIn: {
                return {
                    openingKeyFrames: [
                        { transform: 'perspective(1000px) rotateY(90deg)', opacity: '0', easing: 'ease-in-out' },
                        { transform: 'perspective(1000px) rotateY(0deg)', opacity: '1', easing: 'ease-in-out' }
                    ]
                }
            }
            case DoppeViewerModalOpeningAnimation.Elastic: {
                return {
                    openingKeyFrames: [
                        { transform: 'scale(0.95)', opacity: '0', easing: 'ease-in-out' },
                        { transform: 'scale(1.05)', opacity: '1', easing: 'ease-in-out' },
                        { transform: 'scale(0.98)', easing: 'ease-in-out' },
                        { transform: 'scale(1)', easing: 'ease-in-out' },
                    ]
                }
            }
            case DoppeViewerModalOpeningAnimation.ScaleAndFadeIn: {
                return {
                    openingKeyFrames: [
                        {opacity: 0, transform: 'scale(0.95)', easing: 'ease-out'},
                        {opacity: 1, transform: 'scale(1)', easing: 'ease-out'}
                    ]
                }
            }
            case DoppeViewerModalOpeningAnimation.SlideInFromBottom:
            case DoppeViewerModalOpeningAnimation.SlideInFromTop:
            case DoppeViewerModalOpeningAnimation.SlideInFromLeft:
            case DoppeViewerModalOpeningAnimation.SlideInFromRight: {
                return {
                    openingKeyFrames: [
                        {
                            transform: evaluateFunction(() => {
                                switch (evaluatedOpeningAnimation) {
                                    case DoppeViewerModalOpeningAnimation.SlideInFromBottom: return 'translate(0, 100%)';
                                    case DoppeViewerModalOpeningAnimation.SlideInFromTop: return 'translate(0, -100%)';
                                    case DoppeViewerModalOpeningAnimation.SlideInFromLeft: return 'translate(-100%, 0)';
                                    case DoppeViewerModalOpeningAnimation.SlideInFromRight: return 'translate(100%, 0)';
                                }
                            }),
                            easing: 'ease-out'
                        },
                        {transform: `translate(0,0)`, easing: 'ease-out'}
                    ]
                }
            }
            case DoppeViewerModalOpeningAnimation.NudgeInFromBottom:
            case DoppeViewerModalOpeningAnimation.NudgeInFromLeft:
            case DoppeViewerModalOpeningAnimation.NudgeInFromRight: {
                return {
                    openingKeyFrames: [
                        {
                            transform: evaluateFunction(() => {
                                switch (evaluatedOpeningAnimation) {
                                    case DoppeViewerModalOpeningAnimation.NudgeInFromBottom: return 'translate(0, 10px)';
                                    case DoppeViewerModalOpeningAnimation.NudgeInFromLeft: return 'translate(-10px, 0)';
                                    case DoppeViewerModalOpeningAnimation.NudgeInFromRight: return 'translate(10px, 0)';
                                }
                            }),
                            opacity: 0,
                            easing: 'ease-out'
                        },
                        {
                            transform: `translate(0,0)`,
                            opacity: 1,
                            easing: 'ease-out'
                        }
                    ]
                }
            }
        }
    })

    return (
        <InnerWindowAnimator
            windowClosingControllerRef={windowClosingControllerRef}
            openTransition={animationSpec ? {
                animation: animationSpec.openingKeyFrames,
                durationMilliseconds: animationDuration.openDuration
            } : undefined}
            closeTransition={animationSpec ? {
                animation: arrayReverse<any>(animationSpec.openingKeyFrames),
                durationMilliseconds: animationDuration.closeDuration,
                remainInTheLastStateAfterCompletion: true
            } : undefined}
        >
            {children}
        </InnerWindowAnimator>
    );
});

function resolveAnimationDuration (openingAnimation: DoppeViewerModalOpeningAnimation) {
    switch (openingAnimation) {
        case DoppeViewerModalOpeningAnimation.None: {
            return {openDuration: 0, closeDuration: 0};
        }
        case DoppeViewerModalOpeningAnimation.ScaleAndFadeIn:
        case DoppeViewerModalOpeningAnimation.FadeIn: {
            return {openDuration: 200, closeDuration: 200};
        }
        case DoppeViewerModalOpeningAnimation.NudgeInFromBottom:
        case DoppeViewerModalOpeningAnimation.NudgeInFromLeft:
        case DoppeViewerModalOpeningAnimation.NudgeInFromRight: {
            return {openDuration: 200, closeDuration: 200};
        }
        case DoppeViewerModalOpeningAnimation.SlideInFromBottom:
        case DoppeViewerModalOpeningAnimation.SlideInFromTop:
        case DoppeViewerModalOpeningAnimation.SlideInFromLeft:
        case DoppeViewerModalOpeningAnimation.SlideInFromRight: {
            return {openDuration: 300, closeDuration: 300};
        }
        case DoppeViewerModalOpeningAnimation.FlipIn: {
            return {openDuration: 400, closeDuration: 400};
        }
        case DoppeViewerModalOpeningAnimation.Elastic: {
            return {openDuration: 400, closeDuration: 400};
        }
    }
}