import {MatchingKeys} from "../common-types";
import {identity} from "../common-functions";

export function proxyCreateStub<T> (
    implementation: Partial<T> = {},
    options: {
        onAccessingNotImplementedProperty?: ((propName: string) => void) | null;
    } = {}
) {
    const {
        onAccessingNotImplementedProperty = (propName: string) => {
            throw new Error(`Can't access property '${propName}' on a stub`)
        },
    } = options;

    return new Proxy({}, {
        get(_obj: any, propName: string) {

            if (propName in implementation) {
                return (implementation as any)[propName];
            } else {
                onAccessingNotImplementedProperty?.(propName);

                return undefined;
            }
        }
    }) as T;
}

export function proxyMock<T> (obj: T, overrides: Partial<T> = {}) {

    const proxy = new Proxy({}, {
        get(_obj: any, propName: string) {

            const override = (overrides as any)[propName];

            if (override) {
                return override.bind(proxy);
            } else {
                const field = (obj as any)[propName];

                return typeof field === 'function' ? field.bind(proxy) : field;
            }
        }
    }) as T;

    return proxy;
}



export function proxyHookBeforeMethod<T, METHOD_NAMES = MatchingKeys<T, (...args: any) => any>> (
    obj: T,
    hook: (methodName: METHOD_NAMES, args: unknown[]) => void
) {

    const proxy = new Proxy({}, {
        get(_obj: any, propName: string) {

            const field = (obj as any)[propName];

            return typeof field === 'function' ?
                (...args: any[]) => {
                    hook(propName as any, args as any);
                    field.call(obj, ...args);
                } :
                field;
        }
    }) as T;

    return proxy;
}

export function proxyCreateEnumFromValues<T extends string> ()  {
    return new Proxy({}, {
        get: (_obj: any, propName: string) => propName
    }) as { [value in T]: value }
}

export type ObjectPathSelectorProxyPath = {
    $getPath: () => string;
}

export type ObjectPathSelectorProxy<T> = {
    [key in keyof T]-?: Required<T>[key] extends object ? ObjectPathSelectorProxy<T[key]> & ObjectPathSelectorProxyPath : string & ObjectPathSelectorProxyPath;
}


export function proxyCreateObjectPathSelector<T> (fieldMappingFunc = identity<string>) : ObjectPathSelectorProxy<T> {

    return proxyCreateObjectPathSelectorInner([], fieldMappingFunc);
}

function proxyCreateObjectPathSelectorInner (path: string[], fieldMappingFunc = identity<string>) : any {

    return new Proxy({}, {
        get(_obj: any, propName: string) {

            if (propName === 'toString' || propName === 'toJSON' || propName === '$getPath') {
                return () => path.join('.');
            } else {
                return proxyCreateObjectPathSelectorInner([...path, fieldMappingFunc(propName)], fieldMappingFunc)
            }
        }
    });
}

