import {stringTrimPrefix} from "../string-utils";
import {evaluateFunction} from "../evaluable";
import {numberInterpolate, numberNormalizeToRange} from "../number-utils/number-utils";
import {Values} from "../common-types";

export const LegibilityClassification = {
    A: 'A',
    AA: 'AA',
    AAA: 'AAA',
    FAIL: 'FAIL'
} as const;

export type LegibilityClassification = Values<typeof LegibilityClassification>;

export interface ColorRgb {
    r: number;
    g: number;
    b: number;
}

export interface ColorHsv {
    h: number;
    s: number;
    v: number;
}

export type ColorHsl = {
    h: number;
    s: number;
    l: number;
}

export type Color = ColorRgb | ColorHsv;

export type ColorValue = Color | string;

export function colorIsRgb (color: Color) : color is ColorRgb {
    return (color as ColorRgb).r !== undefined;
}

export function colorIsHsv (color: Color) : color is ColorHsv {
    return (color as ColorHsv).v !== undefined;
}

export function colorHexToRgb (hex: string) : ColorRgb | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export function colorToHex (color: Color) : string {
    const {r, g, b} = colorToRgb(color);

    const integer = ((Math.round(r) & 0xFF) << 16)
        + ((Math.round(g) & 0xFF) << 8)
        + (Math.round(b) & 0xFF);

    const str = integer.toString(16).toUpperCase();
    return '000000'.substring(str.length) + str;
}

export function colorInvert (color: ColorValue) : string | null {
    const rgbColor = colorToRgb(color);
    if (!rgbColor) {
        return null;
    }

    return colorNormalizeHexValue(colorToHex({
        r: 255 - rgbColor.r,
        g: 255 - rgbColor.g,
        b: 255 - rgbColor.b,
    }))
}

export function colorToRgb (color: string) : ColorRgb | null;
export function colorToRgb (color: Color) : ColorRgb;
export function colorToRgb (color: Color | string) : ColorRgb | null;
export function colorToRgb(color: Color | string) : ColorRgb | null {

    if (typeof color === 'string') {
        return colorHexToRgb(colorNormalizeHexValue(color));
    } else if (colorIsRgb(color)) {
        return color;
    } else {
        const [r, g, b] = evaluateFunction(() => {
            const h = color.h / 60;
            const s = color.s / 100;
            let v = color.v / 100;
            const hi = Math.floor(h) % 6;

            const f = h - Math.floor(h);
            const p = 255 * v * (1 - s);
            const q = 255 * v * (1 - (s * f));
            const t = 255 * v * (1 - (s * (1 - f)));
            v *= 255;

            switch (hi) {
                case 0:
                    return [v, t, p];
                case 1:
                    return [q, v, p];
                case 2:
                    return [p, v, t];
                case 3:
                    return [p, q, v];
                case 4:
                    return [t, p, v];
                case 5:
                    return [v, p, q];
                default: {
                    throw new Error();
                }
            }
        });

        return {r, g, b}
    }
}

export function colorIsEqual (color: ColorValue, otherColor: ColorValue) {
    const colorRgb = colorToRgb(color);
    const otherColorRgb = colorToRgb(otherColor);

    if (colorRgb === null || otherColorRgb === null) {
        return false;
    }

    return colorRgb.r === otherColorRgb.r && colorRgb.g === otherColorRgb.g && colorRgb.b === otherColorRgb.b;
}

export function colorGetAnalogousColor (color: ColorValue) : string | null {
    const hsv = colorToHsv(color);

    if (!hsv) {
        return null;
    }

    return colorNormalizeHexValue(colorToHex({
        h: (hsv.h + 30) % 360,
        s: hsv.s,
        v: hsv.v
    }));
}

export function colorRotateHue (color: Color, degrees: number) : string;
export function colorRotateHue (color: ColorValue, degrees: number) : string | null;
export function colorRotateHue (color: ColorValue, degrees: number) : string | null {
    const hsv = colorToHsv(color);

    if (!hsv) {
        return null;
    }

    return colorNormalizeHexValue(colorToHex({
        h: (hsv.h + degrees) % 360,
        s: hsv.s,
        v: hsv.v
    }));
}

export function colorConvertHslToRgb (colorHsl: ColorHsl) : ColorRgb {

    const { h, s, l } = colorHsl;

    // Convert s and l from percentages (0-100) to decimals (0-1)
    const sDecimal = s / 100;
    const lDecimal = l / 100;

    const c = (1 - Math.abs(2 * lDecimal - 1)) * sDecimal; // Chroma
    const x = c * (1 - Math.abs((h / 60) % 2 - 1)); // Second largest component
    const m = lDecimal - c / 2;

    let r = 0, g = 0, b = 0;

    if (h >= 0 && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (h >= 60 && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (h >= 180 && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (h >= 240 && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (h >= 300 && h < 360) {
        r = c;
        g = 0;
        b = x;
    }

    // Convert to 0-255 range and add the lightness adjustment (m)
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return { r, g, b };
}

export function colorToHsl (color: string) : ColorHsl | null;
export function colorToHsl (color: Color) : ColorHsl;
export function colorToHsl (color: ColorValue) : ColorHsl | null;
export function colorToHsl (color: ColorValue) : ColorHsl | null {
    const rgb = colorToRgb(color);

    if (!rgb) {
        return null;
    }

    // Convert RGB to the range 0-1
    const r = rgb.r / 255;
    const g = rgb.g / 255;
    const b = rgb.b / 255;

    // Find the maximum and minimum values to get chroma
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const chroma = max - min;

    // Calculate lightness
    const l = (max + min) / 2;

    // Initially declare saturation
    let s = 0;
    if (chroma !== 0) {
        s = chroma / (1 - Math.abs(2 * l - 1));
    }

    // Initially declare hue
    let h = 0;
    if (chroma !== 0) {
        if (max === r) {
            h = (g - b) / chroma + (g < b ? 6 : 0);
        } else if (max === g) {
            h = (b - r) / chroma + 2;
        } else if (max === b) {
            h = (r - g) / chroma + 4;
        }
        h *= 60;
    }

    // Convert saturation and lightness to percentages
    s *= 100;
    const lPercent = l * 100;

    return {
        h: Math.round(h * 100) / 100,
        s: Math.round(s * 100) / 100,
        l: Math.round(lPercent * 100) / 100
    };
}

export function colorToHsv (color: string) : ColorHsv | null;
export function colorToHsv (color: Color) : ColorHsv;
export function colorToHsv (color: Color | string) : ColorHsv | null;
export function colorToHsv (color: Color | string) : ColorHsv | null {

    if (typeof color === 'string') {
        const rgbColor = colorHexToRgb(color);

        if (rgbColor === null) {
            return null;
        } else {
            return colorToHsv(rgbColor)
        }
    } else if (colorIsHsv(color)) {
        return color;
    } else {

        let rdif;
        let gdif;
        let bdif;
        let h;
        let s;

        const r = color.r / 255;
        const g = color.g / 255;
        const b = color.b / 255;

        const v = Math.max(r, g, b);
        const diff = v - Math.min(r, g, b);
        const diffc = function (c: number) {
            return (v - c) / 6 / diff + 1 / 2;
        };

        if (diff === 0) {
            h = 0;
            s = 0;
        } else {
            s = diff / v;
            rdif = diffc(r);
            gdif = diffc(g);
            bdif = diffc(b);

            if (r === v) {
                h = bdif - gdif;
            } else if (g === v) {
                h = (1 / 3) + rdif - bdif;
            } else if (b === v) {
                h = (2 / 3) + gdif - rdif;
            }

            if (h !== undefined) {
                if (h < 0) {
                    h += 1;
                } else if (h > 1) {
                    h -= 1;
                }
            }
        }

        return {
            h: Math.round((h ?? 0) * 360),
            s: Math.round(s * 100),
            v: Math.round(v * 100)
        }
    }


}

export function colorHsvToHex (colorHsv: ColorHsv) : string {
    return colorNormalizeHexValue(colorToHex(colorHsv));
}

export function colorGetRgba (color: Color | string, alphaChannel: number) : string | null {
    const rgbColor = colorToRgb(color);

    if (!rgbColor) {
        return null;
    } else {
        return `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b},${numberNormalizeToRange(alphaChannel, 0, 1)})`
    }
}

export function colorIsDark (color: ColorValue) {
    return colorResolveLegibleForegroundColor(color) === 'white';
}

export function colorInterpolateForegroundColor (
    backgroundColor: ColorValue,
    lightColor: ColorValue,
    darkColor: ColorValue
) {
    const backgroundRgb = colorToRgb(backgroundColor);
    const lightColorRgb = colorToRgb(lightColor);
    const darkColorRgb = colorToRgb(darkColor);

    if (backgroundRgb === null || lightColorRgb === null || darkColorRgb === null) {
        return null;
    }

    const brightness = (backgroundRgb.r * 299 + backgroundRgb.g * 587 + backgroundRgb.b * 114) / 1000;

    // Interpolate between lightColor and darkColor based on background brightness
    const ratio = 1 - (brightness / 255);
    const interpolatedColor = {
        r: numberInterpolate(darkColorRgb.r, lightColorRgb.r, ratio),
        g: numberInterpolate(darkColorRgb.g, lightColorRgb.g, ratio),
        b: numberInterpolate(darkColorRgb.b, lightColorRgb.b, ratio)
    };

    return colorNormalizeHexValue(colorToHex(interpolatedColor));
}

export function colorResolveLegibleForegroundColor<D extends string, B extends string> (backgroundColor: Color | string, darkValue: D, brightValue: B) : D | B;
export function colorResolveLegibleForegroundColor (backgroundColor: Color | string) : 'black' | 'white';
export function colorResolveLegibleForegroundColor<D extends string, B extends string> (backgroundColor: Color | string, darkValue?: D, brightValue?: B) : 'black' | 'white' | D | B {

    const backgroundColorRgb = colorToRgb(backgroundColor);

    if (!backgroundColorRgb) {
        return darkValue ?? 'black';
    }

    const brightness = Math.round(((backgroundColorRgb.r * 299) +
        (backgroundColorRgb.g * 587) +
        (backgroundColorRgb.b * 114)) / 1000);
    return (brightness > 125) ? (darkValue ?? 'black') : (brightValue ?? 'white');
}

export function colorNormalizeHexValue<INVALID> (hex: string, invalidValue: INVALID) : string | INVALID;
export function colorNormalizeHexValue (hex: string) : string;
export function colorNormalizeHexValue<INVALID> (hex: string, invalidValue?: INVALID) {

    const validHex = stringTrimPrefix(hex, '#').toUpperCase().replace(/[^A-F0-9]/g, '').substring(0, 6);

    if (validHex.length === 0) {
        return invalidValue ?? '#000000';
    }

    return '#' + evaluateFunction(() => {
        switch (validHex.length) {
            case 0:
                return '000000';
            case 1:
                return validHex[0].repeat(6);
            case 2:
                return (validHex[0] + validHex[1]).repeat(3);
            case 3:
            case 4:
            case 5:
                return validHex[0].repeat(2) + validHex[1].repeat(2) + validHex[2].repeat(2);
            default:
                return validHex
        }
    });

}

export function colorResolveMiddleColor (color1: ColorValue, color2: ColorValue) : string | null {
    const color1RGB = colorToRgb(color1);
    const color2RGB = colorToRgb(color2);

    if (color1RGB === null || color2RGB === null) {
        return null;
    }

    const blendedRGB = {
        r: Math.round(color1RGB.r + color2RGB.r) / 2,
        g: Math.round(color1RGB.g + color2RGB.g) / 2,
        b: Math.round(color1RGB.b + color2RGB.b) / 2,
    };

    return colorNormalizeHexValue(colorToHex(blendedRGB));
}

export function colorWhiten (color: Color, alpha: number) : string;
export function colorWhiten (color: Color | string, alpha: number) : string | null;
export function colorWhiten (color: Color | string, alpha: number) : string | null {
    return colorBlend(color, '#ffffff', alpha)
}

export function colorDarken (color: Color, alpha: number) : string;
export function colorDarken (color: Color | string, alpha: number) : string | null;
export function colorDarken (color: Color | string, alpha: number) : string | null {
    return colorBlend(color, '#000000', alpha)
}

export function colorBlend(color1: Color | string, color2: Color | string, alpha: number): string | null {
    // Parse the colors into RGB values
    const color1RGB = colorToRgb(color1);
    const color2RGB = colorToRgb(color2);

    if (color1RGB === null || color2RGB === null) {
        return null;
    }

    // Calculate the blended RGB values
    const blendedRGB = {
        r: alpha * color1RGB.r + (1 - alpha) * color2RGB.r,
        g: alpha * color1RGB.g + (1 - alpha) * color2RGB.g,
        b: alpha * color1RGB.b + (1 - alpha) * color2RGB.b,
    };

    // Convert the blended RGB values back to a hexadecimal string
    return colorNormalizeHexValue(colorToHex(blendedRGB));
}

export function colorCalculateLuminance (color: Color | string) {
    const colorRGB = colorToRgb(color);

    if (!colorRGB) {
        return null;
    }

    const rgb = [colorRGB.r, colorRGB.g, colorRGB.b];

    const [r, g, b] = rgb.map((val) => {
        const sRGB = val / 255;
        return sRGB <= 0.03928 ? sRGB / 12.92 : Math.pow((sRGB + 0.055) / 1.055, 2.4);
    });

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export function colorResolveContrastRatio(bgColor: Color | string, textColor: Color | string) {

    const bgLuminance = colorCalculateLuminance(bgColor);
    const textLuminance = colorCalculateLuminance(textColor);

    if (bgLuminance === null || textLuminance === null) {
        return null;
    }

    const brightest = Math.max(bgLuminance, textLuminance);
    const darkest = Math.min(bgLuminance, textLuminance);
    return (brightest + 0.05) /
        (darkest + 0.05);

    // return bgLuminance > textLuminance ? (textLuminance + 0.05) / (bgLuminance + 0.05) : (bgLuminance + 0.05) / (textLuminance + 0.05);
}

export function colorGetLegibilityScoreClassification (contrastRatio: number) {
    if (contrastRatio >= 7) {
        return LegibilityClassification.AAA;
    } else if (contrastRatio >= 4.5) {
        return LegibilityClassification.AA;
    } else if (contrastRatio >= 3) {
        return LegibilityClassification.A;
    } else {
        return LegibilityClassification.FAIL
    }
}