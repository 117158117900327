import {
    enumIsEnumValue,
    enumReduce,
    evaluateFunction,
    objectFilterEntries,
    objectGetKeys,
    objectGetValues,
    objectPick,
    PickByValue,
    Values
} from '@wix/devzai-utils-common';
import {
    WixPremiumBillingCycle,
    wixPremiumBooleanFeatureIsEnabled, WixPremiumCycleIntervalUnit,
    WixPremiumFeaturesStatus,
    WixPremiumFeatureStatus,
    WixPremiumFeatureType,
    WixPremiumProductId,
    WixPremiumProductsPricing,
    wixPremiumQuotaFeatureGetLimit,
    wixPremiumQuotaFeatureGetRemainedQuota,
    WixPremiumSubscriptionInfo,
    wixPremiumSubscriptionInfoIsBilledOnWix
} from '@wix/devzai-common-wix';
import {DoppePremiumFeatures, DoppePremiumFeaturesSpec} from './doppe-premium-features';
import {DoppeDtoPremiumFeatureCatalogInfo} from '../types';

export type DoppePremiumOrderPageData = {
    userEmail: string;
    orderSessionId: string;
    riskCheckIframeUrl: string;
    eventAggregator: string;
}

export const DoppePremiumProductsTypeId = '9c7a270d-c9f2-41bf-bb56-e6543c6b0008';

export const DoppePremiumProducts = {
    Starter: '270041d1-81b3-4b07-be41-06fbd38edbad',
    NameComProTest: 'ece1673b-20b3-48f9-9271-74ad50bca8f0',
    ProBrandMaker: 'f37b13d1-e25a-4479-a65c-45fd5742f6b3',
    ProBrandMakerWithDomain: '3667d7c4-d6d0-4f9e-9bab-ff2e384a30e0',
    Pro: 'c1be10e7-b2d3-4e1f-99a3-34eff5478810',
    ProWithOutDomain: '3a3b641e-270d-48ac-b9bf-e5ebfca5ee57',
    Tier1: '3eb0990a-93e7-41db-8829-28ab32a105e3',
    Tier2: '4fb355bd-8151-4df3-bec0-84ac6a9867ce',
    Tier3: '84073c0c-cdaf-4169-a5d6-c8829971fada',
} as const;

export type DoppePremiumProducts = Values<typeof DoppePremiumProducts>;

export const DoppeMainPremiumProducts = enumReduce(DoppePremiumProducts, [
    DoppePremiumProducts.Pro,
    DoppePremiumProducts.Tier1,
    DoppePremiumProducts.Tier2,
    DoppePremiumProducts.Tier3,
])

export type DoppeMainPremiumProducts = Values<typeof DoppeMainPremiumProducts>;


export function doppePremiumProductHasHigherTier (premiumProduct: DoppePremiumProducts) {
    switch (premiumProduct) {
        case DoppePremiumProducts.Pro:
        case DoppePremiumProducts.Tier3: {
            return false;
        }
        default: {
            return true;
        }
    }
}

export const DoppePremiumBillingCycles = objectPick(WixPremiumBillingCycle, [
    'Monthly',
    'Yearly',
    // 'TwoYears'
]);

export type DoppePremiumBillingCycles = Values<typeof DoppePremiumBillingCycles>;

export type DoppePremiumBooleanFeatures = keyof PickByValue<typeof DoppePremiumFeaturesSpec, typeof WixPremiumFeatureType.Boolean>
export type DoppePremiumQuotaFeatures = keyof PickByValue<typeof DoppePremiumFeaturesSpec, typeof WixPremiumFeatureType.Quota>

export type DoppePremiumFeaturesStatus = WixPremiumFeaturesStatus<typeof DoppePremiumFeaturesSpec>

export function doppePremiumProductHasBillingCycleWithFreeDomainOffering (premiumProduct: DoppePremiumProducts) {
    switch (premiumProduct) {
        case DoppePremiumProducts.Tier1:
        case DoppePremiumProducts.NameComProTest:
        case DoppePremiumProducts.ProBrandMaker:
        case DoppePremiumProducts.ProWithOutDomain: {
            return false;
        }
        case DoppePremiumProducts.ProBrandMakerWithDomain:
        case DoppePremiumProducts.Pro:
        case DoppePremiumProducts.Starter:
        case DoppePremiumProducts.Tier2:
        case DoppePremiumProducts.Tier3: {
            return true;
        }
    }
}

export function doppePremiumHasFreeDomainOffering (
    premiumProduct: DoppePremiumProducts,
    doppePremiumBillingCycle: DoppePremiumBillingCycles
) {
    if (doppePremiumProductHasBillingCycleWithFreeDomainOffering(premiumProduct)) {
        switch (doppePremiumBillingCycle) {
            case DoppePremiumBillingCycles.Monthly: {
                return false;
            }
            case DoppePremiumBillingCycles.Yearly: {
                return true;
            }
        }
    } else {
        return false;
    }
}

export function doppePremiumBillingCycleResolveCycleInterval (doppePremiumBillingCycle: DoppePremiumBillingCycles) {
    return {
        intervalUnit: evaluateFunction(() => {
            switch (doppePremiumBillingCycle) {
                // case DoppePremiumBillingCycles.TwoYears: return WixPremiumCycleIntervalUnit.Year;
                case DoppePremiumBillingCycles.Yearly: return WixPremiumCycleIntervalUnit.Year;
                case DoppePremiumBillingCycles.Monthly: return WixPremiumCycleIntervalUnit.Month;
            }
        }),
        intervalCount: evaluateFunction(() => {
            switch (doppePremiumBillingCycle) {
                // case DoppePremiumBillingCycles.TwoYears: return 2;
                case DoppePremiumBillingCycles.Yearly: return 1;
                case DoppePremiumBillingCycles.Monthly: return 1;
            }
        })
    }
}

export function doppePremiumFeatureIsEnabled<F extends DoppePremiumBooleanFeatures>(
    premiumFeaturesStatus: DoppePremiumFeaturesStatus | null,
    feature: F
) {
    if (premiumFeaturesStatus === null) {
        return true;
    }

    return wixPremiumBooleanFeatureIsEnabled(premiumFeaturesStatus[feature] as WixPremiumFeatureStatus.BooleanFeature);
}

export function doppePremiumFeatureIsDisabled<F extends DoppePremiumBooleanFeatures>(
    premiumFeaturesStatus: DoppePremiumFeaturesStatus | null,
    feature: F
) {
    return !doppePremiumFeatureIsEnabled(premiumFeaturesStatus, feature);
}

export function doppePremiumFeatureHasFiniteRemainedQuota<F extends DoppePremiumQuotaFeatures>(
    premiumFeaturesStatus: DoppePremiumFeaturesStatus | null,
    feature: F
): boolean {
    return doppePremiumFeatureGetRemainedQuota(premiumFeaturesStatus, feature) < Infinity;
}

export function doppePremiumFeatureGetRemainedQuota<F extends DoppePremiumQuotaFeatures>(
    premiumFeaturesStatus: DoppePremiumFeaturesStatus | null,
    feature: F
): number {
    if (premiumFeaturesStatus === null) {
        return Infinity;
    }

    return wixPremiumQuotaFeatureGetRemainedQuota(premiumFeaturesStatus[feature] as WixPremiumFeatureStatus.QuotaFeature);
}

export function doppePremiumFeatureGetLimit<F extends DoppePremiumQuotaFeatures>(
    premiumFeaturesStatus: DoppePremiumFeaturesStatus | null,
    feature: F,
    defaultLimit?: number
): number {
    if (premiumFeaturesStatus === null) {
        return Infinity;
    }

    return wixPremiumQuotaFeatureGetLimit(premiumFeaturesStatus[feature] as WixPremiumFeatureStatus.QuotaFeature, defaultLimit);
}


export function doppePremiumGetBooleanFeatures() {
    return objectGetKeys(
        objectFilterEntries(DoppePremiumFeaturesSpec, featureType => featureType === WixPremiumFeatureType.Boolean)
    ) as DoppePremiumBooleanFeatures[];
}

export function doppePremiumGetQuotaFeatures() {
    return objectGetKeys(
        objectFilterEntries(DoppePremiumFeaturesSpec, featureType => featureType === WixPremiumFeatureType.Quota)
    ) as DoppePremiumQuotaFeatures[];
}

export function doppePremiumIsActive(featuresStatus: DoppePremiumFeaturesStatus) {
    // Important: We assume that the `hopp_remove_banner` feature is enabled if and only if the user has an active
    // premium plan.
    return doppePremiumFeatureIsEnabled(featuresStatus, DoppePremiumFeatures.hopp_remove_banner);
}

export function doppePremiumGetLongestSupportedBillingCycle () {
    if (enumIsEnumValue(DoppePremiumBillingCycles, WixPremiumBillingCycle.TwoYears)) {
        return WixPremiumBillingCycle.TwoYears;
    } else {
        return WixPremiumBillingCycle.Yearly;
    }
}

export function doppePremiumCanUpgradeSubscription (premiumSubscription: WixPremiumSubscriptionInfo) {

    if (!enumIsEnumValue(DoppePremiumProducts, premiumSubscription.productId)) {
        return false;
    }

    return doppePremiumProductHasHigherTier(premiumSubscription.productId) || evaluateFunction(() => {
        if (!wixPremiumSubscriptionInfoIsBilledOnWix(premiumSubscription)) {
            // TODO: Should we change it true? `false` means that resellers' subscriptions cannot be upgraded.
            return false;
        }

        return premiumSubscription.wixBillingDetails.billingCycle !== doppePremiumGetLongestSupportedBillingCycle();
    })
}

export function doppePremiuIsPackageConsistsAllFeatures(
    premiumProduct: DoppePremiumProducts,
    featuresToCheck: DoppeDtoPremiumFeatureCatalogInfo[],
    featuresByProduct: Record<DoppePremiumProducts, DoppeDtoPremiumFeatureCatalogInfo[]>
) {

    let allExists = true;
    let featureToCheckIndex = 0

    while (allExists && featureToCheckIndex < featuresToCheck.length) {
        const featureToCheck = featuresToCheck[featureToCheckIndex];

        if (featuresByProduct[premiumProduct].find(feature => {

            if (feature.uniqueName === featureToCheck.uniqueName) {
                if (feature.type === 'quota' && featureToCheck.type === 'quota') {
                    return (feature.limit === null ? Infinity : feature.limit?.low ?? 1) > (featureToCheck.limit?.low ?? 0)
                }
                else {
                    return true;
                }
            }

            return false;
        }) === undefined) {
            allExists = false;
        }

        featureToCheckIndex++;
    }


    return allExists;
}

export function doppeIsQuotaFeature(
    premiumProduct: DoppePremiumProducts,
    featureToCheck: string,
    featuresByProduct: Record<DoppePremiumProducts, DoppeDtoPremiumFeatureCatalogInfo[]>
) {

    const feature = featuresByProduct[premiumProduct].find(feature => {
        if (feature.uniqueName === featureToCheck) {
            return true
        }
        return false;
    })

    if (feature?.type === 'quota' ) {
        return true
    }
    else {
        return false;
    }
}

export function doppeGetQuotaFeatureLimit(
    premiumProduct: DoppePremiumProducts,
    featureToCheck: string,
    featuresByProduct: Record<DoppePremiumProducts, DoppeDtoPremiumFeatureCatalogInfo[]>
) {

    const feature = featuresByProduct[premiumProduct].find(feature => {
        if (feature.uniqueName === featureToCheck) {
            return true
        }
        return false;
    })

    if (feature?.type === 'quota' ) {
        return feature?.limit?.low;
    }
    else {
        return null;
    }
}


export function doppeGetPremiumProductsDiscounts(
    premiumProductsPricing: WixPremiumProductsPricing<WixPremiumProductId, DoppePremiumBillingCycles> | null
) {
    const packagesArray = premiumProductsPricing?.productsPricingData ? objectGetValues(premiumProductsPricing?.productsPricingData) : [];
    const packagesArrayDiscounts = packagesArray.map(packageDetails => packageDetails.YEARLY.discountPercentage ?? 0).filter(sale => sale > 0);

    const minSale = packagesArrayDiscounts.length > 0 ? (Math.min(...packagesArrayDiscounts, Infinity) ?? 0) : 0;
    const maxSale = packagesArrayDiscounts.length > 0 ? (Math.max(...packagesArrayDiscounts, 0) ?? 0) : 0;

    return {
        minSale,
        maxSale
    }
}

export function doppeGetMinPricePremiumProductWithDiscount(
    premiumProductsPricing: WixPremiumProductsPricing<WixPremiumProductId, DoppePremiumBillingCycles> | null
)  {
    const packagesArray = premiumProductsPricing?.productsPricingData ? objectGetValues(premiumProductsPricing?.productsPricingData)  : [];
    const packagesArrayDiscounts = packagesArray.map(packageDetails => packageDetails.YEARLY.discountPrice).filter(discountPrice => discountPrice !== null && discountPrice > 0) as number[];

    const minPrice = packagesArrayDiscounts.length > 0 ? (Math.min(...packagesArrayDiscounts, Infinity) ?? 0) : 0;
    const record = objectFilterEntries(premiumProductsPricing?.productsPricingData ?? {}, (packageDetails) => {
        return packageDetails?.YEARLY.discountPrice === minPrice
    })

    return objectGetKeys(record)[0] ?? null;
}

export function doppeIsPremiumProductIncludesFreeDomain(
    premiumProduct: DoppePremiumProducts
) {
    return premiumProduct === DoppePremiumProducts.ProBrandMakerWithDomain
        || premiumProduct === DoppePremiumProducts.Pro
        || premiumProduct === DoppePremiumProducts.NameComProTest
        || premiumProduct === DoppePremiumProducts.Tier2
        || premiumProduct === DoppePremiumProducts.Tier3;
}



