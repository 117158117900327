import {
    assertWixImageResource,
    assertWixVideoInfo, WixImageInfo,
    WixImageResource,
    wixImageResourceGetUrl,
    wixImageResourceIsExternalImage, wixImageResourceIsMediaManagerImageInfo,
    wixImageResourceResolveImageWidthHeightRatio, WixMediaManagerMedia,
    WixVideoInfo,
    WixVideoResource
} from "@wix/devzai-common-wix";
import {assertType, evaluateFunction, PartiallyRequired} from "@wix/devzai-utils-common";

export type WixMediaResource = WixImageResource | WixVideoResource;

export namespace WixMediaResource {
    export type WithKnownDimensions = WixImageInfo | PartiallyRequired<WixVideoInfo, 'poster'>;
}

export function wixMediaResourceIsWixVideoResource (mediaResource: WixMediaResource) : mediaResource is WixVideoResource {

    if (typeof mediaResource === 'string') {
        return false;
    }

    return (mediaResource as WixVideoInfo).quality !== undefined;
}

export function wixMediaResourceIsWixMediaManagerMedia (wixMediaResource: WixMediaResource) : wixMediaResource is WixMediaManagerMedia.ImageOrVideo {
    return wixMediaResourceIsWixVideoResource(wixMediaResource) || wixImageResourceIsMediaManagerImageInfo(wixMediaResource);
}

export function wixMediaResourceIsWixImageResource (mediaResource: WixMediaResource) : mediaResource is WixImageResource {
    return !wixMediaResourceIsWixVideoResource(mediaResource);
}

export function wixMediaResourceHasKnownDimensions (mediaResource: WixMediaResource) : mediaResource is WixMediaResource.WithKnownDimensions {
    if (wixMediaResourceIsWixImageResource(mediaResource)) {
        return typeof mediaResource === 'object' && 'width' in mediaResource;
    }

    return mediaResource.poster !== undefined;
}

export function wixMediaResourceResolveMediaWidthHeightRatio<T extends WixMediaResource> (mediaResource: T) {
    const result = evaluateFunction(() => {
        if (wixMediaResourceIsWixImageResource(mediaResource)) {
            return wixImageResourceResolveImageWidthHeightRatio(mediaResource);
        } else {

            const poster = mediaResource.poster;
            if (poster) {
                return wixImageResourceResolveImageWidthHeightRatio(poster);
            }

            return null;
        }
    })

    return result as (T extends WixMediaResource.WithKnownDimensions ? number : number | null)
}

export function assertWixMediaResource (wixMediaResource: WixMediaResource) : asserts wixMediaResource is WixMediaResource;
export function assertWixMediaResource (wixMediaResource: unknown) : asserts wixMediaResource is WixMediaResource;
export function assertWixMediaResource (wixMediaResource: unknown) : asserts wixMediaResource is WixMediaResource {
    assertType<WixMediaResource>(wixMediaResource, assert => {
        assert.isUnion(
            assert => assert.usingAssertionFunction(assertWixVideoInfo),
            assert => assert.usingAssertionFunction(assertWixImageResource),
        )
    })
}

export function wixMediaResourceReplaceExternalImage<V extends WixMediaResource> (
    wixMediaResource: V,
    externalImagesUrlToImageResourceMap: Map<string, WixImageResource>
) {
    if (wixMediaResourceIsWixImageResource(wixMediaResource)) {
        if (wixImageResourceIsExternalImage(wixMediaResource)) {
            const targetImageResource = externalImagesUrlToImageResourceMap.get(wixImageResourceGetUrl(wixMediaResource));

            if (targetImageResource) {
                return targetImageResource
            }
        }
    }

    return wixMediaResource;
}