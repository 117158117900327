import {BymoPageProps} from '../../client-server-common';
import {dateResolveFromDateTimeValue, DateTimeValue} from '@wix/devzai-utils-common';

export function bymoPageFormatDateTime (
    date: DateTimeValue,
    options: Intl.DateTimeFormatOptions,
    bymoPageProps?: BymoPageProps,
) {
    return Intl.DateTimeFormat(resolveLocale(bymoPageProps), options)
        .format(dateResolveFromDateTimeValue(date))
}

function resolveLocale (bymoPageProps?: BymoPageProps) {
    return bymoPageProps?.lang ?? 'en-US';
}