import {assertNotNullable, cachedValueCreate} from "@wix/devzai-utils-common";
import {v4 as uuidv4} from "uuid";
import {DoppeViewerAppClientServiceBase} from "./doppe-viewer-app-client-service-base";

export class DoppeViewerClientDataService  extends DoppeViewerAppClientServiceBase{

    private cachedClientId = cachedValueCreate(() => {
        const cookieAccessor = assertNotNullable(this.clientServices.doppeViewerAppCookieService.consentSafeCookieStorageAccessor);

        return cookieAccessor.updateItem<string>(
            `clientId`,
            clientId => clientId ?? uuidv4()
        )
    })

    public get clientId () { return this.cachedClientId.getValue(); }

    private cachedSessionId = cachedValueCreate(() => {
        const cookieAccessor = assertNotNullable(this.clientServices.doppeViewerAppCookieService.consentSafeCookieStorageAccessor);

        return cookieAccessor.updateItem<string>(
            `sessionId`,
            clientId => clientId ?? uuidv4()
        )
    })

    public get sessionId () { return this.cachedSessionId.getValue(); }
}