import {pathGetExtension, Values} from '../';

export const MimeTypeString = {
    ImageJpg: 'image/jpeg',
    ImagePng: 'image/png',
    VideoWebm: 'video/webm',
    VideoMp4: 'video/mp4',
    VCF: 'text/vcard',
    TextPlain: 'text/plain',
    JSON: 'application/json'
} as const;

export type MimeTypeString = Values<typeof MimeTypeString>;

export function mimeTypeResolveFromPath (path: string) : MimeTypeString | undefined {
    const extension = pathGetExtension(path);

    switch (extension) {
        case '.jpg':
        case '.jpeg':
            return MimeTypeString.ImageJpg;
        case '.png':
            return MimeTypeString.ImagePng;
        case '.webm':
            return MimeTypeString.VideoWebm;
        case '.mp4':
            return MimeTypeString.VideoMp4;
        case '.vcf':
            return MimeTypeString.VCF;
        case '.txt':
            return MimeTypeString.TextPlain;
        case '.json':
            return MimeTypeString.JSON;
    }

    return undefined;
}
