import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeRequest} from './doppe-action-type-request';

export const doppeActionTypeRequestViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeRequest, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeRequestWidget: (await import('./doppe-action-type-request-widget/doppe-action-type-request-widget')).DoppeActionTypeRequestWidget
        }
    },
    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeRequestWidget
        } = viewerComponents;

        return <DoppeActionTypeRequestWidget {...renderProps} />;
    }
})