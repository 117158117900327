import {WixImageInfo, WixImageSource} from '../wix-image/wix-image';
import {WixVideoInfo} from '../wix-video/wix-video';
import {
    arrayFirst,
    arrayOrderByDesc,
    assertDefined,
    compareNumbers,
    iterableMapToArray,
    regexExec,
    stringTrimPrefix,
    urlGetBaseUrl,
    urlGetPathname
} from '@wix/devzai-utils-common';
import {WixMediaResource} from '../wix-media-resource/wix-media-resource';

export type MediaType = 'image' | 'video';


export interface WixProductMediaChoice {
    name: string,
    optionName: string,
}

export interface WixProductMedia {
    thumbnail: MediaItemUrlAndSize;
    mediaType: MediaType;
    title?: string;
    image?: MediaItemUrlAndSize;
    video?: MediaItemVideo;
    id: string;
    originalImage?: WixImageSource;
}


export interface MediaItemUrlAndSize {
    altText?: string;
    format?: string;
    height?: number;
    url?: string;
    width?: number;
}

export interface MediaItemVideo {
    files?: MediaItemUrlAndSize[];
    stillFrameMediaId?: string;
}

export function wixProductMediaTryConvertToWixMediaResource (wixProductMedia: WixProductMedia): WixMediaResource | null {
    return wixProductMedia.mediaType === 'video' ?
        wixProductMediaTryConvertVideoMediaItemToWixVideoResource(assertDefined(wixProductMedia.video)) :
        wixProductMediaTryConvertImageMediaItemToWixImageResource(assertDefined(wixProductMedia.image));
}

function wixProductMediaTryConvertImageMediaItemToWixImageResource(mediaItem: MediaItemUrlAndSize): WixImageInfo | null {

    if (mediaItem.url === undefined || mediaItem.width === undefined || mediaItem.height === undefined) {
        return null;
    }

    return {
        width: mediaItem.width,
        height: mediaItem.height,
        url: mediaItem.url,
        isVectorImage: false,
        title: mediaItem.altText,
    }
}

function wixProductMediaTryConvertVideoMediaItemToWixVideoResource(mediaItem: MediaItemVideo): WixVideoInfo | null {

    const files = mediaItem.files

    if (!files || !files[0].url) {
        return null;
    }

    const videoFilesInfo = iterableMapToArray(files, (videoFile, skip) => {

        const url = videoFile.url;

        if (!url || urlGetBaseUrl(url) !== 'https://video.wixstatic.com') {
            return skip;
        }

        const path = stringTrimPrefix(urlGetPathname(url), '/');

        const match = regexExec(/^([^/]+\/[^/]+)\/(\d+p)/, path);

        if (!match) {
            return skip;
        }

        const [,baseUrl,quality] = match;

        return {
            ...videoFile,
            baseUrl: baseUrl,
            quality: quality,
            qualityNumeric: parseInt(quality)
        }
    });

    const sortedVideoFilesInfo = arrayOrderByDesc(videoFilesInfo, videoFileInfo => videoFileInfo.qualityNumeric, compareNumbers)

    const bestQualityVideoFileInfo = arrayFirst(sortedVideoFilesInfo)

    if (!bestQualityVideoFileInfo) {
        return null;
    }

    return {
        baseUrl: bestQualityVideoFileInfo.baseUrl,
        quality: sortedVideoFilesInfo.map(videoFileInfo => videoFileInfo.quality),
        poster: mediaItem.stillFrameMediaId && bestQualityVideoFileInfo.width && bestQualityVideoFileInfo.height ? {
            url: mediaItem.stillFrameMediaId,
            width: bestQualityVideoFileInfo.width,
            height: bestQualityVideoFileInfo.height,
        } : undefined,
    }
}