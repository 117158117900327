import '../css-sanitization/doppe-viewer-sanitize.st.css'
import './doppe-user-app-client-bootstrap.st.css'
import React from 'react';
import {hydrate, render} from 'react-dom';
import {DoppeViewerPageTypes} from '../../client-server-common';
import {evaluateFunction} from '@wix/devzai-utils-common';
import {
    doppeViewerClientServicesInitialize,
    DoppeViewerClientServicesReactContext
} from "./doppe-viewer-client-services";
import {bymoPageViewerPageAsyncCreate} from "../doppe-viewer-pages/bymo-page-viewer-page/bymo-page-viewer-page";
import {BymoPageViewerMode} from "../../components/bymo-page-viewer-context/bymo-page-viewer-context";
import {
    doppeDtoBymoLinkViewerDataResolvePageType
} from "../../client-server-common/types/doppe-dto-bymo-link-viewer-data";
import {BrowserHistory} from "@wix/devzai-common-client";
import {browserIsIframeWindow} from "@wix/devzai-utils-browser";

export function doppeUserAppClientBootstrap () {

    const rootContainerId = 'SITE_MAIN';
    const container = document.getElementById(rootContainerId) ??
        (() => {
            const newContainer = document.createElement('div');
            newContainer.id = rootContainerId;
            document.body.appendChild(newContainer);
            return newContainer;
        })();


    const clientServices = doppeViewerClientServicesInitialize();

    if (clientServices.doppeUserAppData.devEnabled) {
        Object.assign(window, {
            $dev: {
                clientServices: clientServices
            }
        })
    }

    evaluateFunction(async () => {
        const currentPathPageData = __doppe_viewer_app_data__.pageData;

        if (!currentPathPageData) {
            return (
                <div>Page Data Not Available</div>
            )
        } else {
            const viewerPageType = currentPathPageData.pageType;

            // TODO: Currently I don't initialize the browser history for pre-roll pages to avoid a conflict
            // TODO: with initializeSkipOnBackBehaviour. I should see if they can co-exist.
            if (viewerPageType === DoppeViewerPageTypes.BymoPage || (viewerPageType === DoppeViewerPageTypes.InAppBymoPage && !browserIsIframeWindow(window))) {
                if (!currentPathPageData.bymoLinkViewerData.targetUrl) {
                    BrowserHistory.initialize();
                }
            }

            switch (viewerPageType) {
                case DoppeViewerPageTypes.Home:
                    return (
                        <div>User Home Page</div>
                    );
                case DoppeViewerPageTypes.BymoPage: {

                    const bymoLinkViewerData = currentPathPageData.bymoLinkViewerData;

                    const livePageData = {
                        linkId: bymoLinkViewerData.linkId,
                        bymoPageId: bymoLinkViewerData.bymoPageId,
                        doppeUserId: bymoLinkViewerData.doppeUserId,
                        pageType: doppeDtoBymoLinkViewerDataResolvePageType(bymoLinkViewerData)
                    };

                    clientServices.bymoService.initialize({
                        bymoLinkViewerData: bymoLinkViewerData
                    });

                    clientServices.doppeViewerAppCookieService.initialize();
                    clientServices.viewerAnalyticsClient.initialize({
                        bymoLinkViewerData: bymoLinkViewerData
                    });

                    if (bymoLinkViewerData.targetUrl) {
                        clientServices.bymoService.initializeSkipOnBackBehaviour({
                            targetUrl: bymoLinkViewerData.targetUrl,
                            livePageData: livePageData
                        });
                    }

                    clientServices.bymoService.initializePageListeners({
                        livePageData: livePageData,
                        logUserActivityEvent: false,
                        targetUrl: bymoLinkViewerData.targetUrl,
                    })

                    return await bymoPageViewerPageAsyncCreate({
                        actionTypesStore: clientServices.actionTypesStore,
                        bymoTemplatesStore: clientServices.bymoTemplatesStore,
                        bymoLinkViewerData: bymoLinkViewerData,
                        mode: BymoPageViewerMode.Live
                    })
                }
                case DoppeViewerPageTypes.InAppBymoPage: {

                    const bymoLinkViewerData = currentPathPageData.bymoLinkViewerData;


                    const pageData = bymoLinkViewerData.pageData;

                    if (pageData === undefined) {
                        return (<div>Not Found</div>)
                    }

                    clientServices.bymoService.initialize({
                        bymoLinkViewerData: bymoLinkViewerData
                    });

                    clientServices.doppeViewerAppCookieService.initialize();

                    return await bymoPageViewerPageAsyncCreate({
                        actionTypesStore: clientServices.actionTypesStore,
                        bymoTemplatesStore: clientServices.bymoTemplatesStore,
                        bymoLinkViewerData: {
                            ...currentPathPageData.bymoLinkViewerData,
                            pageData: pageData
                        },
                        mode: BymoPageViewerMode.LiveInApp
                    })
                }
                case DoppeViewerPageTypes.Bypass: {
                    return (
                        <div>Bypass</div>
                    )
                }
                default: {
                    return (<div>Not Found</div>)
                }
            }
        }
    })
        .then(pageComponent => {
            const app = (
                <DoppeViewerClientServicesReactContext.Provider
                    value={clientServices}
                >
                    {pageComponent}
                </DoppeViewerClientServicesReactContext.Provider>
            )

            if (container.hasAttribute('data-ssr')) {
                console.log('ssr available - hydrating root container...');

                hydrate(app, container);
            } else {
                console.log('no ssr - rendering root container...');

                render(app, container);
            }
        })
        .catch((error) => {
            console.error(`Failed loading page`, error)
        })
}
