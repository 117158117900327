export enum VerticalAlignment {
    Top = 'top',
    Center = 'center',
    Bottom = 'bottom'
}

export enum HorizontalAlignment {
    Left = 'left',
    Center = 'center',
    Right = 'right'
}
