import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeActionTypeContactFormViewerLang} from './doppe-action-type-contact-form-viewer.lang';
import {
    DoppeFormActionSettings,
    getDefaultDoppeFormActionSettings, prepareFormSettingsForViewer
} from '../../client-server-common/types/doppe-form-action-settings';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppePremiumFeatureIsDisabled, DoppePremiumFeatures
} from '../../client-server-common';
import {immerProduce} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';

export namespace DoppeActionTypeContactForm {

    export interface ActionSettings extends DoppeFormActionSettings{
        images: WixMediaResource[] | null;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;

        subscribeButtonText: string;
        collectEmail: boolean;
        collectPhoneNumber: boolean;
        collectName: boolean;
        collectAddress: boolean;
        collectZip: boolean;
        collectMessage: boolean;
    }
}

export const doppeActionTypeContactFormMetadata = doppeActionTypeDefineMetadata<DoppeActionTypeContactForm.ActionSettings>({
    id: 'co.hopp.action/contact-form',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        collectEmail: true,
        collectPhoneNumber: true,
        collectName: true,
        collectAddress: true,
        collectZip: true,
        collectMessage: false,
        subscribeButtonText: DoppeActionTypeContactFormViewerLang.defaultTexts.subscribeButtonText,
        images: null,
        title: doppeHideableValueCreateHidden(''),
        description: doppeHideableValueCreateHidden(''),
        ...getDefaultDoppeFormActionSettings()
    }),
    isWidgetOpener: true,
    isFormAction: true,
    prepareForViewer: actionSettings => {
        prepareFormSettingsForViewer(actionSettings);
    },
    isPremiumAction: (premiumFeaturesStatus) => {
        return doppePremiumFeatureIsDisabled(premiumFeaturesStatus, DoppePremiumFeatures.creators_form_submission)
    },
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    canRenderInPage: true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.images ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.images ? actionSettings.images[0] ?? null : null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.images) {
                actionSettings.images = actionSettings.images.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
})