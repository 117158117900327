import {ListViewComposable} from './list-view-composable';
import {
    listViewDataItemIsSelectable,
    ListViewDataSourceDataItem,
    ListViewItemId,
    ListViewSelectionType,
    ListViewState,
    TypeAheadNavigationType
} from './list-view-types';
import {arrayClone, arrayEqual, arrayEqualInAnyOrder, arrayFindIndex, arrayFindValue} from '@wix/devzai-utils-common';
import React from 'react';
import {NavigationScopeReducingBySearchStrategy} from './list-view-search';

export class ListViewStateController {
    protected currentState!: ListViewState;
    protected isDirty = false;
    public readonly listView;

    private originalState: ListViewState;

    constructor(listView: ListViewComposable) {
        const {
            selectionStartId,
            currentNavigatableItemId,
            selectedIds,
            disabledIds,
            typeAheadValue
        } = listView.getListViewState();

        this.listView = listView;

        this.originalState = this.currentState = {
            selectionStartId:
                selectionStartId !== null || listView.isNotSupportingSelection()
                    ? selectionStartId
                    : currentNavigatableItemId,
            currentNavigatableItemId,
            selectedIds,
            disabledIds,
            typeAheadValue
        };
    }

    public setCurrentState(currentState: ListViewState) {
        this.currentState = currentState;
        this.isDirty = true;
    }

    public getCurrentState() {
        return this.currentState;
    }

    public hasCurrentChanged(): boolean {
        return this.originalState.currentNavigatableItemId !== this.currentState.currentNavigatableItemId;
    }

    public hasSelectionChanged () {
        return !arrayEqualInAnyOrder(this.originalState.selectedIds, this.currentState.selectedIds)
    }

    public hasStateChanged(): boolean {
        if (this.isDirty) {
            const {
                disabledIds: originalDisabledIds,
                selectedIds: originalSelectedIds,
                currentNavigatableItemId: originalCurrentItemId,
                selectionStartId: originalSelectionStartId,
                typeAheadValue: originalTypeAheadValue
            } = this.originalState;

            const {
                disabledIds,
                selectedIds,
                currentNavigatableItemId,
                selectionStartId,
                typeAheadValue
            } = this.currentState;

            return (
                typeAheadValue !== originalTypeAheadValue ||
                selectionStartId !== originalSelectionStartId ||
                currentNavigatableItemId !== originalCurrentItemId ||
                !arrayEqual(selectedIds, originalSelectedIds) ||
                !arrayEqual(disabledIds, originalDisabledIds)
            );
        }

        return false;
    }

    public isItemEnabled(itemId: ListViewItemId): boolean {
        const dataSourceItem = this.listView.getListViewItemById(itemId);

        return dataSourceItem !== undefined && this.isDataSourceDataItemEnabled(dataSourceItem);
    }

    private isDataSourceDataItemEnabled (dataSourceDataItem: ListViewDataSourceDataItem<any>) : boolean {
        if ((dataSourceDataItem.isDisabled === true || dataSourceDataItem.isExcluded === true)) {
            return false;
        }

        const disabledIds = this.currentState.disabledIds;

        return disabledIds === null || !disabledIds.includes(dataSourceDataItem.id);
    }

    public isSelectable(itemId: ListViewItemId): boolean {
        const dataSourceItem = this.listView.getListViewItemById(itemId);

        return !!dataSourceItem && this.isSelectableDataSourceItem(dataSourceItem);
    }

    private isSelectableDataSourceItem(dataSourceItem: ListViewDataSourceDataItem<any>): boolean {
        return listViewDataItemIsSelectable(dataSourceItem) && !dataSourceItem.isExcluded;
    }

    private isNavigatableDataSourceItem(dataSourceItem: ListViewDataSourceDataItem<any>): boolean {
        return !dataSourceItem.isExcluded && !dataSourceItem.isDisabled;
    }

    public isSelected(itemId: ListViewItemId): boolean {
        const selectedIds = this.currentState.selectedIds;

        return selectedIds !== null && selectedIds.includes(itemId);
    }

    public canNavigateToItem(itemId: ListViewItemId): boolean {
        const dataSourceItem = this.listView.getListViewItemById(itemId);

        return !!dataSourceItem && this.isNavigatableDataSourceItem(dataSourceItem) && this.isItemEnabled(itemId);
    }

    public canSelectItem(itemId: ListViewItemId): boolean {
        return this.isSelectable(itemId) && this.isItemEnabled(itemId);
    }


    public setCurrentItemId(itemId: ListViewItemId) {
        if (this.getCurrentItemId() !== itemId && this.canNavigateToItem(itemId)) {
            this.setCurrentState({
                ...this.currentState,
                currentNavigatableItemId: itemId
            });
        }

        return this;
    }

    public setSelectionStartId(itemId: ListViewItemId) {
        if (this.isItemEnabled(itemId)) {
            this.setCurrentState({
                ...this.currentState,
                selectionStartId: itemId
            });
        }

        return this;
    }

    public getNavigatableItems(): Array<ListViewDataSourceDataItem<any>> {
        const disabledItemsIdsSet = new Set(this.currentState.disabledIds);

        return this.getListViewItemsArray().filter(
            listViewItem => this.isNavigatableDataSourceItem(listViewItem) && !disabledItemsIdsSet.has(listViewItem.id)
        );
    }

    public getNavigationScopeItemsIds(): Array<ListViewItemId> {
        return this.reduceToItemsInNavigationScope(this.getNavigatableItems()).map(listViewItem => listViewItem.id);
    }

    private reduceToItemsInNavigationScope(navigatableItems: ListViewDataSourceDataItem<any>[]) {
        const { searchMatchingFunction, searchText, navigationScopeReducingBySearchStrategy } = this.listView.props;

        if (
            searchText &&
            searchMatchingFunction &&
            navigationScopeReducingBySearchStrategy !== NavigationScopeReducingBySearchStrategy.NeverReduce
        ) {
            const matchedItems = navigatableItems.filter(listViewItem => {
                return searchMatchingFunction(searchText, listViewItem);
            });

            switch (navigationScopeReducingBySearchStrategy) {
                case NavigationScopeReducingBySearchStrategy.ReduceAlways:
                    return matchedItems;
                case NavigationScopeReducingBySearchStrategy.ReduceOnlyWhenHasMatches:
                    return matchedItems.length > 0 ? matchedItems : navigatableItems;
                case NavigationScopeReducingBySearchStrategy.ReduceOnlyWhenHasMultipleMatches:
                    return matchedItems.length > 1 ? matchedItems : navigatableItems;
            }
        }

        return navigatableItems;
    }

    public getListViewItemsArray(): Array<ListViewDataSourceDataItem<any>> {
        return this.listView.getListViewItemsArray();
    }

    public getItemIndex(itemId: ListViewItemId): number {
        return this.listView.findItemIndex(itemId);
    }

    public getItemsInRange(
        fromItemId: ListViewItemId | null,
        toItemId: ListViewItemId | null,
        options: {
            selectableOnly?: boolean;
            enabledOnly?: boolean;
            reduceNavigationScopeByTypeAheadText?: boolean;
        }
    ): Array<ListViewItemId> {
        const { selectableOnly = false, enabledOnly = true, reduceNavigationScopeByTypeAheadText = true } = options;

        if (fromItemId === null && toItemId === null) {
            return [];
        }

        if (fromItemId === null) {
            fromItemId = toItemId;
        } else if (toItemId === null) {
            toItemId = fromItemId;
        }

        const fromIndex = this.getItemIndex(fromItemId!);
        const toIndex = this.getItemIndex(toItemId!);

        const minIndex = Math.min(fromIndex, toIndex);
        const maxIndex = Math.max(fromIndex, toIndex);

        let navigatableItemsInRange = this.getListViewItemsArray().slice(minIndex, maxIndex + 1);

        if (selectableOnly || enabledOnly) {
            navigatableItemsInRange = navigatableItemsInRange.filter(
                listViewItem =>
                    (!selectableOnly || listViewDataItemIsSelectable(listViewItem)) &&
                    (!enabledOnly || !this.isItemDisabled(listViewItem.id))
            );
        }

        if (reduceNavigationScopeByTypeAheadText) {
            navigatableItemsInRange = this.reduceToItemsInNavigationScope(navigatableItemsInRange);
        }

        return navigatableItemsInRange.map(listViewItem => listViewItem.id);
    }

    public addItemsInRangeToSelection(startId: ListViewItemId, endId: ListViewItemId) {
        this.assertMultiple();

        const currentState = this.currentState;

        let { selectedIds } = currentState;

        selectedIds = selectedIds ? selectedIds : [];

        const itemsInRange = this.getItemsInRange(startId, endId, {
            selectableOnly: true,
            enabledOnly: true
        });

        const itemsInRangeSet: Set<ListViewItemId> = new Set(itemsInRange);

        for (let i = 0; i < selectedIds.length; i++) {
            const selectedId = selectedIds[i];
            if (!itemsInRangeSet.has(selectedId)) {
                itemsInRange.push(selectedId);
            }
        }

        this.setCurrentState({
            ...currentState,
            selectedIds: itemsInRange
        });

        return this;
    }

    public toggleItemSelection(itemId: ListViewItemId, isToggled?: boolean) {
        this.assertSelectionSupported();

        const listView = this.listView;

        const shouldSelect = isToggled === undefined ? !this.isSelected(itemId) : isToggled;

        if (shouldSelect) {
            if (listView.isMultiSelection()) {
                this.addItemToSelection(itemId);
            } else if (listView.isSingleSelection()) {
                this.selectItem(itemId);
            }
        } else {
            this.removeItemFromSelection(itemId);
        }

        return this;
    }

    public selectItemsInRange(startId: ListViewItemId, endId: ListViewItemId) {
        this.assertMultiple();

        const itemsInRange = this.getItemsInRange(startId, endId, {
            selectableOnly: true,
            enabledOnly: true
        });

        this.setCurrentState({
            ...this.currentState,
            selectedIds: itemsInRange
        });

        return this;
    }

    public removeItemFromSelection(itemId: ListViewItemId) {
        this.assertSelectionSupported();

        const currentState = this.currentState;

        const { selectedIds, selectionStartId } = currentState;

        const isSelected = this.isSelected(itemId);

        let updatedSelectedIds = selectedIds ? selectedIds : [];

        const focusedItemIndexInArray = updatedSelectedIds.indexOf(itemId);

        if (isSelected) {
            updatedSelectedIds = arrayClone(updatedSelectedIds);
            updatedSelectedIds.splice(focusedItemIndexInArray, 1);
        }

        this.setCurrentState({
            ...currentState,
            selectionStartId: this.isItemEnabled(itemId) ? itemId : selectionStartId,
            selectedIds: updatedSelectedIds
        });

        return this;
    }

    public handleInteractiveSelection(itemId: ListViewItemId, event: React.MouseEvent) {

        const listView = this.listView;
        const keymapController = listView.props.keymapController;

        const isCtrlKey = keymapController.isCtrlKey(event);
        const isShiftKey = keymapController.isShiftKey(event);

        if (this.isItemEnabled(itemId)) {
            if (this.getCurrentItemId() === null) {
                this.setCurrentItemId(this.getNavigationScopeItemsIds()[0]);
            }

            if (this.getSelectionStartId() === null && listView.isSupportingSelection()) {
                // NOTE: Current item must exist, since in case that it was null in the beginning of the interaction,
                // we set it to be the first navigatable item.
                this.setSelectionStartId(this.getCurrentItemId()!);
            }

            if (isCtrlKey && isShiftKey) {
                if (listView.isMultiSelection()) {
                    this.addItemsInRangeToSelection(this.getSelectionStartId(), itemId);
                } else if (listView.isSingleSelection()) {
                    this.selectItem(itemId);
                }
            } else if (isShiftKey) {
                if (listView.isMultiSelection()) {
                    this.selectItemsInRange(this.getSelectionStartId(), itemId);
                } else if (listView.isSingleSelection()) {
                    this.selectItem(itemId);
                }
            } else if (isCtrlKey) {
                if (!listView.isNotSupportingSelection()) {
                    this.toggleItemSelection(itemId);
                }
            } else {
                if (!listView.isNotSupportingSelection()) {
                    this.selectItem(itemId);
                }
            }

            this.setCurrentItemId(itemId);
        }
    }

    public selectItem(itemId: ListViewItemId) {
        this.assertSelectionSupported();

        const currentState = this.currentState;

        const { selectionStartId } = currentState;

        const canSelectItem = this.canSelectItem(itemId);

        this.setCurrentState({
            ...currentState,
            selectionStartId: this.isItemEnabled(itemId) ? itemId : selectionStartId,
            selectedIds: canSelectItem ? [itemId] : []
        });

        return this;
    }

    public clearSelection() {
        this.setCurrentState({
            ...this.currentState,
            selectionStartId: null,
            selectedIds: []
        });

        return this;
    }

    public clearCurrentItem() {
        this.setCurrentState({
            ...this.currentState,
            currentNavigatableItemId: null,
            selectionStartId: null
        });

        return this;
    }

    public toggleItemDisabled(itemId: ListViewItemId) {
        if (this.isItemEnabled(itemId)) {
            listViewStateControllerDisableItem(this, itemId);
        } else {
            listViewStateControllerEnableItem(this, itemId);
        }

        return this;
    }

    public isItemDisabled(itemId: ListViewItemId) {
        return !this.isItemEnabled(itemId);
    }

    public addItemToSelection(itemId: ListViewItemId) {
        this.assertMultiple();

        const currentState = this.currentState;

        const { selectedIds, selectionStartId } = currentState;

        const isSelected = this.isSelected(itemId);
        const canSelectItem = this.canSelectItem(itemId);

        let updatedSelectedIds = selectedIds ? selectedIds : [];

        if (!isSelected && canSelectItem) {
            updatedSelectedIds = arrayClone(updatedSelectedIds);
            updatedSelectedIds.push(itemId);
        }

        this.setCurrentState({
            ...currentState,
            selectionStartId: this.isItemEnabled(itemId) ? itemId : selectionStartId,
            selectedIds: updatedSelectedIds
        });

        return this;
    }

    public setTypeAheadValue(typeAheadValue: string) {
        const currentState = this.currentState;

        this.setCurrentState({
            ...currentState,
            typeAheadValue
        });

        return this;
    }

    public navigateToItemBasedOnCurrentTypeAhead(selectItem: boolean) {
        const typeAheadValue = this.currentState.typeAheadValue;

        if (typeAheadValue !== null) {
            listViewStateControllerNavigateToItemBasedOnTypeAhead(this, typeAheadValue, selectItem);
        }

        return this;
    }

    public moveToNextSearchMatchingItem(searchText: string, selectItem?: boolean) {
        const { searchMatchingFunction, selectionType } = this.listView.props;

        if (selectItem === undefined) {
            selectItem = selectionType !== ListViewSelectionType.None;
        }

        if (searchMatchingFunction) {
            const navigatableItems = this.getNavigatableItems();

            const currentItemId = this.getCurrentItemId();

            const currentItemIndex =
                currentItemId !== null
                    ? arrayFindIndex(navigatableItems, listViewItem => listViewItem.id === currentItemId)
                    : -1;

            const nextMatchingItem = arrayFindValue(
                navigatableItems,
                listViewItem => searchMatchingFunction(searchText, listViewItem),
                currentItemIndex === -1 ? 0 : currentItemIndex,
                true
            );

            if (nextMatchingItem) {
                const nextMatchingItemId = nextMatchingItem.id;

                this.setCurrentItemId(nextMatchingItemId);

                if (selectItem) {
                    this.selectItem(nextMatchingItemId);
                }
            }
        }
    }

    public getSelectionStartId() {
        return this.currentState.selectionStartId!;
    }

    public getCurrentItemId() {
        return this.currentState.currentNavigatableItemId;
    }

    private assertMultiple(): void {
        if (!this.listView.isMultiSelection()) {
            throw new Error('Supported Only In Multiple Selection');
        }
    }

    public assertSelectionSupported(): void {
        if (this.listView.isNotSupportingSelection()) {
            throw new Error('Supported Only In List View With Selection');
        }
    }
}

export function listViewStateControllerMovePrev(stateController: ListViewStateController) {
    const currentState = stateController.getCurrentState();
    const currentListViewItemId = currentState.currentNavigatableItemId;

    if (currentListViewItemId !== null) {
        const prevEnabledItemId = listViewStateControllerGetPrevNavigatableItemId(stateController, currentListViewItemId);

        if (prevEnabledItemId !== null && prevEnabledItemId !== undefined) {
            stateController.setCurrentState({
                ...currentState,
                currentNavigatableItemId: prevEnabledItemId
            });
        }
    }

    return stateController;
}

export function listViewStateControllerResolveNextFocusedItemByTypeAheadText(
    stateController: ListViewStateController,
    currentNavigatableItemId: ListViewItemId,
    typeAheadText: string
): ListViewItemId | null {
    if (typeAheadText) {
        const enabledItemsIds = stateController.getNavigationScopeItemsIds();
        const enabledItemsIdsLength = enabledItemsIds.length;

        const currentItemIndex = arrayFindIndex(enabledItemsIds, id => id === currentNavigatableItemId);

        let searchStartingIndex;

        const typeAheadStrategy = stateController.listView.getTypeAheadStrategy();

        if (typeAheadStrategy === null) {
            throw new Error('TypeAhead Navigation not supported');
        }

        const typeAheadNavigationType = typeAheadStrategy.typeAheadNavigationType;
        if (typeAheadNavigationType === TypeAheadNavigationType.GoToNext && typeAheadText.length === 1) {
            searchStartingIndex = currentItemIndex >= 0 ? (currentItemIndex + 1) % enabledItemsIdsLength : 0;
        } else if (
            typeAheadNavigationType === TypeAheadNavigationType.StayOnCurrent ||
            (typeAheadNavigationType === TypeAheadNavigationType.GoToNext && typeAheadText.length > 1)
        ) {
            searchStartingIndex = currentItemIndex >= 0 ? currentItemIndex : 0;
        } else {
            throw new Error('Not supported type ahead navigation type');
        }

        for (
            let i = 0, cursor = searchStartingIndex;
            i < enabledItemsIdsLength;
            i++, cursor = (cursor + 1) % enabledItemsIdsLength
        ) {
            const cursorId = enabledItemsIds[cursor];
            const currentItem = stateController.listView.getListViewItemById(cursorId)!;

            const itemTypeAheadText = currentItem.typeAheadText;

            if (itemTypeAheadText && itemTypeAheadText.toLowerCase().startsWith(typeAheadText.toLowerCase())) {
                return currentItem.id;
            }
        }
    }

    return null;
}

export function listViewStateControllerNavigateToItemBasedOnTypeAhead(
    stateController: ListViewStateController,
    newTypeAheadValue: string,
    selectItem: boolean
) {
    const currentItemId = stateController.getCurrentItemId();

    if (currentItemId !== null) {
        const nextFocusedItemId = listViewStateControllerResolveNextFocusedItemByTypeAheadText(
            stateController,
            currentItemId,
            newTypeAheadValue);

        if (nextFocusedItemId !== null && nextFocusedItemId !== undefined) {
            if (selectItem && stateController.listView.isSupportingSelection()) {
                stateController.selectItem(nextFocusedItemId);
            }

            stateController.setCurrentState({
                ...stateController.getCurrentState(),
                currentNavigatableItemId: nextFocusedItemId
            });
        }
        // if there is no next focused item, we will use the second char to navigate to the next item if the typeahead navigation type is GoToNext.
        else if (newTypeAheadValue.length === 2) {
            // typeaheadStrategy is not null because resolveNextFocusedItemByTypeAheadText will throw error if it's null
            const typeAheadStrategy = stateController.listView.getTypeAheadStrategy()!;

            const typeAheadNavigationType = typeAheadStrategy.typeAheadNavigationType;

            // we take the second char and set it as typeAhead if there is no Next Focused Item for the typeAheadValue
            if (typeAheadNavigationType === TypeAheadNavigationType.GoToNext) {
                const resetTypeAheadValue = newTypeAheadValue.slice(1, 2);
                stateController.setTypeAheadValue(resetTypeAheadValue);
                stateController.navigateToItemBasedOnCurrentTypeAhead(selectItem);
            }
        }
    }

    return stateController;
}

export function listViewStateControllerDisableItem(
    stateController: ListViewStateController,
    itemId: ListViewItemId
) {
    const currentState = stateController.getCurrentState();

    const { disabledIds } = currentState;

    const isSelected = stateController.isSelected(itemId);

    let updateDisabledIds = disabledIds ? disabledIds : [];

    if (stateController.isItemEnabled(itemId)) {
        updateDisabledIds = arrayClone(updateDisabledIds);
        updateDisabledIds.push(itemId);
    }

    if (stateController.listView.isSupportingSelection() && isSelected) {
        // when disable remove item from selection
        stateController.removeItemFromSelection(itemId);
    }

    const updatedState = stateController.getCurrentState();

    // if item is selectionStart clear
    let newSelectionStartId = updatedState.selectionStartId;
    if (newSelectionStartId === itemId) {
        newSelectionStartId = null;
    }

    // if item is currentNavigatableItem clear
    let newCurrentNavigatableItemId = updatedState.currentNavigatableItemId;
    if (newCurrentNavigatableItemId === itemId) {
        newCurrentNavigatableItemId = null;
    }

    stateController.setCurrentState({
        ...updatedState,
        selectionStartId: newSelectionStartId,
        currentNavigatableItemId: newCurrentNavigatableItemId,
        disabledIds: updateDisabledIds
    });

    return stateController;
}

export function listViewStateControllerEnableItem(
    stateController: ListViewStateController,
    itemId: ListViewItemId
) {
    const currentState = stateController.getCurrentState();

    const { disabledIds } = currentState;

    const isDisabled = !stateController.isItemEnabled(itemId);

    let updateDisabledIds = disabledIds ? disabledIds : [];
    const disabledItemIndexInArray = updateDisabledIds.indexOf(itemId);

    if (isDisabled) {
        updateDisabledIds = arrayClone(updateDisabledIds);
        updateDisabledIds.splice(disabledItemIndexInArray, 1);
    }

    stateController.setCurrentState({
        ...currentState,
        disabledIds: updateDisabledIds
    });

    return stateController;
}

export function listViewStateControllerMoveNext(stateController: ListViewStateController) {
    const currentState = stateController.getCurrentState();
    const currentListViewItemId = currentState.currentNavigatableItemId;

    if (currentListViewItemId !== null) {
        const nextEnabledItemId = listViewStateControllerGetNextNavigatableItemId(stateController, currentListViewItemId);

        if (nextEnabledItemId !== null) {
            stateController.setCurrentState({
                ...currentState,
                currentNavigatableItemId: nextEnabledItemId
            });
        }
    }

    return stateController;
}

export function listViewStateControllerGetPrevNavigatableItemId(
    stateController: ListViewStateController,
    itemId: ListViewItemId
): ListViewItemId | null {
    const enabledItemsIds = stateController.getNavigationScopeItemsIds();

    const itemIndex = enabledItemsIds.indexOf(itemId);

    if (itemIndex >= 0) {
        let nextItemIndex = itemIndex;

        nextItemIndex--;

        if (nextItemIndex < 0) {
            if (stateController.listView.isCyclic()) {
                nextItemIndex = enabledItemsIds.length + nextItemIndex;
            } else {
                nextItemIndex = 0;
            }
        }

        return enabledItemsIds[nextItemIndex];
    }

    return null;
}

export function listViewStateControllerGetNextNavigatableItemId(
    stateController: ListViewStateController,
    itemId: ListViewItemId
): ListViewItemId | null {
    const enabledItemsIds = stateController.getNavigationScopeItemsIds();

    const itemIndex = enabledItemsIds.indexOf(itemId);

    if (itemIndex >= 0) {
        let nextItemIndex = itemIndex;

        nextItemIndex++;

        if (nextItemIndex >= enabledItemsIds.length) {
            if (stateController.listView.isCyclic()) {
                nextItemIndex = 0;
            } else {
                nextItemIndex = enabledItemsIds.length - 1;
            }
        }

        return enabledItemsIds[nextItemIndex];
    }

    return null;
}