import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import {doppeActionTypeLink} from "./doppe-action-type-link";

export const doppeActionTypeLinkViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeLink, {
    getActionButtonProps: actionContext => {

        const action = actionContext.action;

        return {
            href: action.settings.url,
            target: '_blank',
            rel: 'noopener',
        }
    }
})