import {enumGetValues, evaluateFunction, Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity, DoppeBusinessEntityReference} from './doppe-business-entity';

/**
 * An enum of all the external providers that can provide a product data.
 * For example: Wix, Amazon, Shopify.
 */
export const DoppeExternalCouponProvider = {
    Wix: 'wix',
} as const;

export type DoppeExternalCouponProvider = Values<typeof DoppeExternalCouponProvider>;

// TODO: This field should include all the possible SEMANTIC fields that an external product providers can provide
// TODO: for a product.
export const DoppeExternalCouponField = {
    CouponCode: 'CouponCode',
} as const;

export type DoppeExternalCouponField = Values<typeof DoppeExternalCouponField>;

const AllExternalCouponProviders = enumGetValues(DoppeExternalCouponProvider);

const externalCouponFieldsToSupportedProviders = {
    [DoppeExternalCouponField.CouponCode]: AllExternalCouponProviders,
} satisfies {
    [field in DoppeExternalCouponField]: DoppeExternalCouponProvider[]
}

export function doppeExternalCouponProviderIsFieldProvided(
    externalCouponProvider: DoppeExternalCouponProvider | null | undefined,
    externalCouponField: DoppeExternalCouponField
) {
    if (externalCouponProvider === null || externalCouponProvider === undefined) {
        return false;
    }

    return externalCouponFieldsToSupportedProviders[externalCouponField].includes(externalCouponProvider);
}

export function doppeExternalCouponProviderResolveBusinessEntityReference(
    externalItemProvider: DoppeExternalCouponProvider,
    externalItemId: string
) {
    return evaluateFunction(() => {
        switch (externalItemProvider) {
            case DoppeExternalCouponProvider.Wix: {
                return {
                    businessEntity: DoppeBusinessEntity.WixCoupon,
                    id: externalItemId
                } as DoppeBusinessEntityReference<typeof DoppeBusinessEntity.WixCoupon>
            }
        }
    }) satisfies DoppeBusinessEntityReference
}