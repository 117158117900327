import { AnimationFrameTimer } from '../animation-frame-timer/animation-frame-timer';
import { IAnimationFrameScheduler } from '../animation-frame-scheduler/animation-frame-scheduler';

export type ReadFunction<T> = () => T;
export type WriteFunction<T> = (value: T) => void;
export type ReadWriteFunctionsEntry<T> = [ReadFunction<T>, WriteFunction<T>];

class ReadWriteCoordinatorSingleton implements IAnimationFrameScheduler {
    private registeredEntries: ReadWriteFunctionsEntry<any>[] = [];

    private animationFrameTimer = new AnimationFrameTimer(() => {
        const result = [];

        for (const [readFunction, writeFunction] of this.registeredEntries) {
            result.push(writeFunction, readFunction());
        }

        for (let i = 0; i < result.length; i += 2) {
            const writeFunction = result[i] as WriteFunction<any>;
            const readResult = result[i + 1];

            writeFunction(readResult);
        }
    });

    public coordinateReadWrite<T>(
        readFunction: ReadFunction<T>,
        writeFunction: WriteFunction<T>
    ): ReadWriteFunctionsEntry<T> {
        const entry: ReadWriteFunctionsEntry<T> = [readFunction, writeFunction];

        this.registeredEntries.push(entry);

        this.animationFrameTimer.activate();

        return entry;
    }

    public unregisterEntry(entry: ReadWriteFunctionsEntry<any>) {
        const registeredEntries = this.registeredEntries;
        const entryIndex = registeredEntries.indexOf(entry);

        if (entryIndex < 0) {
            throw new Error(`Couldn't find entry`);
        }

        registeredEntries.splice(entryIndex, 1);

        if (registeredEntries.length === 0) {
            this.animationFrameTimer.deactivate();
        }
    }

    public requestAnimationFrame(callback: FrameRequestCallback) {
        return this.animationFrameTimer.requestAnimationFrame(callback);
    }
}

export const ReadWriteCoordinator = new ReadWriteCoordinatorSingleton();
