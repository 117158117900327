import {DoppeDtoBymoLinkViewerData} from "../types/doppe-dto-bymo-link-viewer-data";

interface DoppeViewerPageData<PAGE_TYPE extends string> {
    pageType: PAGE_TYPE;
}

export type DoppeViewerPagesData =
    | DoppeViewerPagesData.Home
    | DoppeViewerPagesData.Bypass
    | DoppeViewerPagesData.Blocked
    | DoppeViewerPagesData.BymoPage
    | DoppeViewerPagesData.InAppBymoPage
;

export namespace DoppeViewerPagesData {
    export type Home = DoppeViewerPageData<'Home'>;

    export type Bypass = DoppeViewerPageData<'Bypass'> & {
        bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithTargetUrl;
    };

    export type Blocked = DoppeViewerPageData<'Blocked'> & {
        bymoLinkViewerData: DoppeDtoBymoLinkViewerData;
    };

    export type BymoPage = DoppeViewerPageData<'BymoPage'> & {
        bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithConnectedPage;
    };

    export type InAppBymoPage = DoppeViewerPageData<'InAppBymoPage'> & {
        bymoLinkViewerData: DoppeDtoBymoLinkViewerData.WithConnectedPage;
    };
}

export type DoppeViewerPageTypes = DoppeViewerPagesData['pageType'];

export const DoppeViewerPageTypes: {[page in DoppeViewerPageTypes]: page} = new Proxy({}, {
    get: (_obj: any, propName: string) => propName
})
