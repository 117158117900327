import type {i18n, initOptions} from '@wix/wix-i18n-config';
import {
    assertDefined, evaluateWhenFunction, identity,
    IdGenerator, objectDeepGet, objectGetKeys,
    objectMapValues, OmitStrict,
    stringTrimPrefix,
    stringTrimSuffix,
    treeCreateTreeWalker,
    UnflattenObj, Values
} from "@wix/devzai-utils-common";

export async function i18nInitialize (options: OmitStrict<initOptions, 'disableAutoInit'>) {
    const {initI18n} = await import('@wix/wix-i18n-config');

    const i18n = initI18n({
        ...options,
        disableAutoInit: true
    });

    await i18n.init();

    return i18n;
}

export const I18nMessageType = {
    Text: 'text',
    Html: 'html',
} as const;

export type I18nMessageType = Values<typeof I18nMessageType>;

export type I18n = i18n;

export namespace I18n {

    export type Message = string | ((params: any, formatting: Formatting) => string);

    export type HtmlMessage<M extends Message = Message> = {
        __htmlMessage: M
    }

    export type MessagesStore = {
        [key: string]: MessagesStore | Message | HtmlMessage
    }

    export type NormalizeMessage<M extends Message, R = string> =
        M extends ((params: any, formatting: Formatting) => string) ? ((params: Parameters<M>[0]) => R) :
        M extends string ? R :
        never;

    export type NormalizedMessageStore<M extends MessagesStore, HTML_STR = string> = {
        [key in keyof M]:
            M[key] extends HtmlMessage<infer MESSAGE> ? NormalizeMessage<MESSAGE, HTML_STR> :
            M[key] extends MessagesStore ? NormalizedMessageStore<M[key], HTML_STR> :
            M[key] extends Message ? NormalizeMessage<M[key]> :
            M[key]
    }

    export type I18nextMessageSpec<ARGS> = I18nMessageType | ((args: ARGS) => I18nMessageType);

    export type MessagesObjectSpec<S> = {[key in keyof S & string]?: I18n.I18nextMessageSpec<any>}

    export type MessagePostProcessor = (
        message: string,
        context: {
            contentKey: string;
            isHtml: boolean;
        }
    ) => string;

    export type I18nextSpec<S extends MessagesStore> = {
        [K in keyof S]:
            S[K] extends MessagesStore ? I18nextSpec<S[K]> :
            S[K] extends HtmlMessage<infer MESSAGE> ? (
                MESSAGE extends string ?
                    ['message', typeof I18nMessageType.Html, string] :
                    ['func', typeof I18nMessageType.Html, string]
            ) :
            S[K] extends string ?
                ['message', typeof I18nMessageType.Text, string] :
                ['func', typeof I18nMessageType.Text, string];
    }

    export interface PluralValues {
        zero?: string;
        one?: string;
        other?: string;
    }

    export interface Formatting {
        plural (count: number, pluralValues: PluralValues) : string;
        number (count: number) : string;
    }
}

export function i18nGenerateTranslator (
    options: {
        i18n: i18n;
        parameterizedKeys: Record<string, (...params: any[]) => any>;
        messagePostProcessor?: I18n.MessagePostProcessor;
        keyMapper?: (key: string) => string;
    }
) {
    const {
        i18n,
        parameterizedKeys,
        messagePostProcessor = identity,
        keyMapper = identity
    } = options;

    const parameterizedKeysSet = new Set(objectGetKeys(parameterizedKeys));

    return new Proxy({}, {
        get: (_obj: any, propName: string) => {

            const mappedContentKey = keyMapper(propName);

            if (parameterizedKeysSet.has(propName)) {
                return (params: any) => {
                    return messagePostProcessor(i18n.t(mappedContentKey, params), {
                        contentKey: mappedContentKey,
                        isHtml: false
                    });
                }
            } else {
                return messagePostProcessor(i18n.t(mappedContentKey, {}), {
                    contentKey: mappedContentKey,
                    isHtml: false
                })
            }
        }
    })
}

export function i18nGenerateI18nextTranslatorFromSpec<S extends I18n.MessagesStore, HTML_STR = string> (
    spec: I18n.I18nextSpec<S>,
    options: {
        i18n: i18n;
        htmlStrProcessor: (htmlStr: string) => HTML_STR;
        messagePostProcessor?: I18n.MessagePostProcessor;
        keyMapper?: (key: string) => string;
    }
) : any {

    const {
        i18n,
        htmlStrProcessor,
        messagePostProcessor = identity,
        keyMapper = identity
    } = options;

    const translator: any = new Proxy({}, {
        get: (_obj: any, propName: string) => {

            if (propName.includes('.')) {
                return objectDeepGet(translator, propName);
            }

            const specNode = spec[propName];

            if (Array.isArray(specNode)) {

                const [type, format, contentKey] = specNode;

                const mappedContentKey = keyMapper(contentKey);

                if (type === 'func') {
                    return (params: any) => {
                        const t = messagePostProcessor(i18n.t(mappedContentKey, params), {
                            contentKey: mappedContentKey,
                            isHtml: format !== 'text'
                        });

                        return format === 'text' ? t : htmlStrProcessor(t)
                    }
                } else {
                    const t = messagePostProcessor(i18n.t(mappedContentKey, {}), {
                        contentKey: mappedContentKey,
                        isHtml: format !== 'text'
                    });

                    return format === 'text' ? t : htmlStrProcessor(t)
                }

            } else if (typeof specNode === 'object') {
                return i18nGenerateI18nextTranslatorFromSpec(specNode as any, {
                    i18n: i18n,
                    htmlStrProcessor: htmlStrProcessor,
                    messagePostProcessor: messagePostProcessor,
                    keyMapper: keyMapper
                });
            }

            throw new Error(`Unknown spec node '${JSON.stringify(specNode)}'`)
        }
    })

    return translator;
}

function isHtmlMessage (value: unknown) : value is I18n.HtmlMessage {
    return (value as I18n.HtmlMessage).__htmlMessage !== undefined
}

export function i18nGenerateI18nextSpecFromContentObject<S extends {}> (
    langObj: S,
    messagesSpecs: {[key in keyof S & string]?: I18n.I18nextMessageSpec<any>} = {}
) :
    UnflattenObj<S> extends I18n.MessagesStore ? I18n.I18nextSpec<UnflattenObj<S>> : never
{
    const result = {} as any;

    for (const key of Object.keys(langObj) as (keyof S & string)[]) {

        const splittedDeepKey = key.split('.');
        const lastKey = assertDefined(splittedDeepKey.pop());

        let cursor: any = result;
        for (const keyPart of splittedDeepKey) {

            if (cursor[keyPart] === undefined) {
                cursor[keyPart] = {};
            }

            cursor = cursor[keyPart];
        }

        const messageSpec = messagesSpecs?.[key];
        const messageType = messageSpec ? evaluateWhenFunction(messageSpec, {}) : I18nMessageType.Text;

        cursor[lastKey] = [
            typeof messageSpec === 'function' ? 'func' : 'message',
            messageType,
            key
        ];
    }

    return result;
}

export function i18nGenerateI18nextSpec<S extends I18n.MessagesStore> (langObj: S) : I18n.I18nextSpec<S> {
    const result = {} as any;

    const treeWalker = treeCreateTreeWalker(([, value]: [key: string, value: any]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            return Object.entries(value);
        } else {
            return undefined;
        }
    });

    treeWalker.traverse(Object.entries(langObj), {
        iterator: ([key, value], treeWalkerIterationContext) => {

            const {
                obj,
                keysPath
            } = treeWalkerIterationContext.propagatedData;

            const updatedKeysPath = [...keysPath, key];

            if (typeof value === 'string') {
                obj[key] = ['message', 'text', updatedKeysPath.join('.')];
            } else if (typeof value === 'function') {
                obj[key] = ['func', 'text', updatedKeysPath.join('.')];
            } else if (isHtmlMessage(value)) {

                const htmlMessage = value.__htmlMessage;

                if (typeof htmlMessage === 'string') {
                    obj[key] = ['message', 'html', updatedKeysPath.join('.')];
                } else if (typeof htmlMessage === 'function') {
                    obj[key] = ['func', 'html', updatedKeysPath.join('.')];
                }

                return treeWalkerIterationContext.skipChildren();
            } else {
                const newObj = obj[key] = {};

                treeWalkerIterationContext.propagateData({
                    obj: newObj,
                    keysPath: updatedKeysPath
                });
            }
        },
        propagatedData: {
            obj: result,
            keysPath: [],
        }
    });

    return result;
}

export function i18nCreateFormatter (i18n: i18n) : I18n.Formatting {

    const notExistingKey = IdGenerator.uniqueId('unknown_')

    const formatValue = (value: any, formattingFunc: (paramName: string) => string) => {
        return i18n.t(notExistingKey, {value: value, defaultValue: formattingFunc('value'), fallbackLng: i18n.language})
    }

    return {
        plural(count: number, pluralValues: I18n.PluralValues): string {
            return formatValue(count, paramName => i18nFormatPlural(paramName, pluralValues));
        },
        number(count: number): string {
            return formatValue(count, paramName => i18nFormatNumber(paramName));
        }
    }
}

function i18nFormatNumber (paramName: string) {
    return `{${paramName}, number}`
}

function i18nFormatPlural (paramName: string, pluralValues: I18n.PluralValues) {
    return `{${paramName}, plural, ${Object.entries(pluralValues).map(([pluralType, pluralValue]) => `${pluralType} {${pluralValue}}`).join(' ')}}`
}

export function i18nGenerateI18nextContentObject (
    langObj: I18n.MessagesStore,
    options: {
        debugContent?: boolean;
    } = {}
) {

    const {
        debugContent = false
    } = options;

    const result = {} as any;

    const paramsProxy = new Proxy({}, {
        get: (_obj: any, propName: string) => `{${propName}}`
    })

    const extractParamName = (value: any) => {
        return stringTrimPrefix(stringTrimSuffix(value, '}'), '{')
    }

    const debugReplacementChar = 'x';

    const replaceParameterizedStringInDebugMode = (str: string) => {

        if (!debugContent) {
            return str;
        }

        let bracketsLevel = 0;
        const result = [];

        for (let i = 0; i < str.length; i++) {
            const char = str.charAt(i);
            if (char === '{') {
                result.push(char)
                bracketsLevel++;
            } else if (char === '}') {
                result.push(char)
                bracketsLevel--;
            } else if (bracketsLevel === 0 && /\w/.test(char)) {
                result.push('x');
            } else {
                result.push(char)
            }
        }

        return result.join('');
    }

    // {count, plural, one {follower} other {followers}}
    const formatting: I18n.Formatting = {
        plural(count: number, pluralValues: I18n.PluralValues): string {
            return i18nFormatPlural(
                extractParamName(count),
                debugContent ?
                    objectMapValues(pluralValues as Record<string, any>, value => value.replace(/\w/g, debugReplacementChar)) :
                    pluralValues
            );
        },
        number(count: number) : string {
            return i18nFormatNumber(extractParamName(count));
        }
    }

    const treeWalker = treeCreateTreeWalker(([, value]: [key: string, value: any]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            return Object.entries(value);
        } else {
            return undefined;
        }
    });

    treeWalker.traverse(Object.entries(langObj), {
        iterator: ([key, value], treeWalkerIterationContext) => {

            const keysPath = treeWalkerIterationContext.propagatedData;

            const updatedKeysPath = [...keysPath, key];

            const processMessage = (message: I18n.Message) => {
                if (typeof message === 'string') {
                    return debugContent ? message.replace(/\w/g, debugReplacementChar) : message;
                } else {
                    return replaceParameterizedStringInDebugMode(message(paramsProxy, formatting));
                }
            }

            if (typeof value === 'string' || typeof value === 'function') {
                result[updatedKeysPath.join('.')] = processMessage(value as I18n.Message);
            } else if (isHtmlMessage(value)) {
                result[updatedKeysPath.join('.')] = processMessage(value.__htmlMessage);

                return treeWalkerIterationContext.skipChildren();
            } else {
                treeWalkerIterationContext.propagateData(updatedKeysPath);
            }
        },
        propagatedData: []
    });

    return result;
}