import {useDebouncedCallback} from 'use-debounce'
import {useMemo, useRef, useState} from "react";
import {useForceUpdate} from "./use-force-update";

export function useDebouncedValue<V> (value: V, wait = 1000) {

    const [debouncedValue, setDebouncedValue] = useState(value);
    const forceUpdate = useForceUpdate();
    const debounced = useDebouncedCallback((value) => {
        setDebouncedValue(value);
        forceUpdate();
    }, wait);

    const isInitialValueFlagRef = useRef(true);
    useMemo(() => {
        if (isInitialValueFlagRef.current) {
            isInitialValueFlagRef.current = false;
        } else {
            debounced(value);
        }
    }, [debounced, value, isInitialValueFlagRef]);

    return [debouncedValue, debounced] as const;
}