import {DoppeDtoViewerAppData} from "../../client-server-common";

export class DoppeUserAppData {

    protected get appData () : DoppeDtoViewerAppData {

        if (typeof __doppe_viewer_app_data__ === 'undefined') {
            throw new Error(`'__doppe_viewer_app_data__' is missing on global`)
        } else {
            return __doppe_viewer_app_data__;
        }
    }

    public get appRuntimeEnvironment () {return this.appData.appRuntimeEnvironment;}
    public get appVersion () {return this.appData.appVersion;}
    get baseStaticUrl () {return this.appData.baseStaticUrl;}
    get appBasePath () { return this.appData.appBasePath; }
    get mainAppBaseUrl () { return this.appData.mainAppBaseUrl; }
    get viewerApiBasePath () { return this.appData.viewerApiBasePath; }
    get brandName () { return this.appData.brandName; }
    get devEnabled () { return this.appData.devEnabled; }
    get dynamicModelApiUrl () { return this.appData.dynamicModelApiUrl; }
    get apiBaseUrl () { return this.appData.apiBaseUrl; }

}
