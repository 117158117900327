import {
    bymoTemplateMetadataFactoryCreate,
    DoppePaletteColorType,
    DoppeTemplateColor,
    doppeTemplateColorCreateLegibleColor,
    doppeTemplateColorCreatePaletteBoundColor
} from "../../../doppe-sdk";

export namespace BymoTemplateBasic {

    export interface TemplateProps {
        mainColor: DoppeTemplateColor;
        skipButtonBackgroundColor: DoppeTemplateColor;
        backgroundColor: DoppeTemplateColor;
        mainTextColor: DoppeTemplateColor;
        skipButtonTextColor: DoppeTemplateColor;
    }
}

export const BymoTemplateBasicTemplateMetadata = bymoTemplateMetadataFactoryCreate<BymoTemplateBasic.TemplateProps>()({
    initialValues: {
        backgroundColor: doppeTemplateColorCreatePaletteBoundColor(DoppePaletteColorType.Background),
        mainColor: doppeTemplateColorCreatePaletteBoundColor(DoppePaletteColorType.Main),
        skipButtonBackgroundColor: doppeTemplateColorCreatePaletteBoundColor(DoppePaletteColorType.Secondary),
        mainTextColor: doppeTemplateColorCreatePaletteBoundColor(DoppePaletteColorType.Text),
        skipButtonTextColor: doppeTemplateColorCreateLegibleColor(DoppePaletteColorType.Secondary),
    },
    features: [
        'hasActions',
        'hasActionButton',
        'canBeSkipped',
        'hasProfileContent',
        'hasVisibleBackground',
        'hasCustomTextualContent'
    ]
})