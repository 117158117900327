import {arraySubtract} from "../array-utils";
import {isObject} from "../object-utils";

export class TypeAssertionError extends Error {

    constructor (
        public readonly reason: string
    ) {
        super(`Type assertion failed for the following reason: ${reason}`);
    }
}

export class ObjectFieldTypeAssertionError extends Error {

    constructor (
        public readonly fieldName: string,
        public readonly fieldError: any,
    ) {
        super(`Object field ${fieldName} assertion failed`);
    }
}

function resolveErrorReasonStringRec (error: any) : string {

    if (error instanceof TypeAssertionError) {
        return ` - ${error.reason}`;
    } else if (error instanceof ObjectFieldTypeAssertionError) {
        return `["${error.fieldName}"]${resolveErrorReasonStringRec(error.fieldError)}`
    } else {
        return error.message ?? '';
    }
}

export function typeAssertionResolveErrorReason (error: any) {
    return `<value>${resolveErrorReasonStringRec(error)}`
}

export type ObjectFieldsAssertion<T> = {
    [key in keyof T]-?: AssertionLogicFunction<T[key]>
}

class TypedAssertController<T> {

    private assertionCalledFlag = false;

    constructor(private value: T) {
    }

    private onAssertionCalled () {
        this.assertionCalledFlag = true;
    }

    public get assertionCalled () {
        return this.assertionCalledFlag;
    }

    public isString (validator?: (value: string) => boolean) {
        this.onAssertionCalled();

        const value = this.value;

        if (typeof value !== 'string') {
            throw new TypeAssertionError('not a string');
        }

        if (validator !== undefined && !validator(value)) {
            throw new TypeAssertionError('validation failed');
        }
    }

    public isAny () {
        this.onAssertionCalled();
    }

    public isOneOfValues (possibleValues: T[]) {
        this.onAssertionCalled();

        const value = this.value;

        if (possibleValues) {
            if (!(possibleValues as any).includes(value)) {
                throw new TypeAssertionError(`none of the expected values (${JSON.stringify(possibleValues)})`);
            }
        }
    }

    public isNumber (validator?: (value: number) => boolean) {
        this.onAssertionCalled();

        const value = this.value;

        if (typeof value !== 'number') {
            throw new TypeAssertionError('not a number');
        }

        if (validator !== undefined && !validator(value)) {
            throw new TypeAssertionError('validation failed');
        }
    }

    public isObject (
        keysAssertionLogic: ObjectFieldsAssertion<T>,
        options: {
            assertNoUnknownKeys?: boolean;
            validator?: (obj: T) => boolean;
        } = {}
    ) {
        const {
            assertNoUnknownKeys = true,
            validator
        } = options;

        this.onAssertionCalled();

        const value = this.value;

        if (!isObject(value)) {
            throw new TypeAssertionError('not an object');
        }

        if (assertNoUnknownKeys) {
            const unknownKeys = arraySubtract(Object.keys(value), Object.keys(keysAssertionLogic));
            if (unknownKeys.length > 0) {
                throw new TypeAssertionError(`Unknown keys ${JSON.stringify(unknownKeys)}`)
            }
        }

        for (const [key, entryValueAssertion] of Object.entries(keysAssertionLogic)) {

            try {
                useTypedAssert((value as any)[key], assert => {
                    (entryValueAssertion as any)(assert);
                })
            } catch (error) {
                throw new ObjectFieldTypeAssertionError(key, error);
            }
        }

        if (validator !== undefined && !validator(value)) {
            throw new TypeAssertionError('object validation failed');
        }
    }

    public get optional () : NarrowedTypedAssertController<Exclude<T, undefined>> {
        if (this.value !== undefined) {
            return this as unknown as NarrowedTypedAssertController<Exclude<T, undefined>>;
        } else {
            return new Proxy({}, {
                get: (_obj: any) => {
                    return () => {
                        this.onAssertionCalled();
                    }
                }
            })
        }
    }

    public isArray (
        itemAssertionLogic: T extends (infer V)[] ? AssertionLogicFunction<V> : never,
        validator?: (array: T) => boolean
    ) {
        this.onAssertionCalled();

        const value = this.value;

        if (!Array.isArray(value)) {
            throw new TypeAssertionError('not an array');
        }

        for (const item of value) {
            useTypedAssert(item, assert => {
                itemAssertionLogic(assert);
            })
        }

        if (validator !== undefined && !validator(value)) {
            throw new TypeAssertionError('array validation failed');
        }
    }

    public isUndefined () {
        this.onAssertionCalled();

        if (this.value !== undefined) {
            throw new TypeAssertionError('not undefined');
        }
    }

    public isNull () {
        this.onAssertionCalled();

        if (this.value !== null) {
            throw new TypeAssertionError('not null');
        }
    }

    public isBoolean () {
        this.onAssertionCalled();

        if (typeof this.value !== 'boolean') {
            throw new TypeAssertionError('not boolean');
        }
    }

    public usingAssertionFunction<S extends T> (assertionFunction: TypeAssertionFunction<S>) {
        this.onAssertionCalled();

        assertionFunction(this.value as S);
    }

    public usingValidator<S extends T = T> (validator: (value: S) => boolean) {
        this.onAssertionCalled();

        if (!validator(this.value as S)) {
            throw new TypeAssertionError('validation failed');
        }
    }

    public isUnion (
        ...validators: ((assert: NarrowedNonUnionTypeAssertController<T>) => void)[]
    ) {
        this.onAssertionCalled();

        const value = this.value;

        for (const validationFunc of validators) {

            let validated = true;

            try {
                useTypedAssert(value, assert => {
                    validationFunc(assert);
                })
            } catch (error) {
                validated = false;
            }

            if (validated) {
                return;
            }
        }

        throw new TypeAssertionError('union');
    }
}

function useTypedAssert (value: any, usageFunc: (assert: any) => void) {
    const valueValidator = new TypedAssertController(value);

    usageFunc(valueValidator)

    if (!valueValidator.assertionCalled) {
        throw new Error(`Didn't call assertion`)
    }
}

type NarrowedTypedAssertController<T> = Pick<TypedAssertController<T>, $SupportedAssertionMethods<T>>;
export type NarrowedNonUnionTypeAssertController<T> = Pick<TypedAssertController<T>, $SupportedUnionAssertionMethods<T>>;

export type AssertionLogicFunction<T> = (
    assert: NarrowedTypedAssertController<T>
) => void;

export type NonUnionTypeAssertionLogicFunction<T> = (
    assert: NarrowedNonUnionTypeAssertController<T>
) => void;

/**
 * Inspiration:
 * https://stackoverflow.com/questions/55542332/typescript-conditional-type-with-discriminated-union
 */
type $SupportedAssertionMethods<T> =
    undefined extends T ? 'optional' : (
        'isOneOfValues' |
        'usingAssertionFunction' |
        'usingValidator' |
        'isUnion' |
        'isAny' |
        (
            [T] extends [number] ? 'isNumber' :
            [T] extends [string] ? 'isString' :
            [T] extends [any[]] ? 'isArray' :
            [T] extends [object] ? 'isObject' :
            [T] extends [undefined] ? 'isUndefined' :
            [T] extends [null] ? 'isNull' :
            [T] extends [boolean] ? 'isBoolean' :
            never
        )
    )

type $SupportedUnionAssertionMethods<T> =
    'isOneOfValues' |
    'usingAssertionFunction' |
    'usingValidator' |
    (
        T extends number ? 'isNumber' :
        T extends string ? 'isString' :
        T extends any[] ? 'isArray' :
        T extends object ? 'isObject' :
        T extends undefined ? 'isUndefined' :
        T extends null ? 'isNull' :
        T extends boolean ? 'isBoolean' :
        never
    );

export type TypeAssertionFunction<T> = (value: T) => asserts value is T;

type CustomAssertionError = string | ((error: unknown) => Error);

export function validateType<T> (value: T, valueAssertionLogic: AssertionLogicFunction<T>) : value is T {
    try {
        assertType(value, valueAssertionLogic);
        return true;
    } catch (error) {
        return false;
    }
}

export function assertType<T> (
    value: T,
    valueAssertionLogic: AssertionLogicFunction<T>,
    customError?: CustomAssertionError
) : asserts value is T;
export function assertType<T> (
    value: unknown,
    valueAssertionLogic: AssertionLogicFunction<T>,
    customError?: CustomAssertionError
) : asserts value is T;
export function assertType<T> (
    value: any,
    valueAssertionLogic: AssertionLogicFunction<T>,
    customError?: CustomAssertionError
) : asserts value is T {
    try {
        useTypedAssert(value, assert => {
            valueAssertionLogic(assert);
        });
    } catch (error) {
        if (customError === undefined) {
            throw error;
        } else if (typeof customError === 'string') {
            throw new Error(customError);
        } else {
            throw customError(error);
        }
    }
}