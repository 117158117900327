import {RateType, WixBookingService, WixBookingType, WixImageInfo} from '@wix/devzai-common-wix';
import {BymoPageProps} from '../bymo-pages/bymo-page-props';
import {bymoPageFormatDateTime} from '../../components/bymo-page-locales-utils/bymo-page-locales-utils';


export interface DoppeDtoBookingService {
    id: string;
    name: string;
    description: string;
    media: WixImageInfo[] | null;
    locations: WixBookingService.Location[];
    payment?: WixBookingService.Payment;
    scheduale?: WixBookingService.Schedule;
    type: WixBookingService.ServiceType;
    bookingPageUrl?: string;
    calendarPageUrl?: string;
    servicePageUrl?: string;
    createdDate: Date | null;
    capacity?: number;
}

export namespace DoppeDtoBookingService {
    export type Id = DoppeDtoBookingService['id'];
}

export function doppeDtoBookingServicePaymentConvertToActionFormattedPrice(payment: WixBookingService.Payment): string {
    switch (payment.rateType) {
        case RateType.FIXED:
            return payment.fixed?.price.formattedValue ?? '';
        case RateType.VARIED: {
            if (!payment.varied?.minPrice?.value || !payment.varied?.maxPrice?.value) {
                return payment.varied?.defaultPrice?.formattedValue ?? '';
            } else if (payment.varied?.minPrice.value !== payment.varied?.maxPrice?.value) {
                return `${payment.varied?.minPrice.formattedValue} - ${payment.varied?.maxPrice}`;
            }

            return payment.varied?.defaultPrice?.formattedValue ?? '';
        }
        case RateType.CUSTOM:
            return payment.custom ?? '';
        case RateType.NO_FEE:
            return 'Free';
        case RateType.Unspecified:
            return '---';
    }
}

export function doppeDtoBookingServiceLocationConvertToActionFormattedLocation(location: WixBookingService.Location): string {
    return location.formattedAddress;
}

export function doppeDtoBookingServiceCapacityConvertToActionFormattedCapacity(capacity: number): string {
    return capacity > 1 ? `${capacity} Participants` : `${capacity} Participant`;
}

export function doppeDtoBookingServiceScheduleConvertToActionFormattedDuration(options: {
    schedule: WixBookingService.Schedule,
    serviceType: WixBookingService.ServiceType
}): string[] | null {
    if (options.serviceType === WixBookingType.APPOINTMENT) {
        if (options.schedule.availabilityConstraints?.sessionDurations?.length) {
            return options.schedule.availabilityConstraints.sessionDurations.map(duration => `${duration} minutes`);
        }
    }
    return null;
}

export function doppeDtoBookingServiceScheduleConvertToActionFormattedSchedule(options: {
    schedule: WixBookingService.Schedule,
    serviceType: WixBookingService.ServiceType,
    bymoPageProps?: BymoPageProps;
}): string | null {
    if (options.serviceType === WixBookingType.APPOINTMENT) {
        if (options.schedule.firstSessionStart) {
            return `Starts ${bymoPageFormatDateTime(
                options.schedule.firstSessionStart,
                {
                    month: 'short',
                    day: '2-digit',
                },
                options.bymoPageProps,
            )}`;
        }
    }
    return null;
}