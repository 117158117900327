import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeDonateMetadata} from "./doppe-action-type-donate-metadata";

export const doppeActionTypeDonateViewerMetadata = doppeActionTypeDefineViewerMetadata(doppeActionTypeDonateMetadata, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeDonateWidget: (await import('./doppe-action-type-donate-widget/doppe-action-type-donate-widget')).DoppeActionTypeDonateWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeDonateWidget
        } = viewerComponents;

        return <DoppeActionTypeDonateWidget {...renderProps} />;
    }
})