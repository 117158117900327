import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {arrayRemoveNullValues, immerProduce} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';

export namespace DoppeActionTypeShare {
    export interface ActionSettings {
        shareLink: string;
        shareTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        shareButtonText: string;
        shareImage: WixMediaResource | null;
    }
}

export const doppeActionTypeShare = doppeActionTypeDefineMetadata<DoppeActionTypeShare.ActionSettings>({
    id: 'io.bymo.action/share',

    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        shareLink: '',
        shareTitle: doppeHideableValueCreateHidden(''),
        description: '',
        shareButtonText: '',
        shareImage: null,

    }),
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([actionSettings.shareImage]);
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.shareImage;
    },
    resolveMainLink: actionSettings => {
        return actionSettings.shareLink;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.shareImage) {
                actionSettings.shareImage =
                    wixMediaResourceReplaceExternalImage(actionSettings.shareImage, externalImagesUrlToImageResourceMap)
            }
        })
    },

});