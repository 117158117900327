import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    convertDoppeWixProductToProductSettings,
    DoppeActionTypeProduct, populateProductActionSettingsDefaultTexts
} from '../doppe-action-type-product/doppe-action-type-product';
import {arrayFlatten, asArray, immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    DoppeDtoStoreProduct,
    doppeExternalProductProviderResolveBusinessEntityReference,
    doppeHideableValueCreateHidden, doppeHideableValueIsHidden,
    DoppeListActionSettings,
    DoppeProductsExternalDataSource,
    doppeProductsExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common';
import {doppeDtoActionGetDefaultSearchListSettingsProps} from '../../client-server-common/types/doppe-dto-action';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';

export namespace DoppeActionTypeProducts {

    export interface ProductSettings extends DoppeActionTypeProduct.ActionSettings {
        id: string;
        productTitle: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeProductsExternalDataSource> {
        products: ProductSettings[];
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowCoupon: boolean;
        previewProductButtonText: string;
    }
}

export const doppeActionTypeProducts = doppeActionTypeDefineMetadata<DoppeActionTypeProducts.ActionSettings>({
    id: 'io.bymo.action/products',
    resolveActionSettings: action => {

        const {
            products = [],
            ...restSettings
        } = action.settings;

        return {
            products: products.map(product => {
                return {
                    productProvider: null,
                    productProviderId: null,
                    ribbon: doppeHideableValueCreateHidden('SALE'),
                    ...product as PartiallyOptional<DoppeActionTypeProducts.ProductSettings, 'productProvider' | 'productProviderId' | 'ribbon'>
                }
            }),
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
            layout: DoppeActionLayout.Column,
            showButton: false,
            buttonText: 'Visit My Store',
            buttonUrl: 'https://www.website.com',

            itemShowDescription: true,
            itemShowTitle: true,
            itemShowImage: true,
            itemShowPrice: true,
            itemShowDate: false,
            itemShowTextOnImage: false,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewProductButtonText: 'Buy Now',

            externalDataSource: null,
            externalDataSourceItemsCount: 4,
            listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),

            mainImage: doppeHideableValueCreateHidden(null),
            mainTitle: doppeHideableValueCreateHidden('My Products'),

            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(iterableMapToArray(actionSettings.products, (product, skip) => {
            return product.productImage ? asArray(product.productImage) : skip
        }));
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.products[0]?.productImage ? asArray(actionSettings.products[0]?.productImage)[0] ?? null : null;
    },
    resolveMainLink: actionSettings => {
        return actionSettings.showButton ? actionSettings.buttonUrl : null;
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeProductsExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.products, (product, skip) => {
                return product.productProvider !== null && product.productProviderId !== null ?
                    doppeExternalProductProviderResolveBusinessEntityReference(
                        product.productProvider,
                        product.productProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : actionSettings.products.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.products.map(item => {
            return {
                title: item.productTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.description,
                image: item.productImage ? asArray(item.productImage)[0] : null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.productPageLink
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.products) {
                if (item.productImage) {
                    const images = asArray(item.productImage);
                    item.productImage = images.map(image => {
                        return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                    });
                }
            }
        })
    },
});

export function populateProductsActionProductSettingsDefaultTexts(options: {
    product: DoppeActionTypeProducts.ProductSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}) : DoppeActionTypeProducts.ProductSettings {

    const normalizedProduct = populateProductActionSettingsDefaultTexts({
        pageContentLocales: options.pageContentLocales,
        product: options.product
    })

    return {
        ...options.product,
        ...normalizedProduct,
        productTitle: doppeHideableValueIsHidden(normalizedProduct.productTitle) ? '' : normalizedProduct.productTitle,
    }
}

export function convertDoppeWixProductToProductsSettings(
    product: DoppeDtoStoreProduct,
): DoppeActionTypeProducts.ProductSettings {
    const productSettings = convertDoppeWixProductToProductSettings({
        product: product,
    });

    return {
        ...productSettings,
        productButtonText: doppeHideableValueCreateHidden(''),
        id: product.id,
    }
}