import {
    areaAlignToArea,
    assertType,
    evaluateFunction,
    FocalPoint,
    HorizontalAlignment,
    HtmlObjectHorizontalPosition,
    HtmlObjectResizeMode,
    HtmlObjectVerticalPosition,
    OmitStrict,
    PartiallyRequired,
    pathGetExtension,
    regexExec,
    sizeCalculateContainedSize,
    sizeCalculateCoverSize,
    stringEnsurePrefix, stringTrimPrefix,
    urlIsAbsolute,
    urlJoin,
    urlPathJoin,
    validateIsUrl,
    Values,
    VerticalAlignment
} from '@wix/devzai-utils-common';
import {wixMediaPlatformGetImagesServiceDomain} from "../wix-media-platform/wix-media-platform";

export const WixImageResourceTypes = {
    MediaPlatform: 'media-platform',
    MediaManager: 'media-manager',
    External: 'external'
} as const;

export type WixImageResourceTypes = Values<typeof WixImageResourceTypes>;

export interface WixMediaPlatformImageResource {
    path: string;
    projectId: string;
    type: typeof WixImageResourceTypes.MediaPlatform;
}

export interface WixImageInfo {
    url:string;
    width:number;
    height:number;
    title?: string;
    isVectorImage?: boolean;
    basePath?: string;
    originalImageUrl?: string;
}

export namespace WixImageInfo {

    export type Imported = PartiallyRequired<WixImageInfo, 'originalImageUrl'>;

}

/**
 * This is an older version of WixImageResource, that's left for compatibility with existing projects.
 * @deprecated use WixImageResource whenever possible
 */
export type WixImageSource = WixImageInfo | string;

/**
 * Images that can be rendered in Wix applications are either external images that are represented by a url string
 * or internal images that are represented by either a WixImageInfo object (for media manager)
 * or a WixMediaPlatformImageResource object (for media platform).
 */
export type WixImageResource = WixImageInfo | string | WixMediaPlatformImageResource;

export type WixResponsiveImageResource = OmitStrict<WixImageInfo, 'isVectorImage'> & {
    isVectorImage?: false;
};

export interface CommonWixImageUrlResolvingOptions {
    filters?: {
        blur: number;
    };
    unsharpMask?: {
        amount: number;
        radius: number;
        threshold: number;
    };
    name?: string;
    quality?: number;
    autoEncode?: boolean;
}

export function wixMediaPlatformImageResorceCreate (projectId: string, path: string) : WixMediaPlatformImageResource {
    return {
        path: path,
        projectId: projectId,
        type: WixImageResourceTypes.MediaPlatform
    }
}

export function wixMediaPlatformImageResourceGetImageUrl (imageInfo: WixMediaPlatformImageResource) {
    return urlJoin(wixMediaPlatformGetImagesServiceDomain(imageInfo.projectId), imageInfo.path);
}


export function wixImageSourceIsMediaPlatformImageInfo (wixImageSource: WixImageResource) : wixImageSource is WixMediaPlatformImageResource {
    return typeof wixImageSource !== 'string' && (wixImageSource as WixMediaPlatformImageResource).type === WixImageResourceTypes.MediaPlatform;
}

export function wixImageResourceIsImportedImage (wixImageResource: WixImageResource) : wixImageResource is WixImageInfo.Imported {
    return wixImageResourceIsMediaManagerImageInfo(wixImageResource) && wixImageResource.originalImageUrl !== undefined;
}

export function wixImageResourceIsExternalImage (wixImageResource: WixImageResource) : wixImageResource is WixImageInfo | string {
    if (typeof wixImageResource === 'string') {
        return true;
    } else if (wixImageSourceIsMediaPlatformImageInfo(wixImageResource)) {
        return false;
    } else {
        return wixMediaManagerTryResolveMediaUri(wixImageResource.url) === null
    }
}

export function wixImageResourceGetUrlWhenExternalImage (wixImageResource: WixImageResource) : string | null {
    if (wixImageResourceIsExternalImage(wixImageResource)) {
        return typeof wixImageResource === 'string' ? wixImageResource : wixImageResource.url;
    } else {
        return null;
    }
}

export function wixImageResourceResolveImageWidthHeightRatio (wixImageResource: WixImageResource) : number | null {
    if (typeof wixImageResource !== 'string' && !wixImageSourceIsMediaPlatformImageInfo(wixImageResource)) {
        return wixImageResource.width / wixImageResource.height;
    } else {
        return null;
    }
}

export function wixImageResourceIsMediaManagerImageInfo (wixImageResource: WixImageResource) : wixImageResource is WixImageInfo {
    return typeof wixImageResource !== 'string' &&
        !wixImageSourceIsMediaPlatformImageInfo(wixImageResource) &&
        wixMediaManagerTryResolveMediaUri(wixImageResource.url) !== null;
}

export function wixImageResourceIsVectorImage (wixImageResource: WixImageResource) {
    return wixImageResourceIsMediaManagerImageInfo(wixImageResource) && wixImageResource.isVectorImage === true;
}

export function wixImageResourceIsResponsiveImage (wixImageResource: WixImageResource) : wixImageResource is WixResponsiveImageResource {
    return (
        wixImageResourceIsMediaManagerImageInfo(wixImageResource) &&
        !wixImageResource.isVectorImage)
}

export function wixImageResourceGetOriginalImageSize (wixImageResource: WixImageResource) {

    if (typeof wixImageResource !== 'string' &&
        !wixImageSourceIsMediaPlatformImageInfo(wixImageResource) &&
        !wixImageResource.isVectorImage
    ) {
        return {
            width: wixImageResource.width,
            height: wixImageResource.height
        }
    } else {
        return undefined;
    }
}

export function wixImageResourceGetResizedImageUrl (
    wixImageResource: WixImageResource,
    options: CommonWixImageUrlResolvingOptions & {
        targetWidth?: number;
        targetHeight?: number;
        resizeMode: HtmlObjectResizeMode;
        cropImage?: {
            positionVertical: HtmlObjectVerticalPosition;
            positionHorizontal: HtmlObjectHorizontalPosition;
        } | FocalPoint;
    }
) {
    const {
        targetWidth,
        targetHeight,
        resizeMode,
        cropImage,
        ...restOptions
    } = options;

    if (targetWidth === 0 || targetHeight === 0 || (resizeMode !== HtmlObjectResizeMode.Fill && targetWidth === undefined && targetHeight === undefined)) {
        // Unknown size
        return undefined;
    }

    const roundedTargetWidth = targetWidth !== undefined ? Math.floor(targetWidth) : undefined;
    const roundedTargetHeight = targetHeight !== undefined ? Math.floor(targetHeight) : undefined;

    if (!wixImageResourceIsResponsiveImage(wixImageResource)) {
        return wixImageResourceGetUrl(wixImageResource, restOptions);
    } else {
        const imageWidth = wixImageResource.width;
        const imageHeight = wixImageResource.height;

        if (resizeMode === HtmlObjectResizeMode.ScaleDown && imageWidth <= (roundedTargetWidth ?? Infinity) && imageHeight <= (roundedTargetHeight ?? Infinity)) {
            return wixImageResourceGetUrl(wixImageResource, restOptions);
        } else {
            switch (resizeMode) {
                case HtmlObjectResizeMode.ScaleDown:
                case HtmlObjectResizeMode.Contain: {
                    return wixResponsiveImageResourceGetScaleToFitImageUrl({
                        url: wixImageResource.url,
                        height: imageHeight,
                        width: imageWidth
                    }, {
                        targetWidth: roundedTargetWidth ?? Infinity,
                        targetHeight: roundedTargetHeight ?? Infinity,
                        ...restOptions
                    });
                }
                case HtmlObjectResizeMode.Fill: {
                    return wixImageResourceGetUrl({
                        ...wixImageResource,
                        width: roundedTargetWidth ?? imageWidth,
                        height: roundedTargetHeight ?? imageHeight,
                    })
                }
                case HtmlObjectResizeMode.Cover: {

                    if (cropImage && wixResponsiveImageResourceCanCrop(wixImageResource)) {

                        const normalizedTargetWidth = roundedTargetWidth ?? 0;
                        const normalizedTargetHeight = roundedTargetHeight ?? 0;

                        if ('x' in cropImage) {
                            return wixImageInfoGetCroppedImageUrlWithFocalPoint(wixImageResource, {
                                targetWidth: normalizedTargetWidth,
                                targetHeight: normalizedTargetHeight,
                                focalPoint: cropImage,
                                ...restOptions
                            })
                        } else {
                            const cropSize = sizeCalculateContainedSize(
                                {
                                    width: normalizedTargetWidth,
                                    height: normalizedTargetHeight
                                },
                                {
                                    width: imageWidth,
                                    height: imageHeight
                                })

                            const cropPosition = areaAlignToArea(
                                {left: 0, top: 0, width: cropSize.width, height: cropSize.height},
                                {left: 0, top: 0, width: imageWidth, height: imageHeight},
                                {
                                    horizontalAlignment: evaluateFunction(() => {
                                        switch (cropImage.positionHorizontal) {
                                            case HtmlObjectHorizontalPosition.Left: return HorizontalAlignment.Left;
                                            case HtmlObjectHorizontalPosition.Right: return HorizontalAlignment.Right;
                                            case HtmlObjectHorizontalPosition.Center: return HorizontalAlignment.Center;
                                        }
                                    }),
                                    verticalAlignment: evaluateFunction(() => {
                                        switch (cropImage.positionVertical) {
                                            case HtmlObjectVerticalPosition.Top: return VerticalAlignment.Top;
                                            case HtmlObjectVerticalPosition.Bottom: return VerticalAlignment.Bottom;
                                            case HtmlObjectVerticalPosition.Center: return VerticalAlignment.Center;
                                        }
                                    })
                                }
                            )

                            return wixImageInfoGetCroppedImageUrl(wixImageResource, {
                                cropX: Math.floor(cropPosition.left),
                                cropY: Math.floor(cropPosition.top),
                                cropWidth: cropSize.width,
                                cropHeight: cropSize.height,
                                targetWidth: normalizedTargetWidth,
                                targetHeight: normalizedTargetHeight,
                                ...restOptions
                            })
                        }
                    } else {
                        return wixResponsiveImageResourceGetScaleToCoverImageUrl({
                            url: wixImageResource.url,
                            height: imageHeight,
                            width: imageWidth
                        }, {
                            targetWidth: roundedTargetWidth ?? 0,
                            targetHeight: roundedTargetHeight ?? 0,
                            ...restOptions
                        });
                    }
                }
                default: {
                    throw new Error(`resizeMode '${resizeMode}' is not supported`)
                }
            }
        }
    }
}

export function wixResponsiveImageResourceCanCrop (wixResponsiveImageResource: WixResponsiveImageResource) {
    return !urlIsAbsolute(wixResponsiveImageResource.url);
}

export function wixResponsiveImageResourceGetScaleToCoverImageUrl (
    wixResponsiveImageResource: WixResponsiveImageResource,
    options: CommonWixImageUrlResolvingOptions & {
        targetWidth: number;
        targetHeight: number;
    }
) : string {

    const imageWidth = wixResponsiveImageResource.width;
    const imageHeight = wixResponsiveImageResource.height;

    const coverSize = sizeCalculateCoverSize(
        {width: imageWidth, height: imageHeight},
        {width: options.targetWidth, height: options.targetHeight})

    return wixImageInfoGetScaleToFitImageUrl(wixResponsiveImageResource, {
        ...options,
        targetWidth: coverSize.width,
        targetHeight: coverSize.height
    });
}

export function wixResponsiveImageResourceGetScaleToFitImageUrl (
    wixResponsiveImageResource: WixResponsiveImageResource,
    options: CommonWixImageUrlResolvingOptions & {
        targetWidth: number;
        targetHeight: number;
    }
) : string {
    return wixImageInfoGetScaleToFitImageUrl(wixResponsiveImageResource, options);
}

export function wixImageResourceGetRawImageUrl (wixImageResource: WixImageResource) {
    if (typeof wixImageResource === 'string') {
        return wixImageResource;
    } else if (wixImageSourceIsMediaPlatformImageInfo(wixImageResource)) {
        return wixMediaPlatformImageResourceGetImageUrl(wixImageResource);
    } else {
        const imageUrl = wixImageResource.url;

        const mediaManagerMediaUri = wixMediaManagerTryResolveMediaUri(imageUrl);
        if (!mediaManagerMediaUri) {
            return imageUrl;
        } else if (wixImageResource.isVectorImage) {
            return `https://static.wixstatic.com${stringEnsurePrefix(urlPathJoin(wixImageResource.basePath ?? 'shapes', imageUrl), '/')}`;
        } else {
            return wixMediaResourceResolveMediaManagerAbsoluteUrlFromMediaUri(mediaManagerMediaUri)
        }
    }
}

export function wixImageResourceGetUrl (wixImageResource: WixImageResource, options: CommonWixImageUrlResolvingOptions = {}) {
    if (typeof wixImageResource === 'string') {
        return wixImageResource;
    } else if (wixImageSourceIsMediaPlatformImageInfo(wixImageResource)) {
        return wixMediaPlatformImageResourceGetImageUrl(wixImageResource);
    } else {
        const imageUrl = wixImageResource.url;

        if (urlIsAbsolute(imageUrl)) {
            return imageUrl;
        } else if (wixImageResource.isVectorImage) {
            return `https://static.wixstatic.com${stringEnsurePrefix(urlPathJoin(wixImageResource.basePath ?? 'shapes', imageUrl), '/')}`;
        } else {

            return getScaleToFitImageURL(
                wixImageResource.url,
                wixImageResource.width,
                wixImageResource.height,
                wixImageResource.width,
                wixImageResource.height,
                options
            )
        }
    }
}

/**
 * @deprecated - use wixImageResourceGetUrl instead
 */
export function wixImageInfoGetImageUrl (
    wixImageInfo: WixImageInfo,
    options: CommonWixImageUrlResolvingOptions = {}
) : string {
    return getScaleToFitImageURL(
        wixImageInfo.url,
        wixImageInfo.width,
        wixImageInfo.height,
        wixImageInfo.width,
        wixImageInfo.height,
        options
    )
}

export function wixImageInfoGetScaleToFitImageUrl (
    wixImageInfo: WixImageInfo,
    options: CommonWixImageUrlResolvingOptions & {
        targetWidth: number;
        targetHeight: number;
    }
) : string {
    const {
        targetWidth,
        targetHeight,
        ...commonOptions
    } = options;

    return getScaleToFitImageURL(
        wixImageInfo.url,
        wixImageInfo.width,
        wixImageInfo.height,
        targetWidth,
        targetHeight,
        commonOptions
    )
}

export function wixImageInfoGetCroppedImageUrl (
    wixImageInfo: WixImageInfo,
    options: CommonWixImageUrlResolvingOptions & {
        targetWidth: number;
        targetHeight: number;
        cropWidth: number;
        cropHeight: number;
        cropX: number;
        cropY: number;
    }
) : string {
    const {
        cropWidth,
        cropHeight,
        cropX,
        cropY,
        targetWidth,
        targetHeight,
        ...commonOptions
    } = options;

    const imageUri = wixImageInfo.url;

    const mediaManagerMediaUri = wixMediaManagerTryResolveMediaUri(imageUri);

    if (mediaManagerMediaUri === null) {
        return imageUri;
    }

    const sourceWidth = wixImageInfo.width;
    const sourceHeight = wixImageInfo.height;

    const {
        filters,
        name,
        unsharpMask,
        quality,
        autoEncode = true
    } = commonOptions;

    const containedSize = sizeCalculateContainedSize({width: sourceWidth, height: sourceHeight}, {width: targetWidth, height: targetHeight})

    const paramsStr = [
        'al_c',
        `q_${quality ?? resolveImageDefaultQuality(containedSize.width, containedSize.height)}`
    ]

    const resolvedUsm = unsharpMask ?? evaluateFunction(() => {

        if (containedSize.width < sourceWidth) {
            return wixImageDefaultScaleDownUnsharpMask
        }

        return undefined;
    })

    if (resolvedUsm) {
        paramsStr.push(`usm_${resolvedUsm.radius.toFixed(2)}_${resolvedUsm.amount.toFixed(2)}_${resolvedUsm.threshold.toFixed(2)}`)
    }

    if (filters) {
        paramsStr.push(`blur_${filters.blur}`)
    }

    if (autoEncode) {
        paramsStr.push('enc_auto')
    }

    const imageName = name ? name + pathGetExtension(mediaManagerMediaUri) : mediaManagerMediaUri;

    return `${wixMediaResourceResolveMediaManagerAbsoluteUrlFromMediaUri(mediaManagerMediaUri)}/v1/crop/x_${cropX},y_${cropY},w_${cropWidth},h_${cropHeight}/fill/w_${targetWidth},h_${targetHeight},${paramsStr.join(',')}/${imageName}`
}

export function wixImageInfoGetCroppedImageUrlWithFocalPoint (
    wixImageInfo: WixImageInfo,
    options: CommonWixImageUrlResolvingOptions & {
        targetWidth: number;
        targetHeight: number;
        focalPoint: FocalPoint;
    }
) : string {
    const {
        targetWidth,
        targetHeight,
        focalPoint,
        ...commonOptions
    } = options;

    const imageUri = wixImageInfo.url;

    const mediaManagerMediaUri = wixMediaManagerTryResolveMediaUri(imageUri);

    if (mediaManagerMediaUri === null) {
        return imageUri;
    }

    const sourceWidth = wixImageInfo.width;
    const sourceHeight = wixImageInfo.height;

    const {
        filters,
        name,
        unsharpMask,
        quality,
        autoEncode = true
    } = commonOptions;

    const containedSize = sizeCalculateContainedSize({width: sourceWidth, height: sourceHeight}, {width: targetWidth, height: targetHeight})

    const paramsStr = [
        'al_c',
        `q_${quality ?? resolveImageDefaultQuality(containedSize.width, containedSize.height)}`
    ]

    const resolvedUsm = unsharpMask ?? evaluateFunction(() => {

        if (containedSize.width < sourceWidth) {
            return wixImageDefaultScaleDownUnsharpMask
        }

        return undefined;
    })

    if (resolvedUsm) {
        paramsStr.push(`usm_${resolvedUsm.radius.toFixed(2)}_${resolvedUsm.amount.toFixed(2)}_${resolvedUsm.threshold.toFixed(2)}`)
    }

    if (filters) {
        paramsStr.push(`blur_${filters.blur}`)
    }

    if (autoEncode) {
        paramsStr.push('enc_auto')
    }

    const imageName = name ? name + pathGetExtension(mediaManagerMediaUri) : mediaManagerMediaUri;

    return `${wixMediaResourceResolveMediaManagerAbsoluteUrlFromMediaUri(mediaManagerMediaUri)}/v1/fill/w_${targetWidth},h_${targetHeight},fp_${(focalPoint.x / 100).toFixed(2)}_${(focalPoint.y / 100).toFixed(2)},${paramsStr.join(',')}/${imageName}`
}

export function assertWixImageInfo (wixImageInfo: WixImageInfo) : asserts wixImageInfo is WixImageInfo;
export function assertWixImageInfo (wixImageInfo: unknown) : asserts wixImageInfo is WixImageInfo;
export function assertWixImageInfo (wixImageInfo: unknown) : asserts wixImageInfo is WixImageInfo {
    assertType<WixImageInfo>(wixImageInfo, assert => {
        assert.isObject({
            url: assert => assert.isString(),
            width: assert => assert.isNumber(),
            height: assert => assert.isNumber(),
            title: assert => assert.optional.isString(),
            isVectorImage: assert => assert.optional.isBoolean(),
            basePath: assert => assert.optional.isString(),
            originalImageUrl: assert => assert.optional.isString()
        })
    })
}

export function assertWixMediaPlatformImageResource (wixMediaPlatformImageResource: WixMediaPlatformImageResource) : asserts wixMediaPlatformImageResource is WixMediaPlatformImageResource;
export function assertWixMediaPlatformImageResource (wixMediaPlatformImageResource: unknown) : asserts wixMediaPlatformImageResource is WixMediaPlatformImageResource;
export function assertWixMediaPlatformImageResource (wixMediaPlatformImageResource: unknown) : asserts wixMediaPlatformImageResource is WixMediaPlatformImageResource {
    assertType<WixMediaPlatformImageResource>(wixMediaPlatformImageResource, assert => {
        assert.isObject({
            path: assert => assert.isString(),
            type: assert => assert.isOneOfValues([WixImageResourceTypes.MediaPlatform]),
            projectId: assert => assert.isString()
        })
    })
}

export function assertWixImageResource (wixImageResource: WixImageResource) : asserts wixImageResource is WixImageResource;
export function assertWixImageResource (wixImageResource: unknown) : asserts wixImageResource is WixImageResource;
export function assertWixImageResource (wixImageResource: unknown) : asserts wixImageResource is WixImageResource {
    assertType<WixImageResource>(wixImageResource, assert => {
        assert.isUnion(
            assert => assert.usingAssertionFunction(assertWixImageInfo),
            assert => assert.usingAssertionFunction(assertWixMediaPlatformImageResource),
            assert => assert.isString(value => {
                return validateIsUrl(value, {
                    protocols: ['http', 'https'],
                    require_protocol: true
                })
            }),
        )
    })
}

export function wixMediaManagerTryResolveMediaUri (mediaUri: string) {

    if (urlIsAbsolute(mediaUri)) {
        return regexExec(new RegExp('^https://static.wixstatic.com/media/([^/]+)($|/)'), mediaUri)?.[1] ?? null
    } else {
        return mediaUri;
    }
}

function wixMediaResourceResolveMediaManagerAbsoluteUrlFromMediaUri (mediaUri: string) {

    const imageUri = stringTrimPrefix(mediaUri, 'media/')

    const staticMediaUrl = 'https://static.wixstatic.com/media';
    const mediaRootUrl = 'https://static.wixstatic.com'

    const isExternalUrl = /(^https?)|(^data)|(^blob)|(^\/\/)/.test(imageUri);

    if (isExternalUrl) {
        throw new Error(`imageUri can't be an absolute URL`)
    }

    let path = `${staticMediaUrl}/`;

    if (imageUri) {
        if (/^micons\//.test(imageUri)) {
            path = mediaRootUrl;
        } else if (/[^.]+$/.exec(imageUri)?.[0] === 'ico') {
            // if the image is an icon then it's taken from a slightly different place
            path = path.replace('media', 'ficons');
        }
    }

    return path + imageUri;
}

export const wixImageDefaultScaleDownUnsharpMask = {
    radius: 0.66,
    amount: 1.00,
    threshold: 0.01,
};

function getScaleToFitImageURL (
    imageUri: string,
    sourceWidth: number,
    sourceHeight: number,
    targetWidth: number,
    targetHeight: number,
    options: CommonWixImageUrlResolvingOptions = {}
) {
    const {
        filters,
        name,
        unsharpMask,
        quality,
        autoEncode = true
    } = options;

    const mediaManagerMediaUri = wixMediaManagerTryResolveMediaUri(imageUri);

    if (mediaManagerMediaUri === null) {
        return imageUri;
    }

    const containedSize = sizeCalculateContainedSize({width: sourceWidth, height: sourceHeight}, {width: targetWidth, height: targetHeight})

    const paramsStr = [
        `w_${containedSize.width}`,
        `h_${containedSize.height}`,
        'al_c',
        `q_${quality ?? resolveImageDefaultQuality(containedSize.width, containedSize.height)}`
    ]

    const resolvedUsm = unsharpMask ?? evaluateFunction(() => {

        if (containedSize.width < sourceWidth) {
            return wixImageDefaultScaleDownUnsharpMask
        }

        return undefined;
    })

    if (resolvedUsm) {
        paramsStr.push(`usm_${resolvedUsm.radius.toFixed(2)}_${resolvedUsm.amount.toFixed(2)}_${resolvedUsm.threshold.toFixed(2)}`)
    }

    if (filters) {
        paramsStr.push(`blur_${filters.blur}`)
    }

    if (autoEncode) {
        paramsStr.push('enc_auto')
    }

    const imageName = name ? name + pathGetExtension(mediaManagerMediaUri) : mediaManagerMediaUri;

    return `${wixMediaResourceResolveMediaManagerAbsoluteUrlFromMediaUri(mediaManagerMediaUri)}/v1/fill/${paramsStr.join(',')}/${imageName}`
}

function resolveImageDefaultQuality(imageWidth: number, imageHeight: number) {
    const size = imageWidth * imageHeight;
    if (size > 1400 * 1400) {
        return 90;
    } else if (size > 600 * 600) {
        return 85;
    } else if (size > 400 * 400) {
        return 80;
    } else {
        return 80;
    }
}