import {Values} from '@wix/devzai-utils-common';
import {WixImageInfo} from '../wix-image/wix-image';


export const WixBookingType = {
    UNKNOWN: 'UNKNOWN_SERVICE_TYPE',
    APPOINTMENT: 'APPOINTMENT',
    CLASS: 'CLASS',
    COURSE: 'COURSE',
} as const;

const LocationType = {
    Unspecified: 'UNKNOWN_LOCATION_TYPE',
    CUSTOM: 'CUSTOM',
    BUSINESS: 'BUSINESS',
    CUSTOMER: 'CUSTOMER',
} as const;

//     FIXED: The service has a fixed price.
//     CUSTOM: The service has a custom price, expressed as a price description.
//     VARIED: This service is offered with a set of different prices based on different terms.
//     NO_FEE: This service is offered free of charge.
export const RateType = {
    Unspecified: 'UNKNOWN_RATE_TYPE',
    CUSTOM: 'CUSTOM',
    FIXED: 'FIXED',
    VARIED: 'VARIED',
    NO_FEE: 'NO_FEE',
} as const;


export namespace WixBookingService {

    export type ServiceType = Values<typeof WixBookingType>;
    export type LocationType = Values<typeof LocationType>;
    export type RateType = Values<typeof RateType>;

    export interface Price {
        value: string;
        currency: string;
        formattedValue: string;
    }

    export interface Payment {
        rateType: RateType;
        fixed?: {
            price: Price;
            deposit?: Price;
        };
        custom?: string;
        varied?: {
            defaultPrice?: Price;
            deposit?: Price;
            minPrice?: Price;
            maxPrice?: Price;
        }
        options: {
            online: boolean;
            inPerson: boolean;
            deposit: boolean;
            pricingPlan: boolean;
        }
    }

    export interface Schedule {
        id: string;
        firstSessionStart?: Date;
        lastSessionEnd?: Date;
        availabilityConstraints?: {
            sessionDurations?: number[];
            timeBetweenSessions?: number;
        }

    }

    export interface Media {
        items: WixImageInfo[];
        mainMedia: WixImageInfo | null;
        coverMedia: WixImageInfo | null;
    }

    export interface Location {
        type: WixBookingService.LocationType;
        country: string;
        subdivision?: string;
        city: string;
        postalCode: string;
        streetAddress: {
            number?: string;
            name?: string;
            apt?: string;
        } | string;
        formattedAddress: string;
    }

    export interface PageUrl {
        relativePath?: String;
        url?: String;
    }
}

export interface WixBookingService {
    id: string;
    type?: WixBookingService.ServiceType;
    name: string;
    description: string;
    tagLine: string;
    defaultCapacity: number;
    createdDate: Date;
    locations: WixBookingService.Location[];
    payment: WixBookingService.Payment;
    media: WixBookingService.Media;
    schedule: WixBookingService.Schedule;
    calculatedPrice: string;
    hidden: boolean;
    urls: {
        bookingPage?: WixBookingService.PageUrl;
        calenderPage?: WixBookingService.PageUrl;
        servicePage?: WixBookingService.PageUrl;
    }
}


