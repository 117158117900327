import {Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity} from './doppe-business-entity';
import {DoppeAutoMembershipPlansType} from './doppe-auto-membership-plan-type';

export const DoppeMembershipPlansExternalDataSourceType = {
    LatestWixMembershipPlans: 'LatestWixMembershipPlans'
} as const;

export type DoppeMembershipPlansExternalDataSourceType = Values<typeof DoppeMembershipPlansExternalDataSourceType>;

export type DoppeMembershipPlansExternalDataSource =
    | {
    type: typeof DoppeMembershipPlansExternalDataSourceType.LatestWixMembershipPlans;
};

export function doppeMembershipPlansExternalDataSourceResolveBusinessEntityReferences(
    externalDataSource: DoppeMembershipPlansExternalDataSource
) {
    switch (externalDataSource.type) {
        case DoppeMembershipPlansExternalDataSourceType.LatestWixMembershipPlans: {
            return {businessEntity: DoppeBusinessEntity.WixAutoMembershipPlansList, id: DoppeAutoMembershipPlansType.Latest}
        }
    }
}