import {DoppeDtoAction} from "../types/doppe-dto-action";
import {DoppeActionButtonVariation} from "../types/doppe-action-button-variation";
import {arrayFirst, assertType, Values} from "@wix/devzai-utils-common";

export const BymoActionsMode = {
    SingleAction: 'SingleAction',
    MultipleAction: 'MultipleAction',
    MultipleWithPrimary: 'MultipleWithPrimary'
} as const;

export type BymoActionsMode = Values<typeof BymoActionsMode>;

export const SecondaryActionsViewType = {
    MoreButton: 'MoreButton',
    OnPage: 'OnPage'
} as const;

export type SecondaryActionsViewType = Values<typeof SecondaryActionsViewType>;

export const PrimaryActionViewType = {
    ActionButton: 'ActionButton',
    OnPage: 'OnPage'
} as const;

export type PrimaryActionViewType = Values<typeof PrimaryActionViewType>;

export interface PrimaryActionDisplaySettingsBase<VIEW_TYPE extends PrimaryActionViewType> {
    viewType: VIEW_TYPE;
    showSeparator?: boolean;
    showActionHeader?: boolean;
}

export type PrimaryActionDisplaySettings =
    | PrimaryActionDisplaySettingsBase<typeof PrimaryActionViewType.ActionButton> & {
        buttonVariation: DoppeActionButtonVariation;
    }
    | PrimaryActionDisplaySettingsBase<typeof PrimaryActionViewType.OnPage> & {}

export interface SecondaryActionsViewSettingsBase<VIEW_TYPE extends SecondaryActionsViewType> {
    viewType: VIEW_TYPE;
    buttonVariation: DoppeActionButtonVariation;
}

export type SecondaryActionsViewSettings =
    | SecondaryActionsViewSettingsBase<typeof SecondaryActionsViewType.MoreButton> & {
        moreButtonText: string;
    }
    | SecondaryActionsViewSettingsBase<typeof SecondaryActionsViewType.OnPage>

export interface BymoActionsSettings {
    primaryActionId?: DoppeDtoAction['id'];
    primaryActionViewSettings: PrimaryActionDisplaySettings;
    secondaryActionsViewSettings: SecondaryActionsViewSettings;
    actionsMode: BymoActionsMode;
}

export function bymoActionsResolvePrimaryAction (
    bymoActionsSettings: BymoActionsSettings,
    actions: DoppeDtoAction[]
) {
    const enabledActions = actions.filter(action => action.enabled);
    const primaryActionId = bymoActionsSettings.primaryActionId;
    const primaryAction = primaryActionId !== undefined ? enabledActions.find(action => action.id === primaryActionId) : undefined;

    return primaryAction ?? arrayFirst(enabledActions);
}

export function bymoActionsModeSupportsPrimaryAction (bymoActionMode: BymoActionsMode) {
    return bymoActionMode === BymoActionsMode.MultipleWithPrimary || bymoActionMode === BymoActionsMode.SingleAction;
}

export function assertBymoActionsSettings (bymoActionsSettings: BymoActionsSettings) : asserts bymoActionsSettings is BymoActionsSettings;
export function assertBymoActionsSettings (bymoActionsSettings: unknown) : asserts bymoActionsSettings is BymoActionsSettings;
export function assertBymoActionsSettings (bymoActionsSettings: unknown) : asserts bymoActionsSettings is BymoActionsSettings {
    assertType<BymoActionsSettings>(bymoActionsSettings, assert => {
        assert.isObject({
            primaryActionId: assert => assert.optional.isString(),
            actionsMode: assert => assert.isOneOfValues(Object.values(BymoActionsMode)),
            secondaryActionsViewSettings: assert => assert.usingAssertionFunction(() => {
                // TODO
            }),
            primaryActionViewSettings: assert => assert.usingAssertionFunction(() => {
                // TODO
            })
        })
    })
}