import { Offset, Point } from '@wix/devzai-utils-common';

export type PointerLikeEvent = MouseEvent | TouchEvent;

export function pointerLikeEventIsTouchEvent(event: PointerLikeEvent): event is TouchEvent {
    return (event as TouchEvent).touches !== undefined;
}

export function pointerLikeEventGetPoint(event: PointerLikeEvent, useClientOffset = false): Point {
    const pointSource: Touch | MouseEvent = pointerLikeEventIsTouchEvent(event) ? event.touches[0] : event;

    if (useClientOffset) {
        return {
            x: pointSource.clientX,
            y: pointSource.clientY
        };
    } else {
        return {
            x: pointSource.pageX,
            y: pointSource.pageY
        };
    }
}

export function pointerLikeEventGetOffset(event: PointerLikeEvent, useClientOffset = false): Offset {
    const pointSource: Touch | MouseEvent = pointerLikeEventIsTouchEvent(event) ? event.touches[0] : event;

    if (useClientOffset) {
        return {
            top: pointSource.clientY,
            left: pointSource.clientX
        };
    } else {
        return {
            top: pointSource.pageY,
            left: pointSource.pageX
        };
    }
}
