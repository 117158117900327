import {
    BymoPageCommonProps,
    BymoPageFeatures,
    BymoPageFeaturesProps,
    BymoPageSharedCommonProps
} from "../../client-server-common";

export interface BymoTemplateMetadata<
    PARAMS extends {} = Record<string, any>,
    FEATURES extends BymoPageFeatures[] = BymoPageFeatures[]
> {
    initialValues: PARAMS & Partial<BymoPageCommonProps>;
    features: FEATURES
}

export type BymoTemplateMetadataInferBymoPageProps<METADATA extends BymoTemplateMetadata> =
    METADATA extends BymoTemplateMetadata<infer PARAMS, infer FEATURES> ?
        PARAMS & BymoPageSharedCommonProps & Pick<BymoPageCommonProps,
            FEATURES extends (infer FEATURE)[] ? FEATURE extends BymoPageFeatures ? (keyof BymoPageFeaturesProps[FEATURE]) : never : never
        > :
        never;

export interface BymoTemplateData<METADATA extends BymoTemplateMetadata = any> {
    componentType: React.ComponentType<BymoTemplateMetadataInferBymoPageProps<METADATA>>;
    initialValues: METADATA['initialValues'];
    features: METADATA['features']
}

export function bymoTemplateMetadataFactoryCreate<PARAMS extends {}> () {
    return <METADATA extends BymoTemplateMetadata<PARAMS>>(bymoTemplateMetadata: METADATA) => bymoTemplateMetadata
}

export function bymoTemplateDataCreateForMetadata<METADATA extends BymoTemplateMetadata>(
    templateMetadata: METADATA,
    componentType: React.ComponentType<BymoTemplateMetadataInferBymoPageProps<METADATA>>
) : BymoTemplateData<METADATA> {
    return {
        componentType: componentType,
        ...templateMetadata
    };
}