import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import {doppeActionTypeSendEmail} from "./doppe-action-type-send-email";
import {stringIsNotNullableOrWhiteSpace} from "@wix/devzai-utils-common";

export const doppeActionTypeSendEmailViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeSendEmail, {
    getActionButtonProps: actionContext => {

        const action = actionContext.action;

        const emailAddress = action.settings.email;

        return {
            href: stringIsNotNullableOrWhiteSpace(emailAddress) ?
                `mailto:${emailAddress}` :
                '#'
        }
    }
})