import {HashFunction, HashValue} from '../hash';
import {identity} from "../common-functions";

const ValueWithDisposeFunctionType: unique symbol = Symbol('ValueWithDisposeFunctionType');

interface ValueWithDisposeFunction<VALUE> {
    type: typeof ValueWithDisposeFunctionType;
    value: VALUE;
    disposeFunction: CachedValueDisposeFunction<VALUE>;
}

type CachedValueDisposeFunction<VALUE> = (value: VALUE) => void;

function isValueWithDisposeFunction<VALUE> (value: unknown) : value is ValueWithDisposeFunction<VALUE> {
    return (value as ValueWithDisposeFunction<VALUE>)?.type === ValueWithDisposeFunctionType;
}

export interface ICachedValue<VALUE>  {
    getValue () : VALUE;

    getCachedValue () : VALUE | undefined;

    invalidate () : void;

    setValue (value: VALUE) : void;
}

export interface ICachedValueWithParams<VALUE, P extends object> {
    getValue(params: P) : VALUE;

    setValue (value: VALUE, params: P) : void;

    invalidateAll () : void;

    invalidate (params: P) : void;
}

interface CachedValueEntry<VALUE, P extends object> {
    value: VALUE;
    params: P;
    disposeFunction?: CachedValueDisposeFunction<VALUE>;
}

export namespace CachedValue {
    export type ValueFunctionWithParams<VALUE, P extends object> =
        (params: P, disposeFunction: () => void) => VALUE | ValueWithDisposeFunction<VALUE>;

    export type ValueFunction<VALUE> =
        (disposeFunction: () => void) => VALUE | ValueWithDisposeFunction<VALUE>;
}

export class CachedValueWithParams<VALUE, P extends object> implements ICachedValue<VALUE>, ICachedValueWithParams<VALUE, P> {

    private cache = new Map<HashValue<P>, CachedValueEntry<VALUE, P>>();
    private paramsHashFunction;
    private valueFunction;
    private disposeCallback;
    private withParams;
    private paramsNormalizationFunction;

    constructor(
        options: {
            withParams: boolean;
            paramsHashFunction: HashFunction<Required<P>>,
            valueFunction: CachedValue.ValueFunctionWithParams<VALUE, P> | CachedValue.ValueFunction<VALUE>,
            disposeCallback?: (params: P, prevValue: VALUE) => void;
            paramsNormalizationFunction?: (params: P) => P;
        }
    ) {
        this.withParams = options.withParams;
        this.paramsHashFunction = options.paramsHashFunction;
        this.valueFunction = options.valueFunction;
        this.disposeCallback = options.disposeCallback;
        this.paramsNormalizationFunction = options.paramsNormalizationFunction ?? identity;
    }

    private getParamsHash(params: P) : HashValue<P> {
        return this.paramsHashFunction(this.paramsNormalizationFunction(params) as Required<P>);
    }

    public getCachedValue() : VALUE;
    public getCachedValue(params: P) : VALUE;
    public getCachedValue(params: P = {} as P): VALUE | undefined {
        const paramsHash = this.getParamsHash(params);
        const cache = this.cache;
        const cacheEntry = cache.get(paramsHash);
        return cacheEntry?.value;
    }

    public getValue() : VALUE;
    public getValue(params: P) : VALUE;
    public getValue(params: P = {} as P) : VALUE {

        const paramsHash = this.getParamsHash(params);
        const cache = this.cache;

        const cacheEntry = cache.get(paramsHash);

        if (cacheEntry) {
            return cacheEntry.value;
        }

        const invalidateFunc = () => {
            const cacheMapEntry = [...cache.entries()].find(([,entry]) => entry === newCachedEntry);
            if (cacheMapEntry) {
                const [paramsHash] = cacheMapEntry;

                this.disposeCachedValue(newCachedEntry);

                cache.delete(paramsHash)
            }
        };

        const valueFunctionResult = this.withParams ?
            (this.valueFunction as CachedValue.ValueFunctionWithParams<VALUE, P>)(params, invalidateFunc) :
            (this.valueFunction as CachedValue.ValueFunction<VALUE>)(invalidateFunc);

        let value: VALUE;
        let disposeFunction: CachedValueDisposeFunction<VALUE> | undefined = undefined;

        if (isValueWithDisposeFunction<VALUE>(valueFunctionResult)) {
            value = valueFunctionResult.value;
            disposeFunction = valueFunctionResult.disposeFunction;
        } else {
            value = valueFunctionResult;
        }

        const newCachedEntry: CachedValueEntry<VALUE, P> = {
            value: value,
            disposeFunction: disposeFunction,
            params: params
        }

        this.cache.set(paramsHash, newCachedEntry);

        return value;
    }

    public iterateEntries(callback : (entry: VALUE, params: P) => void) {
        for (const cacheEntry of this.cache.values()) {
            callback(cacheEntry.value, cacheEntry.params);
        }

    }

    public setValue (value: VALUE) : void;
    public setValue (value: VALUE, params: P) : void;
    public setValue (value: VALUE, params: P = {} as P) {
        const paramsHash = this.getParamsHash(params);
        this.cache.set(paramsHash, {
            value: value,
            params: params
        });
    }

    public invalidateAll () {
        const cacheEntries = this.cache.values();

        for (const cacheEntry of cacheEntries) {
            this.disposeCachedValue(cacheEntry);
        }

        this.cache.clear();
    }

    public invalidate (params: P) : void;
    public invalidate () : void;
    public invalidate (params: P = {} as P) {

        const paramsHash = this.getParamsHash(params);

        const cache = this.cache;
        const cacheEntry = cache.get(paramsHash);

        if (cacheEntry) {
            this.disposeCachedValue(cacheEntry);

            cache.delete(paramsHash)
        }
    }

    private disposeCachedValue (cachedValueEntry: CachedValueEntry<VALUE, P>) {
        const disposeCallback = this.disposeCallback;
        const {
            value,
            disposeFunction,
            params
        } = cachedValueEntry;

        if (disposeCallback) {
            disposeCallback(params, value);
        }

        if (disposeFunction) {
            disposeFunction(value);
        }
    }
}

export function cachedValueWithParamsFactoryCreate<P extends object> (
    paramsHashFunction: HashFunction<Required<P>>,
    paramsNormalizationFunction?: (params: P) => P
) {
    return {
        create<VALUE> (valueFunction: CachedValue.ValueFunctionWithParams<VALUE, P>) {
            return new CachedValueWithParams<VALUE, P>({
                withParams: true,
                paramsHashFunction: paramsHashFunction,
                valueFunction: valueFunction,
                paramsNormalizationFunction: paramsNormalizationFunction
            });
        }
    }
}

export function cachedValueCreateWithParams<VALUE, P extends object> (
    paramsHashFunction: HashFunction<Required<P>>,
    valueFunction: CachedValue.ValueFunctionWithParams<VALUE, P>
) : CachedValueWithParams<VALUE, P> {
    return new CachedValueWithParams({
        withParams: true,
        paramsHashFunction: paramsHashFunction,
        valueFunction: valueFunction
    });
}

export function cachedValueCreate<VALUE> (
    valueFunction: CachedValue.ValueFunction<VALUE>
) : ICachedValue<VALUE>{
    return new CachedValueWithParams({
        withParams: false,
        paramsHashFunction: () => 'value',
        valueFunction: valueFunction
    });
}

export function cachedValueCreateDisposableValue<VALUE>(value: VALUE, disposeFunction: CachedValueDisposeFunction<VALUE>) : ValueWithDisposeFunction<VALUE> {
    return {
        value: value,
        disposeFunction: disposeFunction,
        type: ValueWithDisposeFunctionType
    }
}
