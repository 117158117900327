import React from 'react';
import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {doppeLockActionTypeCode} from './doppe-lock-action-type-code';

export const doppeLockActionTypeCodeViewer = doppeActionTypeDefineViewerMetadata(doppeLockActionTypeCode, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeCodeWidget: (await import('./doppe-lock-action-type-code-widget/doppe-lock-action-type-code-widget')).DoppeLockActionTypeCodeWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeCodeWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeCodeWidget
                {...renderProps}
            />
        )
    }
})