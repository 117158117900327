export type Comparer<T> = (value1: T, value2: T) => number;

export function compareStringsCaseInsensitive(str1: string, str2: string): number {
    const upperCase1 = str1.toUpperCase();
    const upperCase2 = str2.toUpperCase();
    if (upperCase1 < upperCase2) {
        return -1;
    }
    if (upperCase1 > upperCase2) {
        return 1;
    }

    return 0;
}

export function compareSemver (a: string, b: string) : number {
    const parseSemver = (version: string) => version.split('.').map(Number);
    const [majorA, minorA, patchA] = parseSemver(a);
    const [majorB, minorB, patchB] = parseSemver(b);

    if (majorA !== majorB) return majorA - majorB;
    if (minorA !== minorB) return minorA - minorB;
    return patchA - patchB;
}

export function compareStringsCaseSensitive(str1: string, str2: string): number {
    if (str1 < str2) {
        return -1;
    }
    if (str1 > str2) {
        return 1;
    }

    return 0;
}

export function compareNumbers(value1: number, value2: number): number {
    return value1 - value2;
}

export function compareBooleansTrueFirst (value1: boolean, value2: boolean) : number {
    return (value1 ? 0 : 1) - (value2 ? 0 : 1)
}

export function compareBooleansFalseFirst (value1: boolean, value2: boolean) : number {
    return (value1 ? 1 : 0) - (value2 ? 1 : 0)
}


interface ObjectFieldComparer<T extends object> {
    fieldName: keyof T;
    comparer: Comparer<any>;
}

export function compareFields<T extends object>(value1: T, value2: T, fieldComparers: ObjectFieldComparer<T>[]) {
    for (const fieldComparer of fieldComparers) {
        const result = fieldComparer.comparer(value1[fieldComparer.fieldName], value2[fieldComparer.fieldName]);

        if (result !== 0) {
            return result;
        }
    }

    return 0;
}

export function compareOrderedValues<T>(value1: T, value2: T, valuesOrder: T[], nonExistingValuesOrder = Infinity) {

    const index1 = valuesOrder.indexOf(value1);
    const index2 = valuesOrder.indexOf(value2);

    if (index1 < 0 && index2 < 0) {
        return 0;
    }

    return compareNumbers(
        index1 < 0 ? nonExistingValuesOrder : index1,
        index2 < 0 ? nonExistingValuesOrder : index2,
    );
}

export function compareArrays<T>(value1: T[], value2: T[], valueComparer: Comparer<T>) {

    const length1 = value1.length;
    const length2 = value2.length;

    const minLength = Math.min(length1, length2);

    for (let i = 0; i < minLength; i++) {
        const comparisonResult = valueComparer(value1[i], value2[i]);

        if (comparisonResult !== 0) {
            return comparisonResult;
        }
    }

    return compareNumbers(length1, length2);
}

export function compareTuples<T1, T2>(
    value1: [T1, T2], value2: [T1, T2], valueComparer1: Comparer<T1>, valueComparer2: Comparer<T2>) : number;
export function compareTuples<T1, T2, T3>(
    value1: [T1, T2, T3], value2: [T1, T2, T3], valueComparer1: Comparer<T1>, valueComparer2: Comparer<T2>, valueComparer3: Comparer<T3>) : number;
export function compareTuples(value1: any, value2: any, ...valueComparers: any[])
{

    const length1 = value1.length;
    const length2 = value2.length;

    const minLength = Math.min(length1, length2);

    for (let i = 0; i < minLength; i++) {
        const comparisonResult = valueComparers[i](value1[i], value2[i]);

        if (comparisonResult !== 0) {
            return comparisonResult;
        }
    }

    return compareNumbers(length1, length2);
}
