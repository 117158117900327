import React, {useLayoutEffect, useMemo, useRef} from 'react';
import {ImageView, ImageViewProps, ImageViewResizeMode} from '../image-view/image-view';
import {useDebouncedValue, useElementSizeBinding, useForceUpdate, useRenderState} from '../hooks';
import {reactCreateMemo} from '../react-utils/react-utils';
import {refCreateUnion} from '../ref-utils';
import {Evaluable, evaluateWhenFunction, IDisposable, OmitStrict, Size} from '@wix/devzai-utils-common';
import {domImageAsyncLoaderLoad} from "@wix/devzai-utils-dom";

export const ResponsiveImageView = reactCreateMemo(function ResponsiveImageView<SOURCE> (props: ResponsiveImageView.Props<SOURCE>) {
    const {
        resolveImageUrl,
        isResponsiveImage,

        src,
        resizeMode,
        imageElementRef,
        containerSizeChangeDebounceMs,
        placeholderSrc,
        ...imageViewProps
    } = props;

    const internalImageElementRef = useRef<HTMLImageElement>(null);


    const imageViewContainerSize =
        useElementSizeBinding(internalImageElementRef, src !== undefined && isResponsiveImage(src)) ?? {width: 0, height: 0};
    const [debouncedContainerSize] = useDebouncedValue(imageViewContainerSize, containerSizeChangeDebounceMs);
    const usedContainerSize = debouncedContainerSize.width === 0 || debouncedContainerSize.height === 0 ?
        imageViewContainerSize : debouncedContainerSize;

    const imageViewContainerWidth = usedContainerSize.width;
    const imageViewContainerHeight = usedContainerSize.height;


    const forceUpdate = useForceUpdate();
    const imageUrlRef = useRef<string | undefined>();
    const imageAsyncLoaderRef = useRef<IDisposable>();

    const renderState = useRenderState({
        imageViewContainerWidth: imageViewContainerWidth,
        imageViewContainerHeight: imageViewContainerHeight,
        resizeMode: resizeMode,
        src: src,
        loadImageAsync: (imageUrl: string) => {

            imageAsyncLoaderRef.current?.dispose();

            if (imageUrlRef.current !== imageUrl) {
                imageAsyncLoaderRef.current = domImageAsyncLoaderLoad({
                    src: imageUrl,
                    decodeImage: true,
                    onLoad: () => {
                        imageUrlRef.current = imageUrl;
                        forceUpdate();
                    }
                })
            } else {
                imageAsyncLoaderRef.current = undefined;
            }
        }
    })

    // When the source is changed, we need to replace the image url immediately
    useMemo(() => {
        const {
            imageViewContainerWidth,
            imageViewContainerHeight,
            resizeMode
        } = renderState.current;

        if (src !== undefined) {

            const evaluatedPlaceholderSrc = evaluateWhenFunction(placeholderSrc, src);

            const imageUrl = resolveImageUrl(src, {width: imageViewContainerWidth, height: imageViewContainerHeight}, resizeMode);

            if (evaluatedPlaceholderSrc) {
                imageUrlRef.current = evaluatedPlaceholderSrc;

                if (imageUrl) {
                    renderState.current.loadImageAsync(imageUrl);
                }
            } else {
                imageUrlRef.current = imageUrl
            }
        } else {
            imageUrlRef.current = undefined;
        }
    }, [src, renderState, imageUrlRef]);

    const imageUrl = imageUrlRef.current;

    useLayoutEffect(() => {

        const {
            src
        } = renderState.current;

        const imageUrl = src !== undefined ?
            resolveImageUrl(src, {width: imageViewContainerWidth, height: imageViewContainerHeight}, resizeMode) :
            undefined;

        if (imageUrl) {

            if (imageUrlRef.current === undefined) {
                imageUrlRef.current = imageUrl
                forceUpdate();
            } else {
                renderState.current.loadImageAsync(imageUrl);
            }

        }
    }, [imageViewContainerWidth, imageViewContainerHeight, resizeMode, renderState, imageUrlRef, resolveImageUrl]);

    return (
        <ImageView
            {...imageViewProps}
            resizeMode={resizeMode}
            imageElementRef={refCreateUnion([imageElementRef, internalImageElementRef])}
            src={imageUrl}
        />
    );
});

export namespace ResponsiveImageView {

    export interface Props<SOURCE> extends OmitStrict<ImageViewProps, 'src' | 'resizeMode'> {
        src?: SOURCE;
        containerSizeChangeDebounceMs: number;
        placeholderSrc?: Evaluable<(src: SOURCE) => string | undefined>;
        resizeMode: ImageViewResizeMode;

        isResponsiveImage: (src: SOURCE) => boolean;
        resolveImageUrl: (src: SOURCE, containerSize: Size, resizeMode: ImageViewResizeMode) => string | undefined;
    }
}