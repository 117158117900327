import {dynamicRefCreate, IObservableRef} from './dynamic-ref/dynamic-ref';
import React, {useMemo} from 'react';
import {useInstanceValue} from './hooks/use-data-member';

export function refCreateObservable<T>(): IObservableRef<T> {
    return dynamicRefCreate<T>();
}

export function refCreateUnion<T>(refs: (React.Ref<T> | undefined | null)[]): React.RefObject<T> {
    const refsUnion = dynamicRefCreate<T>();

    for (let i = 0; i < refs.length; i++) {
        const ref = refs[i];

        if (ref) {
            refsUnion.associateRef(i, ref);
        }
    }

    return refsUnion;
}

export function refCreateObservableUnion<T>(refs: (React.Ref<T> | undefined | null)[]): IObservableRef<T> {
    const refsUnion = dynamicRefCreate<T>();

    for (let i = 0; i < refs.length; i++) {
        const ref = refs[i];

        if (ref) {
            refsUnion.associateRef(i, ref);
        }
    }

    return refsUnion;
}

export function useRefsUnion<T>(refs: (React.Ref<T> | undefined | null)[]): React.RefObject<T> {
    return useMemo(() => {
        return refCreateUnion(refs);
    }, refs);
}

export function useRefsObservableUnion<T>(refs: (React.Ref<T> | undefined | null)[]): IObservableRef<T> {
    return useMemo(() => {
        return refCreateObservableUnion(refs);
    }, refs);
}

export function useRefObservable<T>() {
    return useInstanceValue(() => refCreateObservable<T>());
}

export class LazyRef<T> {
    private value: T | undefined = undefined;

    constructor(private refGetter: () => T) {}

    get current() {
        let value = this.value;
        if (value === undefined) {
            value = this.value = this.refGetter();
        }
        return value;
    }
}
