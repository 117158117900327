import {Color, colorToRgb, ColorValue} from "../color-utils/color-utils";
import {easingCoordinates as easingCoordinatesResolve} from 'easing-coordinates';
import {Direction} from "../direction";
import {evaluateFunction} from "../evaluable";
import {arrayRemoveUndefinedValues} from "../array-utils";

export function cssResolveEasingCoordinates (easingFunction: string, polySteps?: number) {
    return easingCoordinatesResolve(easingFunction, polySteps);
}

export function cssResolveRgbColorComponentsForFunctionalNotationFormat (
    color: Color | string
) {
    const rgbColor = colorToRgb(color);

    return rgbColor ? `${rgbColor.r} ${rgbColor.g} ${rgbColor.b}` : null;
}

export function cssAddUnitsToNumericValue<V extends number | undefined | null>(
    value: V, units: 'px' | '%'
) {
    return (
        value !== undefined && value !== null ? `${value}${units}` : value
    ) as V extends number ? string : V;
}

export function cssCreateEasingGradient (
    options: {
        easingFunction: string;
        direction: Direction;
        color: ColorValue;
        steps?: number;
    }
) {
    const {
        easingFunction,
        direction,
        color,
        steps = 15
    } = options;

    const colorRgb = colorToRgb(color);

    if (!colorRgb) {
        return null;
    }




    const gradientDirectionStr = evaluateFunction(() => {
        switch (direction) {
            case Direction.Down: return 'to bottom';
            case Direction.Up: return 'to top';
            case Direction.Right: return 'to right';
            case Direction.Left: return 'to left';
        }
    })

    if (easingFunction === 'linear') {
        return `linear-gradient(${gradientDirectionStr},rgb(${colorRgb.r},${colorRgb.g},${colorRgb.b}),transparent)`
    }

    const easingCoordinates = cssResolveEasingCoordinates(easingFunction, steps);


    // return easingCoordinates;

    const stopsStr = easingCoordinates.map(coordinate => {
        const y = coordinate.y;
        const opacity = 1 - y;

        return arrayRemoveUndefinedValues([
            opacity === 1 ?
                `rgb(${colorRgb.r},${colorRgb.g},${colorRgb.b})` :
                `rgba(${colorRgb.r},${colorRgb.g},${colorRgb.b},${opacity.toFixed(2)})`,
            coordinate.x === 0 || coordinate.x === 1 ? undefined : `${(coordinate.x * 100).toFixed(2)}%`
        ]).join(' ');
    }).join(',')

    return `linear-gradient(${gradientDirectionStr},${stopsStr})`

}