import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {Values} from "@wix/devzai-utils-common";
import {DoppeAutoProductType, DoppeBusinessEntity} from "../../client-server-common";

export const DoppeActionTypeDevViewerStorybookStories = {
    PageInfo: 'PageInfo',
    Form: 'Form',
    PageMediaViewCropping: 'PageMediaViewCropping',
    Modals: 'Modals',
    StoresCheckout: 'StoresCheckout',
    ApiTest: 'ApiTest',
    Locales: 'Locales',
    Colors: 'Colors',
} as const;

export type DoppeActionTypeDevViewerStorybookStories = Values<typeof DoppeActionTypeDevViewerStorybookStories>;

export namespace DoppeActionTypeDevViewerStorybook {
    export interface ActionSettings {
        story: DoppeActionTypeDevViewerStorybookStories;
    }
}

export const doppeActionTypeDevViewerStorybook = doppeActionTypeDefineMetadata<DoppeActionTypeDevViewerStorybook.ActionSettings>({
    id: 'io.bymo.action/dev-viewer-storybook',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        story: DoppeActionTypeDevViewerStorybookStories.PageInfo,
    }),
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedBusinessEntities: actionSettings => {
        switch (actionSettings.story) {
            case DoppeActionTypeDevViewerStorybookStories.StoresCheckout: {
                return [
                    {businessEntity: DoppeBusinessEntity.WixAutoProductsList, id: DoppeAutoProductType.Latest}
                ];
            }
            default: {
                return [];
            }
        }
    }
});

