import {useRootWindow} from "../../root-window-context/root-window-context";
import {DependencyList, useLayoutEffect, useState} from "react";

export function useWindowDependantMemo<T> (
    factory: (window: Window) => T,
    deps: DependencyList
) {
    const [memoizedValue, setMemoizedValue] = useState<T>()

    const rootWindow = useRootWindow();

    useLayoutEffect(() => {
        setMemoizedValue(factory(rootWindow ?? window))
    }, [rootWindow, ...deps])

    return memoizedValue;
}

export function useWindowDependantMemoWithDefaultValue<T> (
    factory: (window: Window) => T,
    defaultValue: T,
    deps: DependencyList
) {
    const [memoizedValue, setMemoizedValue] = useState<T>(defaultValue)

    const rootWindow = useRootWindow();

    useLayoutEffect(() => {
        setMemoizedValue(factory(rootWindow ?? window))
    }, [rootWindow, ...deps])

    return memoizedValue;
}