import {enumGetValues, Values} from '@wix/devzai-utils-common';

export const DoppeActionLockPreviewMode = {
    Custom: 'custom',
    LockCTA: 'lockCTA',
} as const;

export type DoppeActionLockPreviewMode = Values<typeof DoppeActionLockPreviewMode>;


export function doppeActionLockPreviewModeGetValuesForList(supportsLockInnerCta: boolean) {
    return supportsLockInnerCta ? enumGetValues(DoppeActionLockPreviewMode) :
        enumGetValues(DoppeActionLockPreviewMode).filter(type =>  type !== DoppeActionLockPreviewMode.LockCTA);
}


