/**
 * example: Evaluable<(arg1: string, arg2: number) => boolean> = ((arg1: string, arg2: number) => boolean) | boolean
 */
export type Evaluable<F extends (...arg: any[]) => any> = ReturnType<F> | F;

export function evaluateWhenFunction<VALUE, FUNC_PARAM = undefined>(
    value: VALUE | ((param: FUNC_PARAM) => VALUE)
): VALUE;
export function evaluateWhenFunction<VALUE, FUNC_PARAM>(
    value: VALUE | ((param: FUNC_PARAM) => VALUE),
    param: FUNC_PARAM
): VALUE;
export function evaluateWhenFunction<VALUE, FUNC_PARAM1, FUNC_PARAM2>(
    value: VALUE | ((param1: FUNC_PARAM1, param2: FUNC_PARAM2) => VALUE),
    param1: FUNC_PARAM1,
    param2: FUNC_PARAM2,
): VALUE;
export function evaluateWhenFunction<VALUE, FUNC_PARAM1, FUNC_PARAM2, FUNC_PARAM3>(
    value: VALUE | ((param1: FUNC_PARAM1, param2: FUNC_PARAM2, param3: FUNC_PARAM3) => VALUE),
    param1: FUNC_PARAM1,
    param2: FUNC_PARAM2,
    param3: FUNC_PARAM3,
): VALUE;
export function evaluateWhenFunction<VALUE>(
    value: VALUE | ((...params: unknown[]) => VALUE),
    ...params: unknown[]
): VALUE {
    if (typeof value === 'function') {
        return (value as any)(...params);
    } else {
        return value;
    }
}

export function evaluateFunction<R> (func: () => R) : R {
    return func();
}

export function evaluateFunctionWithFallback<R, F> (func: () => R, fallbackValue: F) : R | F {
    try {
        return func();
    } catch (error) {
        return fallbackValue;
    }
}

const SafeEvaluationError: unique symbol = Symbol('SafeEvaluationError');

export function safeEvaluate<R> (func: () => R, errorHandler?: (error: Error) => void) : R | typeof SafeEvaluationError {
    try {
        return func();
    } catch (error: any) {
        errorHandler?.(error);

        return SafeEvaluationError;
    }
}

export async function safeEvaluateAsync<R> (func: () => Promise<R>, errorHandler?: (error: Error) => void) : Promise<R | typeof SafeEvaluationError> {
    try {
        return await func();
    } catch (error: any) {
        errorHandler?.(error);

        return SafeEvaluationError;
    }
}

export function safeEvaluationFailed<R> (result: R | typeof SafeEvaluationError) : result is typeof SafeEvaluationError {
    return result === SafeEvaluationError;
}