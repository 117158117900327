import {doppeActionTypeDefineViewerMetadata} from '../../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeTwitterEmbed} from '../doppe-action-type-twitter-embed';

export const doppeActionTypeTwitterEmbedViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeTwitterEmbed, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeTwitterEmbedWidget: (await import(
                '../doppe-action-type-twitter-embed-widget/doppe-action-type-twitter-embed-widget'
                )).DoppeActionTypeTwitterEmbedWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeTwitterEmbedWidget
        } = viewerComponents

        return (
            <DoppeActionTypeTwitterEmbedWidget
                {...renderProps}
            />
        )
    },

    activateCTA: actionContext => {
        actionContext.navigateToUrl(actionContext.action.settings.twitterUrl, true);
    }

})