import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeDevViewerStorybook} from "./doppe-action-type-dev-viewer-storybook";

export const doppeActionTypeDevViewerStorybookViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeDevViewerStorybook, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeDevViewerStorybookWidget: (await import(
                './doppe-action-type-dev-viewer-storybook-widget/doppe-action-type-dev-viewer-storybook-widget'
            )).DoppeActionTypeDevViewerStorybookWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {
        const {
            DoppeActionTypeDevViewerStorybookWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeDevViewerStorybookWidget
                {...renderProps}
            />
        );
    }
})