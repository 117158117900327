import {useEffect, useRef} from 'react';
import {Values} from '@wix/devzai-utils-common';

/**
 * @deprecated use useRenderState instead
 */
export function useBoxedValue<T> (value: T) {
    return useRenderState<T>(value)
}

export function useRenderState<T> (value: T) {
    const ref = useRef<T>();
    ref.current = value;
    return ref as {current: T};
}

export const ComponentLifecycleState = {
    Instantiated: 'Instantiated',
    Mount: 'Mount',
    Unmount: 'Unmount'
} as const;

export type ComponentLifecycleState = Values<typeof ComponentLifecycleState>;

export function useComponentLifecycleState () {
    const ref = useRef<ComponentLifecycleState>(ComponentLifecycleState.Instantiated);

    useEffect(() => {

        ref.current = ComponentLifecycleState.Mount;

        return () => {
            ref.current = ComponentLifecycleState.Unmount;
        }

    }, [])

    return ref;
}

export function componentLifecycleStateIsUnmount (lifecycleState: ComponentLifecycleState) {
    return lifecycleState === ComponentLifecycleState.Unmount;
}
