import {DoppeActionLayout} from './doppe-action-layout';
import {DoppeActionListItemClickBehaviour} from './doppe-action-list-item-click-behaviour';
import {DoppeDtoAction, doppeDtoActionGetDefaultSearchListSettingsProps} from './doppe-dto-action';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {DoppeHideableValue} from './doppe-hideable-value';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';

export interface DoppeListActionSettings {
    itemShowDescription: boolean;
    itemShowImage: boolean;
    itemShowTitle: boolean;
    itemShowTextOnImage: boolean;
    layout: DoppeActionLayout;
    latestNonPremiumLayout?: DoppeActionLayout;
    showButton: boolean;
    buttonText: string;
    buttonUrl: string;
    listSearchSettings: DoppeDtoAction.ListSearchSettings;
}


export namespace DoppeListActionSettings {

    export interface WithExternalDataSourceSupport<T extends {}> {
        externalDataSource: null | T;
        externalDataSourceItemsCount: number;
    }


    export interface WithMainDetails  {
        mainImage: DoppeHideableValue<WixMediaResource | null>;
        mainTitle: DoppeHideableValue<string>;
    }

    export interface WithItemClickBehaviour  {
        itemClickBehaviour: DoppeActionListItemClickBehaviour;
    }
}


export function getDefaultDoppeListActionSettings(pageContentLocales?: DoppeAppPageContentLocales) {
    return {
        itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
        layout: DoppeActionLayout.Column,
        showButton: false,
        buttonText: pageContentLocales ? pageContentLocales["lists.actionSettings.buttonText"] : '',
        buttonUrl: 'https://www.website.com',

        itemShowDescription: true,
        itemShowTitle: true,
        itemShowImage: true,
        itemShowTextOnImage: false,
        listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),
    }
}

