import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import {doppeActionTypeDebugApi} from "./doppe-action-type-debug-api";

export const doppeActionTypeDebugApiViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeDebugApi, {
    getActionButtonProps: actionContext => {
        return {
            onClick: async () => {
                const healthTestResult = await actionContext.__apiHealthCheck();

                alert(healthTestResult);
            }
        }
    }
})