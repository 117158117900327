import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypePatreon} from "./doppe-action-type-patreon";

export const doppeActionTypePatreonViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypePatreon, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypePatreonWidget: (await import(
                './doppe-action-type-patreon-widget/doppe-action-type-patreon-widget'
                )).DoppeActionTypePatreonWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypePatreonWidget
        } = viewerComponents;

        return (
            <DoppeActionTypePatreonWidget
                {...renderProps}
            />
        )
    }
})