import {textProcessMarkupString} from "@wix/devzai-utils-common";
import React from "react";

export function i18nReactProcessMarkupString<CONTEXT> (
    input: string,
    tagRenderFunctions: I18nReact.TagRenderFunction<CONTEXT>,
    context: CONTEXT
) {

    return (
        <span
            key={Date.now()}
        >
            {textProcessMarkupString(input, (text, tagName) => {
                if (tagName) {
                    const renderFunction = tagRenderFunctions[tagName];

                    if (renderFunction) {
                        return renderFunction(text, context);
                    }
                }

                return text;
            }).map((reactChild, index) => (
                <React.Fragment
                    key={index}
                >
                    {reactChild}
                </React.Fragment>
            ))}
        </span>
    )

}

export namespace I18nReact {

    export type TagRenderFunction<CONTEXT> = Record<string, (text: string, context: CONTEXT) => React.ReactNode>;
}