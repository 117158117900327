import {HtmlObjectHorizontalPosition, HtmlObjectVerticalPosition, Values} from "@wix/devzai-utils-common";

export const BymoPageActionMediaViewLayout = {
    Auto: 'Auto',
    FullWidth: 'FullWidth',
    WithMargins: 'WithMargins',
} as const;

export type BymoPageActionMediaViewLayout = Values<typeof BymoPageActionMediaViewLayout>;

export const BymoPageActionMediaHeight = {
    Auto: 'Auto',
    AutoWithHeightLimit: 'AutoWithHeightLimit',
    Tiny: 'Tiny',
    Small: 'Small',
    Medium: 'Medium',
    Large: 'Large',
} as const;

export type BymoPageActionMediaHeight = Values<typeof BymoPageActionMediaHeight>;

export const BymoPageActionMediaLayout = {
    Contain: 'Contain',
    Cropped: 'Cropped'
} as const;

export type BymoPageActionMediaLayout = Values<typeof BymoPageActionMediaLayout>;

export type BymoPageActionMediaViewSettings =
    | BymoPageActionMediaViewSettings.Contain
    | BymoPageActionMediaViewSettings.Cropped;

export namespace BymoPageActionMediaViewSettings {
    type BaseType<T extends BymoPageActionMediaLayout> = {
        mediaLayout: T;
        height: BymoPageActionMediaHeight;
    };

    export type Cropped = BaseType<typeof BymoPageActionMediaLayout.Cropped> & {
        alignment: {
            horizontal: HtmlObjectHorizontalPosition;
            vertical: HtmlObjectVerticalPosition;
        }
    }

    export type Contain = BaseType<typeof BymoPageActionMediaLayout.Contain> & {
    }
}
