import {EqualityComparer, EventEmitter, IObservable, referencesEqualityComparer} from '../index';

export interface IObservableValue<T, EVENTS extends IObservable.Events = IObservable.Events> extends IObservable<EVENTS> {
    getValue () : T;
}

export namespace ObservableValue {
    export interface Options<T> {
        equalityComparer?: EqualityComparer<T>
        onUpdate?: (value: T) => void;
    }
}

export class ObservableValue<T> implements IObservableValue<T> {

    private currentValue: T;
    private equalityComparer: EqualityComparer<T>;
    private onUpdate;

    constructor (initialValue: T, options: ObservableValue.Options<T> = {}) {
        const {
            equalityComparer = referencesEqualityComparer,
            onUpdate
        } = options;

        this.currentValue = initialValue;
        this.equalityComparer = equalityComparer;
        this.onUpdate = onUpdate;

    }

    private eventEmitter = new EventEmitter<IObservable.Events>();

    public get eventUpdated () {
        return this.eventEmitter.createEventReference('eventUpdated')
    }

    public setValue (value: T) {
        if (!this.equalityComparer(value, this.currentValue)) {
            this.currentValue = value;
            this.eventEmitter.emit('eventUpdated');
            this.onUpdate?.(value);
        }
    }

    public getValue () : T {
        return this.currentValue;
    }

    public onUpdated (callback: (value: T) => void) {
        return this.eventEmitter.on('eventUpdated', () => {
            callback(this.currentValue)
        })
    }

    public bindToValue (callback: (value: T) => void) {
        const binding = this.onUpdated(callback);

        callback(this.currentValue);

        return binding;
    }
}

export function observableValueCreateCalculated<T> (
    valueFunction: () => T,
    deps: IObservable[]
) {
    const observableValue = new ObservableValue<T>(valueFunction())

    deps.map(dep => {
        return EventEmitter.on(dep.eventUpdated, () => {
            observableValue.setValue(valueFunction())
        })
    });

    return observableValue;
}