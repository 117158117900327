import {enumGetValues, evaluateFunction, Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity, DoppeBusinessEntityReference} from "./doppe-business-entity";

/**
 * An enum of all the external providers that can provide a product data.
 * For example: Wix, Amazon, Shopify.
 */
export const DoppeExternalServiceProvider = {
    Wix: 'wix',
} as const;

export type DoppeExternalServiceProvider = Values<typeof DoppeExternalServiceProvider>;

// TODO: This field should include all the possible SEMANTIC fields that an external Service providers can provide
// TODO: for a Service.
export const DoppeExternalServiceField = {
    ServicePageUrl: 'ServicePageUrl',
    BookingPageUrl: 'BookingPageUrl',
    CalenderPageUrl: 'CalenderPageUrl',
    Locations: 'Locations',
} as const;

export type DoppeExternalServiceField = Values<typeof DoppeExternalServiceField>;

const AllExternalServiceProviders = enumGetValues(DoppeExternalServiceProvider);

const externalServiceFieldsToSupportedProviders = {
    [DoppeExternalServiceField.Locations]: AllExternalServiceProviders,
    [DoppeExternalServiceField.ServicePageUrl]: AllExternalServiceProviders,
    [DoppeExternalServiceField.BookingPageUrl]: AllExternalServiceProviders,
    [DoppeExternalServiceField.CalenderPageUrl]: AllExternalServiceProviders,
} satisfies {
    [field in DoppeExternalServiceField]: DoppeExternalServiceProvider[]
}

export function doppeExternalServiceProviderIsFieldProvided (
    externalServiceProvider: DoppeExternalServiceProvider | null | undefined,
    externalServiceField: DoppeExternalServiceField
) {
    if (externalServiceProvider === null || externalServiceProvider === undefined) {
        return false;
    }

    return externalServiceFieldsToSupportedProviders[externalServiceField].includes(externalServiceProvider);
}

export function doppeExternalServiceProviderResolveBusinessEntityReference (
    externalItemProvider: DoppeExternalServiceProvider,
    externalItemId: string
) {
    return evaluateFunction(() => {
        switch (externalItemProvider) {
            case DoppeExternalServiceProvider.Wix: {
                return {
                    businessEntity: DoppeBusinessEntity.WixService,
                    id: externalItemId
                }
            }
        }
    }) satisfies DoppeBusinessEntityReference
}