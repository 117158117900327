import React from 'react';
import {classes, style} from './doppe-viewer-contact-method-action-panel.st.css';
import {DoppeDtoContactMethods} from '../../client-server-common/types/doppe-dto-contact-method';
import {assertNotNullable, evaluateFunction, validateIsUrl} from '@wix/devzai-utils-common';
import {
    doppeViewerContactMethodIsEmail,
    doppeViewerContactMethodIsLocation,
    doppeViewerContactMethodIsPhoneNumber
} from '../doppe-viewer-contact-methods/doppe-viewer-contact-methods';
import {useDoppeViewerLocales} from '../../client/doppe-viewer-locales/doppe-viewer-locales';
import {
    Button,
    flexColumn,
    flexFillRemainingHorizontalSpace,
    flexRowAlignToCenter,
    Icon,
    IconButton,
    useInnerWindowController
} from '@wix/devzai-utils-react';
import {BymoPageStyleScope, BymoPageStyleScopeContext} from '../bymo-page-style/bymo-page-style';
import {DoppeViewerModalPanel, DoppeViewerModalPanelLayout} from '../doppe-viewer-modal-panel/doppe-viewer-modal-panel';
import {
    DoppeViewerAppIconCallMedium,
    DoppeViewerAppIconChevronRightMedium,
    DoppeViewerAppIconCopyMedium,
    DoppeViewerAppIconDismissMedium,
    DoppeViewerAppIconDownloadSmall,
    DoppeViewerAppIconEmailMedium,
    DoppeViewerAppIconExternalLinkMedium,
    DoppeViewerAppIconFacebookMessangerMedium,
    DoppeViewerAppIconMapMedium,
    DoppeViewerAppIconShareMedium,
    DoppeViewerAppIconWhatsappMedium
} from '../doppe-viewer-icons/doppe-viewer-icons';
import {doppeHideableValueIsVisible} from '../../client-server-common';
import {clipboardCopy} from '@wix/devzai-utils-dom';

export const DoppeViewerContactMethodActionsPanel = React.memo(function ContactMethodActionsPanel(
    props: {
        type: DoppeDtoContactMethods;
        displayValue: string;
        title: string;
        onClick: (value: string) => void;
        showNotification: (text: string) => void;
        saveAsContact: () => void;
    }
) {
    const {
        type,
        displayValue,
        title,
        onClick,
        saveAsContact,
        showNotification
    } = props;

    const hasShare = evaluateFunction(() => {
        return !!navigator.share && validateIsUrl(displayValue) && doppeViewerContactMethodIsEmail(type);
    })

    const doppeViewerLocales = useDoppeViewerLocales();

    const windowController = assertNotNullable(useInnerWindowController());

    return (
        <BymoPageStyleScopeContext.Provider
            value={BymoPageStyleScope.Popup}
        >
            <DoppeViewerModalPanel
                className={style(classes.root, {}, flexColumn())}
                mobileLayout={DoppeViewerModalPanelLayout.Drawer}
                desktopLayout={DoppeViewerModalPanelLayout.InnerCentered}
            >
                <div
                    className={style(classes.header, {}, flexRowAlignToCenter())}
                >
                    <div
                        className={style(classes.headerContent, {}, flexColumn(), flexFillRemainingHorizontalSpace())}
                    >
                        <div
                            className={style(classes.headerTitle, {})}
                        >
                            {title}
                        </div>
                        <div
                            className={style(classes.headerSubtitle, {})}
                        >
                            {displayValue}
                        </div>
                    </div>
                    <IconButton
                        className={style(classes.closeIconButton, {})}
                        icon={DoppeViewerAppIconDismissMedium}
                        onClick={() => {
                            windowController.closeWindow();
                        }}
                    />
                </div>
                <div
                    className={style(classes.actionsPanelContent, {}, flexColumn())}
                >
                    {
                        doppeViewerContactMethodIsLocation(type) ?
                            <Button
                                className={style(classes.actionButton, {}, flexRowAlignToCenter())}
                                tagName={'a'}
                                href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(displayValue)}`}
                                rel={'noreferrer'}
                                target={'_blank'}
                                onClick={() => {
                                    onClick(displayValue);
                                    windowController.closeWindow();
                                }}
                            >
                                <Icon
                                    className={style(classes.actionIcon, {})}
                                    icon={DoppeViewerAppIconMapMedium}
                                />
                                <div
                                    className={style(classes.actionButtonText, {}, flexFillRemainingHorizontalSpace())}
                                >
                                    {doppeViewerLocales['contactCard.navigate']}
                                </div>
                                <Icon
                                    className={style(classes.rightIcon, {})}
                                    icon={DoppeViewerAppIconChevronRightMedium}
                                />
                            </Button>
                            :
                            null

                    }
                    {
                        doppeViewerContactMethodIsPhoneNumber(type) ?
                            <Button
                                className={style(classes.actionButton, {}, flexRowAlignToCenter())}
                                tagName={'a'}
                                href={`tel:${displayValue}`}
                                rel={'noreferrer'}
                                target={'_blank'}
                                onClick={() => {
                                    onClick(displayValue);
                                    windowController.closeWindow();
                                }}
                            >
                                <Icon
                                    className={style(classes.actionIcon, {})}
                                    icon={DoppeViewerAppIconCallMedium}
                                />
                                <div
                                    className={style(classes.actionButtonText, {}, flexFillRemainingHorizontalSpace())}
                                >
                                    {doppeViewerLocales['contactCard.call']}
                                </div>
                                <Icon
                                    className={style(classes.rightIcon, {})}
                                    icon={DoppeViewerAppIconChevronRightMedium}
                                />
                            </Button>
                            :
                            doppeViewerContactMethodIsEmail(type) ?
                                <Button
                                    className={style(classes.actionButton, {}, flexRowAlignToCenter())}
                                    tagName={'a'}
                                    href={`mailto:${displayValue}`}
                                    rel={'noreferrer'}
                                    target={'_blank'}
                                    onClick={() => {
                                        onClick(displayValue);
                                        windowController.closeWindow();
                                    }}
                                >
                                    <Icon
                                        className={style(classes.actionIcon, {})}
                                        icon={DoppeViewerAppIconEmailMedium}
                                    />
                                    <div
                                        className={style(classes.actionButtonText, {}, flexFillRemainingHorizontalSpace())}
                                    >
                                        {doppeViewerLocales['contactCard.email']}
                                    </div>
                                    <Icon
                                        className={style(classes.rightIcon, {})}
                                        icon={DoppeViewerAppIconChevronRightMedium}
                                    />
                                </Button> :
                                type === DoppeDtoContactMethods.WhatsApp ?
                                    <Button
                                        className={style(classes.actionButton, {}, flexRowAlignToCenter())}
                                        tagName={'a'}
                                        href={`https://wa.me/${displayValue}`}
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        onClick={() => {
                                            onClick(displayValue);
                                            windowController.closeWindow();
                                        }}
                                    >
                                        <Icon
                                            className={style(classes.actionIcon, {})}
                                            icon={DoppeViewerAppIconWhatsappMedium}
                                        />
                                        <div
                                            className={style(classes.actionButtonText, {}, flexFillRemainingHorizontalSpace())}
                                        >
                                            {doppeViewerLocales['contactCard.sendMessage']}
                                        </div>
                                        <Icon
                                            className={style(classes.rightIcon, {})}
                                            icon={DoppeViewerAppIconChevronRightMedium}
                                        />
                                    </Button>
                                    :
                                    type === DoppeDtoContactMethods.FacebookMessenger ?
                                        <Button
                                            className={style(classes.actionButton, {}, flexRowAlignToCenter())}
                                            tagName={'a'}
                                            href={`https://m.me/${displayValue}`}
                                            rel={'noreferrer'}
                                            target={'_blank'}
                                            onClick={() => {
                                                onClick(displayValue);
                                                windowController.closeWindow();
                                            }}
                                        >
                                            <Icon
                                                className={style(classes.actionIcon, {})}
                                                icon={DoppeViewerAppIconFacebookMessangerMedium}
                                            />
                                            <div
                                                className={style(classes.actionButtonText, {}, flexFillRemainingHorizontalSpace())}
                                            >
                                                {doppeViewerLocales['contactCard.sendMessage']}
                                            </div>
                                            <Icon
                                                className={style(classes.rightIcon, {})}
                                                icon={DoppeViewerAppIconChevronRightMedium}
                                            />
                                        </Button>
                                        :
                                        validateIsUrl(displayValue) ?
                                            <Button
                                                className={style(classes.actionButton, {}, flexRowAlignToCenter())}
                                                tagName={'a'}
                                                href={displayValue}
                                                rel={'noreferrer'}
                                                target={'_blank'}
                                                onClick={() => {
                                                    onClick(displayValue);
                                                    windowController.closeWindow();
                                                }}
                                            >
                                                <Icon
                                                    className={style(classes.actionIcon, {})}
                                                    icon={DoppeViewerAppIconExternalLinkMedium}
                                                />
                                                <div
                                                    className={style(classes.actionButtonText, {}, flexFillRemainingHorizontalSpace())}
                                                >
                                                    {doppeViewerLocales['contactCard.openLink']}
                                                </div>
                                                <Icon
                                                    className={style(classes.rightIcon, {})}
                                                    icon={DoppeViewerAppIconChevronRightMedium}
                                                />
                                            </Button>
                                            :
                                            null
                    }
                    {
                        hasShare ?
                            <Button
                                className={style(classes.actionButton, {}, flexRowAlignToCenter())}
                                onClick={() => {
                                    void navigator.share({
                                        title: doppeHideableValueIsVisible(title) ? title : '',
                                        url: displayValue,
                                    })
                                    onClick(displayValue);
                                    windowController.closeWindow();
                                }}
                            >
                                <Icon
                                    className={style(classes.actionIcon, {})}
                                    icon={DoppeViewerAppIconShareMedium}
                                />
                                <div
                                    className={style(classes.actionButtonText, {}, flexFillRemainingHorizontalSpace())}
                                >
                                    {doppeViewerLocales['contactCard.share']}
                                </div>
                                <Icon
                                    className={style(classes.rightIcon, {})}
                                    icon={DoppeViewerAppIconChevronRightMedium}
                                />
                            </Button>
                            :
                            null
                    }
                    <Button
                        className={style(classes.actionButton, {}, flexRowAlignToCenter())}
                        onClick={() => {
                            clipboardCopy(displayValue);
                            showNotification(doppeViewerLocales['contactCard.copied']);
                            onClick(displayValue);
                            windowController.closeWindow();
                        }}
                    >
                        <Icon
                            className={style(classes.actionIcon, {})}
                            icon={DoppeViewerAppIconCopyMedium}
                        />
                        <div
                            className={style(classes.actionButtonText, {}, flexFillRemainingHorizontalSpace())}
                        >
                            {doppeViewerLocales['contactCard.copy']}
                        </div>
                        <Icon
                            className={style(classes.rightIcon, {})}
                            icon={DoppeViewerAppIconChevronRightMedium}
                        />
                    </Button>
                    <Button
                        className={style(classes.actionButton, {}, flexRowAlignToCenter())}
                        onClick={() => {
                            saveAsContact();
                            onClick(displayValue);
                            windowController.closeWindow();
                        }}
                    >
                        <Icon
                            className={style(classes.actionIcon, {})}
                            icon={DoppeViewerAppIconDownloadSmall}
                        />
                        <div
                            className={style(classes.actionButtonText, {}, flexFillRemainingHorizontalSpace())}
                        >
                            {doppeViewerLocales['contactCard.save']}
                        </div>
                        <Icon
                            className={style(classes.rightIcon, {})}
                            icon={DoppeViewerAppIconChevronRightMedium}
                        />
                    </Button>
                </div>
            </DoppeViewerModalPanel>
        </BymoPageStyleScopeContext.Provider>
    );
});