import {Size} from "../size";
import {areaCalculateLargestContainedArea} from "../area/area";
import {numberNormalizeToRange} from "../number-utils/number-utils";

export type FocalPoint = {
    x: number; /* number in range [0, 100] */
    y: number; /* number in range [0, 100] */
}

export function focalPointResolveCssPosition (
    options: {
        focalPoint: FocalPoint;
        mediaWithHeightRatio: number;
        containerWidthHeightRatio: number;
    }
) {
    const {
        focalPoint,
        containerWidthHeightRatio,
        mediaWithHeightRatio
    } = options;

    return focalPointResolveCssPositionBasedOnSize({
        focalPoint: focalPoint,
        sourceSize: {width: 100, height: 100 / mediaWithHeightRatio},
        targetSize: {width: 100, height: 100 / containerWidthHeightRatio}
    })
}


// Note: Taken from '@wix/image-kit' package - function 'convertFillFocalToPosition'
export function focalPointResolveCssPositionBasedOnSize (
    options: {
        focalPoint: FocalPoint;
        sourceSize: Size;
        targetSize: Size;
    }
) {
    const {
        focalPoint,
        sourceSize,
        targetSize
    } = options;

    const { width: sW, height: sH } = sourceSize;
    const { width: tW, height: tH } = targetSize;
    const { x: fpX, y: fpY } = focalPoint;
    if (!tW || !tH) {
        // return `${fpX}% ${fpY}%`;
        return {
            x: fpX,
            y: fpY
        }
    }
    const fillScaleFactor = Math.max(tW / sW, tH / sH);
    const imgScaledW = sW * fillScaleFactor;
    const imgScaledH = sH * fillScaleFactor;
    const x = Math.max(0, Math.min(imgScaledW - tW, imgScaledW * (fpX / 100) - tW / 2));
    const y = Math.max(0, Math.min(imgScaledH - tH, imgScaledH * (fpY / 100) - tH / 2));
    const posX = x && Math.floor((x / (imgScaledW - tW)) * 100);
    const posY = y && Math.floor((y / (imgScaledH - tH)) * 100);

    // return `${posX}% ${posY}%`;
    return {
        x: posX,
        y: posY
    }
}


export function focalPointCalculateCropArea (
    options: {
        focalPoint: FocalPoint;
        widthHeightRatio: number;
        mediaSize: Size;
        focalPointMarginsPercentage?: number;
    }
) {
    const {
        focalPoint,
        mediaSize,
        widthHeightRatio,
        focalPointMarginsPercentage = 0
    } = options;

    const normalizedFocalPoint = {
        x: numberNormalizeToRange(focalPoint.x, focalPointMarginsPercentage, 100 - focalPointMarginsPercentage),
        y: numberNormalizeToRange(focalPoint.y, focalPointMarginsPercentage, 100 - focalPointMarginsPercentage)
    };

    return areaCalculateLargestContainedArea({
        widthHeightRatio: widthHeightRatio,
        areaCenter: {
            left: mediaSize.width * (normalizedFocalPoint.x / 100),
            top: mediaSize.height * (normalizedFocalPoint.y / 100)
        },
        targetArea: {
            left: 0,
            top: 0,
            width: mediaSize.width,
            height: mediaSize.height
        }
    })
}

export function focalPointResolveCroppingOffset (
    options: {
        cropSize: Size,
        mediaSize: Size,
        focalPoint: FocalPoint
    }
) {
    const {
        cropSize,
        mediaSize,
        focalPoint
    } = options;

    return {
        left: numberNormalizeToRange(
            mediaSize.width * (focalPoint.x / 100) - cropSize.width / 2,
            0,
            mediaSize.width - cropSize.width
        ),
        top: numberNormalizeToRange(
            mediaSize.height * (focalPoint.y / 100) - cropSize.height / 2,
            0,
            mediaSize.height - cropSize.height
        )
    }
}