import {WixAppRuntimeEnvironment} from '@wix/devzai-common-wix';
import {sentryUtilsLoadSentry} from '@wix/devzai-utils-browser/src/sentry-urils/sentry-utils';

type AppType = 'DoppeAppViewer' | 'DoppeApp';

type InitializeSentryOptions = {
    environment: WixAppRuntimeEnvironment;
    appType: AppType;
    appVersion?: string;
    getInitialScope: () => { user: { id?: string; username?: string }; tags?: Record<string, string | undefined> };
};

export class DoppeClientErrorsReportingService {
    private isInitialized = false;

    async initialize(options: InitializeSentryOptions) {
        if (this.isInitialized) {
            throw new Error('DoppeClientErrorsReportingService is already initialized');
        }

        const scripts = {
            DoppeAppViewer: 'https://js.sentry-cdn.com/34d0997c9f8984ac65486431b0301c91.min.js',
            DoppeApp: 'https://js.sentry-cdn.com/eadb81f1eec32e8ea949650940dc7d82.min.js',
        };

        await sentryUtilsLoadSentry({
            ...options,
            scriptUrl: scripts[options.appType],
        });

        this.isInitialized = true;
    }
}
