import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeServicesList} from "./doppe-action-type-services-list";

export const doppeActionTypeServicesListViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeServicesList, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeServicesListWidget: (await import(
                './doppe-action-type-services-list-widget/doppe-action-type-services-list-widget'
                )).DoppeActionTypeServicesListWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeServicesListWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeServicesListWidget
                {...renderProps}
            />
        );

    }
})