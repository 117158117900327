import {
    doppeLockWizardTemplateCreateMetadata
} from '../../../doppe-sdk/doppe-lock-wizard-template/doppe-lock-wizard-template';


export namespace DoppeStandardLockWizardTemplate {

    export type TemplateProps = {
        roundness: number;
    }
}

export const DoppeStandardLockWizardTemplateMetadata = doppeLockWizardTemplateCreateMetadata<DoppeStandardLockWizardTemplate.TemplateProps>({
    initialValues: {
        roundness: 25,
    }
})