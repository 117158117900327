export function identity<T = any> (value: T) : T {
    return value;
}

export function noop (..._args: any[]) : void {}

export function returnTrue (..._args: any[]) : boolean {
    return true;
}

export function returnFalse (..._args: any[]) : boolean {
    return false;
}

export function replaceUndefined<T>(value: any, defaultValue: T) {
    return value === undefined ? defaultValue : value;
}