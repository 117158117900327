import {stringSplitAtFirstMatch, stringTrimPrefix} from "../string-utils";
import {regexEscapeRegExpString, regexMatchAll} from "../regex-utils/regex-utils";
import {ArrayOrSingle} from "../common-types";
import {asArray} from "../array-utils";
import {evaluateFunction} from "../evaluable";

export function textNormalizeForHtmlDisplay (text: string, gluedLastWordsCount = 0) {

    let normalizedText = text
        .replace(/^ +/g, '')
        .replace(/ +$/g, '')
        .replace(/\n +/g, '\n')
        .replace(/ +\n/g, '\n')
        .replace(/ +/g, ' ');

    if (gluedLastWordsCount > 1) {

        for (let i = 0; i < gluedLastWordsCount - 1; i++) {
            const updatedText = normalizedText.replace(/ ([^ ]+($|\n))/g, '\u00A0$1');

            if (updatedText === normalizedText) {
                break;
            }

            normalizedText = updatedText;
        }

    }

    return normalizedText;

}

export function textFormatUrlForDisplay (
    url: string,
    options: {
        removeProtocol?: boolean;
        removeWWW?: boolean;
    } = {}
) {
    const {
        removeProtocol = false,
        removeWWW = false
    } = options;

    const decodedUrl = evaluateFunction(() => {
        try {
            return decodeURI(url);
        } catch (error) {
            console.warn(`Failed decoding url '${url}'`);

            return url;
        }
    });

    const [protocol, urlWithoutProtocol] = stringSplitAtFirstMatch(decodedUrl, '://', true) ?? [undefined, decodedUrl];

    const processedUrlWithoutProtocol = removeWWW ? stringTrimPrefix(urlWithoutProtocol, 'www.') : urlWithoutProtocol;

    return removeProtocol || !protocol ?
        processedUrlWithoutProtocol :
        `${protocol}://${processedUrlWithoutProtocol}`
}

/**
 * Taken from: https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
 */
export function textExtractUrls (text: string) {
    return regexMatchAll(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig, text, 0)
}

export function textReplaceUrls (
    str: string,
    replacements: ArrayOrSingle<{from: string; to: string}>
) {
    const replacementsArr = asArray(replacements);
    const replacementsMap = new Map(replacementsArr.map(replacement => [replacement.from, replacement.to]));

    const replacementRegExpStr = replacementsArr.map(replacement => {
        const escapedFrom = regexEscapeRegExpString(replacement.from);

        return `(\\b(${escapedFrom})(?=[^-\\w+&@#/%=~_|]|$))`;
    }).join('|');

    return str.replace(new RegExp(replacementRegExpStr, 'g'), (match) => {
        return replacementsMap.get(match) ?? ''
    })
}

export function textProcessMarkupString<T>(input: string, callback: (text: string, tagName?: string) => T): T[] {
    const regex = /<([\w-:]+)>(.*?)<\/\1>|([^<]+)/g;
    let match: RegExpExecArray | null;

    const result = [] as T[];

    while ((match = regex.exec(input)) !== null) {
        if (match[1]) {
            // Text inside tags with tag name
            result.push(callback(match[2], match[1]));
        } else {
            // Text outside tags
            result.push(callback(match[0]));
        }
    }

    return result;
}

export function textConvertToEmojis (input: string) {

    const emojiesArr = ['🅰','🅱','🅲','🅳','🅴','🅵','🅶','🅷','🅸','🅹','🅺','🅻','🅼','🅽','🅾','🅿','🆀','🆁','🆂','🆃','🆄','🆅','🆆','🆇','🆈','🆉']

    return input.toUpperCase().split('').map(char => {
        if (char >= '0' && char <= '9') {
            return `${char}\uFE0F\u20E3`;
        } else if (char >= 'A' && char <= 'Z') {
            return emojiesArr[char.charCodeAt(0) - 65];
        } else {
            return char;
        }
    }).join('');

}
