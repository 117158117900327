import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeService} from "./doppe-action-type-service";

export const doppeActionTypeServiceViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeService, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeServiceWidget: (await import(
                './doppe-action-type-service-widget/doppe-action-type-service-widget'
                )).DoppeActionTypeServiceWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeServiceWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeServiceWidget
                {...renderProps}
            />
        );

    },

    activateCTA: actionContext => {
        actionContext.navigateToUrl(actionContext.action.settings.servicePageLink, true);
    }
})