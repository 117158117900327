import {
    assertWixImageResource,
    WixAppRuntimeEnvironment,
    WixImageResource,
    WixMetaSiteInfo,
    WixPremiumSubscriptionInfo,
    WixUserDomain
} from "@wix/devzai-common-wix";
import {
    assertNotNullable,
    evaluateFunction,
    iterableFindAndSelect,
    ObjectFieldsAssertion,
    OmitStrict
} from "@wix/devzai-utils-common";
import {
    DoppeDtoUserSettings,
    doppeDtoUserSettingsExtendDefaultValues,
    doppeDtoUserSettingsGetSettingValue
} from "./doppe-dto-user-settings";
import type {DoppeDtoBymoPage, DoppeDtoBymoPageOwnerData} from "./doppe-dto-bymo-page";
import {BymoPageStatus} from "./bymo-page-status";
import {doppeValidateUserDisplayName, doppeValidateUserSubdomain} from "../doppe-validators/doppe-validators";
import {DoppeThirdPartyApps} from "./doppe-third-party-apps";
import {DoppeDtoUserOnboardingLeadData} from "./doppe-dto-user-onboarding-lead-data";
import {DoppeOnboardingType} from "./doppe-onboarding-type";
import {DoppeOnboardingPreferences} from "./doppe-onboarding-preferences";
import {DoppeImportedAccountSubdomainPrefix} from "../../server/doppe-server-constants";
import {DoppeBlockingStatus} from "./doppe-blocking-reason";
import {doppeDtoAppFeatureGetValue} from "./doppe-dto-app-features";
import {doppeFreemiumPlanIsEnabled, doppeFreemiumPlanIsMultiplePackagesPremiumModel} from "../doppe-premium";
import {DoppeContactsMetaSiteAutoValues} from "./doppe-contacts-meta-site";
import {DoppeDtoUserOnboardingData} from "./doppe-dto-user-onboarding-data";
import {DoppeDtoWixUserAppSettings} from "./doppe-dto-wix-user";

export type DoppeDtoUserId = string;

export interface DoppeDtoUser extends DoppeDtoUser.OwnerWixUserData {
    id: DoppeDtoUserId;
    subdomain: string;
    wixUserId: string | null;
    displayName: string;
    wixMetaSiteId: string | null;
    creatorsWixMetaSiteId: string | null;
    profilePicture: WixImageResource | null;
    userSettings: DoppeDtoUserSettings;
    bymoPageId: DoppeDtoBymoPage.Id;
    bymoPageStatus: BymoPageStatus;
    speedDialPageId?: DoppeDtoBymoPage.Id;
    connectedTpas: DoppeThirdPartyApps.IntegrationData[];
    ongoingOnboardingType: DoppeOnboardingType | null;

    leadData: DoppeDtoUserOnboardingLeadData;
    latestLeadData: DoppeDtoUserOnboardingLeadData;
    onboardingPreferences: DoppeOnboardingPreferences | null;
}

export namespace DoppeDtoUser {

    export type CommonData = DoppeDtoBymoPageOwnerData;

    export interface OwnerWixUserData {
        ownerWixUserAppSettings: DoppeDtoWixUserAppSettings;
        isBlocked: DoppeBlockingStatus;
    }

    export type Id = DoppeDtoUser['id'];

    export type Active = OmitStrict<DoppeDtoUser, 'wixUserId' | 'wixMetaSiteId' | 'creatorsWixMetaSiteId'> & {
        wixUserId: Exclude<DoppeDtoUser['wixUserId'], null>;
        wixMetaSiteId: Exclude<DoppeDtoUser['wixMetaSiteId'], null>;
        creatorsWixMetaSiteId: Exclude<DoppeDtoUser['creatorsWixMetaSiteId'], null>;
    }

    export type ActiveAndCompletedOnboarding = OmitStrict<Active, 'ongoingOnboardingType'> & {
        ongoingOnboardingType: null;
    }

    export type ActiveDuringOnboarding = OmitStrict<Active, 'ongoingOnboardingType'> & {
        ongoingOnboardingType: DoppeOnboardingType;
    }

    export type Inactive = OmitStrict<DoppeDtoUser, 'wixUserId' | 'wixMetaSiteId' | 'creatorsWixMetaSiteId' | 'isBlocked'> & {
        wixUserId: null;
        wixMetaSiteId: null;
        creatorsWixMetaSiteId: null;
        isBlocked: false;
    }

    export type WithoutId = OmitStrict<DoppeDtoUser, 'id'>;

    export type ForCreation = Pick<DoppeDtoUser, 'subdomain' | 'displayName' | 'profilePicture'>;

    export type ForAdminCreation = Pick<DoppeDtoUser, 'subdomain' | 'displayName' | 'profilePicture' | 'userSettings' | 'speedDialPageId'> & {
        adminNotes: string;
        boStarred: boolean;
    };

    export type ForUpdate = ForCreation;

    export type ForAdminUpdate = ForUpdate;

    export type WithAdminData = DoppeDtoUser & {
        isBaseUser: boolean;
        isCurrentUser: boolean;
        createdDate: number;
        hasLinkWithHoppPage: boolean;
        linksCount: number | null;
        connectedLinksCount: number | null;
        pagesCount: number | null;
        landingPagesCount: number | null;
        preRollsCount: number | null;
        lastVisitDate: number | null;
        adminNotes: string;
        boStarred: boolean;
        originalWixUserId: string | null;
        originalWixMetaSiteId: string | null;
        biGroups: string[];
        invitationCode: string | null;
        onboardingData: DoppeDtoUserOnboardingData | null;
        isPartner: boolean;

        linkInBioPage: DoppeDtoBymoPage | null;
        userEmail: string | null;
        subscriptionInfo: WixPremiumSubscriptionInfo | null;
        linkedSiteSubscriptionInfo: WixPremiumSubscriptionInfo | null;
        creatorsMetaSiteInfo: WixMetaSiteInfo.FullInfo | null;
        linkedMetaSiteInfo: WixMetaSiteInfo.FullInfo | null;
        connectedDomain: WixUserDomain | null;
        wixUserParentAccountId: string | null;
        premiumWixSitesCount: number | null;
        sourceEnvironment: WixAppRuntimeEnvironment | null;
    }

    export type ExternalAdminDataFields = keyof Pick<WithAdminData,
        | 'linkInBioPage' | 'userEmail' | 'subscriptionInfo'
        | 'connectedDomain' | 'wixUserParentAccountId' | 'premiumWixSitesCount' | 'linkedSiteSubscriptionInfo'
        | 'creatorsMetaSiteInfo' | 'linkedMetaSiteInfo'
    >

    export type ImpersonationData = Pick<DoppeDtoUser, 'id' | 'displayName' | 'subdomain'>

    export type WithFreemiumPlanData = {
        userSettings: Pick<DoppeDtoUser['userSettings'], 'freemiumPlan'>
    }
}

export const DoppeDtoUserForCreationFieldsAssertions: ObjectFieldsAssertion<DoppeDtoUser.ForCreation> = {
    displayName: assert => assert.isString(doppeValidateUserDisplayName),
    subdomain: assert => assert.isString(doppeValidateUserSubdomain),
    profilePicture: assert => assert.isUnion(
        assert => assert.isNull(),
        assert => assert.usingAssertionFunction(assertWixImageResource)
    )
}

export function doppeDtoUserHasCompletedOnboarding (doppeUser: Pick<DoppeDtoUser, 'wixUserId' | 'ongoingOnboardingType'>) : doppeUser is DoppeDtoUser.ActiveAndCompletedOnboarding {
    return doppeUser.wixUserId !== null && doppeUser.ongoingOnboardingType === null;
}

export function doppeDtoUserIsActiveDuringOnboarding (doppeUser: Pick<DoppeDtoUser, 'wixUserId' | 'ongoingOnboardingType'>) : doppeUser is DoppeDtoUser.ActiveDuringOnboarding {
    return doppeUser.wixUserId !== null && doppeUser.ongoingOnboardingType !== null;
}

export function doppeDtoUserIsActive (doppeUser: DoppeDtoUser) : doppeUser is DoppeDtoUser.Active {
    return doppeUser.wixUserId !== null;
}

export function doppeDtoUserGetProjectName (doppeUser: Pick<DoppeDtoUser, 'displayName'>) {
    return doppeUser.displayName;
}

export function doppeDtoUserGetSourceEnvironmentWhenImported (doppeUser: DoppeDtoUser) : WixAppRuntimeEnvironment | null {
    const accountName = doppeUser.subdomain;

    return iterableFindAndSelect(Object.entries(DoppeImportedAccountSubdomainPrefix), ([env, prefix], skip) => {
        return accountName.startsWith(prefix) ? env as WixAppRuntimeEnvironment : skip;
    }) ?? null;
}

export function doppeDtoUserGetCreatorsMetaSiteId<T extends Pick<DoppeDtoUser, 'creatorsWixMetaSiteId'>> (
    doppeUser: T
) : T['creatorsWixMetaSiteId'] {
    return doppeUser.creatorsWixMetaSiteId;
}

export function doppeDtoUserGetLinkedMetaSiteId (doppeUser: Pick<DoppeDtoUser, 'wixMetaSiteId' | 'creatorsWixMetaSiteId'> | DoppeDtoUser) {
    if ('ongoingOnboardingType' in doppeUser && doppeUser.ongoingOnboardingType !== null) {
        return doppeUser.onboardingPreferences?.wixMetaSiteId ?? null;
    }

    const metaSiteId = doppeUser.wixMetaSiteId;

    if (metaSiteId !== null && doppeUser.creatorsWixMetaSiteId !== metaSiteId) {
        return metaSiteId;
    }

    return null;
}

export function doppeDtoUserResolveMediaMetaSiteId<T extends Pick<DoppeDtoUser, 'wixMetaSiteId'>> (
    doppeUser: T
) : T['wixMetaSiteId'] {
    return doppeUser.wixMetaSiteId;
}

export function doppeDtoUserGetFreemiumPlan (doppeUser: DoppeDtoUser.WithFreemiumPlanData) {
    return doppeDtoAppFeatureGetValue(doppeUser.userSettings, 'freemiumPlan');
}

export function doppeDtoUserIsPremiumModelEnabled (doppeUser: DoppeDtoUser.WithFreemiumPlanData) {
    return doppeFreemiumPlanIsEnabled(doppeDtoUserGetFreemiumPlan(doppeUser));
}

export function doppeDtoUserIsMultiplePackagesPremiumModel (doppeUser: DoppeDtoUser.WithFreemiumPlanData) {
    return doppeFreemiumPlanIsMultiplePackagesPremiumModel(doppeDtoUserGetFreemiumPlan(doppeUser))
}


export function doppeDtoUserGetContactsMetaSite<T extends DoppeDtoUser> (doppeUser: T) {
    const userSettings = doppeUser.userSettings;

    return doppeDtoUserSettingsGetSettingValue(userSettings, 'userSelectedContactsMetaSite') ?? evaluateFunction(() => {
        if (doppeDtoUserSettingsGetSettingValue(userSettings, 'contactsMetaSiteSelectionWasEnabledWhenAccountCreated')) {
            return DoppeContactsMetaSiteAutoValues.CreatorsMetaSite
        } else {
            return doppeDtoUserGetLinkedMetaSiteId(doppeUser) !== null ?
                DoppeContactsMetaSiteAutoValues.LinkedMetaSite :
                DoppeContactsMetaSiteAutoValues.CreatorsMetaSite;
        }
    })
}

export function doppeDtoUserResolveContactsMetaSiteId<T extends DoppeDtoUser.Active> (doppeUser: T) {
    const contactsMetaSite = doppeDtoUserGetContactsMetaSite(doppeUser);

    switch (contactsMetaSite) {
        case DoppeContactsMetaSiteAutoValues.CreatorsMetaSite: {
            return assertNotNullable(doppeDtoUserGetCreatorsMetaSiteId(doppeUser));
        }
        case DoppeContactsMetaSiteAutoValues.LinkedMetaSite: {
            return assertNotNullable(doppeDtoUserGetLinkedMetaSiteId(doppeUser));
        }
        default: {
            return contactsMetaSite.metaSiteId;
        }
    }
}

export function doppeDtoUserExtendsUserSettingsWithDefaultValues<T extends DoppeDtoUser>(doppeUser: T) {
    return {
        ...doppeUser,
        userSettings: doppeDtoUserSettingsExtendDefaultValues(doppeUser.userSettings)
    } as T
}