export function validateIsFQDN(
    str: string, 
    options: {
        require_tld?: boolean;
        allow_underscores?: boolean;
        allow_trailing_dot?: boolean;
        allow_numeric_tld?: boolean;
        allow_wildcard?: boolean;
    } = {}
) {
    const {
        require_tld = true,
        allow_underscores = false,
        allow_trailing_dot = false,
        allow_numeric_tld = false,
        allow_wildcard = false
    } = options;

    if (allow_trailing_dot && str[str.length - 1] === '.') {
        str = str.substring(0, str.length - 1);
    }
    /* Remove the optional wildcard before checking validity */


    if (allow_wildcard && str.indexOf('*.') === 0) {
        str = str.substring(2);
    }

    const parts = str.split('.');
    const tld = parts[parts.length - 1];

    if (require_tld) {
        // disallow fqdns without tld
        if (parts.length < 2) {
            return false;
        }

        if (!/^([a-z\u00A1-\u00A8\u00AA-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}|xn[a-z0-9-]{2,})$/i.test(tld)) {
            return false;
        } // disallow spaces


        if (/\s/.test(tld)) {
            return false;
        }
    } // reject numeric TLDs


    if (!allow_numeric_tld && /^\d+$/.test(tld)) {
        return false;
    }

    return parts.every(function (part) {
        if (part.length > 63) {
            return false;
        }

        if (!/^[a-z_\u00a1-\uffff0-9-]+$/i.test(part)) {
            return false;
        } // disallow full-width chars


        if (/[\uff01-\uff5e]/.test(part)) {
            return false;
        } // disallow parts starting or ending with hyphen


        if (/^-|-$/.test(part)) {
            return false;
        }

        if (!allow_underscores && /_/.test(part)) {
            return false;
        }

        return true;
    });
}