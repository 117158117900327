import {WixImageResource, wixImageResourceGetResizedImageUrl} from '@wix/devzai-common-wix';
import {DoppeActionFullName} from './doppe-action-full-name';
import {
    DoppeDtoContactMethod,
    doppeDtoContactMethodLinkResolveValue,
    DoppeDtoContactMethods
} from './doppe-dto-contact-method';
import {doppeHideableValueGetVisibleValue} from './doppe-hideable-value';
import {
    assertDefined,
    HtmlObjectHorizontalPosition,
    HtmlObjectResizeMode,
    HtmlObjectVerticalPosition,
    validateIsUrl,
    vcfGenerate
} from '@wix/devzai-utils-common';
import {browserConvertImageToBase64, browserDownloadStringAsFile} from '@wix/devzai-utils-browser';
import {
    doppeViewerContactMethodIsEmail,
    doppeViewerContactMethodIsLocation,
    doppeViewerContactMethodIsPhoneNumber
} from '../../components/doppe-viewer-contact-methods/doppe-viewer-contact-methods';

export interface DoppeDtoContactDetails {
    media: WixImageResource | null;
    name: DoppeActionFullName | null;
    jobTitle: string;
    contactMethods: DoppeDtoContactMethod[];
    company: string;
    notes: string;
}

export async function downloadContactCard(contactDetails: DoppeDtoContactDetails) {

    const {
        name,
        media,
        jobTitle,
        contactMethods,
        company,
        notes,
    } = contactDetails;

    const firstName = name?.firstName ?? '';
    const lastName =  name?.lastName ?? '';

    const calculatedName = firstName || lastName ? `${firstName} ${lastName}` : 'Contact';
    const imageForContact = media;

    const resizedImageUrl = imageForContact ? wixImageResourceGetResizedImageUrl(imageForContact, {
        resizeMode: HtmlObjectResizeMode.Cover,
        targetHeight: 350,
        targetWidth: 350,
        cropImage: {
            positionVertical: HtmlObjectVerticalPosition.Center,
            positionHorizontal: HtmlObjectHorizontalPosition.Center
        }
    }) : null;

    const imageToDownload = resizedImageUrl ? await browserConvertImageToBase64(resizedImageUrl) : undefined;

    browserDownloadStringAsFile(vcfGenerate({
        firstName: firstName,
        lastName: lastName,
        organization: company,
        jobTitle: doppeHideableValueGetVisibleValue(jobTitle, ''),
        emails: contactMethods.filter(method => {
            return doppeViewerContactMethodIsEmail(method.type);
        }).map(method => {
            return {
                email: method.displayValue,
                type: 'work'
            };
        }),
        phoneNumbers: [...contactMethods.filter(method => {
            return doppeViewerContactMethodIsPhoneNumber(method.type);
        }), ...contactMethods.filter(method => {
            return method.type === DoppeDtoContactMethods.WhatsApp;
        })].map(method => {
            return {
                phoneNumber: method.displayValue,
                type: 'work'
            };
        }),
        urls: contactMethods.filter(method => {
            return (validateIsUrl(method.displayValue) && !doppeViewerContactMethodIsEmail(method.type)) || (method.type === DoppeDtoContactMethods.FacebookMessenger && doppeDtoContactMethodLinkResolveValue(method) !== undefined)
        }).map(method => {
            return {
                url: assertDefined(doppeDtoContactMethodLinkResolveValue(method)),
                type: 'work'
            };
        }),
        address: contactMethods.filter(method => {
            return doppeViewerContactMethodIsLocation(method.type);
        })[0]?.displayValue ?? '',
        note: notes,
        photoUrl: imageToDownload,
    }), `${calculatedName}.vcf`);
}