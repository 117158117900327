import {InitializableValue} from "@wix/devzai-utils-common";
import {DoppeViewerClientServices} from "../doppe-user-app/doppe-viewer-client-services";

export abstract class DoppeViewerAppClientServiceBase {

    private deferredDoppeAppClientServices;

    constructor(deferredDoppeAppClientServices: InitializableValue<DoppeViewerClientServices>) {
        this.deferredDoppeAppClientServices = deferredDoppeAppClientServices;
    }

    protected get clientServices() {
        return this.deferredDoppeAppClientServices.getValue();
    }

    protected get apiClient() {
        return this.clientServices.getApiClient();
    }

    protected getClientId () {
        return this.clientServices.doppeViewerClientData.clientId;
    }

    protected getSessionId () {
        return this.clientServices.doppeViewerClientData.sessionId;
    }

}