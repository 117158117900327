import React from 'react';
import {DoppeDtoActionViewType} from '../../client-server-common/types/doppe-dto-action';
import {IDoppeViewerActionContext} from '../doppe-viewer-action-context/doppe-viewer-action-context';
import {AnyObject, HtmlDataAttributes, ObservableValue, OmitStrict} from '@wix/devzai-utils-common';
import {Button} from '@wix/devzai-utils-react';
import {DoppeHideableValue} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource} from '@wix/devzai-common-wix';

export type DoppeActionComponentActionButtonProps = OmitStrict<Button.Props, 'tagName'>;


export interface DoppeActionView {

    showContent: () => void;

    showActionButton () : void;
}

export namespace DoppeActionView {

    export interface InlineContentCloseButtonRenderProps {
        closingFunction: () => void;
    }

    export interface TemplateContextProps {
        renderInlineContentCloseButton: (renderProps: InlineContentCloseButtonRenderProps) => React.ReactElement | null;
        actionWidgetFramelessContainerClassName?: string;
    }

    /**
     * Properties which value are provided to the action component in a context of a specific action.
     * These properties cannot be configured by the user.
     */
    export interface ActionContextProps {
        label: string;
        actionDescription: DoppeHideableValue<string>;
        actionImage: DoppeHideableValue<WixMediaResource | null>;
        actionIndexInList: number | null;
        bannerMedia: WixMediaResource | null;
        viewType: DoppeDtoActionViewType;
        showActionHeader: boolean;
        renderActionWidget: (htmlAttributes: React.HTMLAttributes<any>) => React.ReactElement;
        actionButtonComponentProps: DoppeActionComponentActionButtonProps;
        activateCTA: () => void;
        isWidgetOpener: boolean;
        isLockedObservable?: ObservableValue<boolean>;
        skipReportingOnGoalAchievedOnActionButtonClick: boolean;
        actionContext: IDoppeViewerActionContext<any>;
        isExpanded: boolean;
        actionViewRootHtmlAttributes: React.HTMLAttributes<any> & HtmlDataAttributes;
    }

    export interface ContextProps extends ActionContextProps, TemplateContextProps {

    }

    export interface Props<TEMPLATE_PROPS extends {} = Record<string, any>> extends ContextProps {
        templateProps: TEMPLATE_PROPS;
        enableAnimation: boolean;
    }
}

export interface DoppeActionButtonTemplateMetadata<PARAMS extends AnyObject> {
    initialValues: PARAMS;
}



export interface DoppeActionButtonTemplateDefinition<PARAMS extends AnyObject> extends DoppeActionButtonTemplateMetadata<PARAMS> {
    componentType: React.ComponentType<DoppeActionView.Props<PARAMS>>;
}

export function doppeActionButtonTemplateDefine<PARAMS extends AnyObject> (
    metadata: DoppeActionButtonTemplateMetadata<PARAMS>,
    componentType: React.ComponentType<DoppeActionView.Props<PARAMS>>
) : DoppeActionButtonTemplateDefinition<PARAMS> {
    return {
        ...metadata,
        componentType: componentType
    };
}

export function doppeActionButtonTemplateCreateMetadata<PARAMS extends AnyObject> (
    metadata: DoppeActionButtonTemplateMetadata<PARAMS>
) {
    return metadata;
}

export function dopeActionButtonTemplateResolveProps<PARAMS extends AnyObject> (
    actionButtonTemplateMetadata: DoppeActionButtonTemplateMetadata<PARAMS>,
    partialProps: Partial<PARAMS>
) : PARAMS {
    return {
        ...actionButtonTemplateMetadata.initialValues,
        ...partialProps
    }
}