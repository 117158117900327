/**
 * @deprecated use numberNormalizeToRange instead
 */
export function mathNormalizeToRange(value: number, minValue: number, maxValue: number) {
    return Math.max(minValue, Math.min(value, maxValue));
}

/**
 * @deprecated use numberIsInteger instead
 */
export function mathIsInteger (value: number) {
    return Math.floor(value) === value;
}
