import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeFollow} from "./doppe-action-type-follow";

export const doppeActionTypeFollowViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeFollow, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeFollowWidget: (await import('./doppe-action-type-follow-widget/doppe-action-type-follow-widget')).DoppeActionTypeFollowWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeFollowWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeFollowWidget
                {...renderProps}
            />
        );
    }
})