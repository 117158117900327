import {EqualityComparer, EventReference, referencesEqualityComparer} from '@wix/devzai-utils-common';
import {useEffect, useMemo, useRef} from 'react';
import {useForceUpdate} from './use-force-update';

interface ISubscribable {
    subscribe: (cb: (...args: any[]) => any) => any;
    unsubscribe: (cb: (...args: any[]) => any) => any;
}

export function useValueBinding<T, EVENT_TYPES, EVENT_NAME extends keyof EVENT_TYPES>(
    target: EventReference<EVENT_TYPES, EVENT_NAME> | ISubscribable | undefined | null,
    valueFunction: () => T,
    equalityComparer: EqualityComparer<T> = referencesEqualityComparer
): T {
    let eventReference: EventReference<EVENT_TYPES, EVENT_NAME> | undefined;
    let subscribable: ISubscribable | undefined;
    let depsList: any[];

    if (target === undefined || target === null) {
        depsList = [undefined, undefined];
    } else if (Array.isArray(target)) {
        eventReference = target;

        depsList = [target[0], target[1]];
    } else {
        subscribable = target;
        depsList = [subscribable, undefined];
    }

    depsList.push(valueFunction);

    const valueRef = useRef<T>();
    const forceUpdate = useForceUpdate();

    const updateEventListenerRef = useRef<() => void>();

    const eventBinding = useMemo(() => {
        const updateEventListener = updateEventListenerRef.current = () => {
            if (updateEventListener === updateEventListenerRef.current) {
                const prevValue = valueRef.current;
                const updatedValue = valueFunction();
                if (prevValue === undefined || !equalityComparer(updatedValue, prevValue)) {
                    valueRef.current = updatedValue;
                    forceUpdate();
                }
            }
        };

        if (eventReference !== undefined) {
            const [eventEmitter, eventName] = eventReference;

            return eventEmitter.on(eventName, updateEventListener);
        } else if (subscribable !== undefined) {
            subscribable.subscribe(updateEventListener);

            return {
                dispose: () => subscribable!.unsubscribe(updateEventListener)
            };
        } else {
            return noopTargetBinding;
        }
    }, depsList);

    // Directly recalculate and update the value, when the listener is
    // changed (happens when the subject or the value function has changed).
    useMemo(() => {
        valueRef.current = valueFunction();
    }, [eventBinding]);

    useEffect(() => {
        return () => {
            eventBinding.dispose();
        };
    }, [eventBinding]);

    return valueRef.current!;
}

const noopTargetBinding = {
    dispose: () => {}
};
