import { arrayClone } from './array-utils';
import { MatchingKeys } from './common-types';
import {Evaluable, evaluateWhenFunction} from "./evaluable";

export type EventsWithoutPayload<EVENTS_TYPES> = MatchingKeys<EVENTS_TYPES, void>;

export interface AnyEventPayload {
    payload: any;
    eventName: any;
}

export type EventEmitterListener<PAYLOAD> = (payload: PAYLOAD) => void;

const AnyEvent: unique symbol = Symbol('AnyEvent');

export class EventEmitterBinding {

    private isDisposed = false;

    constructor(
        private eventEmitter: EventEmitter<any>,
        private eventName: any,
        private listener: EventEmitterListener<any>
    ) {}

    public dispose() {

        if (this.isDisposed) {
            console.warn('dispose was called for an already disposed event emitter binding');
        }

        this.isDisposed = true;
        this.eventEmitter.off(this.eventName, this.listener);
    }
}

interface BatchState {
    counter: number;
    pendingEmitting: boolean;
}

interface EventsIgnoringBatchState {
    counter: number;
}

export class EventEmitter<
    EVENTS_TYPES,
    EVENTS extends keyof EVENTS_TYPES = keyof EVENTS_TYPES,
    VOID_EVENTS extends EventsWithoutPayload<EVENTS_TYPES> = EventsWithoutPayload<EVENTS_TYPES>,
    NON_VOID_EVENTS extends Exclude<keyof EVENTS_TYPES, VOID_EVENTS> = Exclude<keyof EVENTS_TYPES, VOID_EVENTS>
> {
    private _eventsMap?: Map<any, EventEmitterListener<any>[]> = undefined;
    private _eventsBatchStateMap?: Map<VOID_EVENTS, BatchState> = undefined;
    private _eventsIgnoringBatchStateMap?: Map<EVENTS, EventsIgnoringBatchState> = undefined;

    public emit<EVENT_TYPE extends NON_VOID_EVENTS>(eventName: EVENT_TYPE, payload: EVENTS_TYPES[EVENT_TYPE]): void;
    public emit<EVENT_TYPE extends VOID_EVENTS>(eventName: EVENT_TYPE): void;
    public emit<EVENT_TYPE extends EVENTS>(eventName: EVENT_TYPE, payload?: EVENTS_TYPES[EVENT_TYPE]): void {

        if ((this._eventsIgnoringBatchStateMap?.get(eventName as any)?.counter ?? 0) > 0) {
            return;
        }

        const batchState = this.getBatchState(eventName);
        if (batchState && batchState.counter > 0) {
            batchState.pendingEmitting = true;
        } else {
            this.emitInternal(eventName, payload);
        }
    }

    private emitInternal<EVENT_TYPE extends EVENTS>(eventName: EVENT_TYPE, payload?: EVENTS_TYPES[EVENT_TYPE]): void {
        const eventsMap = this._eventsMap;
        if (eventsMap !== undefined) {
            const eventListeners = eventsMap.get(eventName);

            if (eventListeners !== undefined) {
                const clonedEventListeners = arrayClone(eventListeners);

                for (const eventListener of clonedEventListeners) {
                    eventListener(payload);
                }
            }

            const anyEventListeners = eventsMap.get(AnyEvent);
            if (anyEventListeners !== undefined) {
                const anyEventPayload = {
                    payload: payload,
                    eventName: eventName
                };

                const clonedEventListeners = arrayClone(anyEventListeners);

                for (const eventListener of clonedEventListeners) {
                    eventListener(anyEventPayload);
                }
            }
        }
    }

    public onAny(eventListener: EventEmitterListener<AnyEventPayload>) {
        return this.on(AnyEvent as any, eventListener as any);
    }

    public on<EVENT_TYPE extends EVENTS>(
        eventType: EVENT_TYPE,
        eventListener: EventEmitterListener<EVENTS_TYPES[EVENT_TYPE]>
    ): EventEmitterBinding {
        let eventsMap = this._eventsMap;
        if (eventsMap === undefined) {
            eventsMap = this._eventsMap = new Map();
        }

        let eventListeners = eventsMap.get(eventType);
        if (eventListeners === undefined) {
            eventListeners = [];
            eventsMap.set(eventType, eventListeners);
        }

        eventListeners.push(eventListener);

        return new EventEmitterBinding(this, eventType, eventListener);
    }

    public one<EVENT_TYPE extends EVENTS>(
        eventType: EVENT_TYPE,
        eventListener: EventEmitterListener<EVENTS_TYPES[EVENT_TYPE]>
    ): EventEmitterBinding {
        const binding = this.on(eventType, (payload: EVENTS_TYPES[EVENT_TYPE]) => {
            eventListener(payload);
            binding.dispose();
        });

        return binding;
    }

    public off<EVENT_TYPE extends EVENTS>(
        eventType: EVENT_TYPE,
        eventListener: EventEmitterListener<EVENTS_TYPES[EVENT_TYPE]>
    ): void {
        const eventsMap = this._eventsMap;
        if (eventsMap !== undefined) {
            const eventListeners = eventsMap.get(eventType);

            if (eventListeners !== undefined) {
                const eventListenerIndex = eventListeners.indexOf(eventListener);

                if (eventListenerIndex > -1) {
                    eventListeners.splice(eventListenerIndex, 1);
                } else {
                    console.warn(`EventEmitter's 'off' function was called for an unknown event listener`)
                }
            }
        }
    }

    private acquireEventsIgnoringBatchStateMap () {

        let eventsBatchStateMap = this._eventsIgnoringBatchStateMap;
        if (eventsBatchStateMap === undefined) {
            eventsBatchStateMap = this._eventsIgnoringBatchStateMap = new Map<EVENTS, EventsIgnoringBatchState>();
        }

        return eventsBatchStateMap;
    }

    private acquireEventsBatchStateMap(): Map<VOID_EVENTS, BatchState> {
        let eventsBatchStateMap = this._eventsBatchStateMap;
        if (eventsBatchStateMap === undefined) {
            eventsBatchStateMap = this._eventsBatchStateMap = new Map<VOID_EVENTS, BatchState>();
        }

        return eventsBatchStateMap;
    }

    private getBatchState<EVENT_TYPE extends EVENTS>(eventName: EVENT_TYPE): BatchState | undefined {
        const eventsBatchStateMap = this._eventsBatchStateMap;
        if (eventsBatchStateMap) {
            return eventsBatchStateMap.get(eventName as any);
        }

        return undefined;
    }

    public ignoreEventEmitting<EVENT_TYPE extends EVENTS, RETURN_VALUE>(
        eventName: EVENT_TYPE,
        emittingFunc: () => RETURN_VALUE
    ) : RETURN_VALUE {
        const eventsBatchStateMap = this.acquireEventsIgnoringBatchStateMap();

        let batchState = eventsBatchStateMap.get(eventName);
        if (batchState === undefined) {
            batchState = {
                counter: 0
            };

            eventsBatchStateMap.set(eventName, batchState);
        }

        batchState.counter++;

        let thrownError: Error | undefined = undefined;
        let result: RETURN_VALUE | undefined;

        try {
            result = emittingFunc();
        } catch (error: any) {
            thrownError = error;
        }

        batchState.counter--;

        if (thrownError) {
            throw thrownError;
        } else {
            return result!;
        }
    }

    public batchEventEmitting<EVENT_TYPE extends VOID_EVENTS, RETURN_VALUE>(
        eventName: EVENT_TYPE,
        emittingFunc: () => RETURN_VALUE
    ): RETURN_VALUE {
        const eventsBatchStateMap = this.acquireEventsBatchStateMap();

        let batchState = eventsBatchStateMap.get(eventName);
        if (batchState === undefined) {
            batchState = {
                counter: 0,
                pendingEmitting: false
            };

            eventsBatchStateMap.set(eventName, batchState);
        }

        batchState.counter++;

        let thrownError: Error | undefined = undefined;
        let result: RETURN_VALUE | undefined;

        try {
            result = emittingFunc();
        } catch (error: any) {
            thrownError = error;
        }

        batchState.counter--;

        if (batchState.counter === 0 && batchState.pendingEmitting) {
            batchState.pendingEmitting = false;

            this.emitInternal(eventName as any);
        }

        if (thrownError) {
            throw thrownError;
        } else {
            return result!;
        }
    }

    public createEventReference<EVENT_NAME extends EVENTS>(
        eventName: EVENT_NAME
    ): EventReference<EVENTS_TYPES, EVENT_NAME> {
        return createEventReference<EVENTS_TYPES, EVENT_NAME>(this, eventName);
    }

    public static on<EVENT_TYPES, EVENT_NAME extends keyof EVENT_TYPES>(
        eventReference: EventReference<EVENT_TYPES, EVENT_NAME>,
        callback: (payload: EVENT_TYPES[EVENT_NAME]) => void
    ) {
        const [eventEmitter, eventName] = eventReference;

        return eventEmitter.on(eventName, callback);
    }

    public static one<EVENT_TYPES, EVENT_NAME extends keyof EVENT_TYPES>(
        eventReference: EventReference<EVENT_TYPES, EVENT_NAME>,
        callback: (payload: EVENT_TYPES[EVENT_NAME]) => void
    ) {
        const [eventEmitter, eventName] = eventReference;

        return eventEmitter.one(eventName, callback);
    }
}

export type EventReference<EVENT_TYPES, EVENT_NAME extends keyof EVENT_TYPES> = [EventEmitter<EVENT_TYPES>, EVENT_NAME];

export function createEventReference<EVENT_TYPES, EVENT_NAME extends keyof EVENT_TYPES>(
    eventEmitter: EventEmitter<EVENT_TYPES>,
    eventName: EVENT_NAME
): EventReference<EVENT_TYPES, EVENT_NAME> {
    return [eventEmitter, eventName];
}


export class EventEmitterProxy<EVENTS_TYPES, EVENTS extends keyof EVENTS_TYPES = keyof EVENTS_TYPES> extends EventEmitter<EVENTS_TYPES, EVENTS> {

    private eventEmitter;

    constructor(eventEmitter: Evaluable<() => EventEmitter<EVENTS_TYPES>>) {
        super();

        this.eventEmitter = eventEmitter;
    }

    public on<EVENT_TYPE extends EVENTS>(
        eventType: EVENT_TYPE,
        eventListener: EventEmitterListener<EVENTS_TYPES[EVENT_TYPE]>
    ): EventEmitterBinding {
        return evaluateWhenFunction(this.eventEmitter).on(eventType, eventListener);
    }

    public off<EVENT_TYPE extends EVENTS>(
        eventType: EVENT_TYPE,
        eventListener: EventEmitterListener<EVENTS_TYPES[EVENT_TYPE]>
    ): void {
        return evaluateWhenFunction(this.eventEmitter).off(eventType, eventListener);
    }

}