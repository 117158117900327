import {Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity} from './doppe-business-entity';
import {DoppeAutoServiceType} from './doppe-auto-service-type';

export const DoppeServicesExternalDataSourceType = {
    LatestWixServices: 'LatestWixServices'
} as const;

export type DoppeServicesExternalDataSourceType = Values<typeof DoppeServicesExternalDataSourceType>;

export type DoppeServicesExternalDataSource =
    | {
    type: typeof DoppeServicesExternalDataSourceType.LatestWixServices;
};

export function doppeServicesExternalDataSourceResolveBusinessEntityReferences(
    externalDataSource: DoppeServicesExternalDataSource
) {
    switch (externalDataSource.type) {
        case DoppeServicesExternalDataSourceType.LatestWixServices: {
            return {businessEntity: DoppeBusinessEntity.WixAutoServicesList, id: DoppeAutoServiceType.Latest}
        }
    }
}