import React, {useContext, useRef} from "react";
import {assertNotNullable} from "@wix/devzai-utils-common";
import {useInstanceValue} from "../use-data-member";

type SsrSafeHtmlIdsProviderContextData = {nextId: number};

const SsrSafeHtmlIdsProviderContext = React.createContext<SsrSafeHtmlIdsProviderContextData | null>(null);

export const SsrSafeHtmlIdsProvider = React.memo(function HtmlIdsProvider (props: { children: React.ReactNode }) {
    const contextDataRef = useRef<SsrSafeHtmlIdsProviderContextData>({nextId: 0})

    return (
        <SsrSafeHtmlIdsProviderContext.Provider
            value={contextDataRef.current}
        >
            {props.children}
        </SsrSafeHtmlIdsProviderContext.Provider>
    );
});

export function useSsrSafeHtmlId () {

    const ssrSafeHtmlIdsProviderContextData = assertNotNullable(useContext(SsrSafeHtmlIdsProviderContext), `SsrSafeHtmlIdsProvider isn't used`)

    return useInstanceValue(() => {
        return `id-${ssrSafeHtmlIdsProviderContextData.nextId++}`
    })
}