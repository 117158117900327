import {
    DoppeFormActionSettings,
    getDefaultDoppeFormActionSettings,
    prepareFormSettingsForViewer
} from '../../client-server-common/types/doppe-form-action-settings';
import {DoppeHideableValue, doppePremiumFeatureIsDisabled, DoppePremiumFeatures} from '../../client-server-common';
import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';

export namespace DoppeLockActionTypeContactForm {

    export interface ActionSettings extends DoppeFormActionSettings{
        subscribeButtonText: DoppeHideableValue<string>;
        collectEmail: boolean;
        collectPhoneNumber: boolean;
        collectName: boolean;
        collectAddress: boolean;
        collectZip: boolean;
        collectMessage: boolean;
    }
}

export const doppeLockActionTypeContactForm = doppeActionTypeDefineMetadata<DoppeLockActionTypeContactForm.ActionSettings>({
    id: 'co.hopp.lockAction/contact-form-lock',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        collectEmail: true,
        collectPhoneNumber: true,
        collectName: true,
        collectAddress: true,
        collectZip: true,
        collectMessage: false,
        subscribeButtonText: '',
        ...getDefaultDoppeFormActionSettings()
    }),
    isWidgetOpener: true,
    isFormAction: true,
    prepareForViewer: actionSettings => {
        prepareFormSettingsForViewer(actionSettings);
    },
    isPremiumAction: (premiumFeaturesStatus) => {
        return doppePremiumFeatureIsDisabled(premiumFeaturesStatus, DoppePremiumFeatures.creators_form_submission)
    },
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    canRenderInPage: true,
})