import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeCoupon} from "./doppe-action-type-coupon";

export const doppeActionTypeCouponViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeCoupon, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeCouponWidget: (await import('./doppe-action-type-coupon-widget/doppe-action-type-coupon-widget')).DoppeActionTypeCouponWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeCouponWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeCouponWidget
                {...renderProps}
            />
        )
    }
})