import type {NonNullishValue} from "../common-types";
import {assertType, AssertionLogicFunction} from "../type-assertion/type-assertion";

export type AutoValue<T> = {
    __autoValue: true;
    value: T;
}

export function autoValueIsAutoValue<T> (value: unknown) : value is AutoValue<T> {
    return !!value && ((value as AutoValue<T>).__autoValue === true);
}

export function autoValueCreate<T> (value: T) : AutoValue<T> {
    return {
        __autoValue: true,
        value: value
    }
}

export function autoValueGetValue<T> (autoValue: AutoValue<T>) : T;
export function autoValueGetValue<T, D> (autoValue: NonNullishValue, defaultValue: D) : T | D;
export function autoValueGetValue<T, D> (autoValue: NonNullishValue, defaultValue?: D) : T | D | undefined {
    return autoValueIsAutoValue<T>(autoValue) ? autoValue.value : defaultValue;
}

export function autoValueAssert<V> (autoValue: AutoValue<V>, assertionLogic: AssertionLogicFunction<V>) : asserts autoValue is AutoValue<V>;
export function autoValueAssert<V> (autoValue: unknown, assertionLogic: AssertionLogicFunction<V>) : asserts autoValue is AutoValue<V>;
export function autoValueAssert<V> (autoValue: unknown, assertionLogic: AssertionLogicFunction<V>) : asserts autoValue is AutoValue<V> {
    assertType<AutoValue<V>>(autoValue, assert => {
        assert.isObject({
            __autoValue: assert => assert.isOneOfValues([true]),
            value: assertionLogic
        })
    })
}