import {doppeActionTypeDefineViewerMetadata} from '../../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeIframe} from '../doppe-action-type-iframe';

export const doppeActionTypeIframeViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeIframe, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeIframeWidget: (await import('../doppe-action-type-iframe-widget/doppe-action-type-iframe-widget')).DoppeActionTypeIframeWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeIframeWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeIframeWidget
                {...renderProps}
            />
        );
    }
})