import { sameValue } from './comparison-utils';

export type EqualityComparer<T> = (value1: T, value2: T) => boolean;

export type FieldsEqualityComparers<T> = { [K in keyof T]: EqualityComparer<T[K]> };

export function referencesEqualityComparer(value1: any, value2: any) {
    return value1 === value2;
}

export function equalityComparerCompare<T>(
    equalityComparer: EqualityComparer<T>,
    value1: T | null | undefined,
    value2: T | null | undefined
) {
    if (value1 === undefined || value2 === undefined || value1 === null || value2 === null) {
        return value1 === value2;
    } else {
        return equalityComparer(value1, value2);
    }
}

export function createNullableValuesEqualityComparer<T>(
    equalityComparer: EqualityComparer<T>
): EqualityComparer<T | null | undefined> {
    return function(value1: T | null | undefined, value2: T | null | undefined) {
        if (value1 === undefined || value2 === undefined || value1 === null || value2 === null) {
            return value1 === value2;
        } else {
            return equalityComparer(value1, value2);
        }
    };
}

/**
 * @deprecated use objectShallowEqual instead.
 * @param options
 */
export function createObjectEqualityComparer<T>(options: {
    fields?: (keyof T)[];
    fieldsEqualityComparers?: Partial<FieldsEqualityComparers<T>>;
}): EqualityComparer<T> {
    const { fields, fieldsEqualityComparers } = options;

    return (obj1: T, obj2: T) => {
        if (obj1 === obj2) {
            return true;
        }

        if (fields !== undefined) {
            for (const fieldName of fields) {
                const value1 = obj1[fieldName];
                const value2 = obj2[fieldName];

                const fieldEqualityComparer = fieldsEqualityComparers && fieldsEqualityComparers[fieldName];
                if (fieldEqualityComparer) {
                    if (!fieldEqualityComparer(value1, value2)) {
                        return false;
                    }
                } else {
                    if (!sameValue(value1, value2)) {
                        return false;
                    }
                }
            }
        }

        return true;
    };
}
