import {Values} from "@wix/devzai-utils-common";
import {doppeActionButtonTemplateCreateMetadata} from "../../../doppe-sdk";

export const DopeStandardActionButtonSkin = {
    Standard: 'standard',
    Inverted: 'inverted',
    HardShadow: 'hardShadow',
    Glass: 'glass',
    Gradient: 'gradient'
} as const;

export type DopeStandardActionButtonSkin = Values<typeof DopeStandardActionButtonSkin>;

export const DopeStandardActionButtonColorMode = {
    Unified: 'unified',
    Alternate: 'alternate',
    Analogous: 'analogous',
    Triad: 'triad'
} as const;

export type DopeStandardActionButtonColorMode = Values<typeof DopeStandardActionButtonColorMode>;

export const DoppeStandardActionButtonHoverAnimation = {
    None: 'none',
    InvertBackground: 'invertBackground'
} as const;

export type DoppeStandardActionButtonHoverAnimation = Values<typeof DoppeStandardActionButtonHoverAnimation>;

export namespace DoppeStandardActionButtonTemplate {

    export type TemplateProps = {
        roundness: number;
        skin: DopeStandardActionButtonSkin;
        hoverAnimation: DoppeStandardActionButtonHoverAnimation;
        colorMode: DopeStandardActionButtonColorMode;
    }
}

export const DoppeStandardActionButtonTemplateMetadata = doppeActionButtonTemplateCreateMetadata<DoppeStandardActionButtonTemplate.TemplateProps>({
    initialValues: {
        roundness: 25,
        skin: DopeStandardActionButtonSkin.Standard,
        hoverAnimation: DoppeStandardActionButtonHoverAnimation.None,
        colorMode: DopeStandardActionButtonColorMode.Unified
    }
})