import {
    BrowserAnimationFrameScheduler,
    IAnimationFrameScheduler
} from '../animation-frame-scheduler/animation-frame-scheduler';

export class AnimationFrameTimer implements IAnimationFrameScheduler {
    private _callback: FrameRequestCallback;
    private _scheduledAnimationFrame: any;
    private _isActivated = false;
    private _syncedAnimationFrameCallbacks: FrameRequestCallback[] = [];

    /**
     * @constructs
     */
    constructor(callback: FrameRequestCallback) {
        this._callback = callback;
        this._scheduledAnimationFrame = undefined;
    }

    private _postAnimationFrameCallback() {
        this._scheduledAnimationFrame = requestAnimationFrame(time => {
            this._animationFrameCallback(time);
        });
    }

    private _animationFrameCallback(time: number) {
        if (this._isActivated) {
            const syncedAnimationFrameCallbacks = this._syncedAnimationFrameCallbacks;

            this._syncedAnimationFrameCallbacks = [];

            for (const callback of syncedAnimationFrameCallbacks) {
                callback(time);
            }

            this._callback(time);

            this._postAnimationFrameCallback();
        }
    }

    activate() {
        if (!this._isActivated) {
            this._postAnimationFrameCallback();

            this._isActivated = true;
        }
    }

    deactivate() {
        if (this._isActivated) {
            const scheduledAnimationFrame = this._scheduledAnimationFrame;

            if (scheduledAnimationFrame !== undefined) {
                this._scheduledAnimationFrame = undefined;

                cancelAnimationFrame(scheduledAnimationFrame);
            }

            this._isActivated = false;
        }
    }

    public requestAnimationFrame(callback: FrameRequestCallback) {
        if (this._isActivated) {
            this._syncedAnimationFrameCallbacks.push(callback);

            return () => {
                const callbackIndex = this._syncedAnimationFrameCallbacks.indexOf(callback);

                if (callbackIndex >= 0) {
                    this._syncedAnimationFrameCallbacks.splice(callbackIndex, 1);
                }
            };
        } else {
            return BrowserAnimationFrameScheduler.requestAnimationFrame(callback);
        }
    }
}
