import getVideoId from 'get-video-id';
import {Values} from "../common-types";
import {urlGetQueryString, urlParseQueryString} from "../url-utils/url-utils";


export const BaseVideoServices = {
    Youtube: 'Youtube',
    TikTok: 'TikTok',
    Vimeo: 'Vimeo',
} as const;

export type BaseVideoServices = Values<typeof BaseVideoServices>;


export const VideoServices = {
    Youtube: 'Youtube',
    TikTok: 'TikTok',
    Vimeo: 'Vimeo',
    YoutubePlaylist: 'YoutubePlaylist',
    YoutubeChannelVideos: 'YoutubeChannelVideos'
} as const;

export type VideoServices = Values<typeof VideoServices>;

interface VideoServiceVideoSourceBase<SERVICE extends VideoServices> {
    videoService: SERVICE;
    videoId: string;
}

export interface TikTokVideoSource extends VideoServiceVideoSourceBase<typeof VideoServices.TikTok> {
    user: string;
}

export interface YoutubeVideoSource extends VideoServiceVideoSourceBase<typeof VideoServices.Youtube> {}
export interface VimeoVideoSource extends VideoServiceVideoSourceBase<typeof VideoServices.Vimeo> {}
export interface YoutubePlaylistVideoSource extends VideoServiceVideoSourceBase<typeof VideoServices.YoutubePlaylist> {}
export interface YoutubeChannelLatestVideoSource extends VideoServiceVideoSourceBase<typeof VideoServices.YoutubeChannelVideos> {}

export type VideoServiceVideoSource =
    | YoutubeVideoSource
    | YoutubePlaylistVideoSource
    | YoutubeChannelLatestVideoSource
    | VimeoVideoSource
    | TikTokVideoSource;


export function videoServiceIsValidUrl (videoService: VideoServices, url: string) {
    const resolvedVideoSource = videoServicesResolveVideoSource(url);

    return resolvedVideoSource !== null && resolvedVideoSource.videoService === videoService;
}

export function videoServicesResolveVideoSource (url: string) : VideoServiceVideoSource | null {

    const tikTokVideoSource = videoServiceResolveTikTokVideoSource(url);
    if (tikTokVideoSource) {
        return tikTokVideoSource;
    }

    const youtubePlaylistSource = videoServiceResolveYoutubePlaylistVideoSource(url);
    if (youtubePlaylistSource) {
        return youtubePlaylistSource;
    }

    const videoInfo = getVideoId(url);

    if (videoInfo.id === null) {
        return null;
    } else {

        switch (videoInfo.service) {
            case 'youtube': {
                return {videoId: videoInfo.id, videoService: VideoServices.Youtube};
            }
            case 'vimeo': {
                return {videoId: videoInfo.id, videoService: VideoServices.Vimeo};
            }
            default: {
                return null;
            }

        }
    }
}

export function videoServiceResolveTikTokVideoSource (url: string) : TikTokVideoSource | null {

    const pattern = /(?:https?:)??(?:\/\/)??(?:w{3}\.)??tiktok\.com\/(@[\w.]+?)\/video\/(\d{19})/;

    const match = pattern.exec(url);
    if (match !== null) {
        return {videoId: match[2], user: match[1], videoService: VideoServices.TikTok}
    }

    return null;
}

export function videoServiceResolveYoutubePlaylistVideoSource(url: string) : YoutubePlaylistVideoSource | null {
    // Define a regular expression pattern to match YouTube playlist URLs
    const pattern = /^https:\/\/(www\.)?youtube\.com\/playlist\?.*list=([A-Za-z0-9_-]+)/;

    // Check if the URL matches the pattern
    if (pattern.test(url)) {
        const list = urlParseQueryString(urlGetQueryString(url))['list'];
        if (list) {
            return { videoId: list, videoService: VideoServices.YoutubePlaylist };
        }
    }

    // Return null if the URL is not a valid YouTube playlist
    return null;
}
