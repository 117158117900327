import { EventEmitter, EventEmitterBinding, EventEmitterListener, EventReference } from './event-emitter';

export interface IEventListener {
    activate(): this;

    deactivate(): this;
}

export class EventListener<EVENT_TYPES, EVENT_NAME extends keyof EVENT_TYPES> implements IEventListener {
    private eventEmitterBinding: EventEmitterBinding | undefined = undefined;
    private eventEmitter: EventEmitter<EVENT_TYPES>;
    private eventName: EVENT_NAME;
    private listener: EventEmitterListener<EVENT_TYPES[EVENT_NAME]>;

    constructor(
        eventReference: EventReference<EVENT_TYPES, EVENT_NAME>,
        listener: EventEmitterListener<EVENT_TYPES[EVENT_NAME]>
    );
    constructor(
        eventEmitter: EventEmitter<EVENT_TYPES>,
        eventName: EVENT_NAME,
        listener: EventEmitterListener<EVENT_TYPES[EVENT_NAME]>
    );
    constructor(...args: any) {
        if (args.length === 3) {
            this.eventEmitter = args[0];
            this.eventName = args[1];
            this.listener = args[2];
        } else {
            const [eventEmitter, eventName] = args[0] as EventReference<EVENT_TYPES, EVENT_NAME>;
            const listener = args[1];

            this.eventEmitter = eventEmitter;
            this.eventName = eventName;
            this.listener = listener;
        }
    }

    isActive() {
        return this.eventEmitterBinding !== undefined;
    }

    activate() {
        if (this.eventEmitterBinding === undefined) {
            this.eventEmitterBinding = this.eventEmitter.on(this.eventName, this.listener);
        }

        return this;
    }

    deactivate() {
        const eventEmitterBinding = this.eventEmitterBinding;
        if (eventEmitterBinding !== undefined) {
            eventEmitterBinding.dispose();
            this.eventEmitterBinding = undefined;
        }

        return this;
    }
}

export class DynamicEventListener<T, EVENT_TYPES, EVENT_NAME extends keyof EVENT_TYPES> implements IEventListener {
    private target: T | undefined = undefined;
    private eventListener: EventListener<EVENT_TYPES, EVENT_NAME> | undefined = undefined;
    private isActive = false;

    constructor(
        private eventReferenceSelector: (target: T) => EventReference<EVENT_TYPES, EVENT_NAME>,
        private listener: EventEmitterListener<EVENT_TYPES[EVENT_NAME]>
    ) {}

    setTarget(target: T | undefined): this {
        const prevTarget = this.target;
        if (prevTarget !== target) {
            this.target = target;

            const prevEventListener = this.eventListener;
            if (prevEventListener !== undefined) {
                prevEventListener.deactivate();
            }

            if (target === undefined) {
                this.eventListener = undefined;
            } else {
                const [eventEmitter, eventName] = this.eventReferenceSelector(target);

                const eventListener = (this.eventListener = new EventListener(eventEmitter, eventName, this.listener));

                if (this.isActive) {
                    eventListener.activate();
                }
            }
        }

        return this;
    }

    activate() {
        if (!this.isActive) {
            this.isActive = true;

            const eventListener = this.eventListener;
            if (eventListener) {
                eventListener.activate();
            }
        }

        return this;
    }

    deactivate() {
        if (this.isActive) {
            this.isActive = false;

            const eventListener = this.eventListener;
            if (eventListener) {
                eventListener.deactivate();
            }
        }

        return this;
    }
}

export class EventListenersGroup implements IEventListener {
    private eventListeners: IEventListener[] = [];

    add(eventListener: IEventListener) {
        this.eventListeners.push(eventListener);
    }

    activate() {
        for (const eventListener of this.eventListeners) {
            eventListener.activate();
        }

        return this;
    }

    deactivate() {
        for (const eventListener of this.eventListeners) {
            eventListener.deactivate();
        }

        return this;
    }
}
