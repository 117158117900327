import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import {doppeActionTypeDevContent} from "./doppe-action-type-dev-content";
import React from 'react';

export const doppeActionTypeDevContentViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeDevContent, {
    loadViewerComponents: async () => {
        return {
            DoppeActionTypeDevContentWidget: (await import('./doppe-action-type-dev-content-widget/doppe-action-type-dev-content-widget')).DoppeActionTypeDevContentWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeDevContentWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeDevContentWidget
                {...renderProps}
            />
        );

    },

})