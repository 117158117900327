import React, {useContext} from 'react';
import {iterableMapToArray} from "@wix/devzai-utils-common";

export interface IInnerWindowController {
    closeWindow: (data?: any, event?: Event) => any;

    /**
     * Used to closed the window immediately without animations and closing confirmation.
     */
    closeWindowImmediately: (event?: Event) => void;
}

export interface IInnerWindowClosingController {
    handleWindowClosing (closeCallback: () => void) : void;
}

export const InnerWindowContext = React.createContext<IInnerWindowController | null>(null);

export function useInnerWindowController () {
    return useContext(InnerWindowContext);
}


export async function innerWindowClosingControllerWaitForClosingComplete (
    ...innerWindowClosingControllerRef: React.RefObject<IInnerWindowClosingController>[]
) {
    const windowClosingControllersArr = iterableMapToArray(innerWindowClosingControllerRef, (innerWindowClosingControllerRef, skip) => {
        return innerWindowClosingControllerRef.current ?? skip
    })

    if (windowClosingControllersArr.length > 0) {
        try {
            await Promise.all(windowClosingControllersArr.map(windowClosingController => {
                return new Promise((successCallback) => {
                    windowClosingController.handleWindowClosing(() => {
                        successCallback(undefined)
                    })
                });
            }))
        } catch (error) {
            console.warn(`Error while closing a window controller`, error);
        }
    }
}
