import {IArea, IdGenerator, numberNormalizeToRange, objectRemoveEntriesWithValue, Size} from '@wix/devzai-utils-common';
import {DynamicStyleSheet} from "../dynamic-style-sheet/dynamic-style-sheet";
import {DomEventListener} from "../dom-event-listener/dom-event-listener";
import {domNodeDetach, domNodesListToArray} from "../dom-node-utils/dom-node-utils";

export function isDomElement (value: any) : value is Element {

    if (value instanceof Element) {
        return true;
    }

    const elementCtor = (value as Element)?.ownerDocument?.defaultView?.Element;

    return elementCtor !== undefined && value instanceof elementCtor;
}

export function elementIsRtl (element: Element) {
    return getComputedStyle(element).direction === 'rtl';
}

export function elementHasTextChildNodes (element: Element) {
    for (let i = 0; i < element.childNodes.length; i++) {
        if (element.childNodes[i].nodeType === Node.TEXT_NODE) {
            return true;
        }
    }
    return false;
}

export function elementGetChildTextNodes (element: Element) {
    return domNodesListToArray(element.childNodes).filter(node => node.nodeType === Node.TEXT_NODE) as Text[];
}

export function elementAnimateWithClassAnimation (element: Element, className: string) {
    element.classList.add(className);
    DomEventListener.one(element, 'animationend', () => {
        element.classList.remove(className);
    })
}

export function elementGetOwnerWindow (element: Element) {
    return element.ownerDocument.defaultView ?? window;
}

export function elementDetach (element: Element) {
    domNodeDetach(element);
}

export function elementGetRootViewport (element: Element) {

    const ownerWindow = elementGetOwnerWindow(element);

    return {
        top: 0,
        left: 0,
        width: ownerWindow.innerWidth,
        height: ownerWindow.innerHeight
    }
}

export function elementScrollIntoView (
    element: Element,
    arg: boolean | ScrollIntoViewOptions,
    options: {
        viewportShrinking?: {
            top?: number;
            bottom?: number;
            left?: number;
            right?: number;
        }
    } = {}
) {
    const {
        viewportShrinking
    } = options;

    if (viewportShrinking) {
        const dynamicClassName = IdGenerator.uniqueId();

        const dynamicCssRule = DynamicStyleSheet.Default.addRule(`.${dynamicClassName}`)
            .setCssProps(objectRemoveEntriesWithValue({
                'scroll-margin-bottom': viewportShrinking.bottom ? `${viewportShrinking.bottom}px` : undefined,
                'scroll-margin-top': viewportShrinking.top ? `${viewportShrinking.top}px` : undefined,
                'scroll-margin-left': viewportShrinking.left ? `${viewportShrinking.left}px` : undefined,
                'scroll-margin-right': viewportShrinking.left ? `${viewportShrinking.right}px` : undefined,
            }, undefined))

        element.classList.add(dynamicClassName);

        setTimeout(() => {
            element.scrollIntoView(arg);

            element.classList.remove(dynamicClassName);
            dynamicCssRule.dispose();
        }, 10)
    } else {
        element.scrollIntoView(arg);
    }

}

export function elementGetIndexInParent (element: Element) : number {
    return Array.prototype.indexOf.call(element.parentNode?.children ?? [], element);
}

export function elementGetChildAtIndex<ELEMENT extends Element> (element: Element, index: number) : ELEMENT | null {
    return element.children.item(index) as ELEMENT | null
}

export function elementGetAllSiblings (element: Element) : Element[] {

    const parentNode = element.parentNode;
    if (!parentNode) {
        return [];
    }

    return Array.from(parentNode.children).filter(child => child !== element);
}

export function elementGetSibling<ELEMENT extends Element> (element: Element, relativeIndex: number) : ELEMENT | null {

    let cursor: Element | null = element;

    if (relativeIndex >= 0) {

        for (let i = 0; i < relativeIndex; i++) {
            cursor = cursor?.nextElementSibling ?? null;

            if (cursor === null) {
                return null;
            }
        }
    } else {
        for (let i = 0; i < Math.abs(relativeIndex); i++) {
            cursor = cursor?.previousElementSibling ?? null;

            if (cursor === null) {
                return null;
            }
        }
    }

    return cursor as ELEMENT;
}

export function elementQuerySelector (element: Element, selector: string, includeSelf = false) {
    if (includeSelf && element.matches(selector)) {
        return element;
    }

    return element.querySelector(selector);
}

export function elementFindAllAncestors (
    element: Element,
    selector: string | Element[] | ((element: Element) => boolean),
    includeSelf = true
) : Element[] {

    const result: Element[] = [];
    let currentElement: Element | null = element;

    if (!includeSelf) {
        currentElement = currentElement.parentElement;
    }

    while (currentElement) {

        if (typeof selector === 'string') {
            if (currentElement.matches(selector)) {
                result.push(currentElement);
            }
        } else if (typeof selector === 'function') {
            if (selector(currentElement)) {
                result.push(currentElement);
            }
        } else {
            if (selector.includes(currentElement)) {
                result.push(currentElement);
            }
        }

        currentElement = currentElement.parentElement;
    }

    return result;
}

export function elementClosest(
    element: Element,
    selector: string | Element | ((element: Element) => boolean),
    includeSelf = true
): Element | null {
    let currentElement: Element | null = element;

    if (!includeSelf) {
        currentElement = currentElement.parentElement;
    }

    if (typeof selector === 'string') {
        if (currentElement) {
            return currentElement.closest(selector);
        } else {
            return null;
        }
    } else if (typeof selector === 'object') {
        while (currentElement && selector !== currentElement) {
            currentElement = currentElement.parentElement;
        }

        return currentElement;
    } else {
        while (currentElement && !selector(currentElement)) {
            currentElement = currentElement.parentElement;
        }

        return currentElement;
    }
}

export function elementResolveScaleFactor (element: Element) {

    const offsetWidth = (element as HTMLElement).offsetWidth;
    if (offsetWidth === undefined) {
        return 1;
    }

    return element.getBoundingClientRect().width / offsetWidth;
}

export function elementGetClientOuterArea(element: Element | Window): IArea {

    if (elementOrWindowIsWindow(element)) {
        return {
            top: 0,
            left: 0,
            width: element.innerWidth,
            height: element.innerHeight
        }
    } else {
        return element.getBoundingClientRect();
    }
}

export function elementGetOuterArea(element: Element): IArea {
    const rect = element.getBoundingClientRect();
    const ownerWindow = elementGetOwnerWindow(element);

    const scrollLeft = ownerWindow.scrollX || ownerWindow.document.documentElement.scrollLeft;
    const scrollTop = ownerWindow.scrollY || ownerWindow.document.documentElement.scrollTop;

    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft,
        width: rect.width,
        height: rect.height
    }
}

export function elementGetOuterAreaRelativeToScrollParent(element: Element): IArea {
    const scrollParent = elementFindScrollParent(element);
    if (!scrollParent || scrollParent === element.ownerDocument.documentElement) {
        return elementGetOuterArea(element);
    } else {
        const elementClientArea = element.getBoundingClientRect();
        const scrollParentClientArea = scrollParent.getBoundingClientRect();

        const scrollLeft = scrollParent.scrollLeft;
        const scrollTop = scrollParent.scrollTop;

        return {
            top: elementClientArea.top - scrollParentClientArea.top + scrollTop,
            left: elementClientArea.left - scrollParentClientArea.left + scrollLeft,
            width: elementClientArea.width,
            height: elementClientArea.height
        }
    }

}

export function elementGetOuterAreaRelativeToOffsetParent (element: Element) {
    const offsetParent = (element as HTMLElement).offsetParent ?? null;

    if (offsetParent) {
        return elementGetOuterAreaRelativeToAnotherElement(element, offsetParent);
    } else {
        return elementGetOuterArea(element);
    }
}

export function elementGetOuterAreaRelativeToAnotherElement (element: Element, anchorElement: Element) {

    if (anchorElement === element.ownerDocument.documentElement) {
        return elementGetOuterArea(element);
    } else {
        const elementClientArea = element.getBoundingClientRect();
        const scrollParentClientArea = anchorElement.getBoundingClientRect();

        const scrollLeft = anchorElement.scrollLeft;
        const scrollTop = anchorElement.scrollTop;

        return {
            top: elementClientArea.top - scrollParentClientArea.top + scrollTop,
            left: elementClientArea.left - scrollParentClientArea.left + scrollLeft,
            width: elementClientArea.width,
            height: elementClientArea.height
        }
    }
}

export function elementGetClientOuterAreaRelativeToAnotherElement (element: HTMLElement, anchorElement: HTMLElement) {

    const elementClientArea = element.getBoundingClientRect();
    const offsetParentClientArea = anchorElement.getBoundingClientRect();

    return {
        top: elementClientArea.top - offsetParentClientArea.top,
        left: elementClientArea.left - offsetParentClientArea.left,
        width: elementClientArea.width,
        height: elementClientArea.height
    }
}

export function elementGetOuterSize(element: HTMLElement) : Size {
    return {
        width: element.offsetWidth,
        height: element.offsetHeight
    };
}

export function elementGetOuterWidth(element: HTMLElement) : number {
    return element.offsetWidth
}

function canOverflow(overflow: string | null, skipOverflowHiddenElements?: boolean) {
    if (skipOverflowHiddenElements && overflow === 'hidden') {
        return false;
    }

    return overflow !== 'visible' && overflow !== 'clip';
}

export function elementFindViewportRoot(element: Element): Element | null {
    return elementClosest(
        element,
        element => {
            const style = getComputedStyle(element, null);
            return canOverflow(style.overflowY, false) || canOverflow(style.overflowX, false);
        },
        false
    );
}

export function elementFindScrollParent(element: Element): Element {
    return elementFindViewportRoot(element) ?? element.ownerDocument.documentElement;
}

export function elementFindAllScrollableAncestors (element: Element) : Element[] {
    let currentElement: Element | null = element;

    const result: Element[] = [];

    while (currentElement) {

        const style = getComputedStyle(currentElement, null);
        if (canOverflow(style.overflowY, false) || canOverflow(style.overflowX, false)) {
            result.push(currentElement);
        }

        currentElement = currentElement.parentElement;
    }

    return result;
}

export function elementFindVerticalScrollParent(element: Element): Element {
    return elementClosest(
        element,
        element => {
            const style = getComputedStyle(element, null);
            return canOverflow(style.overflowY, false);
        },
        false
    ) ?? element.ownerDocument.documentElement;
}

export function elementFindHorizontalScrollParent(element: Element): Element {
    return elementClosest(
        element,
        element => {
            const style = getComputedStyle(element, null);
            return canOverflow(style.overflowX, false);
        },
        false
    ) ?? element.ownerDocument.documentElement;
}

export function elementGetMaxScrollLeft (element: Element | Window) {
    if (elementOrWindowIsWindow(element)) {
        return element.document.documentElement.scrollWidth - element.innerWidth;
    } else {
        return element.scrollWidth - element.clientWidth;
    }
}

export function elementGetMaxScrollTop (element: Element | Window) {
    if (elementOrWindowIsWindow(element)) {
        return element.document.documentElement.scrollHeight - element.innerHeight;
    } else {
        return element.scrollHeight - element.clientHeight;
    }
}

export function elementHtmlCollectionToArray<T extends Element> (htmlCollection: HTMLCollection) : T[] {
    const result: T[] = [];
    for (let i = 0; i < htmlCollection.length; i++) {
        result.push(htmlCollection[i] as T);
    }
    return result;
}

export function elementSelectTextRange (field: HTMLInputElement | HTMLTextAreaElement, start: number, end?: number) {
    if((field as HTMLInputElement).setSelectionRange !== undefined)
    {
        const textInput = field as HTMLInputElement;

        textInput.setSelectionRange(start, end !== undefined ? end : textInput.value.length);
    }
    else if((field as HTMLTextAreaElement).selectionStart)
    {
        const textArea = field as HTMLTextAreaElement;

        textArea.selectionStart = start;
        textArea.selectionEnd = end !== undefined ? end : textArea.value.length;
    }
}

export function elementGetTextSelectionRange (field: HTMLInputElement | HTMLTextAreaElement) {
    return field.selectionStart !== null ?
        {start: field.selectionStart, end: field.selectionEnd ?? field.selectionStart} :
        null
}

export function elementWrapContent (domElement: Element, tagName: string): HTMLElement {
    // Create a new element with the specified tag name
    const wrapperElement = document.createElement(tagName);

    // Move all children of the original element to the new wrapper element
    while (domElement.firstChild) {
        wrapperElement.appendChild(domElement.firstChild);
    }

    // Append the wrapper element back to the original element
    domElement.appendChild(wrapperElement);

    // Return the wrapper element
    return wrapperElement;
}

export type ScrollAnimationController = {
    stopAnimation: () => void;
}

export type ScrollAnimationOptions = {
    startDelayMilliseconds: number;
    pixelsPerSecond: number;
    endDelayMilliseconds: number;
    loop?: boolean;
    scrollToTopOnEnd?: boolean;
    scrollToTopBehaviour?: ScrollBehavior;
}

export function elementAnimateScroll(
    element: Element | Window,
    options: ScrollAnimationOptions
) : ScrollAnimationController {

    const {
        startDelayMilliseconds,
        pixelsPerSecond,
        endDelayMilliseconds,
        loop = false,
        scrollToTopOnEnd = false,
        scrollToTopBehaviour = 'smooth'
    } = options;

    let isActive = true;

    const scrollToEnd = async (): Promise<void> => {
        const maxScrollTop = elementGetMaxScrollTop(element)

        const startTime = performance.now();
        let currentTime: number;

        const animate = () => {
            if (!isActive) {
                return;
            }

            currentTime = performance.now();
            const elapsedTime = currentTime - startTime;
            const scrollTop = Math.min((elapsedTime * pixelsPerSecond) / 1000, maxScrollTop);
            element.scrollTo(0, scrollTop);

            if (scrollTop < maxScrollTop) {
                requestAnimationFrame(animate);
            } else {
                setTimeout(() => {
                    if (isActive) {
                        resetScroll()
                    }
                }, endDelayMilliseconds);
            }
        };

        requestAnimationFrame(animate);
    };

    const resetScroll = (): void => {

        if (loop || scrollToTopOnEnd) {
            element.scrollTo({
                top: 0,
                left: 0,
                behavior: scrollToTopBehaviour
            });
        }

        if (loop) {
            setTimeout(() => {
                if (isActive) {
                    void scrollToEnd()
                }
            }, startDelayMilliseconds);
        } else {
            isActive = false
        }
    };

    setTimeout(() => {
        if (isActive) {
            void scrollToEnd();
        }
    }, startDelayMilliseconds);

    return {
        stopAnimation: () => {
            isActive = false;

            element.scrollTo({
                top: 0,
                left: 0,
                behavior: scrollToTopBehaviour
            });
        }
    }
}

function elementOrWindowIsWindow (element: Element | Window) : element is Window{
    return ((element as Window).innerWidth !== undefined);
}

/**
 * Returns the element, which scroll event can be listened to, in order to track scrolling of the provided element.
 */
export function elementResolveScrollEventListeningTarget (element: Element | Window) {
    if (elementOrWindowIsWindow(element)) {
        return element;
    } else if (element.ownerDocument.documentElement === element) {
        return elementGetOwnerWindow(element);
    } else {
        return element;
    }
}

export function elementResolveScrollValuesSource (element: Element | Window) {
    return elementOrWindowIsWindow(element) ? element.document.documentElement : element;
}

export function elementScrollTo (
    element: Element | Window,
    options: {
        top?: number;
        left?: number;
        behavior?: ScrollBehavior | 'instant';
        completionPixelsPrecision?: number
    }
) : Promise<void> {

    const {
        behavior = 'instant',
        completionPixelsPrecision = 5
    } = options;

    const top = options.top !== undefined ?
        numberNormalizeToRange(options.top, 0, elementGetMaxScrollTop(element)) : undefined;
    const left = options.left !== undefined ?
        numberNormalizeToRange(options.left, 0, elementGetMaxScrollLeft(element)) : undefined;

    return new Promise((resolve) => {

        const scrollValuesSourceElement = elementResolveScrollValuesSource(element);

        const reachedTargetScrollValues = () => {
            return !(
                (top !== undefined && Math.abs(scrollValuesSourceElement.scrollTop - top) > completionPixelsPrecision) ||
                (left !== undefined && Math.abs(scrollValuesSourceElement.scrollLeft - left) > completionPixelsPrecision)
            )
        }

        if (reachedTargetScrollValues()) {
            resolve();
        } else {

            if (behavior === 'instant') {
                element.scrollTo({
                    top: top,
                    left: left
                });

                resolve();
            } else {
                element.scrollTo({ top: top, left: left, behavior: behavior });

                const scrollEventListeningTarget = elementResolveScrollEventListeningTarget(element);

                const onScroll = () => {
                    if (reachedTargetScrollValues()) {
                        scrollEventListeningTarget.removeEventListener('scroll', onScroll);
                        resolve();
                    }
                };

                scrollEventListeningTarget.addEventListener('scroll', onScroll);
            }
        }
    });
}

export function elementVerticallyScrollToInScrollParent (
    element: Element,
    options: {
        marginTop?: number;
        scrollBehaviour?: ScrollBehavior | 'instant';
        completionPixelsPrecision?: number
    } = {}
): Promise<void> {

    const {
        marginTop = 0,
        scrollBehaviour = 'smooth',
        completionPixelsPrecision
    } = options;

    const scrollParent = elementFindVerticalScrollParent(element);

    return elementScrollTo(scrollParent, {
        top: elementGetOuterAreaRelativeToAnotherElement(element, scrollParent).top - marginTop,
        behavior: scrollBehaviour,
        completionPixelsPrecision: completionPixelsPrecision
    })
}

export function elementHorizontallyScrollToInScrollParent (
    element: Element,
    options: {
        marginLeft?: number;
        scrollBehaviour?: ScrollBehavior | 'instant';
        completionPixelsPrecision?: number
    } = {}
): Promise<void> {

    const {
        marginLeft = 0,
        scrollBehaviour = 'smooth',
        completionPixelsPrecision
    } = options;

    const scrollParent = elementFindHorizontalScrollParent(element);

    return elementScrollTo(scrollParent, {
        left: elementGetOuterAreaRelativeToAnotherElement(element, scrollParent).left - marginLeft,
        behavior: scrollBehaviour,
        completionPixelsPrecision: completionPixelsPrecision
    })
}

export function elementStartsInsideVerticalScrollParentViewport (element: Element) {
    const scrollParent = elementFindVerticalScrollParent(element);
    const scrollParentSize = elementGetOuterSize(scrollParent as HTMLElement);
    const elementOuterArea = elementGetOuterArea(element);

    return elementOuterArea.top >= scrollParent.scrollTop && elementOuterArea.top <= scrollParent.scrollTop + scrollParentSize.height;

}