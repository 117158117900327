import {Evaluable, IEventListener} from '@wix/devzai-utils-common';
const noop = () => undefined;

export namespace DomEventListener {

    export type EventListeningTarget = Element | Document | Window;

    export interface EventListenerOptions {
        capture?: boolean;
        passive?: boolean;
    }
}

export class DomEventListener<T extends Event = Event> implements IEventListener {
    private static _supportsPassive?: boolean;

    public static supportsPassive(): boolean {
        let supportsPassive = DomEventListener._supportsPassive;

        if (supportsPassive === undefined) {
            supportsPassive = false;
            try {
                document.addEventListener('test', noop, {
                    get passive() {
                        supportsPassive = true;
                        return true;
                    }
                });
            } finally {
                document.removeEventListener('test', noop);
            }

            DomEventListener._supportsPassive = supportsPassive;
        }

        return supportsPassive;
    }

    private capture: boolean;
    private passive: boolean;
    private eventListener;
    private currentTargetElement?: DomEventListener.EventListeningTarget = undefined;

    constructor(
        private target: Evaluable<(contextArg?: any) => DomEventListener.EventListeningTarget>,
        private type: string,
        eventListener: (evt: T, domEventListener: DomEventListener) => void,
        options: DomEventListener.EventListenerOptions = {}
    ) {
        this.capture = options.capture ?? false;
        this.passive = options.passive ?? false;
        this.eventListener = (evt: Event) => {
            eventListener(evt as T, this);
        };
    }

    activate(contextArg?: any) {
        this.deactivate();

        const target = this.target;

        const targetElement = (this.currentTargetElement = typeof target === 'function' ? target(contextArg) : target);

        if (DomEventListener.supportsPassive()) {
            targetElement.addEventListener(this.type, this.eventListener, {
                capture: this.capture,
                passive: this.passive
            });
        } else {
            targetElement.addEventListener(this.type, this.eventListener, this.capture);
        }

        return this;
    }

    deactivate() {
        const currentTargetElement = this.currentTargetElement;
        if (currentTargetElement) {
            currentTargetElement.removeEventListener(this.type, this.eventListener, this.capture);
            this.currentTargetElement = undefined;
        }

        return this;
    }

    static bind<T extends Event = Event> (
        target: Evaluable<(contextArg?: any) => DomEventListener.EventListeningTarget>,
        type: string,
        eventListener: (evt: T, domEventListener: DomEventListener) => void,
        options?: DomEventListener.EventListenerOptions
    ) {
        const listener = new DomEventListener(target, type, eventListener, options).activate();

        return {
            dispose () {
                listener.deactivate();
            }
        }
    }

    static one<T extends Event = Event> (
        target: Evaluable<(contextArg?: any) => DomEventListener.EventListeningTarget>,
        type: string,
        eventListener: (evt: T, domEventListener: DomEventListener) => void,
        options?: DomEventListener.EventListenerOptions
    ) {
        const binding = this.bind<T>(
            target,
            type,
            (evt, domEventListener) => {
                binding.dispose();
                eventListener(evt, domEventListener);
            },
            options);

        return binding;
    }
}

export function domEventOnceOneOfEventsEmitted (
    target: DomEventListener.EventListeningTarget,
    types: string[],
    callback: (evt: Event) => void,
    options?: DomEventListener.EventListenerOptions
) {
    const bindings = types.map(type => {
        return DomEventListener.bind(target, type, event => {
            for (const binding of bindings) {
                binding.dispose();
            }

            callback(event);
        }, options);
    });

    return {
        dispose () {
            for (const binding of bindings) {
                binding.dispose();
            }
        }
    }
}