import {IListViewCommon, ListViewItemId} from './list-view-types';
import {arrayFindIndex, arrayFindValue} from "@wix/devzai-utils-common";

export function getItemElementById(itemsList: IListViewCommon, itemId: ListViewItemId) {
    const itemIndex = itemsList.findItemIndex(itemId);

    return itemIndex >= 0 ? getItemElementByIndex(itemsList, itemIndex) : undefined;
}

export function getItemElementByIndex(itemsList: IListViewCommon, itemIndex: number) {
    const itemsElements = itemsList.getItemsNodeList();

    return itemsElements ? itemsElements.item(itemIndex) : undefined;
}

export function listViewGetFirstEnabledItemIndex (listView: IListViewCommon) {
    const disabledIds = new Set(listView.getListViewState().disabledIds);

    const index = arrayFindIndex(
        listView.getOrderedListViewItemsIds(),
        id => listView.getListViewItemById(id)?.isDisabled !== true && !disabledIds.has(id));

    return index < 0 ? undefined : index;
}

export function focusListView(listView: IListViewCommon) {
    const currentItemId = listView.getListViewState().currentNavigatableItemId;

    const currentItemIndex = currentItemId === null ?
        (listViewGetFirstEnabledItemIndex(listView) ?? 0) :
        listView.findItemIndex(currentItemId);

    if (currentItemIndex >= 0) {
        const itemElement = getItemElementByIndex(listView, currentItemIndex);

        if (itemElement) {
            itemElement.focus();
        }
    }
}

export function listViewGetCurrentItemId (listView: IListViewCommon) {
    return listView.getListViewState().currentNavigatableItemId;
}

export function listViewGetCurrentDataItem<T> (listView: IListViewCommon<T>) {
    const currentDataSourceItem = listViewGetCurrentDataSourceItem(listView);

    if (currentDataSourceItem !== undefined) {
        return currentDataSourceItem.dataItem;
    } else {
        return undefined;
    }
}

export function listViewGetCurrentDataSourceItem<T> (listView: IListViewCommon<T>) {
    const currentItemId = listViewGetCurrentItemId(listView);
    if (currentItemId !== null) {
        return listViewGetDataSourceItemById(listView, currentItemId);
    } else {
        return undefined;
    }
}

export function listViewGetDataSourceItemById<T> (listView: IListViewCommon<T>, itemId: ListViewItemId) {
    return arrayFindValue(listView.getListViewItemsArray(), dataSourceItem => dataSourceItem.id === itemId);
}