import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeYoutubeVideo, YoutubeEmbedType} from './doppe-action-type-youtube-video';

export const doppeActionTypeYoutubeVideoViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeYoutubeVideo, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeVideoWidget: (await import(
                './doppe-action-type-youtube-video-widget/doppe-action-type-youtube-video-widget'
            )).DoppeActionTypeYoutubeVideoWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeVideoWidget
        } = viewerComponents

        return (
            <DoppeActionTypeVideoWidget
                {...renderProps}
            />
        )
    },

    activateCTA: actionContext => {
        const url = actionContext.action.settings.url;
        const type = actionContext.action.settings.type;

        const normalizedVideoURL = type === YoutubeEmbedType.LatestVideo ? `https://www.youtube.com/channel/${url}` : url;
        actionContext.navigateToUrl(normalizedVideoURL, true);
    }
})