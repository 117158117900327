import {DoppeViewerAppClientServiceBase} from './doppe-viewer-app-client-service-base';
import {assertNotNullable, cachedValueCreate} from '@wix/devzai-utils-common';
import {WixAppRuntimeEnvironment} from '@wix/devzai-common-wix';

export class DoppeViewerAppStripeService extends DoppeViewerAppClientServiceBase {
    private readonly stripe = cachedValueCreate(async () => {
        const stripePublishableKey =
            this.clientServices.doppeUserAppData.appRuntimeEnvironment === WixAppRuntimeEnvironment.Production
                ? 'pk_live_51JrPOkHqoyvy8DrWPNOebaaPT8Qp80H9hlKxaJxnmHqoqPZtBqcQgPrzQo82Ug8IDVEhPvaXIlZyefCEW2W7LigG00J8JnwyLX'
                : 'pk_test_51JrPOkHqoyvy8DrWcXS4UW8hKDnuXVwu8WKGqLDLQtfgwG8WGfz7iXU1PKNF1laPIJFrHQ9ok0QegVqOohrhy9cs00Mj6IJx6b';
        const { stripeAccountId } = await this.clientServices.getApiClient().getStripeAccountId();

        const loadStripe = (await import('@stripe/stripe-js')).loadStripe;

        const stripe = await loadStripe(stripePublishableKey, {
            stripeAccount: stripeAccountId
        });

        return assertNotNullable(stripe);
    });

    public getStripe() {
        return this.stripe.getValue();
    }
}
