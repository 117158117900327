import {doppeActionTypeDefineViewerMetadata} from '../../../client-server-common/doppe-action-types/doppe-action-type';
import {doppeActionTypeCalendly} from '../doppe-action-type-calendly';
import React from 'react';

export const doppeActionTypeCalendlyViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeCalendly, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeCalendlyWidget: (await import('../doppe-action-type-calendly-widget/doppe-action-type-calendly-widget')).DoppeActionTypeCalendlyWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeCalendlyWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeCalendlyWidget
                {...renderProps}
            />
        );
    }
})